import { RACE_CONTRACT } from 'src/abis';
import { exponentialBackOff } from 'src/utils/exponentialBackoff';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import { ChainId } from 'src/constant/chainId';
import ContractService from './baseContractService';

export class RaceContractService extends ContractService {
  constructor() {
    super(ChainId.Polygon, RACE_CONTRACT.address, RACE_CONTRACT.abi as AbiItem[]);

    ContractService.add(this);
  }

  async getContractRaceChickenIds(raceId: number) {
    return this.contractCall(async () => {
      const getChickenIdsFunc = async () => this.contract.methods.getChickenIds(raceId).call();
      const { result: chickenIds } = await exponentialBackOff(getChickenIdsFunc);

      return chickenIds.map((chickenId: string) => Number(chickenId));
    });
  }

  async enterRace(
    race: any,
    chickenId: string,
    userWalletId: string,
    gasLimit: number,
    coupon: any,
  ) {
    return this.contractCall(async () => {
      const qty = Web3.utils.toWei(race.fee.toString(), 'ether');
      const functionSignature = this.contract.methods.enter(qty, race.id, Number(chickenId), race.maxCapacity, coupon).encodeABI();

      return this.signForwardTransaction(userWalletId, this.contractAddress, functionSignature, gasLimit);
    });
  }

  async getBawkRewards(userWalletId: string) {
    return this.contractCall(async () => {
      const getBawkRewardsFunc = async () => this.contract.methods.bawkRewards(userWalletId).call();
      const { result } = await exponentialBackOff(getBawkRewardsFunc);

      return result;
    });
  }
}
