import { ICoupon } from './common';

export interface IBawkStakingSeason {
  name: string;
  epoch: number;
  amounts?: number;
}

export enum BawkStakingType {
  Stake = 'stake',
  Unstake = 'unstake',
  ClaimJewel = 'claim-jewel',
  ClaimBawk = 'claim-bawk',
}

export enum BawkType {
  Unclaimed = 'unclaimed',
  Claimed = 'claimed',
}

export interface IBawkStakingRequest {
  epoch: number;
  bawkStakingCompanyId: number;
  amount?: number;
  type: BawkStakingType;
  bawkType?: BawkType;
  signature?: string;
  deadline?: number;
  gasLimit?: number;
  coupon?: ICoupon;
}

export interface IBawkStakingCheckResponse {
  gasLimit: number;
  coupon: ICoupon;
}
