import { createSelector } from 'reselect';
import { RootState } from '../common/reducer';

export const selectRaceState = (rootState: RootState) => rootState.race;

export const gameResultSelector = createSelector([selectRaceState], (raceState) => raceState.gameResults?.rows || []);

export const totalRaceCountSelector = createSelector([selectRaceState], (raceState) => raceState.gameResults?.count || 0);

export const totalEarningsSelector = createSelector([selectRaceState], (raceState) => raceState.gameResults?.totalEarnings || 0);

export const totalRacesSelector = createSelector([selectRaceState], (raceState) => raceState.gameResults?.totalRaces || 0);

export const isFetchingRaceSelector = createSelector([selectRaceState], (raceState) => raceState.isFetching);

export const scheduledRacesSelector = createSelector([selectRaceState], (raceState) => raceState.scheduledRaces?.rows || []);

export const totalScheduledRaceCountSelector = createSelector([selectRaceState], (raceState) => raceState.scheduledRaces?.count || 0);

export const activeRacesSelector = createSelector([selectRaceState], (raceState) => raceState.activeRaces?.rows || []);

export const totalActiveRaceCountSelector = createSelector([selectRaceState], (raceState) => raceState.activeRaces?.count || 0);

export const isActiveRacesReachedSelector = createSelector([selectRaceState], (raceState) => raceState.activeRaces?.isReached || false);

export const incomingRacesSelector = createSelector([selectRaceState], (raceState) => raceState.incomingRaces?.rows || []);

export const totalIncomingRaceCountSelector = createSelector([selectRaceState], (raceState) => raceState.incomingRaces?.count || 0);

export const isIncomingRacesReachedSelector = createSelector([selectRaceState], (raceState) => raceState.incomingRaces?.isReached || false);

export const chickenRacesSelector = createSelector([selectRaceState], (raceState) => raceState.chickenRaces?.rows || []);

export const totalChickenRaceCountSelector = createSelector([selectRaceState], (raceState) => raceState.chickenRaces?.count || 0);

export const isChickenRacesReachedSelector = createSelector([selectRaceState], (raceState) => raceState.chickenRaces?.isReached || false);

export const isDeployingSelector = createSelector([selectRaceState], (raceState) => raceState.isDeploying);

export const raceSelector = createSelector([selectRaceState], (raceState) => raceState.race);

export const raceChickensSelector = createSelector([selectRaceState], (raceState) => raceState.chickens?.rows || []);

export const raceChickensCountSelector = createSelector([selectRaceState], (raceState) => raceState.chickens?.count || 0);

export const raceChickensReachedSelector = createSelector([selectRaceState], (raceState) => raceState.chickens?.isReached || false);

export const raceChickensLoadingSelector = createSelector([selectRaceState], (raceState) => raceState.isFetchingChickens || false);

export const raceChickensOtherPeckingOrdersSelector = createSelector([selectRaceState], (raceState) => raceState.chickens?.otherPeckingOrders || []);
export const leaderboardSelector = createSelector([selectRaceState], (raceState) => raceState.leaderboard || {});

export const totalRaceSelector = createSelector([selectRaceState], (raceState) => {
  if (raceState.totalRace) {
    return raceState.totalRace;
  }

  return {
    totalRaces: 0,
    totalEarnings: 0,
  };
});
