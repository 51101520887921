import React, {
  useEffect, useRef, useState,
} from 'react';
import { Modal } from 'react-bootstrap';
import { IMAGES_URL } from 'src/constant/images';
import _ from 'lodash';
// import * as utils from 'src/utils/utils';
// import { downwardPoints, maxPoints, upwardPoints } from 'src/constant';
// import { getChickenName } from 'src/utils/chicken';
import { Formik, FormikProps } from 'formik';
import { useValidation } from 'src/contexts/validation';
import { TradeOffer } from 'src/models';
import { useMarketplaceContext } from 'src/contexts/marketplace';

export function OfferTrade({
  open,
  marketItem,
  tradeOffer,
  onClose,
  onOffer,
  chickens,
}: {
  chickens: any[];
  marketItem?: any,
  tradeOffer?: any,
  open: boolean;
  onClose: () => void;
  onOffer: (tradeOffer: TradeOffer) => void;
}) {
  const formikRef = useRef<FormikProps<any>>();
  const { tradeOfferSchema, tradeOfferWithMinSchema } = useValidation();
  const { onPurchaseMarketItem } = useMarketplaceContext();
  const [showConfirm, setShowConfirm] = useState(false);

  // const onToggleChicken = (chicken) => {
  //   const { tokensAttached } = formikRef.current.values;
  //   const index = tokensAttached.findIndex((chickenId) => chickenId === chicken.id);

  //   if (index > -1) {
  //     const items = [...tokensAttached];
  //     items.splice(index, 1);
  //     formikRef.current.setFieldValue('tokensAttached', items);
  //   } else {
  //     formikRef.current.setFieldValue('tokensAttached', [
  //       ...tokensAttached,
  //       chicken.id,
  //     ]);
  //   }
  // };

  const onChangePrice = (e) => {
    const price = e.target.value ? Number(e.target.value) : 0;

    const decimalLength = price.toString().split('.')[1]?.length || 0;

    if (decimalLength > 6) {
      return;
    }

    formikRef.current.setFieldValue('price', e.target.value);
  };

  const onSubmit = (values) => {
    if (!marketItem && !tradeOffer) {
      onClose();
    }
    if (marketItem.price && Number(values.price) > marketItem.price) {
      setShowConfirm(true);
      return;
    }

    onOfferHandler(values);
  };

  const onOfferHandler = (values) => {
    if (tradeOffer) {
      onOffer({
        tradeOfferId: tradeOffer.tradeOfferId,
        marketItemId: tradeOffer.marketItemId,
        nftContract: tradeOffer.nftContract,
        price: Number(values.price),
        tokensAttached: values.tokensAttached || [],
      });

      return;
    }

    onOffer({
      marketItemId: marketItem.id,
      nftContract: marketItem.nftContract,
      price: Number(values.price),
      tokensAttached: values.tokensAttached || [],
    });
  };

  const onBuy = () => {
    if (!marketItem) {
      return;
    }

    onPurchaseMarketItem({
      marketItemId: marketItem.id,
      nftContract: marketItem.nftContract,
      tokenId: marketItem.tokenId,
      price: marketItem.price,
      usdPrice: marketItem.usdPrice,
      minimum: marketItem.minimum,
      tradeType: marketItem.tradeType,
    });

    onClose();
  };

  useEffect(() => {
    if (open && tradeOffer) {
      formikRef.current.setValues({
        price: tradeOffer.price,
        minimum: 0,
        tokensAttached: tradeOffer.tokensAttached,
      });
    }
  }, [open, tradeOffer]);

  useEffect(() => {
    if (open && marketItem) {
      formikRef.current.setFieldValue('minimum', marketItem.minimum);
    }
  }, [marketItem, open]);

  useEffect(() => {
    if (open) {
      setShowConfirm(false);
    }
  }, [open]);

  return (
    <>
      <Modal
        centered
        dialogClassName="select-chicken offer-trade"
        show={open}
        onHide={() => onClose()}
      >
        <Modal.Header>
          <Modal.Title className="ml-auto">
            {tradeOffer ? 'Edit an Offer' : 'Make an Offer'}
          </Modal.Title>
          <button type="button" className="close" onClick={() => onClose()}>
            <span aria-hidden="true">
              <img src={IMAGES_URL.CLOSEICON} alt="" />
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            innerRef={formikRef}
            initialValues={{
              price: '',
              minimum: marketItem?.minimum || 0,
              tokensAttached: [],
            }}
            validationSchema={marketItem?.minimum ? tradeOfferWithMinSchema : tradeOfferSchema}
            onSubmit={onSubmit}
          >
            {({
              touched, setFieldValue, values, errors, isValid,
            }) => {
              const disabled = !_.isEmpty(touched) && !isValid;

              if (showConfirm) {
                return (
                  <div className="row">
                    <div className="col-12 text-center">
                      {`Your offer of ${values.price} WETH is higher than listed price of ${marketItem.price} WETH`}
                      <br />
                      Are you sure you want to make this offer?
                    </div>
                    <div className="col-12 flex items-center justify-center mt-3">
                      <a className="btn btn-offer" onClick={() => onOfferHandler(values)}>Yes</a>
                      <a className="btn btn-buy" onClick={() => onBuy()}>
                        Buy
                        <br />
                        {marketItem.price}
                      </a>
                      <a className="btn btn-cancel" onClick={() => setShowConfirm(false)}>No</a>
                    </div>
                  </div>
                );
              }

              return (
                <>
                  <div className="row">
                    <div className="col-12">
                      {/* {chickens.filter((c) => values.tokensAttached.includes(c.id)).map((c) => (
                          <div className="offer-chicken flex items-center" key={c.id}>
                            <ChickenImg src={c.image} size={45} />
                            <div
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: getChickenName(c, true),
                              }}
                            />
                          </div>
                        ))} */}
                      {marketItem && (
                        <div className="flex items-center justify-around">
                          {marketItem.price > 0 && (
                            <div className="flex items-center">
                              <span>Current Price:</span>
                              <img src={IMAGES_URL.WETH} alt="" className="ml-1 mr-1 weth-icon" />
                              <span>{marketItem.price}</span>
                            </div>
                          )}
                          {marketItem.minimum > 0 && (
                            <div className="flex items-center justify-end">
                              <span>Minimum:</span>
                              <img src={IMAGES_URL.WETH} alt="" className="ml-1 mr-1 weth-icon" />
                              <span>{marketItem.minimum}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col-12 flex items-center justify-center mt-4">
                      <div className="offer-price">
                        <div id="input_container">
                          <input
                            value={values.price}
                            onChange={onChangePrice}
                            type="number"
                            placeholder="0.00"
                            className="form-control"
                            id="input"
                          />
                          <img src={IMAGES_URL.WETH} id="input_img" alt="" />
                        </div>
                        {errors.price && touched.price && <div className="error-msg">{typeof errors.price === 'string' ? errors.price : errors.price[0]}</div>}
                      </div>
                    </div>

                    <div className="col-12 flex items-center justify-center mt-3">
                      <a className={`btn btn-offer ${disabled ? 'disabled' : ''}`} onClick={() => !disabled && formikRef.current.submitForm()}>{tradeOffer ? 'Edit Offer' : 'Offer'}</a>
                    </div>
                  </div>
                  {/* <div className="table-responsive">
                      <table className="table order-table">
                        <tbody>
                          <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Heritage</th>
                            <th>Perfection</th>
                            <th>POP</th>
                            <th>Races</th>
                            <th>History</th>
                            <th>Talent</th>
                            <th />
                          </tr>
                          {chickens.map((chicken) => (
                            <tr key={chicken.id}>
                              <td>
                                <ChickenImg src={chicken.image} size={45} />
                              </td>
                              <td
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: getChickenName(chicken, true),
                                }}
                              />
                              <td
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: utils.breakText(chicken.heritage, 6),
                                }}
                              />
                              <td>
                                {chicken.perfection}
                                %
                              </td>
                              <td className={downwardPoints.includes(chicken.poPoints) ? 'downward' : upwardPoints.includes(chicken.poPoints) ? 'upward' : chicken.poPoints >= maxPoints ? 'star' : ''}>
                                {chicken.poPoints}
                              </td>
                              <td>
                                <div className="flex items-center">
                                  {chicken.races}
                                  {chicken.chickRaces < 7 && (
                                  <img
                                    className="chick-icon-small ml-2"
                                    src={IMAGES_URL.ICONS.CHICK_ICON_1}
                                    alt="CHICK"
                                  />
                                  )}
                                </div>
                              </td>
                              <td>
                                {chicken.firsts}
                                /
                                {chicken.seconds}
                                /
                                {chicken.thirds}
                              </td>
                              <td
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: utils.breakText(chicken.talent, 8),
                                }}
                              />
                              <td className="flex items-center justify-center">
                                <a
                                  onClick={() => onToggleChicken(chicken)}
                                  className="btn btnEnter"
                                >
                                  {values.tokensAttached.includes(chicken.id) ? 'Remove' : 'Add'}
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div> */}
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
