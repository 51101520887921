import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from 'src/components/loading';
import { OfferTrade } from 'src/modals/offerTrade';
import { MarketItem, MarketPlaceActionType, TradeOffer } from 'src/models';
import { ExchangeActions } from 'src/store/common/action';
import { checkMarketplaceAssignmentRequest, processMarketplaceActionRequest } from 'src/store/marketplace/reducer';
import { isCheckingMarketplaceAssignmentSelector, isProcessingMarketplaceSelector, marketplaceActionAssignmentSelector } from 'src/store/marketplace/selector';
import { isLoggedInSelector } from 'src/store/userWallet/selector';
import { useWalletContext } from './walletProvider';

interface MarketplaceContextProps {
  onCreateMarketItem: (value: MarketItem) => void;
  onEditMarketItem: (value: MarketItem) => void;
  onCancelMarketItem: (value: MarketItem) => void;
  onPurchaseMarketItem: (value: MarketItem) => void;
  onCreateTradeOffer: (value: TradeOffer) => void;
  onEditTradeOffer: (value: TradeOffer, marketItem: MarketItem) => void;
  onFulfillTradeOffer: (value: TradeOffer) => void;
  onCancelTradeOffer: (value: TradeOffer) => void;
}

export const MarketplaceContext = createContext<MarketplaceContextProps | undefined>(
  undefined,
);

export const useMarketplaceContext = () => {
  const context = useContext(MarketplaceContext);

  return context;
};

export default function MarketplaceProvider(props: {
  children: React.ReactNode;
}) {
  const dispatch = useDispatch();
  const { onLogin } = useWalletContext();
  const { t } = useTranslation('translation', { keyPrefix: 'CONTEXT.MARKETPLACE' });
  const isLoggedIn = useSelector(isLoggedInSelector);
  const isProcessing = useSelector(isProcessingMarketplaceSelector);
  const isChecking = useSelector(isCheckingMarketplaceAssignmentSelector);
  const action = useSelector(marketplaceActionAssignmentSelector);
  const [openOffer, setOpenOffer] = useState(false);
  const [tradeOffer, setTradeOffer] = useState<TradeOffer>(null);
  const [openMarketItem, setOpenMarketItem] = useState<MarketItem>(null);

  const actionText = useMemo(() => {
    switch (action) {
      case MarketPlaceActionType.CreateMarketItem:
        return t('CREATE_MARKET_ITEM');

      case MarketPlaceActionType.EditMarketItem:
        return t('EDIT_MARKET_ITEM');

      case MarketPlaceActionType.CancelMarketItem:
        return t('CANCEL_MARKET_ITEM');

      case MarketPlaceActionType.PurchaseMarketItem:
        return t('PURCHASE_MARKET_ITEM');

      case MarketPlaceActionType.MakeOffer:
        return t('CREATE_OFFER_ITEM');

      case MarketPlaceActionType.EditOffer:
        return t('EDIT_OFFER_ITEM');

      case MarketPlaceActionType.FulfillOffer:
        return t('ACCEPT_OFFER_ITEM');

      case MarketPlaceActionType.CancelOrDeclineOffer:
        return t('CANCEL_OFFER_ITEM');

      default:
        return '';
    }
  }, [t, action]);

  const isConfirm = useMemo(() => action === MarketPlaceActionType.PurchaseMarketItem || action === MarketPlaceActionType.FulfillOffer, [action]);

  const onCreateMarketItem = useCallback(async (marketItem: MarketItem) => {
    if (!isLoggedIn) {
      onLogin();
      return;
    }

    dispatch(processMarketplaceActionRequest({
      type: MarketPlaceActionType.CreateMarketItem,
      item: marketItem,
    }));
  }, [isLoggedIn, onLogin, dispatch]);

  const onEditMarketItem = useCallback(async (marketItem: MarketItem) => {
    if (!isLoggedIn) {
      onLogin();
      return;
    }

    dispatch(processMarketplaceActionRequest({
      type: MarketPlaceActionType.EditMarketItem,
      item: marketItem,
    }));
  }, [isLoggedIn, onLogin, dispatch]);

  const onCancelMarketItem = useCallback(async (marketItem: MarketItem) => {
    if (!isLoggedIn) {
      onLogin();
      return;
    }

    dispatch(processMarketplaceActionRequest({
      type: MarketPlaceActionType.CancelMarketItem,
      item: marketItem,
    }));
  }, [isLoggedIn, onLogin, dispatch]);

  const onPurchaseMarketItem = useCallback(async (value: MarketItem) => {
    if (!isLoggedIn) {
      onLogin();

      return;
    }

    dispatch(processMarketplaceActionRequest({
      type: MarketPlaceActionType.PurchaseMarketItem,
      item: value,
    }));
  }, [isLoggedIn, onLogin, dispatch]);

  const onCreateTradeOffer = useCallback(async (value: TradeOffer) => {
    if (!isLoggedIn) {
      onLogin();
      return;
    }

    dispatch(processMarketplaceActionRequest({
      type: MarketPlaceActionType.MakeOffer,
      item: value,
    }));
  }, [isLoggedIn, onLogin, dispatch]);

  const onEditTradeOffer = useCallback(async (value: TradeOffer, marketItem: MarketItem) => {
    if (!isLoggedIn) {
      onLogin();
      return;
    }

    setTradeOffer(value);
    setOpenMarketItem(marketItem);
    setOpenOffer(true);
  }, [isLoggedIn, onLogin]);

  const onFulfillTradeOffer = useCallback(async (value: TradeOffer) => {
    if (!isLoggedIn) {
      onLogin();
      return;
    }

    dispatch(processMarketplaceActionRequest({
      type: MarketPlaceActionType.FulfillOffer,
      item: value,
    }));
  }, [isLoggedIn, onLogin, dispatch]);

  const onCancelTradeOffer = useCallback(async (value: TradeOffer) => {
    if (!isLoggedIn) {
      onLogin();
      return;
    }
    dispatch(processMarketplaceActionRequest({
      type: MarketPlaceActionType.CancelOrDeclineOffer,
      item: value,
    }));
  }, [isLoggedIn, onLogin, dispatch]);

  const onUpdateOffer = (value: TradeOffer) => {
    dispatch(processMarketplaceActionRequest({
      type: MarketPlaceActionType.EditOffer,
      item: value,
    }));
    setOpenOffer(false);
  };

  useEffect(() => {
    dispatch(checkMarketplaceAssignmentRequest());
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    dispatch(ExchangeActions.getCoinRequest({}));
  }, [dispatch]);

  const value = useMemo(
    () => ({
      onCreateMarketItem,
      onEditMarketItem,
      onCancelMarketItem,
      onPurchaseMarketItem,
      onCreateTradeOffer,
      onEditTradeOffer,
      onFulfillTradeOffer,
      onCancelTradeOffer,
    }),
    [
      onCreateMarketItem,
      onEditMarketItem,
      onCancelMarketItem,
      onPurchaseMarketItem,
      onCreateTradeOffer,
      onEditTradeOffer,
      onFulfillTradeOffer,
      onCancelTradeOffer,
    ],
  );

  return (
    <MarketplaceContext.Provider
      value={value}
    >
      {props.children}
      <Modal
        centered
        dialogClassName="complete-transaction"
        show={isProcessing || isChecking}
        backdrop="static"
      >
        <Modal.Body>
          {isProcessing ? (
            <>
              <h3>
                <Trans t={t} i18nKey="COMPLETE_TRANSACTION_METAMASK">
                  Please complete your transaction for
                  <b>
                    t(
                    {actionText}
                    )
                  </b>
                  <br />
                  in MetaMask
                </Trans>
              </h3>
              <p>
                {isConfirm ? t('TO_COMPLETE_TRANSACTION_CONFIRM') : t('TO_COMPLETE_TRANSACTION_SIGN')}
              </p>
              <Loading size={150} />
              <h5>
                <Trans t={t} i18nKey="TIP_IF_METAMASk_DOES_NOT_APPEAR">
                  Tip: If MetaMask does not appear automatically,
                  <br />
                  click on the MetaMask icon in your browser notification toolbar
                </Trans>
              </h5>
            </>
          ) : (
            <>
              <h3>{t('TRANSACTION_CONFIRMED')}</h3>
              <p>{t('WE_ARE_WAITING', { action: actionText })}</p>
              <Loading size={150} />
              <h5>
                {t('TIP_THIS_CAN_TAKE_SOME_TIME')}
              </h5>
            </>
          )}
        </Modal.Body>
      </Modal>
      <OfferTrade
        open={openOffer}
        tradeOffer={tradeOffer}
        marketItem={openMarketItem}
        onClose={(() => setOpenOffer(false))}
        chickens={[]}
        onOffer={onUpdateOffer}
      />
    </MarketplaceContext.Provider>

  );
}
