import { exponentialBackOff } from 'src/utils/exponentialBackoff';
import { EventsEnum } from 'src/events';
import { JEWELS_CONTRACT } from 'src/abis/jewelsContract';
import { BICONOMY_JEWELS_APPROVE_API_ID } from 'src/utils/config';
import { ChainId } from 'src/constant/chainId';
import { ContractService, polygonUsdcContractService } from '.';
import { TokenContractService } from './tokenContractService';

export class JewelsContractService extends TokenContractService {
  private readonly biconomyApiId = BICONOMY_JEWELS_APPROVE_API_ID;

  private readonly name = JEWELS_CONTRACT.name;

  private readonly version = JEWELS_CONTRACT.version

  constructor() {
    super(ChainId.Polygon, JEWELS_CONTRACT);
    ContractService.add(this);
  }

  async calcUsdcAmount(amount: string) {
    return this.contractCall(async () => {
      const method = async () => this.contract.methods.calcUsdcAmount(amount).call();
      const { result } = await exponentialBackOff(method);

      return result;
    });
  }

  async mint(userWalletId: string, amount: string) {
    return this.contractCall(async () => {
      const tether = await this.calcUsdcAmount(amount);
      await polygonUsdcContractService.checkApproval(
        userWalletId,
        this.contractAddress,
        tether,
      );
      const method = this.contract.methods.mint(userWalletId, amount);
      const functionSignature = method.encodeABI();

      const { transactionHash } = await this.sendRawTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
      );

      this.emitTransactionMined(
        EventsEnum.JewelsMintTransactionMined,
        {
          transactionHash,
          amount,
        },
      );
    });
  }

  async burn(userWalletId: string, amount: string) {
    return this.contractCall(async () => {
      const method = this.contract.methods.burn(amount);
      const functionSignature = method.encodeABI();

      const { transactionHash } = await this.sendRawTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
      );

      this.emitTransactionMined(
        EventsEnum.JewelsBurnTransactionMined,
        {
          transactionHash,
          amount,
        },
      );
    });
  }

  public async approveWithBiconomy(
    account: string,
    functionSignature,
    {
      onError, onSubmit, onTx, onReceipt,
    },
  ) {
    return this.contractCall(async () => this.singAndSendForwardTransaction(
      this.biconomyApiId,
      account,
      functionSignature,
      {
        onError,
        onSubmit,
        onTx,
        onReceipt,
      },
    ));
  }
}
