import { IContract } from 'src/models';
import { COIN_PRESALE_CONTRACT_ADDRESS } from 'src/utils/config';

export const COIN_PRESALE_CONTRACT: IContract = {
  address: COIN_PRESALE_CONTRACT_ADDRESS,
  abi: [{ inputs: [], stateMutability: 'nonpayable', type: 'constructor' }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'previousAdmin', type: 'address',
    }, {
      indexed: false, internalType: 'address', name: 'newAdmin', type: 'address',
    }],
    name: 'AdminChanged',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'beacon', type: 'address',
    }],
    name: 'BeaconUpgraded',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'uint8', name: 'version', type: 'uint8',
    }],
    name: 'Initialized',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferStarted',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'account', type: 'address',
    }],
    name: 'Paused',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'user', type: 'address',
    }, {
      indexed: true, internalType: 'uint256', name: 'tokensBought', type: 'uint256',
    }, {
      indexed: true, internalType: 'enum BawkPresale.Currency', name: 'curr', type: 'uint8',
    }, {
      indexed: false, internalType: 'uint256', name: 'amountPaid', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'timestamp', type: 'uint256',
    }],
    name: 'TokensBought',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'account', type: 'address',
    }],
    name: 'Unpaused',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'implementation', type: 'address',
    }],
    name: 'Upgraded',
    type: 'event',
  }, {
    inputs: [], name: 'USDTInterface', outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'acceptOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address[]', name: '_users', type: 'address[]' }], name: 'addToBlacklist', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'aggregatorInterface', outputs: [{ internalType: 'contract IAggregator', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'blacklist', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'buyWithEth', outputs: [], stateMutability: 'payable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'buyWithUSDT', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'calculatePrice', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'currentStep', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'ethBuyHelper', outputs: [{ internalType: 'uint256', name: 'ethAmount', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'getCurrentPrice', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'getCurrentStageGoal', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'getLatestPrice', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_fromIdx', type: 'uint256' }, { internalType: 'uint256', name: '_toIdx', type: 'uint256' }], name: 'getPresaleUsers', outputs: [{ internalType: 'address[]', name: 'partOfPresaleUsers', type: 'address[]' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'getPresaleUsersCount', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'getSoldOnCurrentStage', outputs: [{ internalType: 'uint256', name: 'soldOnCurrentStage', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'getTotalPresaleAmount', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_aggregatorInterface', type: 'address' }, { internalType: 'address', name: '_USDTInterface', type: 'address' }, { internalType: 'uint256[21]', name: '_stageTokenAmounts', type: 'uint256[21]' }, { internalType: 'uint256[21]', name: '_stageTokenPrices', type: 'uint256[21]' }], name: 'initialize', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'pause', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'paused', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'pendingOwner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'proxiableUUID', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address[]', name: '_users', type: 'address[]' }], name: 'removeFromBlacklist', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_tokenAddress', type: 'address' }, { internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'resqueERC20', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'stageTokenAmounts', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'stageTokenPrices', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'totalSoldPrice', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'totalTokensSold', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'unpause', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newImplementation', type: 'address' }], name: 'upgradeTo', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newImplementation', type: 'address' }, { internalType: 'bytes', name: 'data', type: 'bytes' }], name: 'upgradeToAndCall', outputs: [], stateMutability: 'payable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'usdtBuyHelper', outputs: [{ internalType: 'uint256', name: 'usdtPrice', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_user', type: 'address' }], name: 'userDeposits', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }],
};
