import React from 'react';
import { Modal } from 'react-bootstrap';
import { IMAGES_URL } from 'src/constant/images';
import { useTranslation } from 'react-i18next';
import { ProviderName } from 'src/services/provider/provider.enum';

export function ChooseAccount({
  isOpen,
  onClose,
  onChoose,
}: {
  isOpen: boolean;
  onClose: () => void;
  onChoose: (providerName: ProviderName) => void;
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'MODALS.CHOOSE_ACCOUNT' });
  return (
    <Modal
      contentClassName="choose-an-account"
      centered
      show={isOpen}
      onHide={onClose}
    >
      <Modal.Header>
        <h3>Connect</h3>
        <button type="button" className="close" onClick={onClose}>
          {' '}
          <img src={IMAGES_URL.CLOSEICON} alt="" />
          {' '}
        </button>
      </Modal.Header>
      <Modal.Body>
        <p>Choose a wallet connection method.</p>
        <div className="choose-account-container">
          <div className="choose-account-item" onClick={() => onChoose(ProviderName.metamask)}>
            <img src={IMAGES_URL.METAMASK} alt="" />
            <p>{t('METAMASK')}</p>
          </div>
          <div className="choose-account-item" onClick={() => onChoose(ProviderName.walletconnect)}>
            <img src={IMAGES_URL.WALLETCONNECT} alt="" />
            <p>WalletConnect</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
