import { createSelector } from 'reselect';
import { RootState } from '../common/reducer';

export const selectTournamentState = (rootState: RootState) => rootState.tournament;

export const tournamentLoadingSelector = createSelector([selectTournamentState], (state) => state.loadingTournament);
export const tournamentRankingLoadingSelector = createSelector([selectTournamentState], (state) => state.loadingRankings);
export const lastTournamentSelector = createSelector([selectTournamentState], (state) => state.lastTournament);
export const tournamentSelector = createSelector([selectTournamentState], (state) => state.tournament);
export const tournamentRankingsSelector = createSelector([selectTournamentState], (state) => state.rankings?.rows || []);
export const tournamentMasterRankingsSelector = createSelector([selectTournamentState], (state) => state.masterRankings || []);
export const tournamentRankingsCountSelector = createSelector([selectTournamentState], (state) => state.rankings?.count || 0);
export const tournamentRankingsReachedSelector = createSelector([selectTournamentState], (state) => state.rankings?.isReached || false);
export const tournamentsLoadingSelector = createSelector([selectTournamentState], (state) => state.loadingTournaments);
export const tournamentsSelector = createSelector([selectTournamentState], (state) => state.tournaments?.rows || []);
export const tournamentsCountSelector = createSelector([selectTournamentState], (state) => state.tournaments?.count || 0);
export const tournamentsReachedSelector = createSelector([selectTournamentState], (state) => state.tournaments?.isReached || false);
export const tournamentRankingTotalsSelector = createSelector([selectTournamentState], (state) => state.rankingTotals);
export const tournamentRankingTotalsLoadingSelector = createSelector([selectTournamentState], (state) => state.loadingRankingTotals);

export const tournamentGroupRankingsSelector = createSelector([selectTournamentState], (state) => state.groupRankings);
export const tournamentGroupRankingsLoadingSelector = createSelector([selectTournamentState], (state) => state.loadingGroupRankings);
