export const chickenSortList: any = [
  { label: 'A_TO_Z', value: 'name_ASC' },
  { label: 'Z_TO_A', value: 'name_DESC' },
  { label: 'MOST_RACES', value: 'races_DESC' },
  { label: 'FEWEST_RACES', value: 'races_ASC' },
  { label: 'HIGHEST_PODIUM', value: 'placedPercentage_DESC' },
  { label: 'LOWEST_PODIUM', value: 'placedPercentage_ASC' },
  { label: 'HIGHEST_WINNINGS', value: 'earnings_DESC' },
  { label: 'LOWEST_WINNINGS', value: 'earnings_ASC' },
];

export const chickenMarketSortList: any = [
  { label: 'Newly Listed', value: 'lastActiveMarketItemId_DESC' },
  { label: 'Lowest Price', value: 'price_ASC' },
  { label: 'Highest Price', value: 'price_DESC' },
  // { label: 'Lowest Last Sale', value: 'lastSale_ASC' },
  // { label: 'Highest Last Sale', value: 'lastSale_DESC' },
  { label: 'Highest Podium %', value: 'placedPercentage_DESC' },
  { label: 'Fewest Races', value: 'races_ASC' },
  { label: 'Most Races', value: 'races_DESC' },
  { label: 'A to Z', value: 'name_ASC' },
  { label: 'Z to A', value: 'name_DESC' },
];
