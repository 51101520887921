import {
  ChickenRacesRequest,
  IBawkStakingCheckResponse,
  IBawkStakingRequest,
  IBawkStakingSeason,
  IChickenStakingSeason,
  MarketItem,
  MarketPlaceActionType,
  PaginatedList,
  PaginatedRequest,
  RacesRequest,
  TournamentRankingsRequest,
  TradeOffer,
} from 'src/models';
import { SeasonRankingsRequest } from 'src/models/season.type';
import { FusionRequest } from 'src/store/fusion/model';
import { AssignLaneRequest } from 'src/store/lane/model';
import { SerumMintRequest } from 'src/store/serum/model';
import { RegisterRequest, LoginRequest } from 'src/store/userWallet/model';
import {
  IPreRegisterLandPayload,
  IPreRegisterLandValidateNamePayload,
  IPreRegisterLandValidateEmailPayload,
} from 'src/store/pre-register-land/model';
import { GachaPullMode } from 'src/constant/gacha';
import { ChickenStatsFilter } from 'src/models/chicken-stats-filter.type';
import { axiosService } from './axiosService';

class ApiService {
  public getNonce = async (userWalletId: string) => axiosService.get(`/nonces/${userWalletId}`);

  public getRoadMaps = async () => axiosService.get('/road-maps');

  public registerWallet = async (registerRequest: RegisterRequest) => axiosService.post('/user-wallets', registerRequest);

  public loginWallet = async (loginRequest: LoginRequest) => axiosService.post('/user-wallets/login', loginRequest);

  public logoutWallet = async (userWalletId: string) => axiosService.post('/user-wallets/logout', { userWalletId });

  public updateWallet = async (data: any) => axiosService.patch('/user-wallets', data);

  public getWallet = async () => axiosService.get('/user-wallets/me');

  public getPublicWallet = async (userWalletId) => axiosService.get(`/user-wallets/${userWalletId}`);

  public loginDiscord = async (code: string) => axiosService.post('/user-wallets/discord-login', { code });

  public logoutDiscord = async () => axiosService.post('/user-wallets/discord-logout');

  public getRaces = (params: RacesRequest) => axiosService.get('/races', { params });

  public getTournamentRaces = (params: RacesRequest) => axiosService.get('/tournamentRaces', { params });

  public getRace = async (id: string) => axiosService.get(`/races/${id}`);

  public getResults = async (params: RacesRequest) => axiosService.get('/results', { params });

  public getTournamentResults = async (params: RacesRequest) => axiosService.get('/tournamentResults', { params });

  public getChickenRaces = async ({
    chickenId,
    ...rest
  }: ChickenRacesRequest) => axiosService.get(`/races/chickens/${chickenId}`, { params: rest });

  public checkIsDeploying = async () => axiosService.get('/settings/is-deploying');

  public getTotalRaces = async () => axiosService.get('/races/count');

  public getActiveTournament = async () => axiosService.get('/tournaments/last');

  public getTournament = async (id: number) => axiosService.get(`/tournaments/${id}`);

  public getTournamentRankings = async ({
    url,
    ...params
  }: TournamentRankingsRequest) => axiosService.get(`/tournaments/rankings/${url}`, {
    params,
  });

  public getTournamentRankingsTotals = async (url) => axiosService.get(`/tournaments/rankings/${url}/totals`);

  public getTournamentGroupRankings = async ({ url, userWalletId }) => axiosService.get(`/tournaments/rankings/${url}/group`, {
    params: {
      userWalletId,
    },
  });

  public getTournamentRankingsMaster = async (url: string) => axiosService.get(`/tournaments/rankings/master/${url}`);

  public getTournaments = async (params: any) => axiosService.get('/tournaments', { params });

  public assignLaneCheck = async (data: AssignLaneRequest) => axiosService.put('/lanes/check', data);

  public assignLaneForFreeRace = async (data: AssignLaneRequest) => axiosService.put('/lanes/free', data);

  public assignLaneForPaidRace = async (data: AssignLaneRequest) => axiosService.put('/lanes/paid', data);

  public checkLastRaceAssignment = async () => axiosService.get('/race-assignments/last');

  public getChicken = async (chickenId: string) => axiosService.get(`/chickens/${chickenId}`);

  public getChickenGenealogy = async (chickenId: number) => axiosService.get(`/chickens/${chickenId}/genealogy`);

  public getChickenRaceHistory = async (chickenId: number, type: string, filter?: ChickenStatsFilter) => axiosService.get(`/race-histories/${chickenId}`, {
    params: {
      type,
      filter: filter ? JSON.stringify(filter) : null,
    },
  });

  public getLeaderboard = async () => axiosService.get('/race-histories/leaderboard');

  public getChickens = async (params: any = {}) => axiosService.get('/chickens', { params });

  public updateChickenName = async (chickenId: string, name: string) => axiosService.patch(`/chickens/${chickenId}/name`, { name });

  public getCoops = async () => axiosService.get('/coops/me');

  public addChickenToCoop = async ({ coopId, chickenId }) => axiosService.put(`/coops/${coopId}/chickens/${chickenId}`);

  public removeChickenFromCoop = async ({ coopId, chickenId }) => axiosService.delete(`/coops/${coopId}/chickens/${chickenId}`);

  public updateCoop = async (coopId: number, data: any) => axiosService.patch(`/coops/${coopId}`, data);

  public getCoinRate = async () => axiosService.get('/coin-rates');

  // land
  public checkLastMintLand = async () => axiosService.get('/lands/mint/last');

  public checkLandMint = async () => axiosService.get('/lands/mint/check');

  public landMint = async (data: any) => axiosService.post('/lands/mint', data);

  public getLands = async () => axiosService.get('/lands/me');

  public basicAuth = async (data) => axiosService.post('/basicAuth', data);

  public getClothings = async (params: PaginatedRequest) => axiosService.get('/clothings/me', {
    params,
  });

  public getClothingSets = async () => axiosService.get('/clothings/clothing-set');

  public setClothing = async (data: {
    chickenId: number;
    clothingId: number;
    removeChickenId?: number;
  }) => axiosService.post('/chicken-clothings', data);

  public removeClothing = async (chickenId: number, clothingId) => axiosService.delete('/chicken-clothings', {
    params: {
      chickenId,
      clothingId,
    },
  });

  public checkMarketplaceAssignments = async (
    assignmentType: MarketPlaceActionType,
    marketplaceItem: MarketItem | TradeOffer,
  ) => {
    let data: any = {
      assignmentType,
      ...marketplaceItem,
    };

    switch (assignmentType) {
      case MarketPlaceActionType.CreateMarketItem:
        data = {
          assignmentType,
          marketItemId: (marketplaceItem as MarketItem).marketItemId,
          nftContract: (marketplaceItem as MarketItem).nftContract,
          tokenId: (marketplaceItem as MarketItem).tokenId,
          price: (marketplaceItem as MarketItem).price,
          minimum: (marketplaceItem as MarketItem).minimum,
          tradeType: (marketplaceItem as MarketItem).tradeType,
          signature: (marketplaceItem as MarketItem).signature,
          deadline: (marketplaceItem as MarketItem).deadline,
        };
        break;
      case MarketPlaceActionType.EditMarketItem:
        data = {
          assignmentType,
          marketItemId: (marketplaceItem as MarketItem).marketItemId,
          price: (marketplaceItem as MarketItem).price,
          minimum: (marketplaceItem as MarketItem).minimum,
          tradeType: (marketplaceItem as MarketItem).tradeType,
          signature: (marketplaceItem as MarketItem).signature,
          deadline: (marketplaceItem as MarketItem).deadline,
        };
        break;
      case MarketPlaceActionType.CancelMarketItem:
        data = {
          assignmentType,
          marketItemId: (marketplaceItem as MarketItem).marketItemId,
          signature: (marketplaceItem as MarketItem).signature,
          deadline: (marketplaceItem as MarketItem).deadline,
        };
        break;
      case MarketPlaceActionType.PurchaseMarketItem:
        data = {
          assignmentType,
          marketItemId: (marketplaceItem as MarketItem).marketItemId,
          signature: (marketplaceItem as MarketItem).signature,
          deadline: (marketplaceItem as MarketItem).deadline,
        };
        break;
      case MarketPlaceActionType.MakeOffer:
        data = {
          assignmentType,
          marketItemId: (marketplaceItem as TradeOffer).marketItemId,
          tradeOfferId: (marketplaceItem as TradeOffer).tradeOfferId,
          nftContract: (marketplaceItem as TradeOffer).nftContract,
          price: (marketplaceItem as TradeOffer).price,
          tokensAttached: (marketplaceItem as TradeOffer).tokensAttached,
          signature: (marketplaceItem as TradeOffer).signature,
          deadline: (marketplaceItem as TradeOffer).deadline,
        };
        break;
      case MarketPlaceActionType.EditOffer:
        data = {
          assignmentType,
          tradeOfferId: (marketplaceItem as TradeOffer).tradeOfferId,
          price: (marketplaceItem as TradeOffer).price,
          nftContract: (marketplaceItem as TradeOffer).nftContract,
          tokensAttached: (marketplaceItem as TradeOffer).tokensAttached,
          signature: (marketplaceItem as TradeOffer).signature,
          deadline: (marketplaceItem as TradeOffer).deadline,
        };
        break;
      case MarketPlaceActionType.FulfillOffer:
        data = {
          assignmentType,
          tradeOfferId: (marketplaceItem as TradeOffer).tradeOfferId,
          signature: (marketplaceItem as TradeOffer).signature,
          deadline: (marketplaceItem as TradeOffer).deadline,
        };
        break;
      case MarketPlaceActionType.CancelOrDeclineOffer:
        data = {
          assignmentType,
          tradeOfferId: (marketplaceItem as TradeOffer).tradeOfferId,
          signature: (marketplaceItem as TradeOffer).signature,
          deadline: (marketplaceItem as TradeOffer).deadline,
        };
        break;
      default:
        data = {
          assignmentType,
          ...data,
        };
    }

    return axiosService.post('/marketplace-assignments/check', data);
  };

  public createMarketItem = async (item: MarketItem) => axiosService.post('/market-items', {
    marketItemId: item.marketItemId,
    nftContract: item.nftContract,
    tokenId: item.tokenId,
    price: item.price,
    minimum: item.minimum,
    tradeType: item.tradeType,
    signature: item.signature,
    deadline: item.deadline,
    gasLimit: item.gasLimit,
  });

  public editMarketItem = async (item: MarketItem) => axiosService.patch(`/market-items/${item.marketItemId}`, {
    price: item.price,
    minimum: item.minimum,
    tradeType: item.tradeType,
    signature: item.signature,
    deadline: item.deadline,
    gasLimit: item.gasLimit,
  });

  public purchaseMarketItem = async (item: MarketItem) => axiosService.post(`/market-items/${item.marketItemId}/purchase`, {
    transactionHash: item.transactionHash,
    gasLimit: item.gasLimit,
  });

  public cancelMarketItem = async (item: MarketItem) => axiosService.patch(`/market-items/${item.marketItemId}/cancel`, {
    signature: item.signature,
    deadline: item.deadline,
    gasLimit: item.gasLimit,
  });

  public createTradeOffer = async (item: TradeOffer) => axiosService.post('/trade-offers', {
    marketItemId: item.marketItemId,
    tradeOfferId: item.tradeOfferId,
    nftContract: item.nftContract,
    price: item.price,
    tokensAttached: item.tokensAttached,
    signature: item.signature,
    deadline: item.deadline,
    gasLimit: item.gasLimit,
  });

  public editTradeOffer = async (item: TradeOffer) => axiosService.patch(`/trade-offers/${item.tradeOfferId}`, {
    price: item.price,
    nftContract: item.nftContract,
    tokensAttached: item.tokensAttached,
    signature: item.signature,
    deadline: item.deadline,
    gasLimit: item.gasLimit,
  });

  public acceptTradeOffer = async (item: TradeOffer) => axiosService.post(`/trade-offers/${item.tradeOfferId}/fulfill`, {
    transactionHash: item.transactionHash,
    gasLimit: item.gasLimit,
  });

  public cancelTradeOffer = async (item: TradeOffer) => axiosService.patch(`/trade-offers/${item.tradeOfferId}/cancel`, {
    signature: item.signature,
    deadline: item.deadline,
    gasLimit: item.gasLimit,
  });

  public checkLastMarketplaceAssignment = async () => axiosService.get('/marketplace-assignments/last');

  public getChickenTradeOffers = async (params) => axiosService.get('/trade-offers/chickens', { params });

  public getChickenMarketItems = async (params) => axiosService.get('/market-items/chickens', { params });

  public getMarketplaceStatus = async (nftContract: string) => axiosService.get(`/marketplace-stats/${nftContract}`);

  public getChickenTradeActivities = async (params) => axiosService.get('/trade-activities/chickens', { params });

  public getSeason = async (id: number) => axiosService.get(`/seasons/${id}`);

  public getSeasons = async (params: PaginatedRequest) => axiosService.get('/seasons', { params });

  public getSeasonRankings = async ({ id, ...params }: SeasonRankingsRequest) => axiosService.get(`/seasons/${id}/rankings`, {
    params,
  });

  public getChickenSeasonBadges = async (chickenId: number, params) => axiosService.get(`/badges/seasons/${chickenId}`, { params });

  public getChickenTournamentBadges = async (chickenId: number, params) => axiosService.get(`/badges/tournaments/${chickenId}`, { params });

  public getFusionPrices = async (materialChickenIds) => axiosService.get('/fusions/prices', {
    params: {
      materialChickenIds,
    },
  });

  public checkLastFusionAssignment = async () => axiosService.get('/fusion-assignments/last');

  public checkFusionAssignment = async (data: FusionRequest) => axiosService.post('/fusion-assignments/check', data);

  public fuseChicken = async (data: FusionRequest) => axiosService.post('/fusions/fuse', data);

  public checkLastSerumMint = async () => axiosService.get('/serums/mint/last');

  public checkSerumMint = async (data: SerumMintRequest) => axiosService.post('/serums/mint/check', data);

  public serumMint = async (data: SerumMintRequest) => axiosService.post('/serums/mint', data);

  public preRegisterLand = async (data: IPreRegisterLandPayload) => axiosService.post('/pre-register-lands', data);

  public getPreRegisterLandStats = async () => axiosService.get('/pre-register-lands/stats');

  public preRegisterLandValidateName = async (
    data: IPreRegisterLandValidateNamePayload,
  ) => axiosService.post('/pre-register-lands/validate-name', data);

  public preRegisterLandValidateEmail = async (
    data: IPreRegisterLandValidateEmailPayload,
  ) => axiosService.post('/pre-register-lands/validate-email', data);

  public getPreSaleEnabled = async () => axiosService.get('/settings/coin-presale-enabled');

  public getGachaPull = async (mode: GachaPullMode) => axiosService.post('/gacha/pull', {
    mode,
  });

  public getTrainingPartners = async (params: PaginatedRequest) => axiosService.get('/user-wallet-training-partners/me', { params });

  public getChickenContractStats = async () => axiosService.get('/chickens/contract-stats');

  public getBawkStakingStats = async () => axiosService.get('/bawk-staking');

  public checkBawkStaking = async (data: IBawkStakingRequest) => axiosService.post<IBawkStakingCheckResponse>('/bawk-staking/check', data);

  public postBawkStaking = async (data: IBawkStakingRequest) => axiosService.post('/bawk-staking', data);

  public checkLastBawkStakingAssignment = async () => axiosService.get('/bawk-staking-assignments/last');

  public getBawkStakingSeasons = async (params: PaginatedRequest) => axiosService.get<PaginatedList<IBawkStakingSeason>>('/bawk-staking/seasons', {
    params,
  });

  public getBawkPreSaleInfo = async () => axiosService.get('/bawk-presales/me');

  public getBawkPreSaleRefundInfo = async () => axiosService.get('/bawk-presales/refund');

  public getBawkPreSaleDates = async () => axiosService.get('/bawk-presales/dates');

  public claimBawkPreSaleInviteCode = async (inviteCode: string) => axiosService.post('/bawk-presales/claim-invite-code', {
    inviteCode,
  });

  public getLiveChickenStakingSeason = async () => axiosService.get<IChickenStakingSeason>('/chicken-staking/seasons/live');
}

export const apiService = new ApiService();
