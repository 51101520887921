import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getClothingChickensRequest, removeClothingRequest } from 'src/store/clothing/reducer';
import { IMAGES_URL } from 'src/constant/images';
import { useTranslation } from 'react-i18next';
import {
  clothingChickensCountSelector, clothingChickensLoadingSelector, clothingChickensReachedSelector, clothingChickensSelector, clothingSettingLoadingSelector,
} from 'src/store/clothing/selector';
import { ChickenImg } from 'src/components/chickenImg';
import * as utils from 'src/utils/utils';
import { userWalletSelector } from 'src/store/userWallet/selector';
import useInfiniteScroll from 'src/utils/useInfiniteScroll';

export function ClothingChickensModal({
  open,
  clothing,
  onClose,
  onUnequip,
}: {
  open: boolean,
  clothing: any,
  onClose: () => void,
  onUnequip?: (chickenClothing) => void,
}) {
  const dispatch = useDispatch();
  const chickens = useSelector(clothingChickensSelector);
  const isLoading = useSelector(clothingChickensLoadingSelector);
  const isSetting = useSelector(clothingSettingLoadingSelector);
  const userWallet = useSelector(userWalletSelector);
  const isReached = useSelector(clothingChickensReachedSelector);
  const totalCount = useSelector(clothingChickensCountSelector);
  const { t } = useTranslation('translation', { keyPrefix: 'MODALS.CLOTHING_CHICKENS' });

  const [page, setPage] = useState(1);
  const [limit] = useState(5);

  const onClickUnEquip = (chicken) => {
    const chickenClothing = chicken.chickenClothings?.find((entry) => entry.clothingId === clothing?.id);

    if (!chickenClothing) {
      return;
    }

    if (onUnequip) {
      onUnequip(chickenClothing);
    } else {
      dispatch(removeClothingRequest(chickenClothing));
    }
  };

  const [lastElementRef] = useInfiniteScroll(() => {
    if (isLoading || isReached) return;
    onFetchChickens(page + 1);
  }, isLoading);

  const onFetchChickens = useCallback(
    (pageNumber: number) => {
      if (!userWallet?.id || !clothing?.id || isSetting) {
        return;
      }

      setPage(pageNumber);
      dispatch(
        getClothingChickensRequest({
          page: pageNumber,
          limit,
          filter: JSON.stringify({
            userWalletId: userWallet.id,
            clothingId: clothing.id,
          }),
        }),
      );
    },
    [dispatch, limit, userWallet, clothing, isSetting],
  );

  useEffect(() => {
    onFetchChickens(1);
  }, [onFetchChickens]);

  const loadingElements = useMemo(() => {
    if (!isLoading || !clothing) {
      return [];
    }

    const loadings = [];
    const clothingCount = clothing?.chickenClothings?.length || 5;
    const loadingCount = Math.min(!totalCount ? clothingCount : totalCount - chickens.length, clothingCount);

    for (let i = 0; i < loadingCount; i += 1) {
      loadings.push({ id: `loading_${i}`, loading: true });
    }

    return loadings;
  }, [isLoading, clothing, totalCount, chickens]);

  if (!clothing) return <></>;

  return (
    <Modal
      size="lg"
      centered
      dialogClassName="select-chicken"
      show={open}
      onHide={() => onClose()}
    >
      <Modal.Header>
        <Modal.Title className="ml-auto">
          {!isLoading && chickens.length === 0 ? t('NO_TITLE', { name: clothing.name }) : t('TITLE', { name: clothing.name })}
        </Modal.Title>
        <button type="button" className="close" onClick={() => onClose()}>
          <span aria-hidden="true">
            <img src={IMAGES_URL.CLOSEICON} alt="" />
          </span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive">
          <table className="table order-table">
            <tbody>
              <tr>
                <th>{t('IMAGE')}</th>
                <th>{t('NAME')}</th>
                <th>{t('HERITAGE')}</th>
                <th>{t('PERFECTION')}</th>
                <th>{t('RACES')}</th>
                <th />
              </tr>
              {chickens.map((chicken) => (
                <tr key={chicken.id}>
                  <td>
                    <ChickenImg src={chicken.clothingImage || chicken.image} size={45} />
                  </td>
                  <td
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: utils.breakText(chicken.name, 8),
                    }}
                  />
                  <td
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: utils.breakText(chicken.heritage, 6),
                    }}
                  />
                  <td>
                    {chicken.perfection}
                    %
                  </td>
                  <td>
                    <div className="flex items-center">
                      {chicken.races}
                      {chicken.chickRaces < 7 && (
                        <img
                          className="chick-icon-small ml-2"
                          src={IMAGES_URL.ICONS.CHICK_ICON_1}
                          alt="CHICK"
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <a
                      className="btn btnEnter"
                      onClick={() => onClickUnEquip(chicken)}
                    >
                      {t('UNEQUIP')}
                    </a>
                  </td>
                </tr>
              ))}
              {loadingElements.map((item) => (
                <tr key={item}>
                  <td><div className="loading-column loading-pecking-order" /></td>
                  <td><div className="loading-column" /></td>
                  <td><div className="loading-column" /></td>
                  <td><div className="loading-column" /></td>
                  <td><div className="loading-column" /></td>
                  <td><div className="loading-column loading-button" /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div ref={lastElementRef} />
      </Modal.Body>
    </Modal>
  );
}
