import { useRef, useCallback, useState } from 'react';

export const useIntersection = (props?: { delay?: number }) => {
  const observer = useRef<any>();
  const [isIntersecting, setIntersecting] = useState(false);

  const intersectionRef = useCallback(
    (node) => {
      // stop watching targets, you can think of it as a reset
      if (observer.current) observer.current.disconnect();

      // create a new intersection observer and execute the callback incase of an intersecting event
      observer.current = new IntersectionObserver(([entry]) => {
        setTimeout(() => {
          setIntersecting((prev) => prev || entry.isIntersecting);
        }, props?.delay || 1000);
      });

      // if there is a node, let the intersection observer watch that node
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // return reference to the last element
  return { intersectionRef, isIntersecting };
};
