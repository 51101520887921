export const peckingOrders = [
  { label: 'X', value: 'X' },
  { label: 'SSS+', value: 'SSS+' },
  { label: 'SSS', value: 'SSS' },
  { label: 'SS+', value: 'SS+' },
  { label: 'SS', value: 'SS' },
  { label: 'S+', value: 'S+' },
  { label: 'S', value: 'S' },
  { label: 'A+', value: 'A+' },
  { label: 'A', value: 'A' },
  { label: 'B+', value: 'B+' },
  { label: 'B', value: 'B' },
  { label: 'C+', value: 'C+' },
  { label: 'C', value: 'C' },
  { label: 'D+', value: 'D+' },
  { label: 'D', value: 'D' },
  { label: 'E+', value: 'E+' },
  { label: 'E', value: 'E' },
  { label: 'F+', value: 'F+' },
  { label: 'F', value: 'F' },
];

export const racePeckingOrders = [
  { label: 'X', value: 'X' },
  { label: 'SSS+', value: 'SSS+' },
  { label: 'SSS', value: 'SSS' },
  { label: 'SS+', value: 'SS+' },
  { label: 'SS', value: 'SS' },
  { label: 'S+', value: 'S+' },
  { label: 'S', value: 'S' },
  { label: 'A+', value: 'A+' },
  { label: 'A', value: 'A' },
  { label: 'B+', value: 'B+' },
  { label: 'B', value: 'B' },
  { label: 'C+', value: 'C+' },
  { label: 'C', value: 'C' },
  { label: 'D+', value: 'D+' },
  { label: 'D', value: 'D' },
  { label: 'E+', value: 'E+' },
  { label: 'E', value: 'E' },
  { label: 'F+', value: 'F+' },
  { label: 'F', value: 'F' },
];

export const distances = [
  { label: '100m', value: 100 },
  { label: '120m', value: 120 },
  { label: '140m', value: 140 },
  { label: '160m', value: 160 },
  { label: '180m', value: 180 },
  { label: '200m', value: 200 },
];

export const terrains = [
  { label: 'Dirt', value: 'Dirt' },
  { label: 'Grass', value: 'Grass' },
  { label: 'Road', value: 'Road' },
  { label: 'Rock', value: 'Rock' },
  { label: 'Sand', value: 'Sand' },
  { label: 'Snow', value: 'Snow' },
  { label: 'Track', value: 'Track' },
];

export const heritages = [
  { label: 'Serama', value: 'Serama' },
  { label: 'Sultan', value: 'Sultan' },
  { label: 'Lakenvelder', value: 'Lakenvelder' },
  { label: 'Dorking', value: 'Dorking' },
];

export const talents = [
  { label: 'Anvil', value: 'Anvil' },
  { label: 'Black Hole', value: 'Black Hole' },
  { label: 'Blue Egg', value: 'Blue Egg' },
  { label: 'Blue Rooster', value: 'Blue Rooster' },
  { label: 'Chickenapult', value: 'Chickenapult' },
  { label: 'Cold Snap', value: 'Cold Snap' },
  { label: 'Coober', value: 'Coober' },
  { label: 'CK-47', value: 'CK-47' },
  { label: 'Devolution', value: 'Devolution' },
  { label: 'Dig', value: 'Dig' },
  { label: 'Fan Group', value: 'Fan Group' },
  { label: 'Flight?', value: 'Flight?' },
  { label: 'Growth', value: 'Growth' },
  { label: 'Helicopter', value: 'Helicopter' },
  { label: 'Jetpack', value: 'Jetpack' },
  { label: 'Machete', value: 'Machete' },
  { label: 'Moving Walkway', value: 'Moving Walkway' },
  { label: 'Rollerblades', value: 'Rollerblades' },
  { label: 'Royal Procession', value: 'Royal Procession' },
  { label: 'Teleport', value: 'Teleport' },
];

export const distancePreferences = [
  {
    label: 'Short',
    value: 'Short',
  },
  {
    label: 'Intermediate',
    value: 'Intermediate',
  },
  {
    label: 'Medium',
    value: 'Medium',
  },
  {
    label: 'Stretch',
    value: 'Stretch',
  },
  {
    label: 'Long',
    value: 'Long',
  },
  {
    label: 'Extremes',
    value: 'Extremes',
  },
];

export const talentPreferences = [
  {
    label: 'Magic',
    value: 'Magic',
  },
  {
    label: 'Spirit',
    value: 'Spirit',
  },
  {
    label: 'Technology',
    value: 'Technology',
  },
  {
    label: 'Weapon',
    value: 'Weapon',
  },
];

export const stocks = [
  { label: 'Spicy', value: 'Spicy' },
  { label: 'Robust', value: 'Robust' },
  { label: 'Fresh', value: 'Fresh' },
  { label: 'Crisp', value: 'Crisp' },
  { label: 'Tender', value: 'Tender' },
  { label: 'Bland', value: 'Bland' },
];

export const genders = [
  { label: 'Hen', value: 'Hen' },
  { label: 'Rooster', value: 'Rooster' },
];

export const colors = [
  { label: 'Bald Chicken', value: 'Bald Chicken' },
  { label: 'Black', value: 'Black' },
  { label: 'Cherry Dusk', value: 'Cherry Dusk' },
  { label: 'Classic', value: 'Classic' },
  { label: 'Eggshell', value: 'Eggshell' },
  { label: 'English Mustard', value: 'English Mustard' },
  { label: 'Istanblue', value: 'Istanblue' },
  { label: "Joker's Jade", value: "Joker's Jade" },
  { label: 'Manic Mint', value: 'Manic Mint' },
  { label: 'Merah Red', value: 'Merah Red' },
  { label: 'Orange Will', value: 'Orange Will' },
  { label: 'Purple Wine', value: 'Purple Wine' },
  { label: 'Robot', value: 'Robot' },
  { label: 'Rose', value: 'Rose' },
  { label: 'Royal Violet', value: 'Royal Violet' },
  { label: 'Sapphire', value: 'Sapphire' },
  { label: 'Screamin Green', value: 'Screamin Green' },
  { label: 'Shocking Pink', value: 'Shocking Pink' },
  { label: 'Wild Moss', value: 'Wild Moss' },
  { label: 'Plucked', value: 'Plucked' },
  { label: 'Runic', value: 'Runic' },
  { label: 'Translucent Red', value: 'Translucent Red' },
  { label: 'Translucent Purple', value: 'Translucent Purple' },
  { label: 'Translucent Green', value: 'Translucent Green' },
  { label: 'Translucent Yellow', value: 'Translucent Yellow' },
];

export const combs = [
  { label: 'Black', value: 'Black' },
  { label: 'Blue', value: 'Blue' },
  { label: 'Candy', value: 'Candy' },
  { label: 'Green', value: 'Green' },
  { label: 'Orange', value: 'Orange' },
  { label: 'Pink', value: 'Pink' },
  { label: 'Purple', value: 'Purple' },
  { label: 'Red', value: 'Red' },
  { label: 'Studs', value: 'Studs' },
  { label: 'Teal', value: 'Teal' },
  { label: 'White', value: 'White' },
  { label: 'Yellow', value: 'Yellow' },
];

export const wattles = [
  { label: 'Black', value: 'Black' },
  { label: 'Blue', value: 'Blue' },
  { label: 'Candy', value: 'Candy' },
  { label: 'Green', value: 'Green' },
  { label: 'Orange', value: 'Orange' },
  { label: 'Pink', value: 'Pink' },
  { label: 'Purple', value: 'Purple' },
  { label: 'Red', value: 'Red' },
  { label: 'Teal', value: 'Teal' },
  { label: 'White', value: 'White' },
  { label: 'Yellow', value: 'Yellow' },
];

export const beaks = [
  { label: 'Black', value: 'Black' },
  { label: 'Gold', value: 'Gold' },
  { label: 'Orange', value: 'Orange' },
  { label: 'White', value: 'White' },
  { label: 'Yellow', value: 'Yellow' },
  { label: 'Robot', value: 'Robot' },
  { label: 'Falcon', value: 'Falcon' },
  { label: 'Glitter', value: 'Glitter' },
  { label: 'Dragon', value: 'Dragon' },
];

export const accessories = [
  { label: 'Ring', value: 'Ring' },
  { label: 'Vampire', value: 'Vampire' },
  { label: 'Toothpick', value: 'Toothpick' },
  { label: 'Rose', value: 'Rose' },
];

export const eyes = [
  { label: 'Alien', value: 'Alien' },
  { label: 'Angry', value: 'Angry' },
  { label: 'Beauty', value: 'Beauty' },
  { label: 'Bloodshot', value: 'Bloodshot' },
  { label: 'Bulging', value: 'Bulging' },
  { label: 'Cockeyed', value: 'Cockeyed' },
  { label: 'Crosseyed', value: 'Crosseyed' },
  { label: 'Determined', value: 'Determined' },
  { label: 'Exhausted', value: 'Exhausted' },
  { label: 'Eyepatch', value: 'Eyepatch' },
  { label: 'Lizard', value: 'Lizard' },
  { label: 'Robot', value: 'Robot' },
  { label: 'Sad', value: 'Sad' },
  { label: 'Shocked', value: 'Shocked' },
  { label: 'Sleepy', value: 'Sleepy' },
  { label: 'Buttons', value: 'Buttons' },
  { label: 'Cyclops', value: 'Cyclops' },
  { label: 'Owl', value: 'Owl' },
];

export const backgrounds = [
  { label: 'Amethyst', value: 'Amethyst' },
  { label: 'Autumn', value: 'Autumn' },
  { label: 'Flesh', value: 'Flesh' },
  { label: 'Lava', value: 'Lava' },
  { label: 'Lilac', value: 'Lilac' },
  { label: 'Ocean', value: 'Ocean' },
  { label: 'Spring', value: 'Spring' },
  { label: 'Stone', value: 'Stone' },
  { label: 'Summer', value: 'Summer' },
  { label: 'Winter', value: 'Winter' },
];

export const stripes = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const legs = [
  { label: 'Black', value: 'Black' },
  { label: 'Blades', value: 'Blades' },
  { label: 'Gingerbread', value: 'Gingerbread' },
  { label: 'Hooves', value: 'Hooves' },
  { label: 'Normal', value: 'Normal' },
];

export const maxPoints = 110;

export const offensiveTalents = [
  'Anvil',
  'Black Hole',
  'Blue Egg',
  'Cold Snap',
  'CK-47',
  'Devolution',
  'Helicopter',
  'Machete',
  'Teleport',
];

export const chickenLegName: { [leg: string]: string } = {
  legsHen: 'Normal',
  legsRooster: 'Normal',
  legsBlackHen: 'Black',
  legsBlackRooster: 'Black',
  Gingerbread: 'Gingerbread',
  Blades: 'Blades',
  Hooves: 'Hooves',
};
