import React, { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import {
  distancePreferences, heritages, talents, terrains, talentPreferences,
} from 'src/constant';
import { IMAGES_URL } from 'src/constant/images';
import * as _ from 'lodash';
import { Select } from 'src/components/select';
import { useTranslation } from 'react-i18next';

export function ChickenFilterModal({
  filter,
  open,
  onClose,
  onFilter,
}: {
  filter: any;
  open: boolean;
  onClose: () => void;
  onFilter: (value: any, close?: boolean) => void;
}) {
  // const { theme } = useThemeContext();
  const [filterData, setFilterData] = useState<any>({
    heritage: [],
    talent: [],
    terrainPreference: [],
    distancePreference: [],
    talentPreference: [],
    perfectionMin: '',
    perfectionMax: '',
    racesMin: '',
    racesMax: '',
  });
  const { t } = useTranslation('translation', { keyPrefix: 'MODALS.CHICKEN_FILTER_MODAL' });

  useEffect(() => {
    if (open) {
      setFilterData({
        heritage: filter.heritage || [],
        talent: filter.talent || [],
        terrainPreference: filter.terrainPreference || [],
        distancePreference: filter.distancePreference || [],
        talentPreference: filter.talentPreference || [],
        perfectionMin: filter.perfectionMin || '',
        perfectionMax: filter.perfectionMax || '',
        racesMin: filter.racesMin || '',
        racesMax: filter.racesMax || '',
      });
    }
  }, [filter, open]);

  const onChangeValue = (name: string, value: any) => {
    const temp = _.cloneDeep(filterData);
    temp[name] = value;

    setFilterData(temp);
  };

  const onReset = () => {
    const filters = {
      heritage: [],
      talent: [],
      terrainPreference: [],
      distancePreference: [],
      talentPreference: [],
      perfectionMin: '',
      perfectionMax: '',
      racesMin: '',
      racesMax: '',
    };

    setFilterData(filters);

    onFilter(filters, false);
  };

  const onSubmit = () => {
    onFilter(filterData, true);
  };

  return (
    <Modal
      dialogClassName="filter-popup chicken-filter-popup"
      centered
      show={open}
      backdrop="static"
    >
      <Modal.Header>
        <h3 className="modal-title">{t('FILTER')}</h3>
        <button type="button" className="close" onClick={onClose}>
          {' '}
          <img src={IMAGES_URL.CLOSEICON} alt="" />
          {' '}
        </button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="6">
            <div className="form-group">
              <label>{t('HERITAGE')}</label>
              <Select
                value={filterData.heritage}
                options={heritages}
                selectValue
                onChange={(value) => onChangeValue('heritage', value)}
                placeholder={t('SELECT_HERITAGE')}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label>{t('TALENT')}</label>
              <Select
                options={talents}
                selectValue
                placeholder={t('SELECT_TALENT')}
                value={filterData.talent}
                onChange={(value) => onChangeValue('talent', value)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label htmlFor="">
                {t('PERFECTION')}
                (
                <span className="text-dark">{t('MIN')}</span>
                )
              </label>
              <input
                type="number"
                min={0}
                className="form-control filter-perfection-min"
                placeholder={t('INPUT_PERFECTION_MIN')}
                value={filterData.perfectionMin}
                onChange={(e) => onChangeValue('perfectionMin', e.target.value)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label htmlFor="">
                {t('PERFECTION')}
                (
                <span className="text-dark">{t('MAX')}</span>
                )
              </label>
              <input
                type="number"
                min={0}
                className="form-control filter-perfection-max"
                placeholder={t('INPUT_PERFECTION_MAX')}
                value={filterData.perfectionMax}
                onChange={(e) => onChangeValue('perfectionMax', e.target.value)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label htmlFor="">
                {t('RACES')}
                (
                <span className="text-dark">{t('MIN')}</span>
                )
              </label>
              <input
                type="number"
                min={0}
                placeholder={t('INPUT_RACES_MIN')}
                className="form-control filter-races-min"
                value={filterData.racesMin}
                onChange={(e) => onChangeValue('racesMin', e.target.value)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label htmlFor="">
                {t('RACES')}
                (
                <span className="text-dark">{t('MAX')}</span>
                )
              </label>
              <input
                type="number"
                min={0}
                placeholder={t('INPUT_RACES_MAX')}
                className="form-control filter-races-max"
                value={filterData.racesMax}
                onChange={(e) => onChangeValue('racesMax', e.target.value)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label>Terrain Preference</label>
              <Select
                options={terrains}
                selectValue
                placeholder="Terrain Preference"
                value={filterData.terrainPreference}
                onChange={(value) => onChangeValue('terrainPreference', value)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label>Distance Preference</label>
              <Select
                options={distancePreferences}
                selectValue
                placeholder="Distance Preference"
                value={filterData.distancePreference}
                onChange={(value) => onChangeValue('distancePreference', value)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label>Talent Preference</label>
              <Select
                options={talentPreferences}
                selectValue
                placeholder="Talent Preference"
                value={filterData.talentPreference}
                onChange={(value) => onChangeValue('talentPreference', value)}
              />
            </div>
          </Col>
        </Row>
        <div className="footer-btn">
          <button type="button" className="btn btn-reset" onClick={onReset}>
            {t('RESET')}
          </button>
          <button
            type="button"
            className="btn btn-filter-modal"
            onClick={onSubmit}
          >
            {t('FILTER')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
