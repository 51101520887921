import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import {
  distances, racePeckingOrders, terrains,
} from 'src/constant';
import { IMAGES_URL } from 'src/constant/images';
import * as _ from 'lodash';
import { Select } from 'src/components/select';
import { useTranslation } from 'react-i18next';
import { DateRangeInput } from 'src/components/dateRange';
import { ChickenStatsFilter } from 'src/models/chicken-stats-filter.type';
import moment from 'moment-timezone';

const initialFilters = {
  startDate: null,
  endDate: null,
  distance: [],
  terrain: [],
  peckingOrder: [],
  feeMin: null,
  feeMax: null,
  capacityMin: null,
  capacityMax: null,
};

export function ChickenStatsFilterModal({
  filter,
  open,
  onFilter,
}: {
  filter: ChickenStatsFilter;
  open: boolean;
  onFilter: (value: ChickenStatsFilter) => void;
}) {
  const [filterData, setFilterData] = useState<ChickenStatsFilter>(initialFilters);
  const { t } = useTranslation('translation', { keyPrefix: 'MODALS.FILTER_MODAL' });
  const startDate = useMemo(() => (filterData.startDate ? new Date(filterData.startDate) : undefined), [filterData]);
  const endDate = useMemo(() => (filterData.endDate ? new Date(filterData.endDate) : undefined), [filterData]);

  useEffect(() => {
    if (open) {
      setFilterData({
        startDate: filter?.startDate || null,
        endDate: filter?.endDate || null,
        distance: filter?.distance || [],
        terrain: filter?.terrain || [],
        peckingOrder: filter?.peckingOrder || [],
        feeMin: filter?.feeMin || null,
        feeMax: filter?.feeMax || null,
        capacityMin: filter?.capacityMin || null,
        capacityMax: filter?.capacityMax || null,
      });
    }
  }, [filter, open]);

  const onChangeValue = (name: string, value: any) => {
    const temp = _.cloneDeep(filterData);
    temp[name] = value;

    setFilterData(temp);
  };

  const onClose = () => {
    onFilter(filterData);
  };

  const onReset = () => {
    setFilterData(initialFilters);
  };

  const onSubmit = () => {
    onFilter(filterData);
  };

  const onChangeDateRange = (start?: Date, end?: Date) => {
    const temp = _.cloneDeep(filterData);
    temp.startDate = moment(start).startOf('day').toISOString();
    temp.endDate = moment(end).endOf('day').toISOString();

    setFilterData(temp);
  };

  return (
    <Modal
      dialogClassName="filter-popup"
      centered
      show={open}
      backdrop="static"
    >
      <Modal.Header>
        <h3 className="modal-title">{t('FILTER')}</h3>
        <button type="button" className="close" onClick={onClose}>
          {' '}
          <img src={IMAGES_URL.CLOSEICON} alt="" />
          {' '}
        </button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="6">
            <div className="form-group">
              <label>{t('PECKING_ORDER')}</label>
              <Select
                value={filterData.peckingOrder}
                options={racePeckingOrders}
                onChange={(value) => onChangeValue('peckingOrder', value)}
                placeholder={t('SELECT_PECKING_ORDER')}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label>{t('DISTANCE')}</label>
              <Select
                options={distances}
                placeholder={t('SELECT_DISTANCE')}
                value={filterData.distance}
                onChange={(value) => onChangeValue('distance', value)}
              />
            </div>
          </Col>

          <Col md="6">
            <div className="form-group">
              <label>{t('TERRAIN_TYPE')}</label>
              <Select
                options={terrains}
                value={filterData.terrain}
                placeholder={t('SELECT_TERRAINS')}
                onChange={(value) => onChangeValue('terrain', value)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label>Date Range</label>
              <DateRangeInput
                startDate={startDate}
                endDate={endDate}
                onChange={onChangeDateRange}
                placeholder="Date Range"
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label htmlFor="">
                {t('ENTRY_FEE')}
                (
                <span className="text-dark">{t('MIN')}</span>
                )
              </label>
              <input
                type="number"
                min={0}
                className="form-control filter-fee-min"
                placeholder={t('INPUT_ENTRY_FEE_MIN')}
                value={filterData.feeMin}
                onChange={(e) => onChangeValue('feeMin', e.target.value)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label htmlFor="">
                {t('ENTRY_FEE')}
                (
                <span className="text-dark">{t('MAX')}</span>
                )
              </label>
              <input
                type="number"
                min={0}
                placeholder={t('INPUT_ENTRY_FEE_MAX')}
                className="form-control filter-fee-max"
                value={filterData.feeMax}
                onChange={(e) => onChangeValue('feeMax', e.target.value)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label htmlFor="">
                Capacity
                (
                <span className="text-dark">{t('MIN')}</span>
                )
              </label>
              <input
                type="number"
                min={0}
                max={12}
                placeholder="Capacity Min"
                className="form-control filter-prize-min"
                value={filterData.capacityMin}
                onChange={(e) => onChangeValue('capacityMin', parseInt(e.target.value) > 12 ? 12 : parseInt(e.target.value))}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label htmlFor="">
                Capacity
                (
                <span className="text-dark">{t('MAX')}</span>
                )
              </label>
              <input
                className="form-control filter-prize-max"
                type="number"
                min={0}
                max={12}
                placeholder="Capacity Max"
                value={filterData.capacityMax}
                onChange={(e) => onChangeValue('capacityMax', parseInt(e.target.value) > 12 ? 12 : parseInt(e.target.value))}
              />
            </div>
          </Col>
        </Row>
        <div className="footer-btn">
          <button type="button" className="btn btn-reset" onClick={onReset}>
            {t('RESET')}
          </button>
          <button
            type="button"
            className="btn btn-filter-modal"
            onClick={onSubmit}
          >
            {t('FILTER')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
