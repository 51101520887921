import Dinero, { Currency } from 'dinero.js';
import numeral from 'numeral';

import { offensiveTalents } from 'src/constant';
import {
  GAME_VIEWER_URL,
  IS_STAGING_ENV,
  IS_LOCAL_ENV,
} from 'src/utils/config';

// eslint-disable-next-line no-promise-executor-return
export const sleep = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getGameViewerUrl = (raceId: string) => {
  let url = `${GAME_VIEWER_URL}?raceId=${raceId}`;

  if (IS_STAGING_ENV) {
    url = `${url}&env=dev`;
  } else if (IS_LOCAL_ENV) {
    url = `${url}&env=local`;
  }

  return url;
};

export const getRaceUrl = (raceId: string) => {
  const url = `${window.location.origin}/race-detail/${raceId}`;

  return url;
};

export const breakText = (name: string, breakLength = 10) => {
  const hyphen = '&shy;';
  let newName = '';

  for (let i = 0; i < name.length; i += 1) {
    newName += name[i];

    if ((i + 1) % breakLength === 0) {
      newName += hyphen;

      const nextString = name[i + 1];

      if (nextString && nextString.match(/^[^a-zA-Z0-9]/)) {
        newName += '-<br/>';
      }
    }
  }

  return newName;
};

export const formatToken = (amount = 0, coinRate, coinType, currency) => `${formatTokenAmount(amount, coinRate, coinType, currency)} ${currency}`;

export const formatTokenAmount = (amount = 0, coinRate, coinType, currency) => {
  let value = amount || 0;
  const ethPrice = coinRate?.eth || 0;
  const jewelPrice = coinRate?.jewel || 0;

  if (coinType === 'WETH') {
    value = (value * ethPrice) / jewelPrice;
  }

  if (currency === 'USD') {
    value *= jewelPrice;
  }

  return `${formatNumber(value, '0,0.00a')}`;
};

export const formatJewels = (amount = 0, coinRate, currency) => {
  let value = amount || 0;
  const jewelPrice = coinRate?.jewel || 0;

  if (currency === 'USD') {
    value *= jewelPrice;
  }

  return formatNumber(value, '0,0.00a');
};

export const formatCurrency = (
  amount = 0,
  currency: Currency = 'USD',
  format = '$0,0.00',
) => {
  let value = typeof amount === 'number'
    ? amount
    : typeof amount === 'string'
      ? Number(amount)
      : 0;

  if (Number.isNaN(value)) {
    value = 0;
  }

  const amountLocal = newDinero(value * 100, currency);
  return amountLocal.toFormat(format);
};

export const formatNumber = (number: number, format?: string) => {
  let num = number || 0;

  let numberFormat = format;
  if (!format) {
    numberFormat = num >= 1000 ? '0.0 a' : '0,0';
  }

  if (num.toString().includes('e-')) {
    num = 0;
  }

  return numeral(num).format(numberFormat);
};

export const newDinero = (
  amountInCents = 0,
  currency: Currency = 'USD',
): Dinero.Dinero => {
  // amount is comming as cent, so we round it to integer
  const rounderAmountInCents = Math.round(amountInCents);

  return Dinero({ amount: rounderAmountInCents, currency });
};

export const normalizeNumber = (value = 0, defaultDecimal = 2) => {
  const log = Math.log10(value);
  let defaultFormat = '0,0';

  if (defaultDecimal) {
    defaultFormat += '.';
    for (let i = 0; i < defaultDecimal; i += 1) {
      defaultFormat += '0';
    }
  }

  if (log === -Infinity || log === Infinity) {
    return Number(newDinero(0).toFormat(defaultFormat));
  }

  if (log > 0) {
    return newDinero(value * 100).toFormat(defaultFormat);
  }

  const d = Math.floor(log * -1);

  if (d > 6 && d > defaultDecimal) {
    return 0;
  }

  return Number(value.toFixed(d + 2));
};

export const sortCollator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

export const getUserName = (userWalletId: string, username?: string) => username || userWalletId?.toUpperCase()?.slice(2, 8) || '';
export const filterChickensByRace = (race: any, chickens: any[]) => {
  if (race?.group === 10) {
    return chickens.filter((chicken) => offensiveTalents.includes(chicken.talent));
  }

  return chickens;
};

export const truncateToDecimals = (num: number, dec = 2) => {
  const calcDec = 10 ** dec;

  return Math.trunc(num * calcDec) / calcDec;
};
