import React from 'react';

export function MenuIcon({
  size = 24,
  color = '#FFF',
  onClick,
}: {
  size?: number
  color?: string
  onClick?: () => void
}) {
  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
      <path d="M21.5984 19.2H2.39844M21.5984 12H2.39844M21.5984 4.8H2.39844" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
