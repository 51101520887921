import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { IMAGES_URL } from 'src/constant/images';
import { truncateToDecimals } from 'src/utils/utils';
import { useCurrency } from 'src/contexts/currency';
import { BawkStakingType, BawkType, IBawkStakingRequest } from 'src/models';

export function BawkStakingTransactionConfirmation({
  request,
  open,
  onClose,
  onConfirm,
}: {
  request: IBawkStakingRequest;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const { currency, currencyElement } = useCurrency();

  const amountElement = useMemo(() => {
    if (!request) {
      return (
        <></>
      );
    }

    return (
      <>
        {truncateToDecimals(request?.amount, 6)}
        {' '}
        {
          request?.type === BawkStakingType.ClaimJewel ? (
            currencyElement(currency)
          ) : (
            <img
              className={`currency-symbol ${request.bawkType === BawkType.Unclaimed ? 'gray' : ''}`}
              src={IMAGES_URL.BAWK}
              alt="bawks"
              width={20}
            />
          )
        }
      </>
    );
  }, [request, currency, currencyElement]);

  const onSubmit = () => {
    onConfirm();
  };
  const { t } = useTranslation('translation', { keyPrefix: 'MODALS.TRANSACTION_CONFIRMATION' });

  return (
    <Modal
      centered
      dialogClassName="transaction-confirmation"
      show={open}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>{t('TRANSACTION_CONFIRMATION')}</Modal.Title>
        <button type="button" className="close" onClick={onClose}>
          <img src={IMAGES_URL.CLOSEICON} alt="" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <h4>{`AMOUNT TO ${request?.type.toUpperCase()}`}</h4>
        <p>
          {amountElement}
        </p>
        <h4>{t('ESTIMATED_TX_FEE')}</h4>
        <p>
          0.00
          {' '}
          {currencyElement(currency)}
        </p>
        <h4>{t('ESTIMATED_TOTAL')}</h4>
        <p>
          {amountElement}
        </p>
        <h4 className="text-center">
          {amountElement}
        </h4>
        <div className="bottom-btn">
          <button type="button" onClick={onClose} className="btn btn-go">
            {t('GO_BACK')}
          </button>
          <button
            type="button"
            className="btn btn-confirm"
            onClick={onSubmit}
          >
            {t('CONFIRM')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
