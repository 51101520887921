import { toast } from 'react-toastify';
import {
  takeLatest, call, put, delay,
} from 'redux-saga/effects';
import { apiService } from 'src/services/apiService';
import { toastService } from 'src/services/toastService';
import { assignLaneForFreeRaceRequest, assignLaneForPaidRaceRequest } from './action';
import { AssignLaneRequest } from './model';
import {
  assignLaneFailedResponse,
  assignLaneSuccessResponse,
  checkAssignLaneFailedResponse,
  checkAssignLaneRequest,
  checkAssignLaneSuccessResponse,
  checkLastRaceAssignmentFailedResponse,
  checkLastRaceAssignmentRequest,
  checkLastRaceAssignmentSuccessResponse,
} from './reducer';

export function* assignLane({ payload }: { payload: AssignLaneRequest }) {
  try {
    const isPaidRace = Boolean(payload.signature);
    if (isPaidRace) {
      yield call(apiService.assignLaneForPaidRace, payload);
    } else {
      yield call(apiService.assignLaneForFreeRace, payload);
    }

    yield put(assignLaneSuccessResponse(isPaidRace));

    if (isPaidRace) {
      yield put(checkLastRaceAssignmentRequest(true));
    } else {
      toast.success('Assigned lane successfully');
    }
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');

    yield put(assignLaneFailedResponse(undefined));
  }
}

export function* checkAssignLane({ payload }: { payload: AssignLaneRequest }) {
  try {
    const res = yield call(apiService.assignLaneCheck, payload);
    yield put(checkAssignLaneSuccessResponse(res.data));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');

    yield put(checkAssignLaneFailedResponse(undefined));
  }
}

export function* checkLastRaceAssignment({ payload }: { payload: boolean }) {
  try {
    const res = yield call(apiService.checkLastRaceAssignment);
    const { raceAssignment } = res.data;

    if (!raceAssignment) {
      yield put(checkLastRaceAssignmentSuccessResponse(false));
      return;
    }

    if (raceAssignment.status === 'success') {
      yield put(checkLastRaceAssignmentSuccessResponse(!!payload));

      if (payload) {
        toast.success('Assigned lane successfully');
      }
    } else if (raceAssignment.status === 'error') {
      yield put(checkLastRaceAssignmentFailedResponse(false));

      if (payload) {
        throw new Error(raceAssignment.error.message);
      }
    } else {
      yield delay(5 * 1000);
      yield put(checkLastRaceAssignmentRequest(true));
    }
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
    yield put(checkLastRaceAssignmentFailedResponse(undefined));
  }
}

export function* laneEffects() {
  yield takeLatest(assignLaneForFreeRaceRequest.type as any, assignLane);
  yield takeLatest(assignLaneForPaidRaceRequest.type as any, assignLane);
  yield takeLatest(checkAssignLaneRequest.type as any, checkAssignLane);
  yield takeLatest(checkLastRaceAssignmentRequest.type as any, checkLastRaceAssignment);
}

const chikenSagas = [call(laneEffects)];

export default chikenSagas;
