import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LaneState = {
  isAssigned: boolean;
  isAssigningPaidRace: boolean;
  isAssigning: boolean;
  isCheckedAssign: boolean;
  gasLimit: number;
  coupon: any;
};

const initialState: LaneState = {
  isAssigned: false,
  isAssigning: false,
  isAssigningPaidRace: false,
  isCheckedAssign: false,
  gasLimit: 0,
  coupon: null,
};

const laneSlice = createSlice({
  name: 'lane',
  initialState,
  reducers: {
    clearIsAssigned(state, action: PayloadAction<any>) {
      state.isAssigned = false;
    },
    checkAssignLaneRequest(state, action: PayloadAction<any>) {
      state.isAssigned = false;
      state.isAssigning = false;
      state.isAssigningPaidRace = false;
      state.isCheckedAssign = false;
      state.gasLimit = 0;
      state.coupon = null;
    },
    checkAssignLaneSuccessResponse(state, action: PayloadAction<any>) {
      state.isCheckedAssign = true;
      state.isAssigning = true;
      state.gasLimit = action.payload.gasLimit;
      state.coupon = action.payload.coupon;
    },
    checkAssignLaneFailedResponse(state, action: PayloadAction<any>) {
      state.isAssigning = false;
      state.isCheckedAssign = false;
    },
    assignLaneSuccessResponse(state, action: PayloadAction<boolean>) {
      state.isAssigned = !action.payload;
      state.isAssigning = action.payload;
    },
    assignLaneFailedResponse(state, action: PayloadAction<any>) {
      state.isAssigning = false;
    },
    checkLastRaceAssignmentRequest(state, action: PayloadAction<boolean>) {
      state.isAssigned = false;
      state.isAssigning = action.payload;
      state.isAssigningPaidRace = action.payload;
    },
    checkLastRaceAssignmentSuccessResponse(state, action: PayloadAction<boolean>) {
      state.isAssigned = action.payload;
      state.isAssigning = false;
    },
    checkLastRaceAssignmentFailedResponse(state, action: PayloadAction<any>) {
      state.isAssigned = false;
      state.isAssigning = false;
    },
  },
});

export const {
  clearIsAssigned,
  checkAssignLaneRequest,
  checkAssignLaneSuccessResponse,
  checkAssignLaneFailedResponse,
  assignLaneSuccessResponse,
  assignLaneFailedResponse,
  checkLastRaceAssignmentRequest,
  checkLastRaceAssignmentSuccessResponse,
  checkLastRaceAssignmentFailedResponse,
} = laneSlice.actions;

export default laneSlice.reducer;
