import {
  takeLatest, call, put,
} from 'redux-saga/effects';
import { apiService } from 'src/services/apiService';
import {
  coinPreSaleSuccessResponse,
} from './reducer';
import { getCoinPreSaleEnabledAction } from './action';

export function* getPreSaleEnabled() {
  try {
    const response = yield call(apiService.getPreSaleEnabled);
    yield put(coinPreSaleSuccessResponse(response?.data?.isEnabled));
  } catch (err) {
    //
  }
}

export function* userEffects() {
  yield takeLatest(getCoinPreSaleEnabledAction.type, getPreSaleEnabled);
}

const coinPreSaleSagas = [call(userEffects)];

export default coinPreSaleSagas;
