import { LAND_MINT_CONTRACT } from 'src/abis';
import { exponentialBackOff } from 'src/utils/exponentialBackoff';
import { AbiItem } from 'web3-utils';
import { ChainId } from 'src/constant/chainId';
import ContractService from './baseContractService';

export class LandContractService extends ContractService {
  constructor() {
    super(ChainId.Polygon, LAND_MINT_CONTRACT.address, LAND_MINT_CONTRACT.abi as AbiItem[]);

    ContractService.add(this);
  }

  async getOwnerLandCount(walletId: string) {
    return this.contractCall(async () => {
      const getOwnerLandCountFunc = async () => this.contract.methods.balanceOf(walletId).call();
      const { result } = await exponentialBackOff(getOwnerLandCountFunc);

      return result;
    });
  }

  async landMint(userWalletId: string, amount: number) {
    return this.contractCall(async () => {
      const functionSignature = this.contract.methods.mint(amount).encodeABI();

      return this.signForwardTransaction(userWalletId, this.contractAddress, functionSignature);
    });
  }

  async getLandInfo() {
    return this.contractCall(async () => {
      const getLandInfoFunc = async () => this.multiCallProvider.aggregate([
        this.contract.methods.isPublicSaleActive(),
        this.contract.methods.isChickenOwnerSaleActive(),
        this.contract.methods.paused(),
        this.contract.methods.tokenCount(),
        this.contract.methods.maxMintAmount(),
        this.contract.methods.price(),
        this.contract.methods.MAX_SUPPLY(),
      ]);

      const { result } = await exponentialBackOff(getLandInfoFunc);

      return result;
    });
  }
}
