import React, {
  createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { ClothingChickensModal } from 'src/modals/clothingChickens';
import { setClothingRequest } from 'src/store/clothing/reducer';

interface ChickenProps {
  isOpenClothingChickens: boolean;
  isRenderOriginalImage: boolean;
  onOpenClothingChickens: (chicken, clothing) => void;
  onRenderOriginalImage: (value: boolean) => void;
}

export const ChickenContext = createContext<ChickenProps | undefined>(
  undefined,
);

export const useChickenContext = () => {
  const context = useContext(ChickenContext);

  return context;
};

export default function ChickenProvider(props: {
  children: React.ReactNode
}) {
  const dispatch = useDispatch();
  const [isRenderOriginalImage, setIsRenderOriginalImage] = useState(false);
  const [isOpenClothingChickens, setIsOpenClothingChickens] = useState(false);
  const [selectedClothing, setSelectedClothing] = useState<any>();
  const [selectedChicken, setSelectedChicken] = useState<any>();

  const onOpenClothingChickens = useCallback((chicken, clothing) => {
    setSelectedClothing(clothing);
    setSelectedChicken(chicken);
    setIsOpenClothingChickens(true);
  }, []);

  const onRenderOriginalImage = useCallback((value: boolean) => {
    setIsRenderOriginalImage(value);
  }, []);

  const onUnequipAndEquip = useCallback((chickenClothing) => {
    setIsOpenClothingChickens(false);
    if (!selectedChicken) {
      return;
    }

    dispatch(setClothingRequest({
      clothingName: chickenClothing.clothing.name,
      clothingId: chickenClothing.clothingId,
      chickenId: selectedChicken.id,
      removeChickenId: chickenClothing.chickenId,
    }));
  }, [selectedChicken, dispatch]);

  const value = useMemo(
    () => ({
      isOpenClothingChickens,
      isRenderOriginalImage,
      onOpenClothingChickens,
      onRenderOriginalImage,
    }),
    [
      isOpenClothingChickens,
      isRenderOriginalImage,
      onOpenClothingChickens,
      onRenderOriginalImage,
    ],
  );
  return (
    <ChickenContext.Provider
      value={value}
    >
      {props.children}
      <ClothingChickensModal
        open={isOpenClothingChickens}
        clothing={selectedClothing}
        onClose={() => setIsOpenClothingChickens(false)}
        onUnequip={onUnequipAndEquip}
      />
    </ChickenContext.Provider>
  );
}
