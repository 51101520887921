import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AssignmentStatus } from 'src/models/assignment-status.enum';
import {
  IPreRegisterLandPayload,
  IPreRegisterLandStats,
  IPreRegisterLandValidateNamePayload,
  IPreRegisterLandValidateEmailPayload,
} from './model';

type PreRegisterLandState = {
  preRegisterLandStatus: AssignmentStatus;
  preRegisterLandValidateNameStatus: AssignmentStatus;
  preRegisterLandValidateEmailStatus: AssignmentStatus;
  stats: IPreRegisterLandStats;
};

const initialState: PreRegisterLandState = {
  preRegisterLandStatus: AssignmentStatus.Draft,
  preRegisterLandValidateNameStatus: AssignmentStatus.Draft,
  preRegisterLandValidateEmailStatus: AssignmentStatus.Draft,
  stats: {},
};

const preRegisterLandSlice = createSlice({
  name: 'pre-register-land',
  initialState,
  reducers: {
    preRegisterLandRequest(state, action: PayloadAction<IPreRegisterLandPayload>) {
      state.preRegisterLandStatus = AssignmentStatus.Pending;
    },
    preRegisterLandSuccessResponse(state, action: PayloadAction<void>) {
      state.preRegisterLandStatus = AssignmentStatus.Success;
    },
    preRegisterLandFailedResponse(state) {
      state.preRegisterLandStatus = AssignmentStatus.Error;
    },
    preRegisterLandValidateNameRequest(state, action: PayloadAction<IPreRegisterLandValidateNamePayload>) {
      state.preRegisterLandValidateNameStatus = AssignmentStatus.Pending;
    },
    preRegisterLandValidateNameSuccessResponse(state, action: PayloadAction<void>) {
      state.preRegisterLandValidateNameStatus = AssignmentStatus.Success;
    },
    preRegisterLandValidateNameFailedResponse(state) {
      state.preRegisterLandValidateNameStatus = AssignmentStatus.Error;
    },
    preRegisterLandValidateEmailRequest(state, action: PayloadAction<IPreRegisterLandValidateEmailPayload>) {
      state.preRegisterLandValidateEmailStatus = AssignmentStatus.Pending;
    },
    preRegisterLandValidateEmailSuccessResponse(state, action: PayloadAction<void>) {
      state.preRegisterLandValidateEmailStatus = AssignmentStatus.Success;
    },
    preRegisterLandValidateEmailFailedResponse(state) {
      state.preRegisterLandValidateEmailStatus = AssignmentStatus.Error;
    },
    getPreRegisterLandStatsResponse(state, action: PayloadAction<IPreRegisterLandStats>) {
      state.stats = action.payload;
    },
  },
});

export const {
  preRegisterLandRequest,
  preRegisterLandSuccessResponse,
  preRegisterLandFailedResponse,

  preRegisterLandValidateNameRequest,
  preRegisterLandValidateNameSuccessResponse,
  preRegisterLandValidateNameFailedResponse,
  preRegisterLandValidateEmailRequest,
  preRegisterLandValidateEmailSuccessResponse,
  preRegisterLandValidateEmailFailedResponse,

  getPreRegisterLandStatsResponse,
} = preRegisterLandSlice.actions;

export default preRegisterLandSlice.reducer;
