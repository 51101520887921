import { PayloadAction } from '@reduxjs/toolkit';
import {
  takeLatest, call, put,
} from 'redux-saga/effects';
import { apiService } from 'src/services/apiService';
import { toastService } from 'src/services/toastService';
import {
  IPreRegisterLandPayload,
  IPreRegisterLandValidateNamePayload,
  IPreRegisterLandValidateEmailPayload,
} from './model';
import {
  preRegisterLandRequest,
  preRegisterLandSuccessResponse,
  preRegisterLandFailedResponse,
  preRegisterLandValidateNameRequest,
  preRegisterLandValidateNameFailedResponse,
  preRegisterLandValidateNameSuccessResponse,
  preRegisterLandValidateEmailRequest,
  preRegisterLandValidateEmailFailedResponse,
  preRegisterLandValidateEmailSuccessResponse,
  getPreRegisterLandStatsResponse,
} from './reducer';
import { getPreRegisterLandStatsAction } from './action';

export function* preRegisterLand(action: PayloadAction<IPreRegisterLandPayload>) {
  try {
    yield call(apiService.preRegisterLand, action.payload);
    yield put(preRegisterLandSuccessResponse());
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');

    yield put(preRegisterLandFailedResponse());
  }
}

export function* preRegisterLandValidateName(action: PayloadAction<IPreRegisterLandValidateNamePayload>) {
  try {
    yield call(apiService.preRegisterLandValidateName, action.payload);
    yield put(preRegisterLandValidateNameSuccessResponse());
  } catch (err: any) {
    yield put(preRegisterLandValidateNameFailedResponse());
  }
}

export function* preRegisterLandValidateEmail(action: PayloadAction<IPreRegisterLandValidateEmailPayload>) {
  try {
    yield call(apiService.preRegisterLandValidateEmail, action.payload);
    yield put(preRegisterLandValidateEmailSuccessResponse());
  } catch (err: any) {
    yield put(preRegisterLandValidateEmailFailedResponse());
  }
}

export function* getPreRegisterLandStats() {
  try {
    const response = yield call(apiService.getPreRegisterLandStats);
    yield put(getPreRegisterLandStatsResponse(response?.data));
  } catch (err) {
    //
  }
}

export function* userEffects() {
  yield takeLatest(preRegisterLandRequest.type, preRegisterLand);
  yield takeLatest(preRegisterLandValidateNameRequest.type, preRegisterLandValidateName);
  yield takeLatest(preRegisterLandValidateEmailRequest.type, preRegisterLandValidateEmail);
  yield takeLatest(getPreRegisterLandStatsAction.type, getPreRegisterLandStats);
}

const preRegisterLandSagas = [call(userEffects)];

export default preRegisterLandSagas;
