import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import Countdown from 'react-countdown';
import { useSelector } from 'react-redux';
import { lastTournamentSelector } from 'src/store/tournament/selector';
import { Link, useLocation } from 'react-router-dom';
import { tournaments } from 'src/constant/tournaments';
import { useTranslation } from 'react-i18next';
import { IS_PRODUCTION_ENV } from 'src/utils/config';
import { charliesChallenges } from 'src/constant/tournament';

const charliesUrl = 'charlies-challenges';
const challengeDuration = IS_PRODUCTION_ENV ? 2880 : 30;
const challengeCount = 7;

export function TournamentsBanner() {
  const location = useLocation();

  const now = moment();
  const tournament = useSelector(lastTournamentSelector);

  const isLandingPage = useMemo(() => location.pathname === '/', [location]);
  const isGetYourChickensPage = useMemo(() => location.pathname === '/get-your-chicken', [location]);
  const isFarmPage = useMemo(() => location.pathname.includes('/farm'), [location]);
  const isHideHeader = useMemo(() => isLandingPage || isGetYourChickensPage || isFarmPage, [isLandingPage, isGetYourChickensPage, isFarmPage]);

  const isLive = useMemo(() => {
    if (!tournament) {
      return false;
    }

    const start = moment.utc(tournament.startAt);
    const end = moment.utc(tournament.endAt);

    return now.isBetween(start, end, undefined, '[]');
  }, [tournament, now]);

  const isUpcoming = useMemo(() => {
    if (!tournament) {
      return false;
    }

    const start = moment.utc(tournament.startAt);

    return now.isBefore(start);
  }, [tournament, now]);

  const liveTournaments = tournaments.filter((t) => {
    const start = moment.utc(t.start);
    const end = moment.utc(t.end);

    return now.isBetween(start, end, undefined, '[]');
  });
  const { t } = useTranslation('translation', { keyPrefix: 'COMPONENTS.TOURNAMENTS' });

  const renderer = ({
    days, hours, minutes, seconds, completed,
  }) => {
    if (completed) {
      return <span>{isUpcoming ? t('LIVE') : t('COMPLETED')}</span>;
    }

    let challengeNumber = 0;

    const startTime = moment.utc(tournament.startAt);
    const duration = moment.utc().diff(startTime, 'minutes', false);
    challengeNumber = Math.floor((duration / challengeDuration) % challengeCount) + 1;

    // Render a countdown
    return (
      <span>
        {days > 0 && (
          <>
            {days}
            d
            {' '}
          </>
        )}
        {String(hours).padStart(2, '0')}
        h
        {' '}
        {String(minutes).padStart(2, '0')}
        m
        {tournament.url === charliesUrl && isLive ? ` - Current Challenge ${charliesChallenges[challengeNumber - 1].name}` : ''}
      </span>
    );
  };

  if (isHideHeader || (!tournament && !liveTournaments.length)) {
    return <></>;
  }

  return (
    <div className="tournaments-banner">
      {(tournament && (isUpcoming || isLive)) && (
        <div className="tournament">
          <Link to={`/tournament/${tournament.url}`}>
            {tournament.name}
          </Link>
          {' - '}
          {isLive ? t('ENDS_IN') : t('STARTS_IN')}
          {' '}
          <Countdown
            date={isUpcoming ? moment.utc(tournament.startAt).toDate() : moment.utc(tournament.endAt).toDate()}
            renderer={renderer}
          />
          {!!tournament.event && (
            <>
              {t('TODAY_EVENT', { event: tournament.event.name })}
              {' '}
              <b>{tournament.event.value}</b>
            </>
          )}
        </div>
      )}
      {liveTournaments.map((tt) => (
        <div className="tournament" key={tt.title}>
          {tt.title}
          {' '}
          {t('ENDS_IN')}
          {' '}
          <Countdown
            date={moment.utc(tt.end).toDate()}
            renderer={renderer}
          />
        </div>
      ))}

    </div>
  );
}
