import React, { useEffect, useState } from 'react';
import { DateRangePicker, DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import './styles.css';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment-timezone';

export function DateRangeInput({
  startDate, endDate, onChange, placeholder,
}: {
  startDate?: Date,
  endDate?: Date,
  onChange?: (start: Date, end: Date) => void,
  placeholder?: string
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState([
    {
      startDate: startDate || new Date(),
      endDate: endDate || new Date(),
      key: 'selection',
    },
  ]);

  const onClear = () => {
    setIsOpen(false);

    if (onChange) {
      onChange(null, null);
    }
  };

  const onSelect = () => {
    setIsOpen(false);

    if (onChange) {
      onChange(state[0].startDate, state[0].endDate);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setState([{
      startDate: startDate || new Date(),
      endDate: endDate || new Date(),
      key: 'selection',
    }]);
  }, [isOpen, startDate, endDate]);

  return (
    <div className="date-picker-container">
      <div
        className="date-picker-input"
        onClick={() => setIsOpen(true)}
      >
        {!startDate && !endDate && placeholder && <span>{placeholder}</span>}
        {startDate && moment(startDate).format('YYYY-MM-DD')}
        {endDate && ` ~ ${moment(endDate).format('YYYY-MM-DD')}`}
      </div>
      <div className={`date-picker-modal ${isOpen ? 'show' : ''}`}>
        {isMobile ? (
          <DateRange
            editableDateInputs
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        ) : (
          <DateRangePicker
            editableDateInputs
            onChange={(item) => setState([item.selection])}
            showSelectionPreview
            moveRangeOnFirstSelection={false}
            ranges={state}
            direction="vertical"
          />
        )}
        <div className="date-picker-actions">
          <div
            className="date-picker-btn clear-btn"
            onClick={() => onClear()}
          >
            Clear
          </div>
          <div
            className="date-picker-btn select-btn"
            onClick={() => onSelect()}
          >
            Ok
          </div>
        </div>
      </div>
    </div>
  );
}
