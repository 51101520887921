import { IContract } from 'src/models';
import { BAWK_CONTRACT_ADDRESS } from 'src/utils/config';

export const BAWK_CONTRACT: IContract = {
  name: 'JEWELS stable pegged token',
  version: '1.0.0',
  address: BAWK_CONTRACT_ADDRESS,
  abi: [{ inputs: [], stateMutability: 'nonpayable', type: 'constructor' }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'owner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'spender', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
    }],
    name: 'Approval',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferStarted',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: '', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: '', type: 'uint256',
    }],
    name: 'TokenMintedByOperator',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: '', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: '', type: 'uint256',
    }],
    name: 'TokenPurchased',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'from', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'to', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
    }],
    name: 'Transfer',
    type: 'event',
  }, {
    inputs: [], name: 'acceptOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'admin', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'owner', type: 'address' }, { internalType: 'address', name: 'spender', type: 'address' }], name: 'allowance', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_account', type: 'address' }, { internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'appendEscrowEntry', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'approve', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }], name: 'balanceOf', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'bawkEscrow', outputs: [{ internalType: 'contract BawkEscrow', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'decimals', outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' }], name: 'decreaseAllowance', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, { internalType: 'uint256', name: 'addedValue', type: 'uint256' }], name: 'increaseAllowance', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'forwarder', type: 'address' }], name: 'isTrustedForwarder', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_account', type: 'address' }, { internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'mintByOperator', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'pendingOwner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_admin', type: 'address' }], name: 'setAdmin', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_bawkEscrow', type: 'address' }], name: 'setBawkEscrow', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_forwarder', type: 'address' }], name: 'setTrustedForwarder', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'symbol', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'totalSupply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'transfer', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'transferFrom', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'trustedForwarder', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'versionRecipient', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'pure', type: 'function',
  }],
};
