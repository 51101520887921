import ContractService from './baseContractService';
import { RaceContractService } from './raceContractService';
import { LandContractService } from './landContractService';
import { MarketplaceContractService } from './marketplaceContractService';
import { FusionContractService } from './fusionContractService';
import { CoinPresaleContractService } from './coinPresaleContractService';
import { UsdtContractService } from './usdtContractService';
import { PolygonUsdcContractService } from './polygonUsdcContractService';
import { JewelsContractService } from './jewelsContractService';
import { WethContractService } from './wethContractService';
import { ChickenContractService } from './chickenContractService';
import { ChickenStakingContractService } from './chickenStakingContractService';
import { BawkEscrowContractService } from './bawkEscrowContractService';
import { BawkStakingContractService } from './bawkStakingContractService';
import { BawkContractService } from './bawkContractService';
import { BawkPresaleContractService } from './bawkPresaleContractService';

export { ContractService };

export const raceContractService = new RaceContractService();
export const landContractService = new LandContractService();
export const marketplaceContractService = new MarketplaceContractService();
export const fusionContractService = new FusionContractService();
export const coinPresaleContractService = new CoinPresaleContractService();
export const usdtContractService = new UsdtContractService();
export const polygonUsdcContractService = new PolygonUsdcContractService();
export const jewelsContractService = new JewelsContractService();
export const wethContractService = new WethContractService();
export const chickenContractService = new ChickenContractService();
export const chickenStakingContractService = new ChickenStakingContractService();
export const bawkContractService = new BawkContractService();
export const bawkEscrowContractService = new BawkEscrowContractService();
export const bawkStakingContractService = new BawkStakingContractService();
export const bawkPresaleContractService = new BawkPresaleContractService();
