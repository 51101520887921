import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedRequest } from 'src/models';

type TrainingPartnersState = {
  count: number;
  trainingPartners: any;
  totalCount: number;
  error: any;
};

const initialState: TrainingPartnersState = {
  count: 0,
  trainingPartners: [],
  totalCount: 0, // backend!, i dont know why you named like this. confused due to the count and totalCount
  error: null,
};

const trainingPartersSlice = createSlice({
  name: 'trainingParters',
  initialState,
  reducers: {
    trainingPartnersPullRequest(state, action: PayloadAction<PaginatedRequest>) {
      if (action.payload.page === 1) {
        state.trainingPartners = [];
        state.totalCount = 0;
        state.error = null;
      }
    },
    trainingPartnersPullResponse(state, action: PayloadAction<any>) {
      const { data } = action.payload;
      state.trainingPartners = state.trainingPartners.concat(data.rows);
      state.totalCount = data.totalCount;
      state.count = data.count;
    },
    trainingPartnersPullError(state, action: PayloadAction<any>) {
      state.error = action.payload;
    },
  },
});

export const { trainingPartnersPullRequest, trainingPartnersPullResponse, trainingPartnersPullError } = trainingPartersSlice.actions;

export default trainingPartersSlice.reducer;
