import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  takeLatest, call, put, delay,
} from 'redux-saga/effects';
import { PaginatedRequest } from 'src/models';
import { apiService } from 'src/services/apiService';
import { toastService } from 'src/services/toastService';
import { FusionRequest } from './model';
import {
  getFusionChickensResponse,
  getFusionChickensRequest,
  getFusionPricesRequest,
  getFusionPricesResponse,
  checkFuseChickensRequest,
  checkFuseChickensFailedResponse,
  checkFuseChickensSuccessResponse,
  fuseChickenRequest,
  fuseChickensSuccessResponse,
  checkLastFuseAssignmentRequest,
  fuseChickensFailedResponse,
  checkLastFuseAssignmentSuccessResponse,
  checkLastFuseAssignmentFailedResponse,
  setFusedChickenInfo,
} from './reducer';

function* getFusionChickens(action: PayloadAction<PaginatedRequest>) {
  try {
    const res = yield call(apiService.getChickens, action.payload);
    yield put(getFusionChickensResponse(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getFusionChickensResponse({
      rows: [],
      count: 0,
    }));
  }
}

function* getFusionPrices(action: PayloadAction<number[]>) {
  try {
    const res = yield call(apiService.getFusionPrices, action.payload);
    yield put(getFusionPricesResponse(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getFusionPricesResponse(null));
  }
}

export function* checkFuseChickens(action: PayloadAction<FusionRequest>) {
  try {
    const res = yield call(apiService.checkFusionAssignment, action.payload);
    yield put(checkFuseChickensSuccessResponse(res.data));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');

    yield put(checkFuseChickensFailedResponse());
  }
}

export function* fuseChicken(action: PayloadAction<FusionRequest>) {
  try {
    yield call(apiService.fuseChicken, action.payload);

    yield put(fuseChickensSuccessResponse());

    yield put(checkLastFuseAssignmentRequest(true));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');

    yield put(fuseChickensFailedResponse(undefined));
  }
}

export function* checkLastFuseAssignment({ payload }: { payload: boolean }) {
  try {
    const res = yield call(apiService.checkLastFusionAssignment);
    const { fusionAssignment } = res.data;

    if (!fusionAssignment) {
      yield put(checkLastFuseAssignmentSuccessResponse(false));
      return;
    }

    if (fusionAssignment.status === 'success') {
      yield put(setFusedChickenInfo(fusionAssignment));
      yield put(checkLastFuseAssignmentSuccessResponse(!!payload));

      if (payload) {
        toast.success('Fused chickens successfully');
      }
    } else if (fusionAssignment.status === 'error') {
      yield put(checkLastFuseAssignmentFailedResponse());

      if (payload) {
        throw new Error(fusionAssignment.error.message);
      }
    } else {
      yield put(setFusedChickenInfo(fusionAssignment));
      yield delay(5 * 1000);
      yield put(checkLastFuseAssignmentRequest(true));
    }
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
    yield put(checkLastFuseAssignmentFailedResponse());
  }
}

export function* userEffects() {
  yield takeLatest(getFusionChickensRequest.type, getFusionChickens);
  yield takeLatest(getFusionPricesRequest.type, getFusionPrices);
  yield takeLatest(checkFuseChickensRequest.type, checkFuseChickens);
  yield takeLatest(fuseChickenRequest.type, fuseChicken);
  yield takeLatest(checkLastFuseAssignmentRequest.type as any, checkLastFuseAssignment);
}

const fusionSagas = [call(userEffects)];

export default fusionSagas;
