import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  getToken,
  getMessaging,
  isSupported,
  Messaging,
  onMessage,
} from 'firebase/messaging';
import axios, { AxiosRequestConfig } from 'axios';

import { FIREBASE_KEY, FIREBASE_NOTIFICATION_TOKEN } from './constant/config';
import { FirebaseTopic } from './models';

const firebaseConfig = {
  apiKey: 'AIzaSyDOVUlqfw5-V5Fb-ZJMpzhEor3G_PE6pBA',
  authDomain: 'chicken-derby-347717.firebaseapp.com',
  projectId: 'chicken-derby-347717',
  storageBucket: 'chicken-derby-347717.appspot.com',
  messagingSenderId: '360651598401',
  appId: '1:360651598401:web:0986cdad415a9e1557b4c0',
};

class Firebase {
  app: FirebaseApp;

  messaging?: Messaging;

  constructor() {
    this.app = initializeApp(firebaseConfig);
  }

  async initMessage() {
    if (this.messaging) {
      return;
    }

    const supported = await isSupported();

    if (supported) {
      this.messaging = getMessaging(this.app);

      onMessage(this.messaging, (payload) => {
        if (!payload.data) {
          return;
        }

        const { title, body, url } = payload.data;
        // eslint-disable-next-line no-new
        const notification = new Notification(title, {
          body,
          icon: '/favicon.ico',
        });

        notification.addEventListener('click', () => {
          if (url) {
            notification.close();
            window.open(url, '__blank');
          }
        });
      });
    }
  }

  async getFirebaseToken(): Promise<string> {
    const supported = await isSupported();
    if (!supported) {
      return null;
    }

    if (!this.messaging) {
      return null;
    }

    try {
      const token = await getToken(this.messaging, { vapidKey: FIREBASE_KEY });
      return token;
    } catch (err) {
      return null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async subscribeToTopic(token: string, topic: FirebaseTopic) {
    if (!token) {
      return;
    }

    try {
      const topicURL = `https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`;
      const axiosConfig: AxiosRequestConfig = {
        url: topicURL,
        method: 'post',
        timeout: 5000,
        headers: {
          Authorization: `key=${FIREBASE_NOTIFICATION_TOKEN}`,
          'cache-control': 'no-cache',
          'content-type': 'application/json',
        },
      };

      await axios(axiosConfig);
    } catch (err) {
      console.error(`Can't subscribe to ${topic}`, err);
    }
  }
}

export const firebase = new Firebase();
