import { toast } from 'react-toastify';
import {
  takeLatest, call, put, delay, select,
} from 'redux-saga/effects';
import { apiService } from 'src/services/apiService';
import { toastService } from 'src/services/toastService';
import {
  checkLastLandMintFailed,
  checkLastLandMintRequest,
  checkLastLandMintSuccess,
  checkLandMintRequest,
  checkLandMintFailed,
  checkLandMintResponse,
  landMintRequest,
  landMintFailed,
  landMintSuccess,
  getLandsRequest,
  getLandsSuccess,
  getLandsFailed,
} from './reducer';

function* checkLastLandMint({ payload }: { payload: boolean }) {
  try {
    const res = yield call(apiService.checkLastMintLand);
    const { landMint } = res.data;

    if (!landMint) {
      yield put(checkLastLandMintSuccess(false));
      return;
    }

    if (landMint.status === 'success') {
      yield put(checkLastLandMintSuccess(!!payload));

      if (payload) {
        toast.success('Minted land(s) successfully');
      }
    } else if (landMint.status === 'error') {
      yield put(checkLastLandMintFailed(false));

      if (payload) {
        throw new Error(landMint.error.message);
      }
    } else {
      yield delay(5 * 1000);
      yield put(checkLastLandMintRequest(true));
    }
  } catch (err: any) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(checkLastLandMintFailed(false));
  }
}

function* checkLandMint() {
  try {
    const res = yield call(apiService.checkLandMint);
    yield put(checkLandMintResponse(res.data));
  } catch (err: any) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(checkLandMintFailed({}));
  }
}

function* mint({ payload }: { payload: any }) {
  try {
    const res = yield call(apiService.landMint, payload);

    yield put(landMintSuccess(res.data));
    yield put(checkLastLandMintRequest(true));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
    yield put(landMintFailed(message));
  }
}

function* getLands() {
  const state = yield select();
  const isLoggedIn = state.userWallet?.logged;

  if (!isLoggedIn) {
    return;
  }

  try {
    const res = yield call(apiService.getLands);

    yield put(getLandsSuccess(res.data));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
    yield put(getLandsFailed());
  }
}

export function* userEffects() {
  yield takeLatest(checkLastLandMintRequest.type as any, checkLastLandMint);
  yield takeLatest(checkLandMintRequest.type as any, checkLandMint);
  yield takeLatest(landMintRequest as any, mint);
  yield takeLatest(getLandsRequest as any, getLands);
}

const landSagas = [call(userEffects)];

export default landSagas;
