import { createSelector } from 'reselect';
import { RootState } from '../common/reducer';

export const selectMarketPlaceState = (rootState: RootState) => rootState.marketplace;

export const isProcessingMarketplaceSelector = createSelector([selectMarketPlaceState], (state) => state.isProcessing);
export const isCheckingMarketplaceAssignmentSelector = createSelector([selectMarketPlaceState], (state) => state.isCheckingAssignment);

export const marketplaceActionAssignmentSelector = createSelector([selectMarketPlaceState], (state) => state.action);
export const isFetchingMarketplaceListSelector = createSelector([selectMarketPlaceState], (state) => state.isLoadingList || false);

export const madeOffersSelector = createSelector([selectMarketPlaceState], (state) => state.madeOffers?.rows || []);
export const madeOffersCountSelector = createSelector([selectMarketPlaceState], (state) => state.madeOffers?.count || 0);
export const madeOffersReachedSelector = createSelector([selectMarketPlaceState], (state) => state.madeOffers?.isReached || false);

export const receivedOffersSelector = createSelector([selectMarketPlaceState], (state) => state.receivedOffers?.rows || []);
export const receivedOffersCountSelector = createSelector([selectMarketPlaceState], (state) => state.receivedOffers?.count || 0);
export const receivedOffersReachedSelector = createSelector([selectMarketPlaceState], (state) => state.receivedOffers?.isReached || false);
export const chickenMarketItemsSelector = createSelector([selectMarketPlaceState], (state) => state.chickenMarketItems?.rows || []);
export const chickenMarketItemsCountSelector = createSelector([selectMarketPlaceState], (state) => state.chickenMarketItems?.count || 0);
export const chickenMarketItemsReachedSelector = createSelector([selectMarketPlaceState], (state) => state.chickenMarketItems?.isReached || false);
export const chickenTradeActivitiesSelector = createSelector([selectMarketPlaceState], (raceState) => raceState.chickenTradeActivities?.rows || []);
export const chickenTradeActivitiesCountSelector = createSelector([selectMarketPlaceState], (raceState) => raceState.chickenTradeActivities?.count || 0);
export const chickenTradeActivitiesReachedSelector = createSelector([selectMarketPlaceState], (raceState) => raceState.chickenTradeActivities?.isReached || false);
export const marketPlaceStatusSelector = createSelector([selectMarketPlaceState], (state) => state.marketPlaceStatus);
