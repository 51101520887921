import React from 'react';

export function CaretIcon({
  size = 24,
  color = '#FFF',
  onClick,
}: {
  size?: number
  color?: string
  onClick?: () => void
}) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.8008 10.0999L12.0008 14.8999L7.20078 10.0999" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
