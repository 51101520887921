import React, { useState } from 'react';
import { useThemeContext } from 'src/contexts/theme';
import { IMAGES_URL } from 'src/constant/images';
import { CalendarEvent } from 'src/models/calendar-event.type';
import { buildCalendarUrl } from 'src/utils/calendar';
import ReactTooltip from 'react-tooltip';
import { CalendarIcon } from '../calendar-icon';
import { AppleIcon } from '../apple-icon';

import './styles.css';

export function AddToCalendar(props: CalendarEvent) {
  const [isOpen, setIsOpen] = useState(false);
  const { themeColors } = useThemeContext();

  return (
    <div className="add-to-calendar-container">
      <div
        data-for="add-to-calendar"
        data-tip={props?.tooltip || ''}
        data-iscapture="true"
      >
        <CalendarIcon
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(true);
          }}
          color={themeColors.raceText}
        />
      </div>
      {isOpen && (
        <div
          className="calendar-item-overlay"
          onClick={((e) => {
            e.stopPropagation();
            setIsOpen(false);
          })}
        />
      )}
      <div className={`calendar-item-container ${isOpen ? 'show' : ''}`}>
        <a
          className="calendar-item"
          href={buildCalendarUrl(props, 'google')}
          target="_blank"
          rel="noreferrer"
          onClick={((e) => {
            e.stopPropagation();
            setIsOpen(false);
          })}
        >
          <div className="calendar-item-icon">
            <img src={IMAGES_URL.ICONS.GOOGLE_CALENDAR_ICON} alt="" />
          </div>
          Google
        </a>
        <a
          className="calendar-item"
          href={buildCalendarUrl(props, 'outlook')}
          target="_blank"
          rel="noreferrer"
          onClick={((e) => {
            e.stopPropagation();
            setIsOpen(false);
          })}
        >
          <div className="calendar-item-icon">
            <img src={IMAGES_URL.ICONS.OUTLOOK_ICON} alt="" />
          </div>
          Outlook
        </a>
        <a
          className="calendar-item"
          href={buildCalendarUrl(props, 'office')}
          target="_blank"
          rel="noreferrer"
          onClick={((e) => {
            e.stopPropagation();
            setIsOpen(false);
          })}
        >
          <div className="calendar-item-icon">
            <img src={IMAGES_URL.ICONS.OFFICE_ICON} alt="" />
          </div>
          Office 356
        </a>
        <a
          className="calendar-item"
          href={buildCalendarUrl(props, 'apple')}
          target="_blank"
          rel="noreferrer"
          onClick={((e) => {
            e.stopPropagation();
            setIsOpen(false);
          })}
        >
          <div className="calendar-item-icon">
            <AppleIcon size={40} color={themeColors.text} />
          </div>
          Apple
        </a>
      </div>
      <ReactTooltip id="add-to-calendar" />
    </div>
  );
}
