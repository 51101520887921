import { toast } from 'react-toastify';

class ToastService {
  isSentToast = false

  public send = (message: string, type: 'info' | 'error' | 'warning' = 'info') => {
    let toastFunc = toast.info;

    if (type === 'error') {
      toastFunc = toast.error;
    }

    if (type === 'warning') {
      toastFunc = toast.warn;
    }

    if (!this.isSentToast) {
      toastFunc(message);
      this.isSentToast = true;
      setTimeout(() => {
        this.isSentToast = false;
      }, 500);
    }
  }
}

export const toastService = new ToastService();
