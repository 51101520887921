const contractErrors = [
  {
    original: 'execution reverted: Signer and signature do not match',
    message:
      'execution reverted: Signer and signature do not match. Please try to confirm the transaction again',
  },
];

const transformRpcError = (error: any): any => {
  if (!error.message?.includes('Internal JSON-RPC error')) {
    return error;
  }

  try {
    const errorJsonString = error.message.replace(
      'Internal JSON-RPC error.',
      '',
    );
    const errorJsonObject = JSON.parse(errorJsonString);

    const message = errorJsonObject?.message || errorJsonObject?.details;
    if (message) {
      return new Error(message);
    }
  } catch (err) {
    //
  }

  return error;
};

export const transformContractError = (error: any) => {
  try {
    const contractError = contractErrors.find((ctError) => error.message?.includes(ctError.original));
    if (contractError) {
      return new Error(contractError.message);
    }
  } catch (err) {
    //
  }

  return transformRpcError(error);
};
