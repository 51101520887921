import React, { useMemo } from 'react';
import ReactSelect from 'react-select';
import { useThemeContext } from 'src/contexts/theme';
import { SelectOption } from 'src/models';

export function Select({
  value,
  options,
  onChange,
  placeholder,
  selectValue,
  isMulti = true,
  isSearchable = true,
  closeMenuOnSelect = false,
  height,
  minWidth,
}: {
  value: any,
  options: SelectOption[],
  selectValue?: boolean
  onChange: (val: any) => void,
  placeholder?: string,
  isMulti?: boolean
  isSearchable?: boolean
  closeMenuOnSelect?: boolean
  height?: number
  minWidth?: number
}) {
  const { themeColors } = useThemeContext();

  const optionValue = useMemo(() => {
    if (!selectValue) {
      return value;
    }

    if (isMulti) {
      return options.filter((item) => value?.includes(item.value));
    }

    const option = options.find((item) => item.value === value);

    return option;
  }, [options, value, selectValue, isMulti]);

  const onChangeSelect = (data: any) => {
    onChange(selectValue ? isMulti ? data.map((option) => option.value) : data.value : data);
  };

  return (
    <ReactSelect
      styles={{
        placeholder: (styles) => ({ ...styles, color: themeColors.placeholder }),
        control: (styles) => ({
          ...styles, borderColor: '#666666', height, minWidth,
        }),
        menu: (styles) => ({
          ...styles, zIndex: 1000,
        }),
        option: (styles, { isFocused }) => ({
          ...styles,
          color: themeColors.text,
          backgroundColor: isFocused ? themeColors.select : themeColors.input,
        }),
        multiValue: (styles, { data }) => ({
          ...styles,
          backgroundColor: themeColors.select,
        }),
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: themeColors.textLight,
        }),
        singleValue: (styles, { data }) => ({
          ...styles,
          color: themeColors.textLight,
        }),
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          color: themeColors.textLight,
        }),
        clearIndicator: (styles) => ({
          ...styles,
          ':hover': {
            color: themeColors.textLight,
          },
        }),
        dropdownIndicator: (styles) => ({
          ...styles,
          ':hover': {
            color: themeColors.textLight,
          },
        }),
      }}
      isMulti={isMulti}
      isSearchable={isSearchable}
      value={optionValue}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          neutral0: themeColors.input,
        },
      })}
      closeMenuOnSelect={closeMenuOnSelect}
      options={options}
      onChange={onChangeSelect}
      placeholder={placeholder}
    />
  );
}
