import { createSelector } from 'reselect';
import { RootState } from '../common/reducer';

export const selectChickenState = (rootState: RootState) => rootState.chicken;

export const chickenSelector = createSelector([selectChickenState], (state) => state.chicken || null);

export const isChickenLoadingSelector = createSelector([selectChickenState], (state) => state.loading);

export const chickenTradeOffersSelector = createSelector([selectChickenState], (raceState) => raceState.tradeOffers?.rows || []);

export const chickenTradeOffersCountSelector = createSelector([selectChickenState], (raceState) => raceState.tradeOffers?.count || 0);

export const chickenTradeOffersReachedSelector = createSelector([selectChickenState], (raceState) => raceState.tradeOffers?.isReached || false);

export const isFetchingChickenTradeOffersSelector = createSelector([selectChickenState], (raceState) => raceState.isFetchingTradeOffers || false);

export const chickenTradeActivitiesSelector = createSelector([selectChickenState], (raceState) => raceState.tradeActivities?.rows || []);

export const chickenTradeActivitiesCountSelector = createSelector([selectChickenState], (raceState) => raceState.tradeActivities?.count || 0);

export const chickenTradeActivitiesReachedSelector = createSelector([selectChickenState], (raceState) => raceState.tradeActivities?.isReached || false);

export const isFetchingChickenTradeActivitiesSelector = createSelector([selectChickenState], (raceState) => raceState.isFetchingTradeActivities || false);

export const chickenTournamentBadgesSelector = createSelector([selectChickenState], (raceState) => raceState.tournamentBadges?.rows || []);

export const chickenTournamentBadgesCountSelector = createSelector([selectChickenState], (raceState) => raceState.tournamentBadges?.count || 0);

export const chickenTournamentBadgesReachedSelector = createSelector([selectChickenState], (raceState) => raceState.tournamentBadges?.isReached || false);

export const isFetchingChickenTournamentBadgesSelector = createSelector([selectChickenState], (raceState) => raceState.isFetchingTournamentBadges || false);

export const chickenSeasonBadgesSelector = createSelector([selectChickenState], (raceState) => raceState.seasonBadges?.rows || []);

export const chickenSeasonBadgesCountSelector = createSelector([selectChickenState], (raceState) => raceState.seasonBadges?.count || 0);

export const chickenSeasonBadgesReachedSelector = createSelector([selectChickenState], (raceState) => raceState.seasonBadges?.isReached || false);

export const isFetchingChickenSeasonBadgesSelector = createSelector([selectChickenState], (raceState) => raceState.isFetchingSeasonBadges || false);

export const chickensSelector = createSelector([selectChickenState], (raceState) => raceState.chickens?.rows || []);

export const chickensCountSelector = createSelector([selectChickenState], (raceState) => raceState.chickens?.count || 0);

export const chickensReachedSelector = createSelector([selectChickenState], (raceState) => raceState.chickens?.isReached || false);
export const isChickensLoadingSelector = createSelector([selectChickenState], (state) => state.isFetchingChickens);

export const chickenGenealogySelector = createSelector([selectChickenState], (raceState) => raceState.genealogy || []);
export const isFetchingChickenGenealogySelector = createSelector([selectChickenState], (state) => state.isFetchingGenealogy);

export const chickenOverallStatsSelector = createSelector([selectChickenState], (raceState) => raceState.overallStats || []);
export const isFetchingChickenOverallStatsSelector = createSelector([selectChickenState], (state) => state.isFetchingOverallStats);

export const chickenTerrainStatsSelector = createSelector([selectChickenState], (raceState) => raceState.terrainStats || []);
export const isFetchingChickenTerrainStatsSelector = createSelector([selectChickenState], (state) => state.isFetchingTerrainStats);

export const chickenDistanceStatsSelector = createSelector([selectChickenState], (raceState) => raceState.distanceStats || []);
export const isFetchingChickenDistanceStatsSelector = createSelector([selectChickenState], (state) => state.isFetchingDistanceStats);
export const chickenContractStatsSelector = createSelector([selectChickenState], (state) => state.chickenContractStats || null);
