import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put } from 'redux-saga/effects';
import { apiService } from 'src/services/apiService';
import { toastService } from 'src/services/toastService';
import {
  getLastTournamentFailed,
  getLastTournamentRequest,
  getLastTournamentSuccess,
  getTournamentFailed,
  getTournamentGroupRankingsFailed,
  getTournamentGroupRankingsRequest,
  getTournamentGroupRankingsSuccess,
  getTournamentRankingsFailed,
  getTournamentRankingsMasterFailed,
  getTournamentRankingsMasterRequest,
  getTournamentRankingsMasterSuccess,
  getTournamentRankingsRequest,
  getTournamentRankingsSuccess,
  getTournamentRankingTotalsRequest,
  getTournamentRankingTotalsResponse,
  getTournamentRequest,
  getTournamentsFailed,
  getTournamentsRequest,
  getTournamentsSuccess,
  getTournamentSuccess,
} from './reducer';

export function* getActiveTournament() {
  try {
    const response = yield call(
      apiService.getActiveTournament,
    );

    yield put(getLastTournamentSuccess(response?.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getLastTournamentFailed(err));
  }
}

export function* getTournament({ payload }: any) {
  try {
    const res = yield call(apiService.getTournament, payload);
    yield put(getTournamentSuccess(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getTournamentFailed(err));
  }
}

export function* getTournamentRankings({ payload }: any) {
  try {
    const res = yield call(
      apiService.getTournamentRankings,
      payload,
    );
    yield put(getTournamentRankingsSuccess(res?.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getTournamentRankingsFailed(err));
  }
}

export function* getTournamentRankingsMaster({ payload }: any) {
  try {
    const res = yield call(
      apiService.getTournamentRankingsMaster,
      payload,
    );
    yield put(getTournamentRankingsMasterSuccess(res?.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getTournamentRankingsMasterFailed(err));
  }
}

export function* getTournaments({ payload }: any) {
  try {
    const res = yield call(
      apiService.getTournaments,
      payload,
    );
    yield put(getTournamentsSuccess(res?.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getTournamentsFailed(err));
  }
}

export function* getTournamentRankingTotals({ payload }: PayloadAction<string>) {
  try {
    const res = yield call(
      apiService.getTournamentRankingsTotals,
      payload,
    );
    yield put(getTournamentRankingTotalsResponse(res?.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getTournamentRankingTotalsResponse(null));
  }
}

export function* getTournamentGroupRankings({ payload }: PayloadAction<any>) {
  try {
    const res = yield call(
      apiService.getTournamentGroupRankings,
      payload,
    );
    yield put(getTournamentGroupRankingsSuccess(res?.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getTournamentGroupRankingsFailed(null));
  }
}

export function* userEffects() {
  yield takeLatest(getLastTournamentRequest, getActiveTournament);
  yield takeLatest(getTournamentRequest, getTournament);
  yield takeLatest(getTournamentRankingsRequest, getTournamentRankings);
  yield takeLatest(getTournamentRankingsMasterRequest, getTournamentRankingsMaster);
  yield takeLatest(getTournamentsRequest, getTournaments);
  yield takeLatest(getTournamentRankingTotalsRequest, getTournamentRankingTotals);
  yield takeLatest(getTournamentGroupRankingsRequest, getTournamentGroupRankings);
}

const tournamentSagas = [call(userEffects)];

export default tournamentSagas;
