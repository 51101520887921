import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedList, PaginatedRequest } from 'src/models';
import * as _ from 'lodash';
import { FusionRequest } from './model';

type FusionState = {
  chickens: any;
  isLoadingChickens: boolean;
  loading: boolean;
  fusionPrices: any;
  isFused: boolean;
  isFusing: boolean;
  isCheckingFuseAssignment: boolean;
  isCheckedFuseAssignment: boolean;
  fusedChickenId: number | null;
  fusedChicken: any | null;
  materialChickenA: any | null;
  materialChickenB: any | null;
  gasLimit: number;
  coupon: any;
};

const initialState: FusionState = {
  chickens: {
    rows: [],
    count: 0,
    isReached: false,
  },
  loading: false,
  isLoadingChickens: false,
  fusionPrices: null,
  isFused: false,
  isFusing: false,
  isCheckingFuseAssignment: false,
  isCheckedFuseAssignment: false,
  fusedChickenId: null,
  fusedChicken: null,
  materialChickenA: null,
  materialChickenB: null,
  gasLimit: 0,
  coupon: null,
};

const fusionSlice = createSlice({
  name: 'fusion',
  initialState,
  reducers: {
    getFusionChickensRequest(state, action: PayloadAction<PaginatedRequest>) {
      if (action.payload.page === 1) {
        state.chickens = {
          rows: [],
          count: 0,
          isReached: false,
        };
      }
      state.isLoadingChickens = true;
    },
    getFusionChickensResponse(state, action: PayloadAction<PaginatedList<any>>) {
      const chickens = _.uniqBy([...state.chickens.rows, ...action.payload.rows], 'id');
      const isReached = chickens.length === action.payload.count || !action.payload.rows?.length;
      state.chickens = {
        rows: chickens,
        count: action.payload.count,
        isReached,
      };
      state.isLoadingChickens = false;
    },
    getFusionPricesRequest(state, action: PayloadAction<number[]>) {
      state.fusionPrices = null;
      state.loading = true;
    },
    getFusionPricesResponse(state, action: PayloadAction<PaginatedList<any>>) {
      state.fusionPrices = action.payload;
      state.loading = false;
    },
    clearIsFused(state) {
      state.isFused = false;
      state.isCheckedFuseAssignment = false;
      state.isCheckingFuseAssignment = false;
      state.gasLimit = 0;
      state.coupon = null;
      state.fusedChicken = null;
      state.fusedChickenId = null;
      state.materialChickenA = null;
      state.materialChickenB = null;
    },
    checkFuseChickensRequest(state, action: PayloadAction<FusionRequest>) {
      state.isFused = false;
      state.isFusing = false;
      state.isCheckingFuseAssignment = true;
      state.isCheckedFuseAssignment = false;
      state.gasLimit = 0;
      state.coupon = null;
      state.fusedChickenId = null;
      state.fusedChicken = null;
      state.materialChickenA = null;
      state.materialChickenB = null;
    },
    checkFuseChickensSuccessResponse(state, action: PayloadAction<any>) {
      state.isCheckingFuseAssignment = false;
      state.isCheckedFuseAssignment = true;
      state.gasLimit = action.payload.gasLimit;
      state.coupon = action.payload.coupon;
      state.fusedChickenId = action.payload.fusedChickenId;
    },
    checkFuseChickensFailedResponse(state) {
      state.isFusing = false;
      state.isCheckingFuseAssignment = false;
      state.isCheckedFuseAssignment = false;
    },
    fuseChickenRequest(state, action: PayloadAction<FusionRequest>) {
      state.isFusing = true;
    },
    fuseChickensSuccessResponse(state) {
      state.isFusing = true;
    },
    fuseChickensFailedResponse(state, action: PayloadAction<any>) {
      state.isFusing = false;
    },
    checkLastFuseAssignmentRequest(state, action: PayloadAction<boolean>) {
      state.isFused = false;
      state.isFusing = action.payload;
    },
    checkLastFuseAssignmentSuccessResponse(state, action: PayloadAction<boolean>) {
      state.isFused = action.payload;
      state.isFusing = false;
    },
    checkLastFuseAssignmentFailedResponse(state) {
      state.isFused = false;
      state.isFusing = false;
    },
    setFusedChickenInfo(state, action: PayloadAction<any>) {
      state.materialChickenA = action.payload.materialChicken1;
      state.materialChickenB = action.payload.materialChicken2;
      state.fusedChicken = action.payload.chickenData;
    },
  },
});

export const {
  getFusionChickensRequest,
  getFusionChickensResponse,
  getFusionPricesRequest,
  getFusionPricesResponse,
  clearIsFused,
  checkFuseChickensRequest,
  checkFuseChickensSuccessResponse,
  checkFuseChickensFailedResponse,
  fuseChickenRequest,
  fuseChickensSuccessResponse,
  fuseChickensFailedResponse,
  checkLastFuseAssignmentRequest,
  checkLastFuseAssignmentSuccessResponse,
  checkLastFuseAssignmentFailedResponse,
  setFusedChickenInfo,
} = fusionSlice.actions;

export default fusionSlice.reducer;
