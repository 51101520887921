import { BAWK_STAKING_CONTRACT } from 'src/abis';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import { ICoupon } from 'src/models';
import { ChainId } from 'src/constant/chainId';
import ContractService from './baseContractService';

export class BawkStakingContractService extends ContractService {
  constructor() {
    super(
      ChainId.Polygon,
      BAWK_STAKING_CONTRACT.address,
      BAWK_STAKING_CONTRACT.abi as AbiItem[],
    );

    ContractService.add(this);
  }

  async stake(userWalletId: string, cid: number, amount: number, gasLimit: number, coupon: ICoupon) {
    return this.contractCall(async () => {
      const qty = Web3.utils.toWei(amount.toString(), 'ether');
      const functionSignature = this.contract.methods
        .stake(cid, qty, coupon)
        .encodeABI();

      return this.signForwardTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
        gasLimit,
      );
    });
  }

  async unstake(userWalletId: string, cid: number, amount: number, gasLimit: number, coupon: ICoupon) {
    return this.contractCall(async () => {
      const qty = Web3.utils.toWei(amount.toString(), 'ether');
      const functionSignature = this.contract.methods
        .unstake(cid, qty, coupon)
        .encodeABI();

      return this.signForwardTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
        gasLimit,
      );
    });
  }

  async claimRewardsFromCompanyByEpoch(userWalletId: string, cid: number, epoch: number, gasLimit: number, coupon: ICoupon) {
    return this.contractCall(async () => {
      const functionSignature = this.contract.methods.claimRewardsFromCompanyByEpoch(cid, epoch, coupon).encodeABI();

      return this.signForwardTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
        gasLimit,
      );
    });
  }
}
