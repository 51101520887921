import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ExchangeState = {
  rate: any;
};

const initialState: ExchangeState = {
  rate: {},
};

const exchangeSlice = createSlice({
  name: 'exchange',
  initialState,
  reducers: {
    getCoinResponse(state, action: PayloadAction<any>) {
      state.rate = action.payload;
    },
  },
});

export const { getCoinResponse } = exchangeSlice.actions;

export default exchangeSlice.reducer;
