import mitt from 'mitt';
import { ChainId } from 'src/constant/chainId';
import { ProviderEvents } from './events';
import { IProvider } from './provider.interface';
import { MetamaskProvider } from './metamask';
import { ProviderName } from './provider.enum';
import { WalletconnectProvider } from './walletconnect';

const CHICKEN_DERBY_PROVIDER_NAME = 'chickenderby/CHICKEN_DERBY_PROVIDER_NAME';

export class ProviderService {
  static events = mitt<ProviderEvents>();

  public static providers: Map<ProviderName, IProvider> = new Map();

  public static providerArray: IProvider[] = [];

  static get provider() {
    const providerName = localStorage.getItem(
      CHICKEN_DERBY_PROVIDER_NAME,
    ) as ProviderName;

    if (!providerName) {
      return null;
    }

    return this.get(providerName);
  }

  public static async init() {
    const { ethereum } = window as any;
    if (ethereum?.isMetaMask) {
      const metamaskProvider = MetamaskProvider.createInstance(
        ethereum,
        this.events,
      );

      this.add(metamaskProvider);
    }
    const walletconnectProvider = await WalletconnectProvider.createInstance(
      this.events,
    );

    this.add(walletconnectProvider);
  }

  private static add(provider: IProvider) {
    const exists = this.get(provider.name);

    if (exists) {
      return;
    }

    this.providers.set(provider.name, provider);
    this.providerArray.push(provider);
  }

  private static get(name: ProviderName) {
    return this.providers.get(name);
  }

  private static setCurrentProvider(providerName: ProviderName) {
    localStorage.setItem(CHICKEN_DERBY_PROVIDER_NAME, providerName);
  }

  public static getAccounts() {
    return this.provider?.getAccounts();
  }

  public static async login(providerName: ProviderName) {
    const provider = this.get(providerName);

    if (!provider) {
      throw new Error(`Not initialized ${providerName} provider`);
    }

    const accounts = await provider.login();
    this.setCurrentProvider(providerName);

    return accounts;
  }

  public static logout() {
    return this.provider?.logout();
  }

  public static sign(chainId: ChainId, account: string, message: string) {
    if (!this.provider) {
      throw new Error('Please login');
    }

    return this.provider?.sign(chainId, account, message);
  }

  public static signTypedDataV4(chainId: ChainId, account: string, message: any) {
    if (!this.provider) {
      throw new Error('Please login');
    }

    return this.provider?.signTypedDataV4(chainId, account, message);
  }

  public static sendTransaction(chainId: ChainId, account: string, tx: any) {
    if (!this.provider) {
      throw new Error('Please login');
    }

    return this.provider?.sendTransaction(chainId, account, tx);
  }
}
