import React from 'react';
import { Modal } from 'react-bootstrap';
import { CloseIcon } from 'src/components/close-icon';
import { useNavigate } from 'react-router';
import prism from '../../assets/images/presale/prism.png';
import './style.css';

export function InsufficientFundsModal({ show, onClose }: { show: boolean, onClose: () => void }) {
  const navigate = useNavigate();
  const onEnterShop = () => {
    onClose();
    navigate('/jewels-swap');
  };
  return (
    <div className="jewels-modal-div">
      <div
        className="modal show"
        style={{ display: 'block', position: 'initial' }}
      >
        <Modal
          show={show}
          onHide={() => onClose()}
          dialogClassName="jewels-modal"
        >
          <Modal.Body>
            <div className="jewels-modal-div">
              <button className="close-modal-btn" type="button">
                <CloseIcon onClick={() => onClose()} />
              </button>
              <p className="enough-jewels">
                Not enough Jewels
              </p>
              <div>
                <p className="jewels-text">
                  70 Jewels
                  <img src={prism} alt="images" />
                  {' '}
                  = 1 USDC, Always.
                </p>
              </div>
              <p className="jewels-text">Jewels can be cashed out any time.</p>
              <button
                className="enter-shop"
                type="button"
                onClick={() => onEnterShop()}
              >
                Enter Shop
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
