import { ETH_USDT_CONTRACT, SEPOILA_USDT_CONTRACT } from 'src/abis';
import { IS_COIN_TEST_NET } from 'src/utils/config';
import { ChainId } from 'src/constant/chainId';
import ContractService from './baseContractService';
import { TokenContractService } from './tokenContractService';

export class UsdtContractService extends TokenContractService {
  public static readonly NUM_STAGES = 9;

  public static readonly web3 = IS_COIN_TEST_NET ? ContractService.sepoliaWeb3 : ContractService.polygonWeb3

  constructor() {
    const contract = IS_COIN_TEST_NET ? SEPOILA_USDT_CONTRACT : ETH_USDT_CONTRACT;
    const chianId = IS_COIN_TEST_NET ? ChainId.Sepolia : ChainId.Polygon;

    super(chianId, contract, UsdtContractService.web3);

    ContractService.createContract(UsdtContractService.web3, this.contractAddress, this.abi);
  }

  async checkApproval(
    userWalletId: string,
    contractAddress: string,
    tether: string,
  ) {
    const { isApproved, allowance } = await this.isApproved(
      userWalletId,
      contractAddress,
      tether,
    );

    console.log(isApproved, "isApproved");

    if (!isApproved) {
      if (Number(allowance) > 0) {
        // Use approve(_spender, 0) to set the allowance to zero immediately before each of the existing approve() calls.
        // https://github.com/UniverseXYZ/Universe-Marketplace/issues/29
        const { transactionHash } = await this.approve(
          userWalletId,
          contractAddress,
          '0',
        );
        await this.getTransactionReceiptLoop(transactionHash, this.web3);
      }

      const { transactionHash } = await this.approve(
        userWalletId,
        contractAddress,
        tether,
      );
      await this.getTransactionReceiptLoop(transactionHash, this.web3);
    }
  }
}
