import React, { useMemo } from 'react';
import Countdown from 'react-countdown';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { ChickenSituation } from 'src/models';

export function ChickenSituationTimer({
  chicken,
  isMultiline,
  onSelect,
}: {
  chicken: any,
  isMultiline?: boolean,
  onSelect?: (chicken: any) => void,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'MODALS.SELECT_CHICKEN' });
  const date = useMemo(() => (chicken.resetSituationSeconds ? moment().add(chicken.resetSituationSeconds, 'seconds').toDate() : moment().subtract(1, 'seconds').toDate()), [chicken.resetSituationSeconds]);

  const resetSituationRenderer = ({
    props,
  }) => {
    if (props.completed) {
      if (chicken.situation === ChickenSituation.RacingPen || !onSelect) {
        return (
          <div className="text-center">
            {chicken.situation}
          </div>
        );
      }

      return (
        <a
          onClick={() => onSelect(chicken)}
          className="btn btnEnter"
        >
          {t('ENTER')}
        </a>
      );
    }

    let text = '';

    if (props.days) {
      text = `${props.days}d, ${props.hours}h, ${props.minutes}m`;
    } else if (props.hours) {
      text = `${props.hours}h, ${props.minutes}m`;
    } else if (props.minutes) {
      text = `${props.minutes}m, ${props.seconds}s`;
    } else {
      text = `${props.seconds}s`;
    }

    // Render a countdown
    return (
      <div className="closes-time">
        { isMultiline && (
          <div className="text-center">
            Can Race In
          </div>
        )}
        <div className="text-center">
          {isMultiline ? text : `Can Race In ${text}`}
        </div>
      </div>
    );
  };

  return (
    <Countdown
      date={date}
      renderer={(props) => resetSituationRenderer({ props })}
    />
  );
}
