import { all } from 'redux-saga/effects';
import walletSagas from '../userWallet/saga';
import racesSagas from '../races/saga';
import chikenSagas from '../chicken/saga';
import coopSagas from '../coop/saga';
import laneSagas from '../lane/saga';
import exchangeSagas from '../exchange/saga';
import tournamentSagas from '../tournament/saga';
import landSagas from '../land/saga';
import clothingSagas from '../clothing/saga';
import marketplaceSagas from '../marketplace/saga';
import seasonSaga from '../season/saga';
import fusionSaga from '../fusion/saga';
import serumSaga from '../serum/saga';
import preRegisterLandSaga from '../pre-register-land/saga';
import coinPreSaleSagas from '../coin-presale/saga';
import gachaSagas from '../gacha/saga';
import trainingPartnersSaga from '../traningPartners/saga';
import bawkStakingSaga from '../bawk-staking/saga';
import bawkPresaleSaga from '../bawk-presale/saga';
import chickenStakingSaga from '../chicken-staking/saga';

export default function* rootSaga() {
  yield all([
    ...walletSagas,
    ...racesSagas,
    ...chikenSagas,
    ...coopSagas,
    ...laneSagas,
    ...exchangeSagas,
    ...tournamentSagas,
    ...landSagas,
    ...clothingSagas,
    ...marketplaceSagas,
    ...seasonSaga,
    ...fusionSaga,
    ...serumSaga,
    ...preRegisterLandSaga,
    ...coinPreSaleSagas,
    ...gachaSagas,
    ...trainingPartnersSaga,
    ...bawkStakingSaga,
    ...bawkPresaleSaga,
    ...chickenStakingSaga,
  ]);
}
