import { createSelector } from 'reselect';
import { RootState } from '../common/reducer';

export const selectCoopState = (rootState: RootState) => rootState.coop;

export const coopsSelector = createSelector([selectCoopState], (state) => state.coops || []);

export const coopsLoadingSelector = createSelector([selectCoopState], (state) => state.loading);

export const coopChickensSelector = createSelector([selectCoopState], (raceState) => raceState.chickens?.rows || []);

export const coopChickensCountSelector = createSelector([selectCoopState], (raceState) => raceState.chickens?.count || 0);

export const coopChickensReachedSelector = createSelector([selectCoopState], (raceState) => raceState.chickens?.isReached || false);

export const coopChickensLoadingSelector = createSelector([selectCoopState], (raceState) => raceState.isLoadingChickens || false);
