import { IS_COIN_TEST_NET } from 'src/utils/config';

export const DEFAULT_MAIN_CHAINS = [
  // mainnets
  'eip155:137',
  'eip155:1',
];

export const DEFAULT_TEST_CHAINS = [
  // testnets
  'eip155:5',
];

export const DEFAULT_CHAINS = [...DEFAULT_MAIN_CHAINS, ...(IS_COIN_TEST_NET ? DEFAULT_TEST_CHAINS : [])];

export const DEFAULT_PROJECT_ID = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID;
export const DEFAULT_RELAY_URL = process.env.REACT_APP_WALLETCONNECT_RELAY_URL;

export const DEFAULT_LOGGER = process.env.REACT_APP_WALLETCONNECT_LOGGER;

export const DEFAULT_APP_METADATA = {
  name: 'Chicken Derby',
  description: 'Chicken Derby for WalletConnect',
  url: 'https://www.chickenderby.com/',
  icons: ['https://www.chickenderby.com/favicon.ico'],
  verifyUrl: 'https://verify.walletconnect.com',
};

/**
 * EIP155
 */
export enum DEFAULT_EIP155_METHODS {
  ETH_SEND_TRANSACTION = 'eth_sendTransaction',
  PERSONAL_SIGN = 'personal_sign',
  ETH_SIGN_TRANSACTION = 'eth_signTransaction',
  ETH_SIGN = 'eth_sign',
  ETH_SIGN_TYPED_DATA = 'eth_signTypedData',
  ETH_SIGN_TYPED_DATA_V4 = 'eth_signTypedData_v4',
}

export enum DEFAULT_EIP_155_EVENTS {
  ETH_CHAIN_CHANGED = 'chainChanged',
  ETH_ACCOUNTS_CHANGED = 'accountsChanged',
}
