import { BAWK_ESCROW_CONTRACT } from 'src/abis';
import { exponentialBackOff } from 'src/utils/exponentialBackoff';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import { ICoupon } from 'src/models';
import { ChainId } from 'src/constant/chainId';
import ContractService from './baseContractService';

export class BawkEscrowContractService extends ContractService {
  constructor() {
    super(ChainId.Polygon, BAWK_ESCROW_CONTRACT.address, BAWK_ESCROW_CONTRACT.abi as AbiItem[]);

    ContractService.add(this);
  }

  async totalEscrowedAccountBalance(userWalletId: string) {
    return this.contractCall(async () => {
      const method = async () => this.contract.methods.totalEscrowedAccountBalance(userWalletId).call();
      const { result } = await exponentialBackOff(method);

      return result;
    });
  }

  async totalClaimedAccountBalance(userWalletId: string) {
    return this.contractCall(async () => {
      const method = async () => this.contract.methods.totalClaimedAccountBalance(userWalletId).call();
      const { result } = await exponentialBackOff(method);

      return result;
    });
  }

  async claim(userWalletId: string, gasLimit: number) {
    return this.contractCall(async () => {
      const functionSignature = this.contract.methods.claim().encodeABI();

      return this.signForwardTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
        gasLimit,
      );
    });
  }

  async stakeEscrow(userWalletId: string, cid: number, amount: number, gasLimit: number, coupon: ICoupon) {
    return this.contractCall(async () => {
      const qty = Web3.utils.toWei(amount.toString(), 'ether');
      const functionSignature = this.contract.methods.stakeEscrow(cid, qty, coupon).encodeABI();

      return this.signForwardTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
        gasLimit,
      );
    });
  }

  async unstakeEscrow(userWalletId: string, cid: number, amount: number, gasLimit: number, coupon: ICoupon) {
    return this.contractCall(async () => {
      const qty = Web3.utils.toWei(amount.toString(), 'ether');
      const functionSignature = this.contract.methods.unstakeEscrow(cid, qty, coupon).encodeABI();

      return this.signForwardTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
        gasLimit,
      );
    });
  }
}
