import { MARKETPLACE_CONTRACT, WETH_CONTRACT } from 'src/abis';
import { MarketItem, MarketPlaceActionType, TradeOffer } from 'src/models';
import { CHICKEN_CONTRACT_ADDRESS } from 'src/utils/config';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import { ChainId } from 'src/constant/chainId';
import ContractService from './baseContractService';

export class MarketplaceContractService extends ContractService {
  constructor() {
    super(ChainId.Polygon, MARKETPLACE_CONTRACT.address, MARKETPLACE_CONTRACT.abi as AbiItem[]);

    ContractService.add(this);

    this.processMarketplace = this.processMarketplace.bind(this);
    this.createMarketItem = this.createMarketItem.bind(this);
    this.editMarketItem = this.editMarketItem.bind(this);
    this.purchaseMarketItem = this.purchaseMarketItem.bind(this);
    this.cancelMarketItem = this.cancelMarketItem.bind(this);
    this.createTradeOffer = this.createTradeOffer.bind(this);
    this.editTradeOffer = this.editTradeOffer.bind(this);
    this.acceptTradeOffer = this.acceptTradeOffer.bind(this);
    this.cancelOrDeclineOffer = this.cancelOrDeclineOffer.bind(this);
  }

  public async createMarketItem(
    userWalletId: string,
    marketItem: MarketItem,
    gasLimit: number,
  ) {
    return this.contractCall(async () => {
      await this.checkTokenApproval(userWalletId, marketItem.nftContract);
      const priceWei = Web3.utils.toWei(marketItem.price.toString(), 'ether');
      const minimumWei = Web3.utils.toWei(
        marketItem.minimum.toString(),
        'ether',
      );

      const functionSignature = this.contract.methods
        .createMarketItem(
          marketItem.marketItemId,
          marketItem.nftContract,
          marketItem.tokenId,
          priceWei,
          minimumWei,
          marketItem.tradeType,
        )
        .encodeABI();

      return this.signForwardTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
        gasLimit,
      );
    });
  }

  public async editMarketItem(
    userWalletId: string,
    marketItem: MarketItem,
    gasLimit: number,
  ) {
    return this.contractCall(async () => {
      await this.checkTokenApproval(userWalletId, marketItem.nftContract);

      const priceWei = Web3.utils.toWei(marketItem.price.toString(), 'ether');
      const minimumWei = Web3.utils.toWei(
        marketItem.minimum.toString(),
        'ether',
      );

      const functionSignature = this.contract.methods
        .editMarketItem(
          marketItem.marketItemId,
          priceWei,
          minimumWei,
          marketItem.tradeType,
        )
        .encodeABI();

      return this.signForwardTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
        gasLimit,
      );
    });
  }

  public async purchaseMarketItem(
    userWalletId: string,
    marketItem: MarketItem,
    gasLimit: number,
  ) {
    return this.contractCall(async () => {
      await this.checkWbawkBalance(userWalletId, marketItem.price);
      await this.checkWethApproval(userWalletId, marketItem.price);
      const functionSignature = this.contract.methods
        .purchaseMarketItem(marketItem.marketItemId)
        .encodeABI();

      if (marketItem.nftContract === CHICKEN_CONTRACT_ADDRESS) {
        return this.sendRawTransaction(
          userWalletId,
          this.contractAddress,
          functionSignature,
        );
      }

      return this.signForwardTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
        gasLimit,
      );
    });
  }

  public async cancelMarketItem(
    userWalletId: string,
    marketItem: MarketItem,
    gasLimit: number,
  ) {
    return this.contractCall(async () => {
      const functionSignature = this.contract.methods
        .cancelMarketItem(marketItem.marketItemId)
        .encodeABI();

      return this.signForwardTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
        gasLimit,
      );
    });
  }

  public async createTradeOffer(
    userWalletId: string,
    tradeOffer: TradeOffer,
    gasLimit: number,
  ) {
    return this.contractCall(async () => {
      const priceWei = Web3.utils.toWei(tradeOffer.price.toString(), 'ether');

      await this.checkWbawkBalance(userWalletId, tradeOffer.price);
      await this.checkWethApproval(userWalletId, tradeOffer.price);

      if (tradeOffer.tokensAttached?.length) {
        await this.checkTokenApproval(userWalletId, tradeOffer.nftContract);
      }

      const functionSignature = this.contract.methods
        .makeOffer(
          tradeOffer.tradeOfferId,
          tradeOffer.marketItemId,
          tradeOffer.nftContract,
          tradeOffer.tokensAttached,
          priceWei,
        )
        .encodeABI();

      return this.signForwardTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
        gasLimit,
      );
    });
  }

  public async editTradeOffer(
    userWalletId: string,
    tradeOffer: TradeOffer,
    gasLimit: number,
  ) {
    return this.contractCall(async () => {
      const priceWei = Web3.utils.toWei(tradeOffer.price.toString(), 'ether');
      await this.checkWbawkBalance(userWalletId, tradeOffer.price);
      await this.checkWethApproval(userWalletId, tradeOffer.price);

      if (tradeOffer.tokensAttached?.length) {
        await this.checkTokenApproval(userWalletId, tradeOffer.nftContract);
      }

      const functionSignature = this.contract.methods
        .editOffer(
          tradeOffer.tradeOfferId,
          priceWei,
          tradeOffer.nftContract,
          tradeOffer.tokensAttached,
        )
        .encodeABI();

      return this.signForwardTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
        gasLimit,
      );
    });
  }

  public async acceptTradeOffer(
    userWalletId: string,
    tradeOffer: TradeOffer,
    gasLimit: number,
  ) {
    return this.contractCall(async () => {
      const functionSignature = this.contract.methods
        .fulfillOffer(tradeOffer.tradeOfferId)
        .encodeABI();

      if (tradeOffer.nftContract === CHICKEN_CONTRACT_ADDRESS) {
        return this.sendRawTransaction(
          userWalletId,
          this.contractAddress,
          functionSignature,
        );
      }

      return this.signForwardTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
        gasLimit,
      );
    });
  }

  public async cancelOrDeclineOffer(
    userWalletId: string,
    tradeOffer: TradeOffer,
    gasLimit: number,
  ) {
    return this.contractCall(async () => {
      const functionSignature = this.contract.methods
        .cancelOrDeclineOffer(tradeOffer.tradeOfferId)
        .encodeABI();

      return this.signForwardTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
        gasLimit,
      );
    });
  }

  protected async checkWethApproval(userWalletId, price: number) {
    const priceWei = Web3.utils.toWei(price.toString(), 'ether');
    const isApprovedWeth = await this.isApprovedWeth(userWalletId, priceWei);

    if (!isApprovedWeth) {
      await this.approveWeth(userWalletId);
    }
  }

  protected async isApprovedWeth(userWalletId: string, priceWei: string) {
    const allowance = await this.getTokenAllowance(
      userWalletId,
      WETH_CONTRACT.address,
    );

    const approvedWeth = Web3.utils
      .toBN(allowance)
      .gte(Web3.utils.toBN(priceWei));

    return approvedWeth;
  }

  protected async approveWeth(userWalletId: string) {
    const maxQty = Web3.utils.toWei(Web3.utils.toBN(2 ** 64 - 1), 'ether');
    const hexQty = Web3.utils.toHex(maxQty);
    const functionSignature = await this.getApproveSignature(
      hexQty,
      WETH_CONTRACT.address,
    );

    return new Promise((resolve, reject) => {
      this.approveTokenAllowance(
        userWalletId,
        functionSignature,
        WETH_CONTRACT.address,
        {
          onReceipt: (receipt) => resolve(receipt),
          onError: (err) => reject(err),
          onSubmit: () => {
            //
          },
          onTx: () => {
            //
          },
        },
      );
    });
  }

  protected async checkWbawkBalance(userWalletId: string, price: number) {
    const balance = await this.getTokenBalance(
      userWalletId,
      WETH_CONTRACT.address,
    );
    const priceWei = Web3.utils.toWei(price.toString(), 'ether');

    const inefficientFunds = Web3.utils
      .toBN(balance)
      .lt(Web3.utils.toBN(priceWei));

    if (inefficientFunds) {
      throw new Error('You have insufficient funds.');
    }
  }

  public async processMarketplace(
    userWalletId: string,
    type: MarketPlaceActionType,
    item: MarketItem | TradeOffer,
    {
      marketItemId,
      tradeOfferId,
      gasLimit,
    }: {
      marketItemId: number;
      tradeOfferId: number;
      gasLimit: number;
    },
  ) {
    const data = item;

    if (type === MarketPlaceActionType.CreateMarketItem) {
      data.marketItemId = marketItemId;
    }

    if (type === MarketPlaceActionType.MakeOffer) {
      (data as TradeOffer).tradeOfferId = tradeOfferId;
    }

    let func: (
      userWalletId: string,
      item: MarketItem | TradeOffer,
      gasLimit: number
    ) => Promise<{
      signature?: string;
      deadline?: number;
      transactionHash?: string;
    }>;

    switch (type) {
      case MarketPlaceActionType.CreateMarketItem:
        func = this.createMarketItem;
        break;
      case MarketPlaceActionType.EditMarketItem:
        func = this.editMarketItem;
        break;
      case MarketPlaceActionType.PurchaseMarketItem:
        func = this.purchaseMarketItem;
        break;
      case MarketPlaceActionType.CancelMarketItem:
        func = this.cancelMarketItem;
        break;
      case MarketPlaceActionType.MakeOffer:
        func = this.createTradeOffer;
        break;
      case MarketPlaceActionType.EditOffer:
        func = this.editTradeOffer;
        break;
      case MarketPlaceActionType.FulfillOffer:
        func = this.acceptTradeOffer;
        break;
      case MarketPlaceActionType.CancelOrDeclineOffer:
        func = this.cancelOrDeclineOffer;
        break;
      default:
        func = null;
    }

    if (!func) {
      throw new Error(`Can not find action for ${type}`);
    }

    const res = await func(userWalletId, data, gasLimit);

    return {
      ...data,
      ...res,
      gasLimit,
    } as MarketItem | TradeOffer;
  }
}
