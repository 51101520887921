import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { Loading } from 'src/components/loading';
import { useTranslation, Trans } from 'react-i18next';
import { BawkStakingType } from 'src/models';

export function BawkStakingTransactionComplete({
  open,
  type,
  isAssigning,
  isApproving,
}: {
  type: BawkStakingType;
  isAssigning: boolean;
  open: boolean;
  isApproving: boolean;
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'MODALS.TRANSACTION_COMPLETE' });
  const pendingTransactionText = useMemo(() => {
    if (type === BawkStakingType.ClaimJewel) {
      return 'Your JEWELS are being claimed. Please wait.';
    }

    if (type === BawkStakingType.Stake) {
      return 'Your BAWK are being staked. Please wait.';
    }

    return 'Your BAWK are being unstaked. Please wait.';
  }, [type]);

  return (
    <Modal
      centered
      dialogClassName="complete-transaction"
      show={open}
      backdrop="static"
    >
      <Modal.Body>
        {isAssigning ? (
          <>
            <h3>{t('TRANSACTION_CONFIRMED')}</h3>
            <p>{pendingTransactionText}</p>
            <Loading size={150} />
            <h5>
              {t('TIP_THIS_CAN_TAKE_SOME_TIME')}
            </h5>
          </>
        ) : (
          <>
            {isApproving ? (
              <>
                <h3>
                  <Trans t={t} i18nKey="APPROVE_CONTRACT">
                    Please approve our contract
                    <br />
                    in MetaMask
                  </Trans>
                </h3>
                <p>{t('TO_APPROVE_OUR_CONTRACT')}</p>
              </>
            ) : ( // isChecked
              <>
                <h3>
                  <Trans t={t} i18nKey="PLEASE_COMPLETE_YOUR_TRANSACTION_IN_METAMASK">
                    Please complete your transaction
                    <br />
                    in MetaMask
                  </Trans>
                </h3>
                <p>
                  {t('TO_COMPLETE_YOUR_TRANSACTION')}
                </p>
              </>
            )}
            <Loading size={150} />
            <h5>
              <Trans t={t} i18nKey="TIP_IF_METAMASK_DOES_NOT_APPEAR">
                Tip: If MetaMask does not appear automatically,
                <br />
                click on the MetaMask icon in your browser notification toolbar
              </Trans>
            </h5>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
