import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type CoinPreSaleState = {
  isEnabled: boolean;
};

const initialState: CoinPreSaleState = {
  isEnabled: false,
};

const coinPreSaleSlice = createSlice({
  name: 'coin-pre-sale',
  initialState,
  reducers: {
    coinPreSaleSuccessResponse(state, action: PayloadAction<boolean>) {
      state.isEnabled = action.payload;
    },
  },
});

export const {
  coinPreSaleSuccessResponse,
} = coinPreSaleSlice.actions;

export default coinPreSaleSlice.reducer;
