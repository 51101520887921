export const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8081';

export const RACE_CONTRACT_ADDRESS = process.env.REACT_APP_RACE_CONTRACT_ADDRESS
  || '0xbfc1f0Bdb08b3Aa3aDaBE6EEE53e1C7feE56b3D1';

export const BICONOMY_FORWARDER_CONTRACT_ADDRESS = process.env.REACT_APP_BICONOMY_FORWARDER_CONTRACT_ADDRESS
  || '0x86C80a8aa58e0A4fa09A69624c31Ab2a6CAD56b8';

export const CHICKEN_CONTRACT_ADDRESS = process.env.REACT_APP_CHICKEN_CONTRACT_ADDRESS
  || '0xEdEEdb4f3CF44fcc430D0D458674df52E922636c';

export const FUSION_CONTRACT_ADDRESS = process.env.REACT_APP_FUSION_CONTRACT_ADDRESS
  || '0xAcF9CFf8489c4c29ca80aCb14FDAe12B2861fF29';

export const LAND_CONTRACT_ADDRESS = process.env.REACT_APP_LAND_CONTRACT_ADDRESS
  || '0x7DeA43e76713304a2FF5F48a91BCCa3879C21ab6';

export const MARKETPLACE_CONTRACT_ADDRESS = process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS
  || '0xbac36F417168681D798a91c5aD4953d027d240e2';

export const BAWK_PRESALE_CONTRACT_ADDRESS = process.env.REACT_APP_BAWK_PRESALE_CONTRACT_ADDRESS || '0xbCF7d49C6CfcE0E2ED35d1C4338dfEbc235E685b';

export const GAME_VIEWER_URL = process.env.REACT_APP_GAME_VIEWER_URL || 'http://localhost:8080';

export const DEBUG_CONTRACT_RACE = process.env.REACT_APP_DEBUG_CONTRACT_RACE === 'true';

export const IS_PRODUCTION_ENV = process.env.REACT_APP_ENV === 'production';
export const IS_STAGING_ENV = process.env.REACT_APP_ENV === 'staging';
export const IS_LOCAL_ENV = process.env.REACT_APP_ENV === 'development';

export const BICONOMY_WETH_API_ID = '38e98fc7-825b-460b-b899-13b3d9ddb2ab';

export const BICONOMY_JEWELS_APPROVE_API_ID = process.env.REACT_APP_BICONOMY_JEWELS_APPROVE_API_ID || '4b4efe52-1b9c-44b1-b921-3bc4c6309d56';

export const BICONOMY_BAWKS_APPROVE_API_ID = process.env.REACT_APP_BICONOMY_BAWKS_APPROVE_API_ID || '96e19881-a735-461b-8309-b0cfb479f1df';

export const BICONOMY_CUSTOM_GAS_LIMIT = Number(process.env.REACT_APP_BICONOMY_CUSTOM_GAS_LIMIT) || 600000; // 600K

export const DISCORD_CLIENT_ID = '983370778730975322';

export const isRequiredBasicAuth = process.env.REACT_APP_REQUIRED_BASIC_AUTH === '1';

export const NEED_LOCALIZATION = process.env.REACT_APP_LOCALIZATION === '1';

export const FUSION_SERUM_ID = 1;

export const BAWK_CONTRACT_ADDRESS = process.env.REACT_APP_BAWK_CONTRACT_ADDRESS || '0x19e5f345eec3Ad66d93F16d8267a18009e069799';
export const BAWK_ESCROW_CONTRACT_ADDRESS = process.env.REACT_APP_BAWK_ESCROW_CONTRACT_ADDRESS || '0x861797713FADA3f7e7c45A480bDbb953Dd868c0F';
export const COIN_PRESALE_CONTRACT_ADDRESS = process.env.REACT_APP_COIN_PRESALE_CONTRACT_ADDRESS || '0x2EFc55BF6852825357880610351a466935ccFf00';
export const JEWELS_CONTRACT_ADDRESS = process.env.REACT_APP_JEWELS_CONTRACT_ADDRESS || '0xACde0153a05be20eEe9c2771EFcf4918E367f25c';
export const POLYGON_USDC_CONTRACT_ADDRESS = process.env.REACT_APP_POLYGON_USDC_CONTRACT_ADDRESS || '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359';
export const CHICKEN_STAKING_CONTRACT_ADDRESS = process.env.REACT_APP_CHICKEN_STAKING_CONTRACT_ADDRESS || '0x82a6334D207D8dA188F6B98fE829caC503F2C076';
export const BAWK_STAKING_CONTRACT_ADDRESS = process.env.REACT_APP_BAWK_STAKING_CONTRACT_ADDRESS || '0x29F1B8A2616CE4F71EDc3728011fad7eEd5F6ED8';
export const IS_COIN_TEST_NET = process.env.REACT_APP_COIN_IS_TEST_NET === '1';
export const TRANSAK_API_KEY = process.env.REACT_APP_TRANSAK_API_KEY;

export const GOOGLE_RECAPTCHA_KEY = '6LeXS-YgAAAAAAu4EIlTF6OzNJqATT2MYw-5Ky72';

export const CHICKEN_COLDOWN_TIME = 24 * 60 * 60 * 1000;
