import { takeLatest, call, put } from 'redux-saga/effects';
import { apiService } from 'src/services/apiService';
import { PaginatedRequest } from 'src/models';
import { PayloadAction } from '@reduxjs/toolkit';
import { trainingPartnersPullRequest, trainingPartnersPullResponse, trainingPartnersPullError } from './reducer';
import { getUserWalletRequest } from '../userWallet/reducer';

export function* getTrainingPartnersPull(action: PayloadAction<PaginatedRequest>) {
  try {
    const response = yield call(apiService.getTrainingPartners, action.payload);
    yield put(trainingPartnersPullResponse({
      data: response?.data,
      pagination: action.payload,
    }));
  } catch (err) {
    yield put(
      trainingPartnersPullError(err?.data?.message || err.message || 'Unknown Error'),
    );
  }

  yield put(getUserWalletRequest({}));
}

export function* trainingPartnersEffects() {
  yield takeLatest(trainingPartnersPullRequest.type, getTrainingPartnersPull);
}

const trainingPartnersSagas = [call(trainingPartnersEffects)];

export default trainingPartnersSagas;
