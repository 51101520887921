import React, { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { IMAGES_URL } from 'src/constant/images';

import './styles.css';
import { useSelector } from 'react-redux';
import { isLoggedInSelector } from 'src/store/userWallet/selector';
import { useWalletContext } from 'src/contexts/walletProvider';

export function BawkHeader({ isConnectWallet }: {
  isConnectWallet?: boolean
}) {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { onLogin } = useWalletContext();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 900 });

  const onResize = useCallback(() => {
    setIsOpenMenu(false);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return (
    <>
      {isMobile ? (
        <div className={`bawk-header-mobile ${isOpenMenu ? 'open' : ''}`}>
          <div className="bawk-header-top">
            <Link to="/">
              <img className="bawk-logo-mobile" src={IMAGES_URL.LOGO_NEW} alt="logo" />
            </Link>
            <div className="bawk-menu-icon" onClick={() => setIsOpenMenu(!isOpenMenu)}>
              <img src={isOpenMenu ? IMAGES_URL.ICONS.BAWK_MENU_CLOSE_ICON : IMAGES_URL.ICONS.BAWK_MENU_ICON} alt="icon" />
            </div>
          </div>
          <div className={`bawk-menu ${isOpenMenu ? 'open' : ''}`}>
            <Link to="/races/enter">Start Racing</Link>
            {isConnectWallet ? isLoggedIn ? <></> : <a className="yellow" onClick={onLogin}>Connect Wallet</a> : <Link className="yellow" to="/community-sale">JOIN PRESALE</Link>}
            <a href="https://twitter.com/intent/user?screen_name=chickenderbycom" target="__blank">
              <div>X</div>
              <img src={IMAGES_URL.ICONS.X_ICON} alt="" />
            </a>
            <a href="https://discord.com/invite/chicken-derby" target="__blank">
              <div>Discord</div>
              <img src={IMAGES_URL.ICONS.DISCORD_ICON} alt="" />
            </a>
            <a href="https://opensea.io/collection/chicken-derby" target="__blank">
              <div>Opensea</div>
              <img src={IMAGES_URL.ICONS.OPENSEA_ICON} alt="" />
            </a>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="bawk-header">
            <Link to="/">
              <img className="bawk-logo" src={IMAGES_URL.LOGO_NEW} alt="logo" />
            </Link>
            <div className="bawk-menu">
              <Link className="bawk-btn" to="/races/enter">Start Racing</Link>
              {isConnectWallet ? isLoggedIn ? <></> : <a className="bawk-btn yellow" onClick={onLogin}>Connect Wallet</a> : <Link className="bawk-btn yellow" to="/community-sale">JOIN PRESALE</Link>}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
