import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BawkStakingType, IBawkStakingCheckResponse, IBawkStakingRequest } from 'src/models';
import { BawkStakingStatus, IBawkStaking, IBawkStakingStats } from './model';

type BawkStakingState = {
  isStatsLoading: boolean;
  bawkStakingStats?: IBawkStakingStats;
  bawkStaking?: IBawkStaking;
}

const initialState: BawkStakingState = {
  isStatsLoading: false,
  bawkStakingStats: {},
  bawkStaking: {
    type: BawkStakingType.Stake,
    status: BawkStakingStatus.Draft,
    gasLimit: 0,
    coupon: null,
  },
};

const bawkStakingSlice = createSlice({
  name: 'bawk-staking',
  initialState,
  reducers: {
    getBawkStakingStatsRequest(state, action: PayloadAction<void>) {
      state.isStatsLoading = true;
    },
    getBawkStakingStatsSuccessResponse(state, action: PayloadAction<IBawkStakingStats>) {
      state.bawkStakingStats = action.payload;
      state.isStatsLoading = false;
    },
    getBawkStakingStatsFailedResponse(state) {
      state.isStatsLoading = false;
    },

    // stake & unstake & claim
    checkBawkStakingRequest(state, action: PayloadAction<IBawkStakingRequest>) {
      state.bawkStaking.type = action.payload.type;
      state.bawkStaking.status = BawkStakingStatus.Checking;
      state.bawkStaking.gasLimit = 0;
      state.bawkStaking.coupon = null;
    },
    checkBawkStakingSuccessResponse(state, action: PayloadAction<IBawkStakingCheckResponse>) {
      state.bawkStaking.status = BawkStakingStatus.Checked;
      state.bawkStaking.gasLimit = action.payload.gasLimit;
      state.bawkStaking.coupon = action.payload.coupon;
    },
    checkBawkStakingFailedResponse(state, action: PayloadAction<void>) {
      state.bawkStaking.status = BawkStakingStatus.Draft;
    },
    bawkStakingRequest(state, action: PayloadAction<IBawkStakingRequest>) {
      state.bawkStaking.status = BawkStakingStatus.Assigning;
    },
    bawkStakingSuccessResponse(state, action: PayloadAction<void>) {
      state.bawkStaking.status = BawkStakingStatus.Assigning;
    },
    bawkStakingFailedResponse(state, action: PayloadAction<void>) {
      state.bawkStaking.status = BawkStakingStatus.Draft;
    },
    checkLastBawkStakingAssignmentRequest(state, action: PayloadAction<BawkStakingType>) {
      if (action.payload) {
        state.bawkStaking.type = action.payload;
        state.bawkStaking.status = BawkStakingStatus.Assigning;
      } else {
        state.bawkStaking.status = BawkStakingStatus.Draft;
      }
    },
    checkLastBawkStakingAssignmentSuccessResponse(state, action: PayloadAction<BawkStakingType>) {
      if (action.payload) {
        state.bawkStaking.status = BawkStakingStatus.Assigned;
      } else {
        state.bawkStaking.status = BawkStakingStatus.Draft;
      }
    },
    checkLastBawkStakingAssignmentFailedResponse(state, action: PayloadAction<void>) {
      state.bawkStaking.status = BawkStakingStatus.Draft;
    },
    resetBawkStakingStatus(state, action: PayloadAction<void>) {
      state.bawkStaking.status = BawkStakingStatus.Draft;
    },
  },
});

export const {
  getBawkStakingStatsRequest,
  getBawkStakingStatsSuccessResponse,
  getBawkStakingStatsFailedResponse,

  checkBawkStakingRequest,
  checkBawkStakingSuccessResponse,
  checkBawkStakingFailedResponse,
  bawkStakingRequest,
  bawkStakingSuccessResponse,
  bawkStakingFailedResponse,
  checkLastBawkStakingAssignmentRequest,
  checkLastBawkStakingAssignmentSuccessResponse,
  checkLastBawkStakingAssignmentFailedResponse,
  resetBawkStakingStatus,

} = bawkStakingSlice.actions;

export default bawkStakingSlice.reducer;
