import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IChickenStakingSeason,
} from 'src/models';

type ChickenStakingState = {
  isLoading: boolean;
  liveSeason?: IChickenStakingSeason;
}

const initialState: ChickenStakingState = {
  isLoading: false,
  liveSeason: null,
};

const chickenStakingSlice = createSlice({
  name: 'chicken-staking',
  initialState,
  reducers: {
    getLiveChickenStakingSeasonRequest(state, action: PayloadAction<void>) {
      state.isLoading = true;
    },
    getLiveChickenStakingSeasonSuccessResponse(state, action: PayloadAction<IChickenStakingSeason>) {
      state.liveSeason = action.payload;
      state.isLoading = false;
    },
    getLiveChickenStakingSeasonFailedResponse(state) {
      state.isLoading = false;
    },
  },
});

export const {
  getLiveChickenStakingSeasonRequest,
  getLiveChickenStakingSeasonSuccessResponse,
  getLiveChickenStakingSeasonFailedResponse,
} = chickenStakingSlice.actions;

export default chickenStakingSlice.reducer;
