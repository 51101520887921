import { IContract } from 'src/models';
import { BAWK_ESCROW_CONTRACT_ADDRESS } from 'src/utils/config';

export const BAWK_ESCROW_CONTRACT: IContract = {
  name: 'BawkEscrow',
  version: '1.0.0',
  address: BAWK_ESCROW_CONTRACT_ADDRESS,
  abi: [{ inputs: [], stateMutability: 'nonpayable', type: 'constructor' }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'previousAdmin', type: 'address',
    }, {
      indexed: false, internalType: 'address', name: 'newAdmin', type: 'address',
    }],
    name: 'AdminChanged',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: '', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: '', type: 'uint256',
    }],
    name: 'BalanceAppended',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'beacon', type: 'address',
    }],
    name: 'BeaconUpgraded',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'bool', name: '', type: 'bool',
    }],
    name: 'ClaimableChanged',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: '', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: '', type: 'uint256',
    }],
    name: 'EscrowPaid',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: '', type: 'address',
    }, {
      indexed: false, internalType: 'enum BawkStakingRewards.Company', name: '', type: 'uint8',
    }, {
      indexed: false, internalType: 'uint256', name: '', type: 'uint256',
    }],
    name: 'EscrowStaked',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: '', type: 'address',
    }, {
      indexed: false, internalType: 'enum BawkStakingRewards.Company', name: '', type: 'uint8',
    }, {
      indexed: false, internalType: 'uint256', name: '', type: 'uint256',
    }],
    name: 'EscrowUnstaked',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'uint8', name: 'version', type: 'uint8',
    }],
    name: 'Initialized',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'operator', type: 'address',
    }, {
      indexed: false, internalType: 'bool', name: 'approved', type: 'bool',
    }],
    name: 'OperatorApproved',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferStarted',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'implementation', type: 'address',
    }],
    name: 'Upgraded',
    type: 'event',
  }, {
    inputs: [], name: 'acceptOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_account', type: 'address' }, { internalType: 'uint256', name: '_quantity', type: 'uint256' }], name: 'appendEscrowEntry', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_operator', type: 'address' }, { internalType: 'bool', name: '_approved', type: 'bool' }], name: 'approveOperator', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'bawk', outputs: [{ internalType: 'contract IBawk', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'bawkStakingRewards', outputs: [{ internalType: 'contract BawkStakingRewards', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'chickenStakingRewards', outputs: [{ internalType: 'contract ChickenStakingRewards', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{
      components: [{ internalType: 'bytes32', name: 'r', type: 'bytes32' }, { internalType: 'bytes32', name: 's', type: 'bytes32' }, { internalType: 'uint8', name: 'v', type: 'uint8' }], internalType: 'struct Coupon', name: 'coupon', type: 'tuple',
    }],
    name: 'claim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_bawk', type: 'address' }, { internalType: 'address', name: '_chickenStakingRewards', type: 'address' }, { internalType: 'address', name: '_payout', type: 'address' }, { internalType: 'address', name: '_bawkStakingRewards', type: 'address' }], name: 'initialize', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'isClaimable', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'forwarder', type: 'address' }], name: 'isTrustedForwarder', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'operatorApprovals', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'payout', outputs: [{ internalType: 'contract Payout', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'pendingOwner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'proxiableUUID', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_bawk', type: 'address' }], name: 'setBawk', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_bawkStakingRewards', type: 'address' }], name: 'setBawkStakingRewards', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_chickenStakingRewards', type: 'address' }], name: 'setChickenStakingRewards', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'bool', name: '_isClaimable', type: 'bool' }], name: 'setClaimable', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_payout', type: 'address' }], name: 'setPayout', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_signer', type: 'address' }], name: 'setSigner', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_forwarder', type: 'address' }], name: 'setTrustedForwarder', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'signer', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'enum BawkStakingRewards.Company', name: '_cid', type: 'uint8' }, { internalType: 'uint256', name: '_amount', type: 'uint256' }, {
      components: [{ internalType: 'bytes32', name: 'r', type: 'bytes32' }, { internalType: 'bytes32', name: 's', type: 'bytes32' }, { internalType: 'uint8', name: 'v', type: 'uint8' }], internalType: 'struct Coupon', name: 'coupon', type: 'tuple',
    }],
    name: 'stakeEscrow',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'totalClaimedAccountBalance', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'totalEscrowedAccountBalance', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'trustedForwarder', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'enum BawkStakingRewards.Company', name: '_cid', type: 'uint8' }, { internalType: 'uint256', name: '_amount', type: 'uint256' }, {
      components: [{ internalType: 'bytes32', name: 'r', type: 'bytes32' }, { internalType: 'bytes32', name: 's', type: 'bytes32' }, { internalType: 'uint8', name: 'v', type: 'uint8' }], internalType: 'struct Coupon', name: 'coupon', type: 'tuple',
    }],
    name: 'unstakeEscrow',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newImplementation', type: 'address' }], name: 'upgradeTo', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newImplementation', type: 'address' }, { internalType: 'bytes', name: 'data', type: 'bytes' }], name: 'upgradeToAndCall', outputs: [], stateMutability: 'payable', type: 'function',
  }, {
    inputs: [], name: 'versionRecipient', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'pure', type: 'function',
  }],
};
