import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SerumMintRequest } from './model';

type SerumState = {
  serumMint: any;
  isMinted: boolean;
  isMinting: boolean;
  isCheckingSerumMint: boolean;
  isCheckedSerumMint: boolean;
  gasLimit: number;
};

const initialState: SerumState = {
  isMinted: false,
  isMinting: false,
  isCheckingSerumMint: false,
  isCheckedSerumMint: false,
  serumMint: null,
  gasLimit: 0,
};

const fusionSlice = createSlice({
  name: 'serum',
  initialState,
  reducers: {
    clearIsMinted(state) {
      state.isMinted = false;
      state.isCheckedSerumMint = false;
      state.isCheckingSerumMint = false;
      state.gasLimit = 0;
      state.serumMint = null;
    },
    checkSerumMintRequest(state, action: PayloadAction<SerumMintRequest>) {
      state.isMinted = false;
      state.isMinting = false;
      state.isCheckingSerumMint = true;
      state.isCheckedSerumMint = false;
      state.gasLimit = 0;
      state.serumMint = null;
    },
    checkSerumMintSuccessResponse(state, action: PayloadAction<any>) {
      state.isCheckingSerumMint = false;
      state.isCheckedSerumMint = true;
      state.gasLimit = action.payload.gasLimit;
    },
    checkSerumMintFailedResponse(state) {
      state.isMinting = false;
      state.isCheckingSerumMint = false;
      state.isCheckedSerumMint = false;
    },
    serumMintRequest(state, action: PayloadAction<SerumMintRequest>) {
      state.isMinting = true;
    },
    serumMintSuccessResponse(state) {
      state.isMinting = true;
    },
    serumMintFailedResponse(state, action: PayloadAction<any>) {
      state.isMinting = false;
    },
    checkLastSerumMintRequest(state, action: PayloadAction<boolean>) {
      state.isMinted = false;
      state.isMinting = action.payload;
    },
    checkLastSerumMintSuccessResponse(state, action: PayloadAction<boolean>) {
      state.isMinted = action.payload;
      state.isMinting = false;
    },
    checkLastSerumMintFailedResponse(state) {
      state.isMinted = false;
      state.isMinting = false;
    },
    setSerumMintInfo(state, action: PayloadAction<any>) {
      state.serumMint = action.payload.serumMint;
    },
  },
});

export const {
  clearIsMinted,
  checkSerumMintRequest,
  checkSerumMintSuccessResponse,
  checkSerumMintFailedResponse,
  serumMintRequest,
  serumMintSuccessResponse,
  serumMintFailedResponse,
  checkLastSerumMintRequest,
  checkLastSerumMintSuccessResponse,
  checkLastSerumMintFailedResponse,
  setSerumMintInfo,
} = fusionSlice.actions;

export default fusionSlice.reducer;
