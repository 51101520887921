import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { IMAGES_URL } from 'src/constant/images';
import { Currency } from 'src/models/currency.enum';
import { LocalStorageService } from 'src/services/local-storage.service';

const localStorageService = LocalStorageService.getInstance();
interface CurrencyContextType {
  currency: Currency;
  setCurrency: (value: Currency) => void

  currencyElement: (value: Currency, size?: number) => React.ReactNode
}

export const CurrencyContext = createContext<CurrencyContextType | undefined>(
  undefined,
);

export const useCurrency = () => {
  const context = useContext(CurrencyContext);

  return context;
};

export default function CurrencyProvider(props: {
  children: React.ReactNode;
}) {
  const [currency, setCurrency] = useState<Currency>(Currency.JEWELS);

  const currencyElement = useCallback((value: Currency, size = 20) => {
    if (value === Currency.JEWELS) {
      return (
        <img
          className="currency-symbol"
          style={{
            width: size,
          }}
          src={IMAGES_URL.JEWELS}
          alt=""
        />
      );
    }

    return <span className="currency-symbol price-usd">{value}</span>;
  }, []);

  useEffect(() => {
    localStorageService.setCurrency(currency);
  }, [currency]);

  const value = useMemo(
    () => ({
      currency,
      setCurrency,
      currencyElement,
    }),
    [
      currency,
      setCurrency,
      currencyElement,
    ],
  );

  return (
    <CurrencyContext.Provider
      value={value}
    >
      {props.children}
    </CurrencyContext.Provider>
  );
}
