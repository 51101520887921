import React, { useState } from 'react';
import './index.css';

function ExpansionPanelItem({
  title, content, active, multiple, onToggle,
}) {
  const [visiblity, setVisiblity] = useState(false);

  const isActive = () => (multiple ? visiblity : active);

  const toogleVisiblity = () => {
    setVisiblity((v) => !v);
    onToggle();
  };

  return (
    <div className={`card ${isActive() ? 'accordion-active' : ''}`}>
      <div className="card-header" onClick={toogleVisiblity}>
        {title}
        <span className="accordion-icon">
          <i className="fa fa-chevron-right" />
        </span>
      </div>
      <div className="card-body">{content}</div>
    </div>
  );
}

export function ExpansionPanel({ data, multiple = false }) {
  const [active, setActive] = useState(0);

  return (
    <div className="custom-accordion">
      {data.map(({ title, content }, idx) => (
        <ExpansionPanelItem
          key={title}
          title={title}
          content={content}
          active={active === idx}
          multiple={multiple}
          onToggle={(e) => setActive((a) => (a === idx ? '' : idx))}
        />
      ))}
    </div>
  );
}
