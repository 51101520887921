import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GachaPullMode } from 'src/constant/gacha';

type GachaState = {
  trainingPartners: any;
  error: any;
};

const initialState: GachaState = {
  trainingPartners: [],
  error: null,
};

const gachaSlice = createSlice({
  name: 'gacha',
  initialState,
  reducers: {
    gachaPullRequest(state, action: PayloadAction<GachaPullMode>) {
      state.trainingPartners = [];
      state.error = null;
    },
    gachaPullResponse(state, action: PayloadAction<any>) {
      state.trainingPartners = action.payload;
    },
    gachaPullError(state, action: PayloadAction<any>) {
      state.error = action.payload;
    },
  },
});

export const { gachaPullRequest, gachaPullResponse, gachaPullError } = gachaSlice.actions;

export default gachaSlice.reducer;
