import React from 'react';

export function CopyIcon({
  size = 29,
  color = '#FFF',
  onClick,
}: {
  size?: number
  color?: string
  onClick?: () => void
}) {
  return (
    <svg onClick={onClick} width={size} height={size} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_426_6964)">
        <path d="M18.0254 14.9V19.1C18.0254 22.6 16.6254 24 13.1254 24H8.92539C5.42539 24 4.02539 22.6 4.02539 19.1V14.9C4.02539 11.4 5.42539 10 8.92539 10H13.1254C16.6254 10 18.0254 11.4 18.0254 14.9Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M24.0254 8.9V13.1C24.0254 16.6 22.6254 18 19.1254 18H18.0254V14.9C18.0254 11.4 16.6254 10 13.1254 10H10.0254V8.9C10.0254 5.4 11.4254 4 14.9254 4H19.1254C22.6254 4 24.0254 5.4 24.0254 8.9Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_426_6964">
          <rect width="24" height="24" fill="white" transform="translate(2.02539 2)" />
        </clipPath>
      </defs>
    </svg>
  );
}
