import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Modal } from 'react-bootstrap';
import * as _ from 'lodash';

import * as utils from 'src/utils/utils';
import { IMAGES_URL } from 'src/constant/images';
import { useDispatch, useSelector } from 'react-redux';
import { exchangeRateSelector } from 'src/store/exchange/selector';
import useInfiniteScroll from 'src/utils/useInfiniteScroll';
import {
  raceChickensLoadingSelector, raceChickensOtherPeckingOrdersSelector, raceChickensReachedSelector, raceChickensSelector,
} from 'src/store/races/selector';
import { userWalletSelector } from 'src/store/userWallet/selector';
import { getRaceChickensRequest } from 'src/store/races/reducer';
import { useTranslation } from 'react-i18next';
import { ChickenFilters } from 'src/components/chickenFilters';
import { ChickenImg } from 'src/components/chickenImg';
import { Select } from 'src/components/select';
import { coopsSelector } from 'src/store/coop/selector';
import { useDebounce } from 'src/uses';
import { useCurrency } from 'src/contexts/currency';
import { ChickenSituationTimer } from 'src/components';
import { ChickenFilterModal } from './chickenFilterModal';

const CHICKEN_FILTERS = 'SELECT_CHICKEN_FILTERS';
const CHICKEN_DISTANCE_MATCH = 'SELECT_CHICKEN_DISTANCE_MATCH';
const CHICKEN_TERRAIN_MATCH = 'SELECT_CHICKEN_TERRAIN_MATCH';

export function SelectChicken({
  race,
  notify,
  onClose,
  onSelect,
}: {
  race: any,
  notify?: string;
  onClose: () => void
  onSelect: (chicken) => void
}) {
  const dispatch = useDispatch();
  const coinRate = useSelector(exchangeRateSelector);
  const chickens = useSelector(raceChickensSelector);
  const isReached = useSelector(raceChickensReachedSelector);
  const { currency, currencyElement } = useCurrency();
  const isLoadingChickens = useSelector(raceChickensLoadingSelector);
  const otherPeckingOrders = useSelector(raceChickensOtherPeckingOrdersSelector);
  const [terrainMatch, setTerrainMatch] = useState(localStorage.getItem(CHICKEN_TERRAIN_MATCH) === 'true');
  const [distanceMatch, setDistanceMatch] = useState(localStorage.getItem(CHICKEN_DISTANCE_MATCH) === 'true');
  const userWallet = useSelector(userWalletSelector);
  const coops = useSelector(coopsSelector);
  const { t } = useTranslation('translation', { keyPrefix: 'MODALS.SELECT_CHICKEN' });
  const savedFilters = localStorage.getItem(CHICKEN_FILTERS);

  const [page, setPage] = useState(1);
  const [limit] = useState(5);
  const [filter, setFilter] = useState<any>(savedFilters ? JSON.parse(savedFilters) : {});
  const [openFilter, setOpenFilter] = useState(false);
  const debouncedFilters = useDebounce(filter, 1000);

  const hasFilters = useMemo(() => {
    if (!debouncedFilters) {
      return false;
    }

    let value = false;
    Object.values(debouncedFilters).forEach((entry) => {
      if (!value && !_.isEmpty(entry)) {
        value = true;
      }
    });

    return value;
  }, [debouncedFilters]);

  const onSetFilter = (name: string, value) => {
    const clonedFilter = _.cloneDeep(filter);

    clonedFilter[name] = value;

    setFilter(clonedFilter);
  };

  const onFilter = (value: any, close?: boolean) => {
    setFilter({
      ...filter,
      ...value,
    });

    if (close) {
      setOpenFilter(false);
    }
  };

  const clearFilter = (param = 'all') => {
    const tmpFilter = _.cloneDeep(filter);

    switch (param) {
      case 'all':
        setFilter({});
        break;
      default:
        delete tmpFilter[param];
        setFilter({ ...tmpFilter });

        break;
    }
  };

  const [lastElementRef] = useInfiniteScroll(() => {
    if (isLoadingChickens || isReached) return;
    onFetchChickens(page + 1);
  }, isLoadingChickens);

  const onFetchChickens = useCallback(
    (pageNumber: number) => {
      if (!userWallet?.id || !race?.id) {
        return;
      }

      setPage(pageNumber);
      dispatch(
        getRaceChickensRequest({
          page: pageNumber,
          limit,
          filter: JSON.stringify({
            userWalletId: userWallet.id,
            raceId: race.id,
            ...debouncedFilters,
            distanceMatch,
            terrainMatch,
          }),
        }),
      );
    },
    [dispatch, limit, userWallet, race, debouncedFilters, distanceMatch, terrainMatch],
  );

  useEffect(() => {
    onFetchChickens(1);
  }, [onFetchChickens]);

  useEffect(() => {
    localStorage.setItem(CHICKEN_FILTERS, JSON.stringify(debouncedFilters));
  }, [debouncedFilters]);

  useEffect(() => {
    localStorage.setItem(CHICKEN_DISTANCE_MATCH, distanceMatch ? 'true' : 'false');
  }, [distanceMatch]);

  useEffect(() => {
    localStorage.setItem(CHICKEN_TERRAIN_MATCH, terrainMatch ? 'true' : 'false');
  }, [terrainMatch]);

  return (
    <>
      <Modal
        size="lg"
        centered
        dialogClassName="select-chicken"
        show
        onHide={() => onClose()}
      >
        <Modal.Header>
          <Modal.Title className="ml-auto">
            {race?.name}
            <>
              {race && (
                <div className="race-info">
                  <div className="mr-4">
                    {race?.peckingOrder === 'CHICK' ? (
                      <img
                        className="chick-icon"
                        src={IMAGES_URL.ICONS.CHICK_ICON_2}
                        alt="CHICK"
                      />
                    ) : (
                      <span className="text-class light">{race?.peckingOrder}</span>
                    )}
                  </div>
                  <p>
                    {race?.distance}
                    m
                  </p>
                  <img
                    src={require(`src/assets/images${race?.terrain?.image}`).default}
                    className="terrain-image"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={race?.terrain?.name}
                    alt=""
                  />
                  <p className="price-green">
                    {utils.formatTokenAmount(race?.fee, coinRate, race?.coinType, currency)}
                    {' '}
                    {currencyElement(currency)}
                    {' '}
                    /
                    {' '}
                    {utils.formatTokenAmount(race?.prizePool, coinRate, race?.coinType, currency)}
                    {' '}
                    {currencyElement(currency)}
                  </p>
                </div>
              )}
            </>
          </Modal.Title>
          <button type="button" className="close" onClick={() => onClose()}>
            <span aria-hidden="true">
              <img src={IMAGES_URL.CLOSEICON} alt="" />
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-5 order-3 order-lg-1 align-self-center mt-3 mt-lg-0">
              <div className="form-group has-feedback">
                <span className="fa fa-search form-control-feedback" />
                <input
                  type="text"
                  className="form-control customInput"
                  placeholder={t('CHICKEN_NAME')}
                  value={filter?.name || ''}
                  onChange={(e) => {
                    onSetFilter('name', e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-8 col-lg-4 order-1 order-lg-2 align-self-center">
              <Select
                value={filter.coopIds || []}
                options={coops.map((coop) => ({
                  label: `${coop.name} (${coop.count})`,
                  value: coop.id,
                }))}
                selectValue
                onChange={(value) => onSetFilter('coopIds', value)}
                placeholder={t('SELECT_COOPS')}
              />
            </div>
            <div className="col-4 col-lg-3 order-2 order-lg-3 text-right text-lg-center align-self-center">
              <a
                onClick={() => setOpenFilter(true)}
                className="btn btn-filters"
              >
                <span className="fa fa-sliders" />
                {' '}
                {t('FILTERS')}
              </a>
            </div>
          </div>
          <ChickenFilters filter={filter} clearFilter={clearFilter} />
          <div className="flex mt-2">
            <div className="custom-checkbox left">
              <label>
                <input
                  type="checkbox"
                  checked={terrainMatch}
                  onChange={() => setTerrainMatch(!terrainMatch)}
                />
                Terrain Matches
              </label>
            </div>
            <div className="custom-checkbox left ml-4">
              <label>
                <input
                  type="checkbox"
                  checked={distanceMatch}
                  onChange={() => setDistanceMatch(!distanceMatch)}
                />
                Distance Matches
              </label>
            </div>
          </div>
          <div className="table-responsive">
            {!!notify && <p className="text-center mt-3">{notify}</p>}
            <table className="table order-table">
              <tbody>
                <tr>
                  <th>{t('IMAGE')}</th>
                  <th>{t('NAME')}</th>
                  <th className="text-center">
                    {t('HERITAGE')}
                    <br />
                    {t('PERFECTION')}
                  </th>
                  <th className="text-center">
                    Terrain
                    <br />
                    Distance
                  </th>
                  <th>{t('RACES')}</th>
                  <th>{t('HISTORY')}</th>
                  <th>{t('TALENT')}</th>
                  <th>{t('SITUATION')}</th>
                </tr>
                {!isLoadingChickens && chickens?.length === 0 && (
                  <tr>
                    <td colSpan={8}>
                      <h5 className="text-center ml-5 mr-5 mt-2 mb-2">
                        {' '}
                        {hasFilters ? t('YOU_HAVE_NO_CHICKENS_AVAILABLE_WITH_FILTERS') : t('YOU_HAVE_NO_CHICKENS_AVAILABLE')}
                        {' '}
                        <a
                          href="https://bitlovin.gitbook.io/chicken-derby-tutorial/races#pecking-order"
                          rel="noreferrer"
                          target="_blank"
                        >
                          {t('LEARN_MORE')}
                        </a>
                      </h5>
                    </td>
                  </tr>
                )}
                {!isLoadingChickens && otherPeckingOrders?.length > 0 && (
                  <tr>
                    <td colSpan={8} style={{ border: 0 }}>
                      <h5
                        className="text-center ml-5 mr-5 mt-2 mb-2"
                      >
                        {hasFilters ? t('YOU_DO_HAVE_CHICKENS_IN_PECKING_ORDERS_WITH_FILTERS') : t('YOU_DO_HAVE_CHICKENS_IN_PECKING_ORDERS')}
                        <p className="text-center pt-2">
                          {otherPeckingOrders.map((peckingOrder) => (
                            <span key={peckingOrder} className="text-class light ml-1 mr-1">
                              {peckingOrder}
                            </span>
                          ))}
                        </p>
                      </h5>

                    </td>
                  </tr>
                )}
                {chickens.map((chicken) => (
                  <tr key={chicken.id}>
                    <td>
                      <ChickenImg src={chicken.clothingImage || chicken.image} size={45} />
                    </td>
                    <td
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: utils.breakText(chicken.name, 8),
                      }}
                    />
                    <td className="text-center">
                      {chicken.heritage}
                      <br />
                      {chicken.perfection}
                      %
                    </td>
                    <td className="text-center">
                      {chicken.terrainPreference}
                      <br />
                      {chicken.distancePreference}
                    </td>
                    <td className="text-center">
                      {chicken.races}
                    </td>
                    <td>
                      {chicken.firsts}
                      /
                      {chicken.seconds}
                      /
                      {chicken.thirds}
                    </td>
                    <td
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: utils.breakText(chicken.talent, 8),
                      }}
                    />
                    <td>
                      <ChickenSituationTimer
                        chicken={chicken}
                        isMultiline
                        onSelect={onSelect}
                      />
                    </td>
                  </tr>
                ))}
                {isLoadingChickens && (
                  <>
                    {
                      _.range(3).map((i) => (
                        <tr key={i}>
                          <td><div className="loading-column loading-pecking-order" /></td>
                          {
                            _.range(7).map((j) => (
                              <td key={j}><div className="loading-column" /></td>
                            ))
                          }
                        </tr>
                      ))
                    }
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div ref={lastElementRef} />
        </Modal.Body>
      </Modal>
      <ChickenFilterModal
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        onFilter={onFilter}
        filter={filter}
      />
    </>
  );
}
