import React from 'react';
import './style.css';
import { Modal } from 'react-bootstrap';
import { IMAGES_URL } from 'src/constant/images';

export function TrailerVideoModal({ isOpen, onClose }: {
  isOpen: boolean,
  onClose: () => void
}) {
  return (
    <Modal
      contentClassName="trailer-video"
      centered
      size="xl"
      show={isOpen}
      onHide={onClose}
    >
      <Modal.Body>
        <button type="button" className="close" onClick={onClose}>
          {' '}
          <img src={IMAGES_URL.CLOSEICON} alt="" />
          {' '}
        </button>

        <iframe src="https://www.youtube.com/embed/chb7H6PsJeI?rel=0&controls=0&loop=1&playlist=chb7H6PsJeI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
      </Modal.Body>
    </Modal>
  );
}
