export enum MarketplaceTradeType {
  NoTrade = 0,
  NoTokens = 1,
  Any = 2,
  Preference = 3,
  CustomPreference = 4,
  OfferOnly = 5
}

export const tradeTypes = [{
  label: 'Buy Now (No Offers)',
  value: MarketplaceTradeType.NoTrade,
}, {
  label: 'Buy Now (Receive Offers)',
  value: MarketplaceTradeType.NoTokens,
}, {
  label: 'Offers Only',
  value: MarketplaceTradeType.OfferOnly,
}];
