import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import { apiService } from 'src/services/apiService';
import { toastService } from 'src/services/toastService';
import {
  getLiveChickenStakingSeasonSuccessResponse,
  getLiveChickenStakingSeasonFailedResponse,
  getLiveChickenStakingSeasonRequest,
} from './reducer';

function* getLiveChickenStakingSeason() {
  try {
    const res = yield call(apiService.getLiveChickenStakingSeason);

    yield put(getLiveChickenStakingSeasonSuccessResponse(res.data.chickenStakingSeason));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');

    yield put(getLiveChickenStakingSeasonFailedResponse());
  }
}

function* userEffects() {
  yield takeLatest(getLiveChickenStakingSeasonRequest, getLiveChickenStakingSeason);
}

const bawkStakingSaga = [call(userEffects)];

export default bawkStakingSaga;
