/* eslint-disable no-unused-expressions */
/* eslint-disable import/first */
/* eslint-disable class-methods-use-this */
import Web3 from 'web3';
import axios from 'axios';
import { AbiItem } from 'web3-utils';
import Biconomy from '@biconomy/mexa';
import * as ethers from 'ethers';

import moment from 'moment-timezone';
import Multicall from '@dopex-io/web3-multicall';
import {
  BICONOMY_FORWARDER_CONTRACT,
} from 'src/abis';
import { Contract } from 'web3-eth-contract';
import {
  BICONOMY_CUSTOM_GAS_LIMIT,
  IS_COIN_TEST_NET,
} from 'src/utils/config';
import { exponentialBackOff } from 'src/utils/exponentialBackoff';
import { sleep } from 'src/utils/utils';
import { EventPayloadType, EventsEnum, events } from 'src/events';
import { ChainId } from 'src/constant/chainId';
import { ProviderService } from '../provider';
import { TokenContractService } from './tokenContractService';

const ETH_MAINNET_PROVIDER_URL = 'https://eth-mainnet.alchemyapi.io/v2/VmxTbO58cEOJSUrJsC5D0_mOHjP8twLv';
const SEPOLIA_PROVIDER_URL = 'https://eth-sepolia.g.alchemy.com/v2/LaPBl5MZILY-xto1on-Au8eT4eXfVFQq';
const POLYGON_MAINNET_PROVIDER_URL = 'https://polygon-mainnet.g.alchemy.com/v2/7see4kYOnldIJN3tVMd3P4LkZmsr_90D';
const POLYGON_MULTICALL_ADDRESS = '0xc4f1501f337079077842343Ce02665D8960150B0';
const BICONOMY_API_KEY = 'zoU4ADq2m.965bc4fe-1c7b-47e4-8b14-e48145f7e48d';
const SEPOLIA_MULTICALL_ADDRESS = '0x25eef291876194aefad0d60dff89e268b90754bb';
const ETH_MULTICALL_ADDRESS = '0xeefba1e63905ef1d7acba5a8513c70307c1ce441';

const domainType = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'verifyingContract', type: 'address' },
  { name: 'salt', type: 'bytes32' }, // TODO:
];

const metaTransactionType = [
  { name: 'nonce', type: 'uint256' }, // TODO:
  { name: 'from', type: 'address' },
  { name: 'functionSignature', type: 'bytes' },
];

const forwardRequestType = [
  { name: 'from', type: 'address' },
  { name: 'to', type: 'address' },
  { name: 'token', type: 'address' },
  { name: 'txGas', type: 'uint256' },
  { name: 'tokenGasPrice', type: 'uint256' },
  { name: 'batchId', type: 'uint256' },
  { name: 'batchNonce', type: 'uint256' },
  { name: 'deadline', type: 'uint256' },
  { name: 'data', type: 'bytes' },
];

abstract class ContractService {
  public multiCallProvider = new Multicall({
    multicallAddress: POLYGON_MULTICALL_ADDRESS,
    provider: POLYGON_MAINNET_PROVIDER_URL,
  });

  public ethMultiCallProvider = new Multicall({
    multicallAddress: IS_COIN_TEST_NET
      ? SEPOLIA_MULTICALL_ADDRESS
      : ETH_MULTICALL_ADDRESS,
    provider: IS_COIN_TEST_NET ? SEPOLIA_PROVIDER_URL : ETH_MAINNET_PROVIDER_URL,
  });

  public static ethWeb3 = new Web3(ETH_MAINNET_PROVIDER_URL);

  public static sepoliaWeb3 = new Web3(SEPOLIA_PROVIDER_URL);

  public static polygonWeb3 = new Web3(POLYGON_MAINNET_PROVIDER_URL);

  private static services: Map<string, ContractService> = new Map();

  private static servicesArray: Array<ContractService> = [];

  private static contracts: Map<string, Contract> = new Map();

  public contractAddress: string;

  public abi: AbiItem[];

  public chainId: ChainId;

  constructor(chainId: ChainId, contractAddress: string, abi: AbiItem[]) {
    this.contractAddress = contractAddress;
    this.abi = abi;
    this.chainId = chainId;
  }

  protected static add(service: ContractService): void {
    if (ContractService.services.has(service.contractAddress)) {
      throw new Error(`${service.contractAddress} already exists`);
    }

    ContractService.services.set(service.contractAddress, service);
    ContractService.servicesArray.push(service);
  }

  static createContract(web3: Web3, contractAddress: string, abi: AbiItem[]) {
    const contract = new web3.eth.Contract(abi, contractAddress);

    this.addContract(contractAddress, contract);
  }

  private static addContract(
    contractAddress: string,
    contract: Contract,
  ): void {
    if (!ContractService.contracts.has(contractAddress)) {
      ContractService.contracts.set(contractAddress, contract);
    }
  }

  protected getContract(contractAddress: string) {
    return ContractService.contracts.get(contractAddress);
  }

  protected getTokenContract(contractAddress) {
    const service = ContractService.services.get(contractAddress);

    if (!service) {
      throw new Error(`Not initialized contract service for ${contractAddress}`);
    }

    return service as TokenContractService;
  }

  public static initializeContracts() {
    const polygonProvider = new Web3.providers.HttpProvider(
      POLYGON_MAINNET_PROVIDER_URL,
    );

    const biconomy = new Biconomy.Biconomy(polygonProvider, {
      apiKey: BICONOMY_API_KEY,
      debug: false,
    });

    return new Promise((resolve, reject) => {
      biconomy
        .onEvent(biconomy.READY, async () => {
          console.log('biconomy ready!');

          // This web3 instance is used to read normally and write to contract via meta transactions.
          const web3Biconomy = new Web3(biconomy);
          // biconomy forwarder contract
          ContractService.createContract(
            web3Biconomy,
            BICONOMY_FORWARDER_CONTRACT.address,
            BICONOMY_FORWARDER_CONTRACT.abi as AbiItem[],
          );

          // eslint-disable-next-line no-restricted-syntax
          for (const service of this.servicesArray) {
            // each service contract: like race contract, land contract, etc
            ContractService.createContract(
              web3Biconomy,
              service.contractAddress,
              service.abi,
            );
          }

          resolve(true);
        })
        .onEvent(biconomy.ERROR, async (error, message) => {
          console.log('biconomy error!', error);

          reject(error);
        });
    });
  }

  // public get wethContract() {
  //   return this.getContract(WETH_CONTRACT.address);
  // }

  public get biconomyForwarderContract() {
    return this.getContract(BICONOMY_FORWARDER_CONTRACT.address);
  }

  public get contract() {
    return this.getContract(this.contractAddress);
  }

  public async waitUntilBiconomyReady() {
    if (this.biconomyForwarderContract) {
      return;
    }

    await ContractService.initializeContracts();
  }

  public async contractCall<T>(func: () => Promise<T>) {
    await this.waitUntilBiconomyReady();
    return func();
  }

  public domainData(
    name: string,
    version: string,
    contractAddress: string,
  ) {
    return {
      name,
      version,
      verifyingContract: contractAddress,
      salt: Web3.utils.padLeft(Web3.utils.numberToHex(this.chainId), 64),
    };
  }

  public getSignatureParameters(signature: string) {
    if (!Web3.utils.isHexStrict(signature)) {
      throw new Error(`Given value ${signature} is not a valid hex string.`);
    }

    const r = signature.slice(0, 66);
    const s = `0x${signature.slice(66, 130)}`;
    let v: any = `0x${signature.slice(130, 132)}`;
    v = Web3.utils.hexToNumber(v);

    // ledger fix
    if (![27, 28].includes(v)) v += 27;

    return {
      r,
      s,
      v,
    };
  }

  public fixSignature(signature: string) {
    // split and stitch back to fix ledger issue
    const params = this.getSignatureParameters(signature);
    let { v } = params;
    const { r, s } = params;
    v = Web3.utils.numberToHex(v);
    const newSignature = r + s.slice(2) + v.slice(2);

    return newSignature;
  }

  public async sendExecuteMetaTransaction(
    apiId: string,
    contract: Contract,
    account: string,
    params: any,
    { onTx, onReceipt, onError },
    signatureType?: string,
  ) {
    try {
      const gasLimit = BICONOMY_CUSTOM_GAS_LIMIT;

      const receipt = await this.executeMetaTransaction(
        apiId,
        contract,
        account,
        params,
        gasLimit,
        { onTx },
        signatureType,
      );

      if (!receipt.status) {
        throw new Error(
          'execution reverted: Signer and signature do not match',
        );
      }

      if (typeof onReceipt === 'function') {
        onReceipt(receipt);
      }
    } catch (error) {
      if (typeof onError === 'function') onError(error);
    }
  }

  public async singAndSendForwardTransaction(
    apiId: string,
    account: string,
    functionSignature,
    {
      onError, onSubmit, onTx, onReceipt,
    },
  ) {
    try {
      const domainSeparator = this.getDomainSeperator();
      const { signature, forwardTxRequest } = await this.signForwardTransaction(account, this.contractAddress, functionSignature, BICONOMY_CUSTOM_GAS_LIMIT);

      if (typeof onSubmit === 'function') {
        onSubmit(null, signature);
      }

      await this.sendExecuteMetaTransaction(
        apiId,
        this.contract,
        account,
        [forwardTxRequest, domainSeparator, signature],
        {
          onTx,
          onReceipt,
          onError,
        },
        'EIP712_SIGN',
      );
    } catch (err) {
      if (typeof onSubmit === 'function') {
        onSubmit(err, null);
      }

      if (typeof onError === 'function') {
        onError(err);
      }
    }
  }

  public async signAndSendMetaTransaction(
    apiId: string,
    name: string,
    version: string,
    contract: Contract,
    account: string,
    functionSignature,
    {
      onError, onSubmit, onTx, onReceipt,
    },
  ) {
    try {
      const signature = await this.getMetaTransactionSingTypedData(
        name,
        version,
        contract,
        account,
        functionSignature,
      );

      if (typeof onSubmit === 'function') {
        onSubmit(null, signature);
      }

      const { r, s, v } = this.getSignatureParameters(signature);
      await this.sendExecuteMetaTransaction(
        apiId,
        contract,
        account,
        [account, functionSignature, r, s, v],
        {
          onTx,
          onReceipt,
          onError,
        },
      );
    } catch (err) {
      if (typeof onSubmit === 'function') {
        onSubmit(err, null);
      }

      if (typeof onError === 'function') {
        onError(err);
      }
    }
  }

  getDomainSeperator() {
    const domainData = {
      name: BICONOMY_FORWARDER_CONTRACT.name,
      version: BICONOMY_FORWARDER_CONTRACT.version,
      salt: Web3.utils.padLeft(Web3.utils.numberToHex(this.chainId), 64),
      verifyingContract: BICONOMY_FORWARDER_CONTRACT.address,
    };

    const domainSeparator = ethers.utils.keccak256(ethers.utils.defaultAbiCoder.encode([
      'bytes32',
      'bytes32',
      'bytes32',
      'address',
      'bytes32',
    ], [
      ethers.utils.id('EIP712Domain(string name,string version,address verifyingContract,bytes32 salt)'),
      ethers.utils.id(domainData.name),
      ethers.utils.id(domainData.version),
      domainData.verifyingContract,
      domainData.salt,
    ]));

    return domainSeparator;
  }

  public async getMetaTransactionSingTypedData(
    name: string,
    version: string,
    contract: Contract,
    account: string,
    functionSignature,
  ) {
    const nonce = await this.getNonce(contract, account);
    const message = {
      nonce: parseInt(nonce),
      from: account,
      functionSignature,
    };

    const dataToSign = JSON.stringify({
      types: {
        EIP712Domain: domainType,
        MetaTransaction: metaTransactionType,
      },
      domain: this.domainData(name, version, contract.options.address),
      primaryType: 'MetaTransaction',
      message,
    });

    const signTypedDataFunc = async () => ProviderService.signTypedDataV4(this.chainId, account, dataToSign);

    const { result } = await exponentialBackOff(signTypedDataFunc);

    return this.fixSignature(result);
  }

  public buildForwardTxRequest({
    from,
    to,
    gasLimit,
    batchId,
    batchNonce,
    data,
  }) {
    const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
    const forwardTxRequest = {
      from,
      to,
      token: ZERO_ADDRESS,
      txGas: gasLimit,
      tokenGasPrice: '0',
      batchId: parseInt(batchId),
      batchNonce: parseInt(batchNonce),
      deadline: Math.floor(Date.now() / 1000 + 3600), // 1 hour
      data,
    };

    return forwardTxRequest;
  }

  public getDataToSignForEIP712(request) {
    const domain = {
      name: BICONOMY_FORWARDER_CONTRACT.name,
      version: BICONOMY_FORWARDER_CONTRACT.version,
      salt: Web3.utils.padLeft(Web3.utils.numberToHex(this.chainId), 64),
      verifyingContract: BICONOMY_FORWARDER_CONTRACT.address,
    };

    const dataToSign = JSON.stringify({
      types: {
        EIP712Domain: domainType,
        ERC20ForwardRequest: forwardRequestType,
      },
      domain,
      primaryType: 'ERC20ForwardRequest',
      message: request,
    });

    return dataToSign;
  }

  async executeForwardTransaction() {
    //
  }

  async executeMetaTransaction(
    apiId,
    contract: Contract,
    userWalletId: string,
    params: any,
    gasLimit: any,
    { onTx },
    signatureType?: any,
  ) {
    try {
      const postMetaTxFunc = async () => axios.post(
        'https://api.biconomy.io/api/v2/meta-tx/native',
        {
          apiId,
          to: contract.options.address,
          from: userWalletId,
          gasLimit: Web3.utils.toHex(gasLimit),
          params,
          signatureType,
        },
        {
          headers: {
            'x-api-key': BICONOMY_API_KEY,
            'Content-Type': 'application/json;charset=utf-8',
          },
        },
      );

      const { result: res } = await exponentialBackOff<any>(postMetaTxFunc);

      const { data } = res;
      if (data.code !== 200) {
        throw new Error(res.data.message || 'Please try again!');
      }

      const { txHash } = data;

      if (typeof onTx === 'function') {
        onTx(txHash);
      }
      const startTime = moment.utc();
      const receipt = await this.getTransactionReceiptMined(
        txHash,
        startTime,
        data.retryDuration,
        onTx,
      );

      return receipt;
    } catch (err) {
      throw err;
    }
  }

  protected async checkTokenApproval(userWalletId: string, contract: string) {
    const isApproved = await this.isApprovedToken(userWalletId, contract);

    if (!isApproved) {
      await this.setApproveAllTokens(userWalletId, contract);
    }
  }

  public isApprovedToken(userWalletId: string, contractAddress: string) {
    return this.contractCall(async () => {
      const nftContract = this.getContract(contractAddress);

      if (!nftContract) {
        throw new Error(`Can't not find contract for ${contractAddress}`);
      }

      const isApprovedForAllFunc = async () => nftContract.methods
        .isApprovedForAll(userWalletId, this.contractAddress)
        .call();
      const { result: isApproved } = await exponentialBackOff(
        isApprovedForAllFunc,
      );

      return isApproved;
    });
  }

  public setApproveAllTokens(
    userWalletId: string,
    contractAddress: string,
    onConfirm?: (hash: string) => void,
  ) {
    return this.contractCall(async () => {
      const nftContract = this.getContract(contractAddress);

      if (!nftContract) {
        throw new Error(`Can't not find contract for ${contractAddress}`);
      }

      const setApprovalForAllFunc = async () => {
        const functionSignature = nftContract.methods
          .setApprovalForAll(this.contractAddress, true)
          .encodeABI();

        const { transactionHash } = await this.sendRawTransaction(
          userWalletId,
          contractAddress,
          functionSignature,
        );

        onConfirm && onConfirm(transactionHash);

        const receipt = await this.getTransactionReceiptLoop(transactionHash);

        return receipt;
      };

      const { result: receipt } = await exponentialBackOff(
        setApprovalForAllFunc,
      );

      return receipt;
    });
  }

  async getNonce(contract: Contract, account: string) {
    const getNonceFunc = async () => contract.methods.getNonce(account).call();
    const { result: nonce } = await exponentialBackOff(getNonceFunc);

    return nonce;
  }

  async getTransactionNonce(account: string) {
    const getNonceFunc = async () => ContractService.polygonWeb3.eth.getTransactionCount(account, 'latest');
    const { result: nonce } = await exponentialBackOff(getNonceFunc);

    return nonce;
  }

  async getBatchNonce(userWalletId: string, batchId: number) {
    const getNonceFunc = async () => this.biconomyForwarderContract.methods
      .getNonce(userWalletId, batchId)
      .call();
    const { result: nonce } = await exponentialBackOff(getNonceFunc);

    return nonce;
  }

  async getTokenAllowance(account: string, tokenAddress: string) {
    // check market is having weth allowance from that user
    return this.contractCall(async () => {
      const service = this.getTokenContract(tokenAddress);

      return service.getAllowance(account, this.contractAddress);
    });
  }

  async getApproveSignature(qty: string, tokenAddress: string) {
    return this.contractCall(async () => {
      const service = this.getTokenContract(tokenAddress);
      return service.contract.methods.approve(this.contractAddress, qty).encodeABI();
    });
  }

  public async approveTokenAllowance(
    account: string,
    functionSignature,
    tokenAddress,
    {
      onError, onSubmit, onTx, onReceipt,
    },
  ) {
    const service = this.getTokenContract(tokenAddress);

    return this.contractCall(async () => service.approveWithBiconomy(account, functionSignature, {
      onError,
      onReceipt,
      onSubmit,
      onTx,
    }));
  }

  async getEthBalance(userWalletId: string) {
    const bawkBalanceFunc = async () => ContractService.ethWeb3.eth.getBalance(userWalletId);
    const { result: bawkBalance } = await exponentialBackOff(bawkBalanceFunc);

    return bawkBalance;
  }

  async getTokenBalance(userWalletId: string, tokenAddress: string) {
    return this.contractCall(async () => {
      const service = this.getTokenContract(tokenAddress);

      return service.getBalance(userWalletId);
    });
  }

  async getMaticBalance(userWalletId: string) {
    const bawkBalanceFunc = async () => ContractService.polygonWeb3.eth.getBalance(userWalletId);
    const { result: maticBalance } = await exponentialBackOff(bawkBalanceFunc);

    return maticBalance;
  }

  async retrieveTransactionHash(txHash: string) {
    const res: any = await axios.get(
      'https://api.biconomy.io/api/v1/meta-tx/resubmitted',
      {
        params: {
          transactionHash: txHash,
          networkId: this.chainId,
        },
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      },
    );

    const { data } = res;
    if (data.code === 200) {
      return data.data.newHash;
    }

    throw new Error(
      'Transaction was not mined within 50 blocks, please make sure your transaction was properly sent. Be aware that it might still be mined!',
    );
  }

  async getTransactionReceipt(
    txHash: string,
    ethWeb3: Web3 = ContractService.polygonWeb3,
  ) {
    const getTransactionReceiptFunc = async () => ethWeb3.eth.getTransactionReceipt(txHash);

    const { result: receipt } = await exponentialBackOff(
      getTransactionReceiptFunc,
    );

    return receipt;
  }

  async getTransactionReceiptMined(
    txHash: string,
    startTime: moment.Moment,
    retryDuration,
    onTx,
    interval = 3000,
  ) {
    try {
      if (typeof txHash !== 'string') {
        throw new Error(`Invalid Type: ${txHash}`);
      }

      const receipt = await this.getTransactionReceipt(txHash);
      if (receipt) {
        return receipt;
      }

      const now = moment.utc();
      const duration = Math.ceil(
        moment.duration(now.diff(startTime)).asSeconds(),
      );

      if (duration >= retryDuration) {
        // get resubmitted transaction hash from biconomy
        const newHash = await this.retrieveTransactionHash(txHash);

        if (newHash !== txHash) {
          onTx(newHash);
          const newStartTime = moment.utc();
          return this.getTransactionReceiptMined(
            newHash,
            newStartTime,
            retryDuration,
            onTx,
            interval,
          );
        }
      }

      await sleep(interval);

      return this.getTransactionReceiptMined(
        txHash,
        startTime,
        retryDuration,
        onTx,
        interval,
      );
    } catch (err) {
      throw err;
    }
  }

  async getTransactionReceiptLoop(
    txHash: string,
    ethWeb3: Web3 = ContractService.polygonWeb3,
  ) {
    const receipt = await this.getTransactionReceipt(txHash, ethWeb3);

    if (receipt) {
      if (receipt.status) {
        return receipt;
      }

      throw new Error('Failed transaction');
    }

    await sleep(10000);

    return this.getTransactionReceiptLoop(txHash, ethWeb3);
  }

  async emitTransactionMined(
    event: EventsEnum,
    payload: EventPayloadType,
    ethWeb3: Web3 = ContractService.polygonWeb3,
  ) {
    await this.getTransactionReceiptLoop(payload.transactionHash, ethWeb3);
    events.emit(event, payload);
  }

  async signForwardTransaction(
    userWalletId: string,
    contractAddress: string,
    functionSignature: any,
    gasLimit = 0,
  ) {
    // const batchId = await biconomyForwarderContract.methods.getBatch(userWalletId);
    const batchId = 0;
    const batchNonce = await this.getBatchNonce(userWalletId, batchId);

    const forwardTxRequest = this.buildForwardTxRequest({
      from: userWalletId,
      to: contractAddress,
      gasLimit,
      batchId,
      batchNonce,
      data: functionSignature,
    });
    const dataToSign = this.getDataToSignForEIP712(forwardTxRequest);

    const signTypedDataFunc = async () => ProviderService.signTypedDataV4(
      this.chainId,
      userWalletId,
      dataToSign,
    );

    const { result: signature } = await exponentialBackOff(signTypedDataFunc);

    return {
      signature: this.fixSignature(signature),
      deadline: forwardTxRequest.deadline,
      forwardTxRequest,
    };
  }

  async sendRawTransaction(
    userWalletId: string,
    contractAddress: string,
    functionSignature: string,
    valueWei?: string,
  ) {
    const tx: any = {
      chainId: Web3.utils.toHex(this.chainId),
      from: userWalletId,
      to: contractAddress,
      data: functionSignature,
    };

    if (valueWei) {
      tx.value = Web3.utils.toHex(valueWei);
    }

    const sendTransactionFunc = async () => ProviderService.sendTransaction(this.chainId, userWalletId, tx);

    const { result: transactionHash } = await exponentialBackOff(
      sendTransactionFunc,
    );

    return {
      transactionHash,
    };
  }

  async getGasLimit(method: any, userWalletId: string, valueWei?: string) {
    const params: {
      from: string;
      value?: string;
    } = { from: userWalletId };

    if (valueWei) {
      params.value = valueWei;
    }

    const estimateGasFunc = async () => method.estimateGas(params);
    const { result: gasLimit } = await exponentialBackOff(estimateGasFunc);

    return gasLimit;
  }
}

export default ContractService;
