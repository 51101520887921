import { MarketplaceTradeType } from 'src/constant';

export interface MarketItem {
  marketItemId?: number
  nftContract: string;
  tokenId: number;
  price: number;
  usdPrice?: string
  minimum: number
  tradeType: MarketplaceTradeType
  signature?: string
  deadline?: number
  transactionHash?: string
  gasLimit?: number
}

export interface TradeOffer {
  marketItemId: number,
  tradeOfferId?: number,
  nftContract: string,
  price: number,
  tokensAttached?: string[]
  signature?: string
  deadline?: number
  transactionHash?: string
  gasLimit?: number
}

export enum MarketPlaceActionType {
  CreateMarketItem = 'createMarketItem',
  EditMarketItem = 'editMarketItem',
  CancelMarketItem = 'cancelMarketItem',
  PurchaseMarketItem = 'purchaseMarketItem',

  MakeOffer = 'makeOffer',
  EditOffer = 'editOffer',
  FulfillOffer = 'fulfillOffer',
  CancelOrDeclineOffer = 'cancelOrDeclineOffer',
}

export interface MarketPlaceAction {
  type: MarketPlaceActionType;
  item: MarketItem | TradeOffer;
}

export interface MarketplaceAssignmentStatus {
  isPending: boolean;
  action?: MarketPlaceActionType
}

export interface GetOffersAction {
  page: number
  limit: number
}
