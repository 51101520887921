import { AbiItem } from 'web3-utils';

import { CHICKEN_CONTRACT } from 'src/abis';
import { ChainId } from 'src/constant/chainId';
import ContractService from './baseContractService';

export class ChickenContractService extends ContractService {
  constructor() {
    super(ChainId.Polygon, CHICKEN_CONTRACT.address, CHICKEN_CONTRACT.abi as AbiItem[]);

    ContractService.add(this);
  }
}
