import React, {
  createContext, useContext, useState, useEffect, useMemo, useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import { raceContractService } from 'src/services/contracts';
import { useTranslation } from 'react-i18next';

import {
  isLoggedInSelector,
  userWalletIdSelector,
} from 'src/store/userWallet/selector';
import {
  isAssignedSelector,
  isAssigningPaidRaceSelector,
  isAssigningSelector,
  isCheckedAssignSelector,
  raceGasLimitSelector,
  raceCouponSelector,
} from 'src/store/lane/selector';
import { exchangeRateSelector } from 'src/store/exchange/selector';
import {
  assignLaneFailedResponse,
  checkAssignLaneRequest,
  checkLastRaceAssignmentRequest,
  clearIsAssigned,
} from 'src/store/lane/reducer';
import { checkIsDeployingRequest } from 'src/store/races/action';
import { LaneActions } from 'src/store/common/action';

import { JEWELS_CONTRACT_ADDRESS } from 'src/utils/config';
import * as utils from 'src/utils/utils';
import { TransactionComplete } from 'src/modals/transactionComplete';
import { TransactionConfirmation } from 'src/modals/transactionConfirmation';
import { SelectChicken } from 'src/modals/selectChicken';
import { InsufficientFundsModal } from 'src/modals/insuffiencet-funds';
import { useWalletContext } from './walletProvider';
import { useCurrency } from './currency';

interface EnterRaceContextProps {
  onEnter: (race: any) => Promise<void>;
}

export const EnterRaceContext = createContext<
  EnterRaceContextProps | undefined
>(undefined);

export const useEnterRaceContext = () => {
  const context = useContext(EnterRaceContext);

  return context;
};

export default function EnterRaceProvider(props: {
  children: React.ReactNode;
}) {
  const dispatch = useDispatch();
  const userWalletId = useSelector(userWalletIdSelector);
  const coinRate = useSelector(exchangeRateSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const isAssigned = useSelector(isAssignedSelector);
  const isAssigning = useSelector(isAssigningSelector);
  const isAssigningPaidRace = useSelector(isAssigningPaidRaceSelector);
  const isCheckedAssign = useSelector(isCheckedAssignSelector);
  const gasLimit = useSelector(raceGasLimitSelector);
  const coupon = useSelector(raceCouponSelector);
  const [openedRace, setOpenedRace] = useState<any>();
  const [selectedChicken, setSelectedChicken] = useState<any>();
  const [showInsufficientFundsModal, setShowInsufficientFundsModal] = useState(false);

  const [isApproved, setIsApproved] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [openSelectChicken, setOpenSelectChicken] = useState(false);
  const [openTransactionConformation, setOpenTransactionConformation] = useState(false);
  const [openTransactionComplete, setOpenTransactionComplete] = useState(false);
  const [recaptchaKey, setRecaptchaKey] = useState<string>();

  const { onLogin } = useWalletContext();

  const { t } = useTranslation('translation', { keyPrefix: 'CONTEXT.ENTER_RACE' });

  const onConfirmTransaction = async (recaptcha?: string) => {
    if (!openedRace || !selectedChicken) {
      return;
    }

    setOpenTransactionConformation(false);

    if (openedRace.fee > 0) {
      const allowance = await raceContractService.getTokenAllowance(userWalletId, JEWELS_CONTRACT_ADDRESS);
      const qty = Web3.utils.toWei(openedRace.fee.toString(), 'ether');

      const approved = Web3.utils
        .toBN(allowance)
        .gte(Web3.utils.toBN(qty.toString()));

      setIsApproved(approved);
    } else {
      setIsApproved(true);
    }
    setRecaptchaKey(recaptcha);

    dispatch(
      checkAssignLaneRequest({
        raceId: openedRace.id,
        chickenId: selectedChicken.id,
      }),
    );
  };

  const transferWithAssignLane = useCallback(async (race: any, chickenId: string) => {
    setIsApproving(false);

    try {
      const { signature, deadline } = await raceContractService.enterRace(
        race,
        chickenId,
        userWalletId,
        gasLimit,
        coupon,
      );

      const data: any = {
        raceId: race.id,
        chickenId,
        signature,
        deadline,
        gasLimit,
        coupon,
      };

      dispatch(
        LaneActions.assignLaneForPaidRaceRequest(data),
      );
    } catch (err) {
      toast.error(err.message);
      dispatch(assignLaneFailedResponse(err));
    }
  }, [dispatch, userWalletId, gasLimit, coupon]);

  const transferWithApprove = useCallback(async (race: any, chickenId: string) => {
    // const qty = Web3.utils.toWei(openedRace.fee, 'ether');
    setIsApproving(true);
    const maxQty = Web3.utils.toWei(
      Web3.utils.toBN(2 ** 64 - 1),
      'ether',
    );
    const hexQty = Web3.utils.toHex(maxQty);

    const functionSignature = await raceContractService.getApproveSignature(hexQty, JEWELS_CONTRACT_ADDRESS);

    try {
      await raceContractService.approveTokenAllowance(
        userWalletId,
        functionSignature,
        JEWELS_CONTRACT_ADDRESS,
        {
          onTx: async (tx: string) => {
            //
          },
          onError: async (err: any) => {
            toast.error(err.message);
            dispatch(assignLaneFailedResponse(err));
            await utils.sleep(1000);
            setIsApproving(false);
          },
          onSubmit: async (err: any, result: any) => {
            //
          },
          onReceipt: async (receipt: any) => {
            await transferWithAssignLane(race, chickenId);
          },
        },
      );
    } catch (err) {
      toast.error(err.message);
      dispatch(assignLaneFailedResponse(err));
    }
  }, [dispatch, userWalletId, transferWithAssignLane]);

  const onAssignLane = useCallback(async () => {
    try {
      if (!openedRace) {
        throw new Error('Can not find opened race');
      }

      if (!selectedChicken) {
        throw new Error('Can not find selected chicken');
      }

      if (!userWalletId) {
        throw new Error('Please connect provider');
      }

      if (openedRace.fee > 0) {
        if (!isApproved) {
          await transferWithApprove(openedRace, selectedChicken.id);
        } else {
          await transferWithAssignLane(openedRace, selectedChicken.id);
        }
      } else {
        const data = {
          raceId: openedRace.id,
          chickenId: selectedChicken.id,
          grecaptcha: recaptchaKey,
        };

        dispatch(
          LaneActions.assignLaneForFreeRaceRequest(data),
        );
      }
    } catch (err) {
      toast.error(err.message);
      dispatch(assignLaneFailedResponse(err));
    }
  }, [dispatch, isApproved, openedRace, userWalletId, selectedChicken, recaptchaKey, transferWithApprove, transferWithAssignLane]);

  const toggleCompleteTransaction = async (isOpen: boolean) => {
    if (isOpen) {
      setOpenTransactionComplete(true);
      return;
    }

    await utils.sleep(500);
    setOpenTransactionComplete(false);
  };

  const onSelectChicken = (chicken) => {
    setSelectedChicken(chicken);
    setOpenSelectChicken(false);
    setOpenTransactionConformation(true);
  };

  const checkBalance = useCallback(async (race: any) => {
    if (race.fee <= 0) {
      return true;
    }

    const weth = await raceContractService.getTokenBalance(userWalletId, race.coinContract);
    const balance = Number(Web3.utils.fromWei(weth));

    console.log(balance, race.fee);
    if (balance < race.fee) {
      setShowInsufficientFundsModal(true);
      return false;
    }

    return true;
  }, [userWalletId]);

  const checkContractRaceCapacity = useCallback(async (race: any) => {
    if (race.fee <= 0) {
      return true;
    }

    const raceChickenIds = await raceContractService.getContractRaceChickenIds(race.id);
    if (raceChickenIds && raceChickenIds.length >= race.maxCapacity) {
      toast.error(t('SORRY_THIS_RACE_IS_NOW_FULL'));

      return false;
    }

    return true;
  }, [t]);

  const onEnter = useCallback(async (race: any) => {
    setOpenedRace(race);
    if (isLoggedIn) {
      const isRaceNotFullOnContract = await checkContractRaceCapacity(race);
      const isBalanceEnough = await checkBalance(race);

      if (isRaceNotFullOnContract && isBalanceEnough) {
        setOpenSelectChicken(true);
      }
    } else {
      onLogin();
    }
  }, [isLoggedIn, checkContractRaceCapacity, checkBalance, setOpenSelectChicken, setOpenedRace, onLogin]);

  useEffect(() => {
    if (isAssigned) {
      dispatch(clearIsAssigned({}));
      setRecaptchaKey(undefined);
    }
  }, [dispatch, isAssigned]);

  useEffect(() => {
    toggleCompleteTransaction(isAssigning);
    dispatch(checkIsDeployingRequest());
  }, [dispatch, isAssigning]);

  useEffect(() => {
    if (
      isAssigning
      && isCheckedAssign
      && openedRace
      && selectedChicken
      && userWalletId
    ) {
      onAssignLane();
    }
  }, [isAssigning, isCheckedAssign, onAssignLane, openedRace, userWalletId, selectedChicken]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(checkLastRaceAssignmentRequest(false));
    }
  }, [dispatch, isLoggedIn]);

  const value = useMemo(
    () => ({
      onEnter,
    }),
    [
      onEnter,
    ],
  );

  let selectChickenNotify = null;

  if (openedRace?.group === 10) {
    selectChickenNotify = t('LIMITED_TO_OFFENSIVE_TALENT_CHICKENS');
  } else if (openedRace?.group === 11) {
    selectChickenNotify = t('LIMITED_TO_SPEED_TALENT_CHICKENS');
  }

  return (
    <EnterRaceContext.Provider
      value={value}
    >
      {props.children}
      {openSelectChicken && (
        <SelectChicken
          race={openedRace}
          notify={selectChickenNotify}
          onClose={() => setOpenSelectChicken(false)}
          onSelect={onSelectChicken}
        />
      )}
      <TransactionConfirmation
        open={openTransactionConformation}
        coinRate={coinRate}
        race={openedRace}
        onClose={() => setOpenTransactionConformation(false)}
        onConfirm={onConfirmTransaction}
      />
      <TransactionComplete
        open={openTransactionComplete}
        race={openedRace}
        isApproving={isApproving}
        isAssigningPaidRace={isAssigningPaidRace}
      />
      <InsufficientFundsModal
        show={showInsufficientFundsModal}
        onClose={() => setShowInsufficientFundsModal(false)}
      />
    </EnterRaceContext.Provider>
  );
}
