import { BawkStakingType, ICoupon } from 'src/models';

interface IBawkStakingCompany {
  id: number;
  name: string;
  imageUrl: string;
  createdAt: string;
  updatedAt: string;
}

interface IBawkStakingkMajority {
  userWalletId: string;
  username: string;
  percentage: number;
  amounts: number;
}

export interface IBawkStakingCompanyStats {
  company: IBawkStakingCompany;
  numberOfStakers: number;
  totalSeasonEarnings: number;
  majority: IBawkStakingkMajority;
  totalStakeAmount: number;
  myStakePercentage: number;
  mySeasonEarnings: number;
  myStakeAmount: number;
  myPreviousStakeAmount: number;
  myTotalEscrowedStakeAmount: number;
  myTotalNonEscrowedStakeAmount: number;
}

export interface IBawkStakingStats {
  seasonNumber?: number;
  seasonEndDate?: string;
  seasonStakeEndDate?: string;
  totalInvestedAmount?: number;
  previousSeasonInvestedAmount?: number;
  currentSeasonInvestedAmount?: number;
  myBawkBalance?: number;
  myEscrowBalance?: number;
  myTotalSeasonEarnings?: number;
  rows?: IBawkStakingCompanyStats[];
}

export enum BawkStakingStatus {
  Draft = 'draft',
  Checking = 'checking',
  Checked = 'checked',
  Assigning = 'assigning',
  Assigned = 'assigned',
}

export interface IBawkStaking {
  type: BawkStakingType;
  status: BawkStakingStatus;
  gasLimit: number;
  coupon: ICoupon;
}
