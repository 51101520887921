import { IContract } from 'src/models';
import { FUSION_CONTRACT_ADDRESS } from 'src/utils/config';

export const FUSION_CONTRACT: IContract = {
  address: FUSION_CONTRACT_ADDRESS,
  abi: [
    {
      anonymous: false,
      inputs: [
        {
          indexed: true, internalType: 'address', name: 'account', type: 'address',
        },
        {
          indexed: true, internalType: 'address', name: 'operator', type: 'address',
        },
        {
          indexed: false, internalType: 'bool', name: 'approved', type: 'bool',
        },
      ],
      name: 'ApprovalForAll',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false, internalType: 'uint256', name: 'material0', type: 'uint256',
        },
        {
          indexed: false, internalType: 'uint256', name: 'material1', type: 'uint256',
        },
        {
          indexed: false, internalType: 'uint256', name: 'serums', type: 'uint256',
        },
        {
          indexed: false, internalType: 'uint256', name: 'newChicken', type: 'uint256',
        },
      ],
      name: 'ChickenFused',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [{
        indexed: false, internalType: 'uint8', name: 'version', type: 'uint8',
      }],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
        },
        {
          indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true, internalType: 'address', name: 'operator', type: 'address',
        },
        {
          indexed: true, internalType: 'address', name: 'from', type: 'address',
        },
        {
          indexed: true, internalType: 'address', name: 'to', type: 'address',
        },
        {
          indexed: false, internalType: 'uint256[]', name: 'ids', type: 'uint256[]',
        },
        {
          indexed: false, internalType: 'uint256[]', name: 'values', type: 'uint256[]',
        },
      ],
      name: 'TransferBatch',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true, internalType: 'address', name: 'operator', type: 'address',
        },
        {
          indexed: true, internalType: 'address', name: 'from', type: 'address',
        },
        {
          indexed: true, internalType: 'address', name: 'to', type: 'address',
        },
        {
          indexed: false, internalType: 'uint256', name: 'id', type: 'uint256',
        },
        {
          indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
        },
      ],
      name: 'TransferSingle',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false, internalType: 'string', name: 'value', type: 'string',
        },
        {
          indexed: true, internalType: 'uint256', name: 'id', type: 'uint256',
        },
      ],
      name: 'URI',
      type: 'event',
    },
    {
      inputs: [],
      name: 'FUSION_SERUM',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'adminAddr',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256', name: 'id', type: 'uint256' },
      ],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address[]', name: 'accounts', type: 'address[]' },
        { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' },
      ],
      name: 'balanceOfBatch',
      outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'tokenId_', type: 'uint256' },
        { internalType: 'uint256', name: 'quantity_', type: 'uint256' },
      ],
      name: 'burn',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'id_', type: 'uint256' },
        { internalType: 'uint256', name: 'tokenMintLimit_', type: 'uint256' },
        { internalType: 'uint256', name: 'price_', type: 'uint256' },
        { internalType: 'string', name: 'tokenURI_', type: 'string' },
      ],
      name: 'create',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'crun',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }],
      name: 'exists',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256[]', name: 'materialChickenIds_', type: 'uint256[]' },
        { internalType: 'uint256', name: 'serums_', type: 'uint256' },
        { internalType: 'uint256', name: 'newChickenId_', type: 'uint256' },
        {
          components: [
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
            { internalType: 'uint8', name: 'v', type: 'uint8' },
          ],
          internalType: 'struct Serum.Coupon',
          name: 'coupon',
          type: 'tuple',
        },
      ],
      name: 'fuse',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'trustedForwarder_', type: 'address' },
        { internalType: 'address', name: 'weth_', type: 'address' },
        { internalType: 'address', name: 'crun_', type: 'address' },
        { internalType: 'address', name: 'adminAddr_', type: 'address' },
        { internalType: 'string', name: 'serumUri_', type: 'string' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'address', name: 'operator', type: 'address' },
      ],
      name: 'isApprovedForAll',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'isMintingActivated',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'forwarder', type: 'address' }],
      name: 'isTrustedForwarder',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'tokenId_', type: 'uint256' },
        { internalType: 'uint256', name: 'quantity_', type: 'uint256' },
      ],
      name: 'mint',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'tokenId_', type: 'uint256' },
        { internalType: 'address[]', name: 'addresses_', type: 'address[]' },
        { internalType: 'uint256[]', name: 'quantities_', type: 'uint256[]' },
      ],
      name: 'mintBatch',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'mintLimit',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155BatchReceived',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC721Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'recipient',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' },
        { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
        { internalType: 'bytes', name: 'data', type: 'bytes' },
      ],
      name: 'safeBatchTransferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'id', type: 'uint256' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'bytes', name: 'data', type: 'bytes' },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'adminAddress_', type: 'address' }],
      name: 'setAdminAddress',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'operator', type: 'address' },
        { internalType: 'bool', name: 'approved', type: 'bool' },
      ],
      name: 'setApprovalForAll',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'recipient_', type: 'address' }],
      name: 'setFeeRecipient',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'id_', type: 'uint256' },
        { internalType: 'uint256', name: 'limit_', type: 'uint256' },
      ],
      name: 'setMintLimit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'bool', name: 'isActive_', type: 'bool' }],
      name: 'setMintingActivated',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'tokenId_', type: 'uint256' },
        { internalType: 'uint256', name: 'price_', type: 'uint256' },
      ],
      name: 'setPrice',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'tokenId_', type: 'uint256' },
        { internalType: 'string', name: 'newURI_', type: 'string' },
      ],
      name: 'setTokenURI',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'forwarder_', type: 'address' }],
      name: 'setTrustedForwarder',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'string', name: 'uri_', type: 'string' }],
      name: 'setURI',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
      name: 'supportsInterface',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'tokenPrice',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'tokenUri',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'totalMint',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'trustedForwarder',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: 'id_', type: 'uint256' }],
      name: 'uri',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'versionRecipient',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'pure',
      type: 'function',
    },
    {
      inputs: [],
      name: 'weth',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
  ],

};
