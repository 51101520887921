import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import {
  bawkEscrowContractService,
  bawkStakingContractService,
} from 'src/services/contracts';

import {
  isLoggedInSelector,
  userWalletIdSelector,
} from 'src/store/userWallet/selector';
import { checkIsDeployingRequest } from 'src/store/races/action';

import { BAWK_CONTRACT_ADDRESS } from 'src/utils/config';
import * as utils from 'src/utils/utils';
import { BawkStakingTransactionConfirmation } from 'src/modals/bawk-staking/bawk-staking-transaction-confirmation';
import { BawkStakingTransactionComplete } from 'src/modals/bawk-staking/bawk-staking-transaction-complete';
import {
  BawkStakingType,
  BawkType,
  IBawkStakingRequest,
} from 'src/models';
import {
  bawkStakingFailedResponse,
  bawkStakingRequest,
  checkBawkStakingRequest,
  checkLastBawkStakingAssignmentRequest,
  getBawkStakingStatsRequest,
  resetBawkStakingStatus,
} from 'src/store/bawk-staking/reducer';
import {
  bawkStakingCouponSelector,
  bawkStakingGasLimitSelector,
  bawkStakingStatusSelector,
} from 'src/store/bawk-staking/selector';
import { BawkStakingStatus } from 'src/store/bawk-staking/model';
import { WalletActions } from 'src/store/common/action';
import { useWalletContext } from './walletProvider';

interface BawkStakingContextProps {
  onSubmit: (request: IBawkStakingRequest) => Promise<void>;
}

export const BawkStakingContext = createContext<
  BawkStakingContextProps | undefined
>(undefined);

export const useBawkStakingContext = () => {
  const context = useContext(BawkStakingContext);
  return context;
};

export default function BawkStakingProvider(props: {
  children: React.ReactNode;
}) {
  const dispatch = useDispatch();
  const userWalletId = useSelector(userWalletIdSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);

  const bawkStakingStatus = useSelector(bawkStakingStatusSelector);
  const gasLimit = useSelector(bawkStakingGasLimitSelector);
  const coupon = useSelector(bawkStakingCouponSelector);

  const [request, setRequest] = useState<IBawkStakingRequest>();

  const [isApproved, setIsApproved] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [openTransactionConformation, setOpenTransactionConformation] = useState(false);
  const [openTransactionComplete, setOpenTransactionComplete] = useState(false);

  const isChecked = useMemo(() => bawkStakingStatus === BawkStakingStatus.Checked, [bawkStakingStatus]);
  const isAssigning = useMemo(() => bawkStakingStatus === BawkStakingStatus.Assigning, [bawkStakingStatus]);
  const isAssigned = useMemo(() => bawkStakingStatus === BawkStakingStatus.Assigned, [bawkStakingStatus]);

  const { onLogin } = useWalletContext();

  const bawkStakingCallback = useCallback(async () => {
    const {
      type,
      bawkType,
      bawkStakingCompanyId,
      epoch,
      amount,
    } = request;
    const cid = bawkStakingCompanyId - 1;

    if (type === BawkStakingType.ClaimJewel) {
      return bawkStakingContractService.claimRewardsFromCompanyByEpoch(userWalletId, cid, epoch, gasLimit, coupon);
    }

    if (bawkType === BawkType.Unclaimed) {
      if (type === BawkStakingType.Stake) {
        return bawkEscrowContractService.stakeEscrow(userWalletId, cid, amount, gasLimit, coupon);
      }

      return bawkEscrowContractService.unstakeEscrow(userWalletId, cid, amount, gasLimit, coupon);
    }

    if (type === BawkStakingType.Stake) {
      return bawkStakingContractService.stake(userWalletId, cid, amount, gasLimit, coupon);
    }

    return bawkStakingContractService.unstake(userWalletId, cid, amount, gasLimit, coupon);
  }, [userWalletId, request, gasLimit, coupon]);

  const onConfirmTransaction = useCallback(async () => {
    setOpenTransactionConformation(false);

    if (!request || !userWalletId) {
      return;
    }

    if (request.type === BawkStakingType.Stake && request.bawkType === BawkType.Claimed) {
      const allowance = await bawkStakingContractService.getTokenAllowance(userWalletId, BAWK_CONTRACT_ADDRESS);
      const qty = Web3.utils.toWei(request.amount.toString(), 'ether');

      const approved = Web3.utils
        .toBN(allowance)
        .gte(Web3.utils.toBN(qty.toString()));

      setIsApproved(approved);
    } else {
      setIsApproved(true);
    }

    dispatch(
      checkBawkStakingRequest(request),
    );
  }, [request, dispatch, userWalletId]);

  const transferWithStaking = useCallback(async () => {
    setIsApproving(false);

    try {
      const { signature, deadline } = await bawkStakingCallback();

      dispatch(
        bawkStakingRequest({
          ...request,
          gasLimit,
          signature,
          deadline,
          coupon,
        }),
      );
    } catch (err) {
      toast.error(err.message);
      dispatch(bawkStakingFailedResponse());
    }
  }, [bawkStakingCallback, dispatch, gasLimit, request, coupon]);

  const transferWithApprove = useCallback(async () => {
    // const qty = Web3.utils.toWei(openedRace.fee, 'ether');
    setIsApproving(true);
    const maxQty = Web3.utils.toWei(
      Web3.utils.toBN(2 ** 64 - 1),
      'ether',
    );
    const hexQty = Web3.utils.toHex(maxQty);

    const functionSignature = await bawkStakingContractService.getApproveSignature(hexQty, BAWK_CONTRACT_ADDRESS);

    try {
      await bawkStakingContractService.approveTokenAllowance(
        userWalletId,
        functionSignature,
        BAWK_CONTRACT_ADDRESS,
        {
          onTx: async (tx: string) => {
            //
          },
          onError: async (err: any) => {
            toast.error(err.message);
            dispatch(bawkStakingFailedResponse());
            await utils.sleep(1000);
            setIsApproving(false);
          },
          onSubmit: async (err: any, result: any) => {
            //
          },
          onReceipt: async (receipt: any) => {
            await transferWithStaking();
          },
        },
      );
    } catch (err) {
      toast.error(err.message);
      dispatch(bawkStakingFailedResponse());
    }
  }, [dispatch, userWalletId, transferWithStaking]);

  const onStart = useCallback(async () => {
    try {
      if (!request) {
        throw new Error('Can not find request');
      }

      if (!userWalletId) {
        throw new Error('Please connect provider');
      }

      if (!isApproved) {
        await transferWithApprove();
      } else {
        await transferWithStaking();
      }
    } catch (err) {
      toast.error(err.message);
      dispatch(bawkStakingFailedResponse());
    }
  }, [dispatch, isApproved, request, userWalletId, transferWithApprove, transferWithStaking]);

  const toggleCompleteTransaction = async (isOpen: boolean) => {
    if (isOpen) {
      setOpenTransactionComplete(true);
      return;
    }

    await utils.sleep(500);
    setOpenTransactionComplete(false);
  };

  const onSubmit = useCallback(async (req: IBawkStakingRequest) => {
    setRequest(req);

    if (isLoggedIn) {
      setOpenTransactionConformation(true);
    } else {
      onLogin();
    }
  }, [isLoggedIn, setRequest, onLogin, setOpenTransactionConformation]);

  useEffect(() => {
    toggleCompleteTransaction(isApproving || isChecked || isAssigning);
    dispatch(checkIsDeployingRequest());
  }, [dispatch, isAssigning, isChecked, isApproving]);

  useEffect(() => {
    if (
      isChecked
      && userWalletId
      && userWalletId
    ) {
      onStart();
    }
  }, [isChecked, onStart, userWalletId]);

  useEffect(() => {
    if (isAssigned) {
      dispatch(resetBawkStakingStatus());
      dispatch(getBawkStakingStatsRequest());
      dispatch(WalletActions.loadBalancesAction(userWalletId));
    }
  }, [isAssigned, dispatch, userWalletId]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(checkLastBawkStakingAssignmentRequest(null));
    }
  }, [dispatch, isLoggedIn]);

  const value = useMemo(
    () => ({
      onSubmit,
    }),
    [
      onSubmit,
    ],
  );

  return (
    <BawkStakingContext.Provider
      value={value}
    >
      {props.children}
      <BawkStakingTransactionConfirmation
        open={openTransactionConformation}
        request={request}
        onClose={() => setOpenTransactionConformation(false)}
        onConfirm={onConfirmTransaction}
      />
      <BawkStakingTransactionComplete
        open={openTransactionComplete}
        type={request?.type}
        isApproving={isApproving}
        isAssigning={isAssigning}
      />
    </BawkStakingContext.Provider>
  );
}
