import { createAction } from '@reduxjs/toolkit';

export const refreshRaceAction = createAction('REFRESH_RACE', (data: any) => ({
  payload: data,
}));

export const checkIsDeployingRequest = createAction('CHECK_IS_DEPLOYING', (data?: any) => ({
  payload: data,
}));

export const getTotalRaceAction = createAction('GET_TOTAL_RACE', (data?: any) => ({
  payload: data,
}));
