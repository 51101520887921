import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import {
  takeLatest, call, put, select, delay,
} from 'redux-saga/effects';
import {
  GetOffersAction,
  MarketItem, MarketPlaceAction, MarketPlaceActionType, MarketplaceAssignmentStatus, PaginatedRequest, TradeOffer,
} from 'src/models';
import { apiService } from 'src/services/apiService';
import { marketplaceContractService } from 'src/services/contracts';
import { toastService } from 'src/services/toastService';
import { logoutAction } from '../userWallet/action';

import {
  checkMarketplaceAssignmentFailed,
  checkMarketplaceAssignmentRequest,
  checkMarketplaceAssignmentSuccess,
  getChickenMarketItemsRequest,
  getChickenMarketItemsSuccess,
  getChickenTradeActivitiesRequest,
  getChickenTradeActivitiesSuccess,
  getMadeTradeOffersRequest,
  getMadeTradeOffersSuccess,
  getMarketplaceStatusRequest,
  getMarketplaceStatusSuccess,
  getReceivedTradeOffersRequest,
  getReceivedTradeOffersSuccess,
  processMarketplaceActionFailed,
  processMarketplaceActionRequest,
  processMarketplaceActionSuccess,
} from './reducer';

function* processMarketplaceAction({ payload }: { payload: MarketPlaceAction }) {
  try {
    const state = yield select();
    const isLoggedIn = state.userWallet?.logged;
    const { userWalletId } = state.userWallet;

    if (!isLoggedIn) {
      return;
    }

    const assignmentRes = yield call(apiService.checkMarketplaceAssignments, payload.type, payload.item);
    const item: MarketItem | TradeOffer = yield call(marketplaceContractService.processMarketplace, userWalletId, payload.type, payload.item, assignmentRes.data);

    let apiFunc: (item: MarketItem | TradeOffer) => Promise<AxiosResponse<any>>;
    switch (payload.type) {
      case MarketPlaceActionType.CreateMarketItem:
        apiFunc = apiService.createMarketItem;
        break;
      case MarketPlaceActionType.EditMarketItem:
        apiFunc = apiService.editMarketItem;
        break;
      case MarketPlaceActionType.PurchaseMarketItem:
        apiFunc = apiService.purchaseMarketItem;
        break;
      case MarketPlaceActionType.CancelMarketItem:
        apiFunc = apiService.cancelMarketItem;
        break;
      case MarketPlaceActionType.MakeOffer:
        apiFunc = apiService.createTradeOffer;
        break;
      case MarketPlaceActionType.EditOffer:
        apiFunc = apiService.editTradeOffer;
        break;
      case MarketPlaceActionType.FulfillOffer:
        apiFunc = apiService.acceptTradeOffer;
        break;
      case MarketPlaceActionType.CancelOrDeclineOffer:
        apiFunc = apiService.cancelTradeOffer;
        break;
      default:
        apiFunc = null;
    }

    if (!apiFunc) {
      throw new Error(`Can't find api for ${payload.type}`);
    }

    yield call(apiFunc, item);
    yield put(processMarketplaceActionSuccess());
    yield put(checkMarketplaceAssignmentRequest({
      isPending: true,
      action: payload.type,
    }));
  } catch (err: any) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(processMarketplaceActionFailed());
  }
}

function* checkMarketplaceAssignment({ payload }: { payload: MarketplaceAssignmentStatus }) {
  try {
    const state = yield select();
    const isLoggedIn = state.userWallet?.logged;
    const userWalletId = state.userWallet?.userWalletId;

    if (!isLoggedIn) {
      return;
    }

    const res = yield call(apiService.checkLastMarketplaceAssignment);
    const { marketplaceAssignment } = res.data;

    if (!marketplaceAssignment) {
      yield put(checkMarketplaceAssignmentSuccess());
      return;
    }

    const { type, tradeOffer } = marketplaceAssignment;

    if (marketplaceAssignment.status === 'success') {
      yield put(checkMarketplaceAssignmentSuccess());

      if (payload?.isPending) {
        let message = 'Transaction completed successfully';

        switch (type) {
          case MarketPlaceActionType.CreateMarketItem:
            message = 'Your item has been listed for sale.';
            break;

          case MarketPlaceActionType.EditMarketItem:
            message = 'Your item\'s listing has been updated.';
            break;

          case MarketPlaceActionType.CancelMarketItem:
            message = 'Your item has been unlisted.';
            break;

          case MarketPlaceActionType.PurchaseMarketItem:
            message = 'Item purchased successfully!';
            break;

          case MarketPlaceActionType.MakeOffer:
            message = 'Your offer has been sent.';
            break;

          case MarketPlaceActionType.EditOffer:
            message = 'Your offer has been updated.';
            break;

          case MarketPlaceActionType.FulfillOffer:
            message = 'Offer accepted and trade completed.';
            break;

          case MarketPlaceActionType.CancelOrDeclineOffer:
            if (tradeOffer?.offerMaker === userWalletId) {
              message = 'Your offer has been cancelled.';
            } else {
              message = 'The offer has been declined.';
            }
            break;

          default:
            message = 'Transaction completed successfully';
            break;
        }

        toast.success(message);
      }
    } else if (marketplaceAssignment.status === 'error') {
      yield put(checkMarketplaceAssignmentFailed());

      if (payload?.isPending) {
        throw new Error(marketplaceAssignment.error.message);
      }
    } else {
      yield delay(5 * 1000);
      yield put(checkMarketplaceAssignmentRequest({
        isPending: true,
        action: type,
      }));
    }
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(checkMarketplaceAssignmentFailed());
  }
}

function* getReceivedTradeOffers(action: PayloadAction<GetOffersAction>) {
  const state = yield select();
  const isLoggedIn = state.userWallet?.logged;
  const userWalletId = state.userWallet?.userWalletId;

  if (!isLoggedIn || !userWalletId) {
    yield put(getReceivedTradeOffersSuccess({
      rows: [],
      count: 0,
      reset: true,
    }));

    return;
  }

  try {
    const res = yield call(apiService.getChickenTradeOffers, {
      ...action.payload,
      filter: JSON.stringify({
        type: 'received',
        userWalletId,
      }),
    });
    yield put(getReceivedTradeOffersSuccess(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getReceivedTradeOffersSuccess({
      rows: [],
      count: 0,
    }));
  }
}

function* getMadeTradeOffers(action: PayloadAction<GetOffersAction>) {
  const state = yield select();
  const isLoggedIn = state.userWallet?.logged;
  const userWalletId = state.userWallet?.userWalletId;

  if (!isLoggedIn || !userWalletId) {
    yield put(getMadeTradeOffersSuccess({
      rows: [],
      count: 0,
      reset: true,
    }));

    return;
  }

  try {
    const res = yield call(apiService.getChickenTradeOffers, {
      ...action.payload,
      filter: JSON.stringify({
        type: 'made',
        userWalletId,
      }),
    });
    yield put(getMadeTradeOffersSuccess(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getMadeTradeOffersSuccess({
      rows: [],
      count: 0,
    }));
  }
}

function* getChickenTradeActivities(action: PayloadAction<GetOffersAction>) {
  try {
    const res = yield call(apiService.getChickenTradeActivities, action.payload);
    yield put(getChickenTradeActivitiesSuccess(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getChickenTradeActivitiesSuccess({
      rows: [],
      count: 0,
    }));
  }
}

function* getChickenMarketItems(action: PayloadAction<PaginatedRequest>) {
  try {
    const res = yield call(apiService.getChickenMarketItems, action.payload);
    yield put(getChickenMarketItemsSuccess(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getChickenMarketItemsSuccess({
      rows: [],
      count: 0,
    }));
  }
}

function* getMarketplaceStatus(action: PayloadAction<string>) {
  try {
    const res = yield call(apiService.getMarketplaceStatus, action.payload);
    yield put(getMarketplaceStatusSuccess(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
  }
}

export function* userEffects() {
  yield takeLatest(processMarketplaceActionRequest.type as any, processMarketplaceAction);
  yield takeLatest(checkMarketplaceAssignmentRequest.type as any, checkMarketplaceAssignment);
  yield takeLatest(getReceivedTradeOffersRequest.type as any, getReceivedTradeOffers);
  yield takeLatest(getMadeTradeOffersRequest.type as any, getMadeTradeOffers);
  yield takeLatest(logoutAction.type, getMadeTradeOffers);
  yield takeLatest(logoutAction.type, getReceivedTradeOffers);
  yield takeLatest(getChickenMarketItemsRequest.type, getChickenMarketItems);
  yield takeLatest(getMarketplaceStatusRequest.type, getMarketplaceStatus);
  yield takeLatest(getChickenTradeActivitiesRequest.type as any, getChickenTradeActivities);
}

const marketplaceSagas = [call(userEffects)];

export default marketplaceSagas;
