import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TournamentRankingsRequest } from 'src/models';
import * as _ from 'lodash';

type TournamentState = {
  tournaments: any
  lastTournament?: any
  tournament?: any
  rankings: any
  masterRankings: any
  rankingTotals: any,
  groupRankings: any,
  loadingGroupRankings: boolean,
  loadingTournament: boolean,
  loadingRankings: boolean,
  loadingTournaments: boolean,
  loadingRankingTotals: boolean;
};

const initialState: TournamentState = {
  loadingRankings: false,
  loadingTournament: false,
  loadingTournaments: false,
  loadingRankingTotals: false,
  loadingGroupRankings: false,
  tournaments: {
    rows: [],
    count: 0,
  },
  masterRankings: [],
  rankings: {
    rows: [],
    count: 0,
    isReached: false,
  },
  groupRankings: [],
  rankingTotals: null,
};

const tournamentSlice = createSlice({
  name: 'tournament',
  initialState,
  reducers: {
    getLastTournamentRequest(state, action: PayloadAction<any>) {
      //
    },
    getLastTournamentSuccess(state, action: PayloadAction<any>) {
      state.lastTournament = action.payload;
    },
    getLastTournamentFailed(state, action: PayloadAction<any>) {
      state.lastTournament = null;
    },
    clearTournament(state) {
      state.tournament = null;
    },
    getTournamentRequest(state, action: PayloadAction<string>) {
      state.tournament = null;
      state.loadingTournament = true;
    },
    getTournamentSuccess(state, action: PayloadAction<any>) {
      state.tournament = action.payload;
      state.loadingTournament = false;
    },
    getTournamentFailed(state, action: PayloadAction<any>) {
      state.loadingTournament = false;
    },
    getTournamentRankingsRequest(state, action: PayloadAction<TournamentRankingsRequest>) {
      if (action.payload.page === 1) {
        state.rankings = {
          rows: [],
          count: 0,
        };
      }
      state.loadingRankings = true;
    },
    getTournamentRankingsSuccess(state, action: PayloadAction<any>) {
      const rankings = _.uniqBy([...state.rankings.rows, ...action.payload.rows], 'id');
      const isReached = !action.payload.rows?.length;

      state.rankings = {
        rows: rankings,
        count: action.payload.count,
        isReached,
      };

      state.loadingRankings = false;
    },
    getTournamentRankingsFailed(state, action: PayloadAction<any>) {
      state.loadingRankings = false;
      state.rankings = {
        rows: [],
        count: 0,
        isReached: true,
      };
    },
    getTournamentRankingsMasterRequest(state, action: PayloadAction<any>) {
      state.masterRankings = [];
      state.loadingRankings = true;
    },
    getTournamentRankingsMasterSuccess(state, action: PayloadAction<any>) {
      state.masterRankings = action.payload;
      state.loadingRankings = false;
    },
    getTournamentRankingsMasterFailed(state, action: PayloadAction<any>) {
      state.loadingRankings = false;
      state.masterRankings = [];
    },
    getTournamentGroupRankingsRequest(state, action: PayloadAction<any>) {
      state.groupRankings = [];
      state.loadingGroupRankings = true;
    },
    getTournamentGroupRankingsSuccess(state, action: PayloadAction<any>) {
      state.groupRankings = action.payload;
      state.loadingGroupRankings = false;
    },
    getTournamentGroupRankingsFailed(state, action: PayloadAction<any>) {
      state.groupRankings = [];
      state.loadingGroupRankings = false;
    },
    getTournamentsRequest(state, action: PayloadAction<any>) {
      if (action.payload.page === 1) {
        state.tournaments = {
          rows: [],
          count: 0,
        };
      }
      state.loadingTournaments = true;
    },
    getTournamentsSuccess(state, action: PayloadAction<any>) {
      const tournaments = _.uniqBy([...state.tournaments.rows, ...action.payload.rows], 'id');
      const isReached = !action.payload.rows?.length;

      state.tournaments = {
        rows: tournaments,
        count: action.payload.count,
        isReached,
      };

      state.loadingTournaments = false;
    },
    getTournamentsFailed(state, action: PayloadAction<any>) {
      state.tournaments = {
        rows: state.tournaments.rows,
        count: state.tournaments.count,
        isReached: true,
      };

      state.loadingTournaments = false;
    },
    getTournamentRankingTotalsRequest(state, action: PayloadAction<string>) {
      state.rankingTotals = null;
      state.loadingRankingTotals = true;
    },
    getTournamentRankingTotalsResponse(state, action: PayloadAction<any>) {
      state.rankingTotals = action.payload;
      state.loadingRankingTotals = false;
    },
  },
});

export const {
  getLastTournamentRequest,
  getLastTournamentSuccess,
  getLastTournamentFailed,
  getTournamentRequest,
  getTournamentSuccess,
  getTournamentFailed,
  clearTournament,
  getTournamentRankingsRequest,
  getTournamentRankingsSuccess,
  getTournamentRankingsFailed,
  getTournamentsRequest,
  getTournamentsSuccess,
  getTournamentsFailed,
  getTournamentRankingsMasterRequest,
  getTournamentRankingsMasterSuccess,
  getTournamentRankingsMasterFailed,
  getTournamentRankingTotalsRequest,
  getTournamentRankingTotalsResponse,
  getTournamentGroupRankingsRequest,
  getTournamentGroupRankingsSuccess,
  getTournamentGroupRankingsFailed,
} = tournamentSlice.actions;

export default tournamentSlice.reducer;
