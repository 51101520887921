import React, { useState, useEffect, useCallback } from 'react';
import {
  BrowserRouter as Router, Route, Routes, Navigate,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import AppRoute from 'src/route';
import { ContractService } from 'src/services/contracts';
import { WalletActions } from 'src/store/common/action';
import {
  isBasicAuthLoggedInSelector,
  isLoggedInSelector,
  userWalletIdSelector,
  userWalletSelector,
} from 'src/store/userWallet/selector';
import { isChickenLoadingSelector } from 'src/store/chicken/selector';
import { isDeployingSelector } from 'src/store/races/selector';
import { Loading } from 'src/components/loading';
import { TournamentsBanner } from 'src/components/tournaments';
import { getUserWalletRequest, setBiconomyLoaded } from 'src/store/userWallet/reducer';
import { firebase } from 'src/firebase';
import { getLastTournamentRequest } from 'src/store/tournament/reducer';
// import { LandMint } from 'src/pages/lands/mintLand';
import { useTranslation } from 'react-i18next';
import { ContextProvider } from 'src/contexts';
import { checkLastFuseAssignmentRequest } from 'src/store/fusion/reducer';
import { NotificationModal } from 'src/modals/notification';
import { IS_PRODUCTION_ENV } from 'src/utils/config';
import { Footer } from './landing-pages/footer';
import { SerumMint } from './serumMint';
import { Header } from './landing-pages/header';
import { CommunitySale } from './community-sale';

const WorldMap = React.lazy(() => import('src/pages/worldMap'));
const Summon = React.lazy(() => import('src/pages/summon'));
const Home = React.lazy(() => import('src/pages/landing-pages/home'));

const NOTIFICATION_ENABLED_STORAGE = 'chickenderby_notification_enabled';

export function Layout(): JSX.Element {
  const loading = useSelector(isChickenLoadingSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const isBasicLoggedIn = useSelector(isBasicAuthLoggedInSelector);
  const userWalletId = useSelector(userWalletIdSelector);
  const userWallet = useSelector(userWalletSelector);
  const dispatch = useDispatch();

  const [openNotification, setOpenNotification] = useState(false);
  const [timeToOpenNotification, setTimeToOpenNotification] = useState(false);
  const [firebaseLoaded, setFireBaseLoaded] = useState(false);
  const [firebaseToken, setFireBaseToken] = useState<string>(null);
  const { t } = useTranslation('translation', { keyPrefix: 'PAGES.INDEX' });

  useEffect(() => {
    if (isBasicLoggedIn && isLoggedIn && userWalletId) {
      dispatch(WalletActions.loadBalancesAction(userWalletId));
    }
  }, [dispatch, isLoggedIn, userWalletId, isBasicLoggedIn]);

  const initializeBiconomy = async (): Promise<void> => {
    try {
      await ContractService.initializeContracts();

      dispatch(setBiconomyLoaded(true));
    } catch (err) {
      toast.warn(t('FAILED_TO_LOAD_BICONOMY'));
    }
  };

  const getFirebaseToken = async (): Promise<void> => {
    setOpenNotification(false);
    try {
      await firebase.initMessage();
      const token = await firebase.getFirebaseToken();
      // await firebase.subscribeToTopic(token, FirebaseTopic.CoinSaleTokensBought);
      setFireBaseToken(token);
      setFireBaseLoaded(true);
      localStorage.setItem(NOTIFICATION_ENABLED_STORAGE, token ? 'true' : 'false');
    } catch (err) {
      setFireBaseLoaded(false);
      localStorage.setItem(NOTIFICATION_ENABLED_STORAGE, 'false');
    }
  };

  const onRejectNotification = () => {
    setOpenNotification(false);

    localStorage.setItem(NOTIFICATION_ENABLED_STORAGE, 'false');
  };

  const initializeFirebase = useCallback(() => {
    if (!window?.Notification) {
      return;
    }

    if (localStorage.getItem(NOTIFICATION_ENABLED_STORAGE)) {
      return;
    }

    const { permission } = window.Notification;

    if (permission === 'default') {
      setTimeout(() => {
        setOpenNotification(true);
      }, 3000);
    } else if (permission === 'denied') {
      localStorage.setItem(NOTIFICATION_ENABLED_STORAGE, 'false');
    } if (permission === 'granted') {
      localStorage.setItem(NOTIFICATION_ENABLED_STORAGE, 'true');
    }
  }, []);

  const updateFirebaseToken = useCallback(async () => {
    if (!isLoggedIn) {
      return;
    }

    if (!isBasicLoggedIn) {
      return;
    }

    if (!firebaseLoaded) {
      return;
    }

    if (!userWallet) {
      return;
    }

    if (firebaseToken === userWallet.firebaseToken) {
      return;
    }

    dispatch(WalletActions.updateAction({
      firebaseToken,
    }));
  }, [isLoggedIn, isBasicLoggedIn, userWallet, firebaseToken, dispatch, firebaseLoaded]);

  useEffect(() => {
    initializeBiconomy();
    initializeFirebase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn && isBasicLoggedIn) {
      dispatch(getUserWalletRequest({}));
    }
  }, [dispatch, isLoggedIn, isBasicLoggedIn]);

  useEffect(() => {
    updateFirebaseToken();
  }, [updateFirebaseToken]);

  useEffect(() => {
    if (isBasicLoggedIn) {
      dispatch(getLastTournamentRequest(null));
    }
  }, [dispatch, isBasicLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(checkLastFuseAssignmentRequest(false));
    }
  }, [dispatch, isLoggedIn]);

  return (
    <Router>
      <ContextProvider>
        <Routes>
          <Route path="/mint-serum" element={<SerumMint />} />
          <Route path="/" element={<React.Suspense fallback={<div className="flex-1" />}><Home onOpenNotification={() => setTimeToOpenNotification(true)} /></React.Suspense>} />
          <Route path="/community-sale" element={<CommunitySale />} />
          <Route path="/*" element={<Main />} />
          <Route path="/world-map" element={IS_PRODUCTION_ENV ? <Navigate to="/" replace /> : <React.Suspense fallback={<div className="flex-1" />}><WorldMap /></React.Suspense>} />
          <Route path="/summon" element={IS_PRODUCTION_ENV ? <Navigate to="/" replace /> : <React.Suspense fallback={<div className="flex-1" />}><Summon /></React.Suspense>} />
        </Routes>
        {(loading) && (
          <div className="loading-main">
            <Loading size={200} />
          </div>
        )}
        <NotificationModal open={openNotification && timeToOpenNotification} onAccept={() => getFirebaseToken()} onReject={() => onRejectNotification()} />
      </ContextProvider>
    </Router>
  );
}

function Main() {
  const isDeploying = useSelector(isDeployingSelector);
  const { t } = useTranslation('translation', { keyPrefix: 'PAGES.INDEX' });
  return (
    <>
      <Header />
      {isDeploying && (
        <p className="announcement">
          {isDeploying.message
            || t('UPDATE_IN_PROGRESS')}
        </p>
      )}
      <TournamentsBanner />
      <AppRoute />
      <Footer />
    </>
  );
}
