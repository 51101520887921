import React from 'react';

export function ExternalLinkIcon({
  size = 24,
  color = '#FFF',
  onClick,
}: {
  size?: number
  color?: string
  onClick?: () => void
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill="none">
      <path d="M10.7984 2.89996H5.99844C4.01021 2.89996 2.39844 4.51174 2.39844 6.49996V18.5001C2.39844 20.4883 4.01021 22.1001 5.99844 22.1001H17.9984C19.9867 22.1001 21.5984 20.4883 21.5984 18.5001V13.7M15.5978 2.90025L21.5984 2.89996M21.5984 2.89996V8.30011M21.5984 2.89996L11.3976 13.0997" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
