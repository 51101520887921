import {
  takeLatest, call, put, select,
} from 'redux-saga/effects';
import { bawkContractService, bawkEscrowContractService, raceContractService } from 'src/services/contracts';
import Web3 from 'web3';
import { apiService } from 'src/services/apiService';
import { toastService } from 'src/services/toastService';
import { JEWELS_CONTRACT } from 'src/abis/jewelsContract';
import {
  registerAction,
  loginAction,
  logoutAction,
  setBalancesAction,
  updateAction,
  loadBalancesAction,
  getNonceAction,
} from './action';
import {
  getNonceResponse,
  registerResponse,
  registerResponseFailed,
  loginResponse,
  logoutResponse,
  setBalancesResponse,
  loginResponseFailed,
  walletUpdateResponse,
  getUserWalletResponse,
  getUserWalletRequest,
  discordLoginRequest,
  discordLoginResponse,
  discordLoginError,
  discordLogoutResponse,
  discordLogoutError,
  discordLogoutRequest,
  basicLoginRequest,
  basicLoginSuccess,
  basicLoginFailed,
  getPublicUserWalletRequest,
  getPublicUserWalletResponse,
  getRoadMapsRequest,
  getRoadMapsResponse,
} from './reducer';

import { WalletActions } from '../common/action';
import { checkLastRaceAssignmentSuccessResponse } from '../lane/reducer';
import { getCoopsRequest, getCoopsResponse } from '../coop/reducer';

export function* getNonce(data: any) {
  try {
    const { payload } = data;

    const response = yield call(apiService.getNonce, payload);

    yield put(getNonceResponse(response?.data));
  } catch (err) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');

    localStorage.removeItem('auth_token');
  }
}

export function* register(data: any) {
  try {
    const { payload } = data;

    const response = yield call(apiService.registerWallet, payload);

    localStorage.setItem('auth_token', response.data.token);
    localStorage.setItem('user_wallet_id', response.data.id);

    yield put(registerResponse(response?.data));
  } catch (err) {
    yield put(registerResponseFailed(err.response?.data));
    localStorage.removeItem('auth_token');
  }
}

export function* login(data: any) {
  try {
    const { payload } = data;

    const response = yield call(apiService.loginWallet, payload);

    localStorage.setItem('auth_token', response.data.token);
    localStorage.setItem('user_wallet_id', response.data.id);

    yield put(loginResponse(response?.data));
  } catch (err) {
    yield put(loginResponseFailed(err.response?.data));
    localStorage.removeItem('auth_token');
  }
}
export function* logout() {
  try {
    localStorage.removeItem('auth_token');

    yield put(logoutResponse(null));
    yield put(getCoopsResponse([]));
  } catch (err) {
    //
  }
}

export function* discordLogin(data: any) {
  try {
    const { payload } = data;

    const response = yield call(apiService.loginDiscord, payload);

    yield put(discordLoginResponse(response?.data));
  } catch (err) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
    yield put(discordLoginError(err.response?.data));
  }
}

export function* discordLogout() {
  try {
    const response = yield call(apiService.logoutDiscord);

    yield put(discordLogoutResponse(response?.data));
  } catch (err) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
    yield put(discordLogoutError(err.response?.data));
  }
}

export function* basicLogin(data: any) {
  try {
    const { payload } = data;

    const response = yield call(apiService.basicAuth, payload);
    localStorage.setItem('basic_auth_token', response.data.token);
    yield put(basicLoginSuccess(response?.data));
  } catch (err) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
    yield put(basicLoginFailed(err.response?.data));
  }
}

export function* setBalances(data: any) {
  yield put(setBalancesResponse(data.payload));
}

export function* update(data: any) {
  try {
    const { payload } = data;
    const response = yield call(apiService.updateWallet, payload);
    yield put(walletUpdateResponse(response?.data));
  } catch (err) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
  }
}

export function* loadTokensAndBalances(data: any) {
  const userWalletId = data.payload;
  try {
    yield put(getCoopsRequest({}));

    const bawkEscrowBalance = yield bawkEscrowContractService.totalEscrowedAccountBalance(userWalletId);
    const bawkBalance = yield bawkContractService.getBalance(userWalletId);
    const jewelsBalance = yield raceContractService.getTokenBalance(
      userWalletId,
      JEWELS_CONTRACT.address,
    );

    yield put(
      WalletActions.setBalancesAction({
        bawkEscrowBalance: Number(Web3.utils.fromWei(bawkEscrowBalance)),
        bawkBalance: Number(Web3.utils.fromWei(bawkBalance)),
        jewelsBalance: Number(Web3.utils.fromWei(jewelsBalance)),
      }),
    );
  } catch (err) {
    //
  }
}

export function* loadBalances(data: any) {
  if (!data.payload) {
    return;
  }

  try {
    const state = yield select();
    const userWalletId = state.userWallet?.userWallet?.id;

    if (!userWalletId) {
      return;
    }

    const bawkEscrowBalance = yield bawkEscrowContractService.totalEscrowedAccountBalance(userWalletId);
    const bawkBalance = yield bawkContractService.getBalance(userWalletId);
    const jewelsBalance = yield raceContractService.getTokenBalance(
      userWalletId,
      JEWELS_CONTRACT.address,
    );

    yield put(
      WalletActions.setBalancesAction({
        bawkEscrowBalance: Number(Web3.utils.fromWei(bawkEscrowBalance)),
        bawkBalance: Number(Web3.utils.fromWei(bawkBalance)),
        jewelsBalance: Number(Web3.utils.fromWei(jewelsBalance)),
      }),
    );
  } catch (err) {
    //
  }
}

export function* getUserWallet() {
  try {
    const state = yield select();
    const isLoggedIn = state.userWallet?.logged;

    if (!isLoggedIn) {
      return;
    }

    const response = yield call(apiService.getWallet);
    yield put(getUserWalletResponse(response?.data));
  } catch (err) {
    //
  }
}

export function* getPublicUserWallet({ payload }: any) {
  try {
    const response = yield call(apiService.getPublicWallet, payload);
    yield put(getPublicUserWalletResponse(response?.data));
  } catch (err) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
    yield put(getPublicUserWalletResponse(null));
  }
}

export function* getRoadMaps() {
  try {
    const response = yield call(apiService.getRoadMaps);
    yield put(getRoadMapsResponse(response?.data || []));
  } catch (err) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
    yield put(getRoadMapsResponse([]));
  }
}

export function* userEffects() {
  yield takeLatest(getNonceAction.type, getNonce);
  yield takeLatest(registerAction.type, register);
  yield takeLatest(loginAction.type, login);
  yield takeLatest(logoutAction.type, logout);
  yield takeLatest(setBalancesAction.type, setBalances);
  yield takeLatest(updateAction.type, update);
  yield takeLatest(loadBalancesAction.type, loadTokensAndBalances);
  yield takeLatest(checkLastRaceAssignmentSuccessResponse.type, loadBalances);
  yield takeLatest(getUserWalletRequest, getUserWallet);
  yield takeLatest(getPublicUserWalletRequest, getPublicUserWallet);
  yield takeLatest(discordLoginRequest, discordLogin);
  yield takeLatest(discordLogoutRequest, discordLogout);
  yield takeLatest(basicLoginRequest, basicLogin);
  yield takeLatest(getRoadMapsRequest, getRoadMaps);
}

const userSagas = [call(userEffects)];

export default userSagas;
