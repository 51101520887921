import * as _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedList, PaginatedRequest } from 'src/models';

type ClothingState = {
  clothings: {
    rows: any[],
    count: number,
    totalCount: number,
    isReached: boolean,
  };
  loading: boolean;
  isSettingClothing: boolean;
  isChickensLoading: boolean;
  clothingSets: string[];
  chickens: any;
};

const initialState: ClothingState = {
  clothings: {
    rows: [],
    count: 0,
    totalCount: 0,
    isReached: false,
  },
  loading: false,
  isSettingClothing: false,
  isChickensLoading: false,
  clothingSets: [],
  chickens: {
    rows: [],
    count: 0,
    isReached: false,
  },
};

const clothingSlice = createSlice({
  name: 'clothing',
  initialState,
  reducers: {
    getClothingsRequest(state, action: PayloadAction<PaginatedRequest>) {
      state.loading = true;
      if (action.payload.page === 1) {
        state.clothings = {
          rows: [],
          count: 0,
          totalCount: 0,
          isReached: false,
        };
      }
    },
    getClothingsSuccess(state, action: PayloadAction<PaginatedList<any>>) {
      const clothings = _.uniqBy(action.payload.reset ? action.payload.rows : [...state.clothings.rows, ...action.payload.rows], 'id');
      const isReached = action.payload.count === clothings.length || !action.payload.rows?.length;
      state.clothings = {
        rows: clothings,
        count: action.payload.count,
        totalCount: action.payload.totalCount || action.payload.count,
        isReached,
      };
      state.loading = false;
    },
    getClothingsFailed(state) {
      state.loading = false;
      state.clothings = {
        rows: [],
        count: 0,
        totalCount: 0,
        isReached: false,
      };
    },
    setClothingRequest(state, action: PayloadAction<{
      clothingName: string,
      chickenId: number,
      clothingId: number,
      removeChickenId?: number
    }>) {
      state.isSettingClothing = true;
    },
    setClothingSuccess(state) {
      state.isSettingClothing = false;
    },
    removeClothingRequest(state, action: PayloadAction<any>) {
      state.isSettingClothing = true;
    },
    removeClothingSuccess(state) {
      state.isSettingClothing = false;
    },
    getClothingSetsRequest(state) {
      state.clothingSets = [];
    },
    getClothingSetsSuccess(state, action: PayloadAction<string[]>) {
      state.clothingSets = action.payload;
    },
    getClothingChickensRequest(state, action: PayloadAction<PaginatedRequest>) {
      if (action.payload.page === 1) {
        state.chickens = {
          rows: [],
          count: 0,
          isReached: false,
        };
      }
      state.isChickensLoading = true;
    },
    getClothingChickensSuccess(state, action: PayloadAction<PaginatedList<any>>) {
      const chickens = _.uniqBy([...state.chickens.rows, ...action.payload.rows], 'id');
      const isReached = chickens.length === action.payload.count || !action.payload.rows?.length;
      state.chickens = {
        rows: chickens,
        count: action.payload.count,
        isReached,
      };
      state.isChickensLoading = false;
    },
  },
});

export const {
  getClothingsRequest,
  getClothingsSuccess,
  getClothingsFailed,
  setClothingRequest,
  setClothingSuccess,
  removeClothingRequest,
  removeClothingSuccess,
  getClothingSetsRequest,
  getClothingSetsSuccess,
  getClothingChickensRequest,
  getClothingChickensSuccess,
} = clothingSlice.actions;

export default clothingSlice.reducer;
