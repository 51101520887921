import {
  takeLatest, call, put,
} from 'redux-saga/effects';
import { apiService } from 'src/services/apiService';
import { toast } from 'react-toastify';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  claimBawkPreSaleInviteCodeFailed,
  claimBawkPreSaleInviteCodeRequest,
  claimBawkPreSaleInviteCodeSuccess,
  getBawkPreSaleDatesRequest,
  getBawkPreSaleDatesResponse,
  getBawkPreSaleInfoRequest,
  getBawkPreSaleInfoResponse,
  getBawkPresaleRefundInfoRequest,
  getBawkPresaleRefundInfoResponse,
} from './reducer';

function* getBawkPresaleInfo() {
  try {
    const response = yield call(apiService.getBawkPreSaleInfo);
    yield put(getBawkPreSaleInfoResponse(response?.data));
  } catch (err) {
    toast.error(err?.data?.message);
    yield put(getBawkPreSaleInfoResponse(null));
  }
}

function* getBawkPresaleDates() {
  try {
    const response = yield call(apiService.getBawkPreSaleDates);
    yield put(getBawkPreSaleDatesResponse(response?.data));
  } catch (err) {
    toast.error(err?.data?.message);
    yield put(getBawkPreSaleDatesResponse({}));
  }
}

function* claimBawkPresaleInviteCode(action: PayloadAction<string>) {
  try {
    yield call(apiService.claimBawkPreSaleInviteCode, action.payload);
    yield put(claimBawkPreSaleInviteCodeSuccess());
    toast.success(`Claimed bawk pre sale invite code (${action.payload}) successfully`);
  } catch (err) {
    toast.error(err?.data?.message || 'Failed claim invite code');
    yield put(claimBawkPreSaleInviteCodeFailed());
  }
}

function* getBawkPresaleRefundData() {
  try {
    const response = yield call(apiService.getBawkPreSaleRefundInfo);
    yield put(getBawkPresaleRefundInfoResponse(response.data));
  } catch (err) {
    toast.error(err?.data?.message);
    yield put(getBawkPresaleRefundInfoResponse());
  }
}

export function* userEffects() {
  yield takeLatest(getBawkPreSaleInfoRequest.type, getBawkPresaleInfo);
  yield takeLatest(claimBawkPreSaleInviteCodeSuccess.type, getBawkPresaleInfo);
  yield takeLatest(claimBawkPreSaleInviteCodeRequest.type, claimBawkPresaleInviteCode);
  yield takeLatest(getBawkPreSaleDatesRequest.type, getBawkPresaleDates);
  yield takeLatest(getBawkPresaleRefundInfoRequest.type, getBawkPresaleRefundData);
}

const bawkPreSaleSagas = [call(userEffects)];

export default bawkPreSaleSagas;
