import { FUSION_CONTRACT, WETH_CONTRACT } from 'src/abis';
import { CHICKEN_CONTRACT_ADDRESS } from 'src/utils/config';
import { exponentialBackOff } from 'src/utils/exponentialBackoff';
import { AbiItem } from 'web3-utils';
import Web3 from 'web3';
import { ChainId } from 'src/constant/chainId';
import ContractService from './baseContractService';

export class FusionContractService extends ContractService {
  constructor() {
    super(ChainId.Polygon, FUSION_CONTRACT.address, FUSION_CONTRACT.abi as AbiItem[]);

    ContractService.add(this);
  }

  async getSerumCount(userWalletId: string, tokenId: number) {
    return this.contractCall(async () => {
      const balanceOf = async () => this.contract.methods.balanceOf(userWalletId, tokenId).call();
      const { result } = await exponentialBackOff(balanceOf);

      return result;
    });
  }

  async getSerumsInfo(tokenId: number) {
    return this.contractCall(async () => {
      const getSerumsInfoFunc = async () => this.multiCallProvider.aggregate([
        this.contract.methods.isMintingActivated(),
        this.contract.methods.tokenPrice(tokenId),
        this.contract.methods.totalMint(tokenId),
        this.contract.methods.mintLimit(tokenId),
      ]);

      const { result } = await exponentialBackOff(getSerumsInfoFunc);

      return result;
    });
  }

  async fuseChicken(userWalletId: string, materialChickenIds: number[], serums: number, newChickenId: number, coupon: any, gasLimit: number) {
    return this.contractCall(async () => {
      await this.checkTokenApproval(userWalletId, CHICKEN_CONTRACT_ADDRESS);
      const functionSignature = this.contract.methods.fuse(materialChickenIds, serums, newChickenId, coupon).encodeABI();

      return this.sendRawTransaction(userWalletId, this.contractAddress, functionSignature);
    });
  }

  async mint(userWalletId: string, tokenId: number, amount: number, gasLimit: number) {
    return this.contractCall(async () => {
      const functionSignature = this.contract.methods.mint(tokenId, amount).encodeABI();

      return this.signForwardTransaction(userWalletId, this.contractAddress, functionSignature, gasLimit);
    });
  }

  async approveWethContract(userWalletId: string, onConfirm: () => void) {
    const maxQty = Web3.utils.toWei(
      Web3.utils.toBN(2 ** 64 - 1),
      'ether',
    );
    const hexQty = Web3.utils.toHex(maxQty);
    const functionSignature = await this.getApproveSignature(hexQty, WETH_CONTRACT.address);

    return new Promise<any>((resolve, reject) => {
      this.approveTokenAllowance(
        userWalletId,
        functionSignature,
        WETH_CONTRACT.address,
        {
          onTx: async (tx: string) => {
            //
          },
          onError: async (err: any) => {
            reject(err);
          },
          onSubmit: async (err: any, result: any) => {
            onConfirm();
          },
          onReceipt: async (receipt: any) => {
            resolve(receipt);
          },
        },
      );
    });
  }
}
