import { createSelector } from 'reselect';
import { RootState } from '../common/reducer';

export const selectUserWalletState = (rootState: RootState) => rootState.userWallet;

export const isLoggedInSelector = createSelector(
  [selectUserWalletState],
  (state) => state.logged || false,
);

export const userWalletIdSelector = createSelector(
  [selectUserWalletState],
  (state) => state.userWalletId || '',
);

export const userWalletSelector = createSelector(
  [selectUserWalletState],
  (state) => state.userWallet || null,
);

export const publicUserWalletSelector = createSelector(
  [selectUserWalletState],
  (state) => state.publicUserWallet || null,
);

export const bawkBalanceSelector = createSelector(
  [selectUserWalletState],
  (state) => state.bawkBalance || 0,
);

export const bawkEscrowBalanceSelector = createSelector(
  [selectUserWalletState],
  (state) => state.bawkEscrowBalance || 0,
);

export const jewelsBalanceSelector = createSelector(
  [selectUserWalletState],
  (state) => state.jewelsBalance || 0,
);
export const loadedBalancesSelector = createSelector(
  [selectUserWalletState],
  (state) => state.loadedBalances || false,
);

export const loadingUserWalletSelector = createSelector(
  [selectUserWalletState],
  (state) => state.loading,
);

export const biconomyLoadedSelector = createSelector(
  [selectUserWalletState],
  (state) => state.biconomyLoaded,
);

export const nonceSelector = createSelector(
  [selectUserWalletState],
  (state) => state.nonce || null,
);

export const isBasicAuthLoggedInSelector = createSelector(
  [selectUserWalletState],
  (state) => state.basicAuthLogged || false,
);

export const roadMapsSelector = createSelector(
  [selectUserWalletState],
  (state) => state.roadMaps,
);
