import {
  takeLatest,
  call,
  put,
  delay,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiService } from 'src/services/apiService';
import { toastService } from 'src/services/toastService';
import { BawkStakingType, IBawkStakingRequest } from 'src/models';
import {
  getBawkStakingStatsRequest,
  getBawkStakingStatsSuccessResponse,
  getBawkStakingStatsFailedResponse,
  checkBawkStakingSuccessResponse,
  checkBawkStakingFailedResponse,
  bawkStakingSuccessResponse,
  checkLastBawkStakingAssignmentRequest,
  bawkStakingFailedResponse,
  checkLastBawkStakingAssignmentSuccessResponse,
  checkLastBawkStakingAssignmentFailedResponse,
  checkBawkStakingRequest,
  bawkStakingRequest,
} from './reducer';

function* getBawkStakingStats() {
  try {
    const res = yield call(apiService.getBawkStakingStats);

    yield put(getBawkStakingStatsSuccessResponse(res.data));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');

    yield put(getBawkStakingStatsFailedResponse());
  }
}

function* checkBawkStaking({ payload }: { payload: IBawkStakingRequest }) {
  try {
    const res = yield call(apiService.checkBawkStaking, payload);
    yield put(checkBawkStakingSuccessResponse(res.data));
  } catch (err) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');

    yield put(checkBawkStakingFailedResponse());
  }
}

function* postBawkStaking({ payload }: { payload: IBawkStakingRequest }) {
  try {
    yield call(apiService.postBawkStaking, payload);

    yield put(bawkStakingSuccessResponse());
    yield put(checkLastBawkStakingAssignmentRequest(payload.type));
  } catch (err) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');

    yield put(bawkStakingFailedResponse());
  }
}

function* checkLastBawkStakingAssignment({ payload }: { payload: BawkStakingType }) {
  try {
    const res = yield call(apiService.checkLastBawkStakingAssignment);
    const { bawkStakingAssignment } = res.data;

    if (!bawkStakingAssignment) {
      yield put(checkLastBawkStakingAssignmentSuccessResponse(null));
      return;
    }

    if (bawkStakingAssignment.status === 'success') {
      yield put(checkLastBawkStakingAssignmentSuccessResponse(payload));

      if (payload) {
        let message: string;
        if (bawkStakingAssignment.type === BawkStakingType.Stake) {
          message = `Successfully staked ${bawkStakingAssignment.amount} BAWK`;
        } else if (bawkStakingAssignment.type === BawkStakingType.Unstake) {
          message = `Successfully unstaked ${bawkStakingAssignment.amount} BAWK`;
        } else {
          message = `Successfully claimed ${bawkStakingAssignment.amount} JEWELS`;
        }

        toast.success(message);
      }
    } else if (bawkStakingAssignment.status === 'error') {
      yield put(checkLastBawkStakingAssignmentFailedResponse());

      if (payload) {
        throw new Error(bawkStakingAssignment.error.message);
      }
    } else {
      yield delay(5 * 1000);
      yield put(checkLastBawkStakingAssignmentRequest(bawkStakingAssignment.type));
    }
  } catch (err) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');

    yield put(checkLastBawkStakingAssignmentFailedResponse());
  }
}

function* userEffects() {
  yield takeLatest(getBawkStakingStatsRequest, getBawkStakingStats);
  yield takeLatest(checkBawkStakingRequest, checkBawkStaking);
  yield takeLatest(bawkStakingRequest, postBawkStaking);
  yield takeLatest(checkLastBawkStakingAssignmentRequest, checkLastBawkStakingAssignment);
}

const bawkStakingSaga = [call(userEffects)];

export default bawkStakingSaga;
