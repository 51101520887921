/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { IMAGES_URL } from 'src/constant/images';
import {
  bawkBalanceSelector,
  loadedBalancesSelector,
  jewelsBalanceSelector,
  isLoggedInSelector,
  bawkEscrowBalanceSelector,
} from 'src/store/userWallet/selector';
import { formatCurrency, formatNumber } from 'src/utils/utils';
import ContentLoader from 'react-content-loader';
import { LocalStorageService } from 'src/services/local-storage.service';
import { exchangeRateSelector } from 'src/store/exchange/selector';
import { useCurrency } from 'src/contexts/currency';
import ReactTooltip from 'react-tooltip';
import { useWalletContext } from 'src/contexts/walletProvider';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

export function PricingSection({ onHide }: { onHide: () => void }) {
  const bawkBalance = useSelector(bawkBalanceSelector);
  const bawkEscrowBalance = useSelector(bawkEscrowBalanceSelector);
  const jewelsBalance = useSelector(jewelsBalanceSelector);
  const isLoadedBalances = useSelector(loadedBalancesSelector);
  const coinRate = useSelector(exchangeRateSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  // const { theme, onToggleTheme } = useThemeContext();
  const { onLogin, isReady } = useWalletContext();

  const { currency } = useCurrency();
  const showBalanceOnHeader = LocalStorageService.getInstance().getShowBalanceOnHeader();
  const [isShowBalance, setIsShowBalance] = useState<boolean>(showBalanceOnHeader);
  const handleCardIconClick = (e: any) => {
    e.preventDefault();

    const showBalance = !isShowBalance;
    setIsShowBalance(showBalance);
    LocalStorageService.getInstance().setShowBalanceOnHeader(showBalance);
  };

  const login = () => {
    onHide();

    if (!isReady) {
      return;
    }

    onLogin();
  };

  return (
    <div className="pricing-section">
      <div className="price-items-wrap">
        {isLoggedIn ? (!isMobile || isShowBalance) ? (
          <div className={`price-items ${!isShowBalance ? 'hidden' : ''}`}>
            {/* <Link to="/bawk-staking">
              <div
                className="pricing-item"
                data-for="coin-balance"
                data-tip="BAWK Balance"
                data-iscapture="true"
              >
                {(!isLoadedBalances)
                  ? (
                    <ContentLoader viewBox="0 0 40 18" width={40} gradientRatio={1.2} backgroundColor="#d6d6d6">
                      <rect x="0" y="0" rx="3" ry="3" width="40" height="18" />
                    </ContentLoader>
                  )
                  : (
                    <>
                      <p>{formatNumber(bawkBalance, '0,0.00a')}</p>
                    </>
                  )}
                <img src={IMAGES_URL.BAWK} alt="jewels" />
              </div>
            </Link> */}
            <Link to="/vault">
              <div
                className="pricing-item gray"
                data-for="coin-balance"
                data-tip="BAWK Balance"
                data-iscapture="true"
              >
                {(!isLoadedBalances)
                  ? (
                    <ContentLoader viewBox="0 0 40 18" width={40} gradientRatio={1.2} backgroundColor="#d6d6d6">
                      <rect x="0" y="0" rx="3" ry="3" width="40" height="18" />
                    </ContentLoader>
                  )
                  : (
                    <>
                      <p>{formatNumber(bawkEscrowBalance, '0,0.00a')}</p>
                    </>
                  )}
                <img src={IMAGES_URL.BAWK} alt="jewels" />
              </div>
            </Link>
            <Link to="/jewels-swap">
              <div
                className="pricing-item"
                data-for="coin-balance"
                data-tip="Jewels Balance"
                data-iscapture="true"
              >
                {(!isLoadedBalances)
                  ? (
                    <ContentLoader viewBox="0 0 40 18" width={40} gradientRatio={1.2} backgroundColor="#d6d6d6">
                      <rect x="0" y="0" rx="3" ry="3" width="40" height="18" />
                    </ContentLoader>
                  ) : <p>{currency === 'USD' ? `${formatCurrency(jewelsBalance * (coinRate?.jewel || 0))}` : `${formatNumber(jewelsBalance, '0,0.00a')}`}</p>}
                <img src={IMAGES_URL.JEWELS} alt="jewels" />
              </div>
            </Link>
          </div>
        ) : <></> : (
          <a
            className="btn btn-login"
            onClick={() => login()}
          >
            Connect Wallet
          </a>
        )}
      </div>
      <div className="pricing-buttons">
        {isLoggedIn && (
          <div className="pricing-btn" onClick={handleCardIconClick}>
            {isShowBalance ? <i className="fa fa-eye" /> : <i className="fa fa-eye-slash" />}
          </div>
        )}
        {/* <div className="theme-item">
          <label className="theme-switch">
            <input type="checkbox" checked={theme === 'dark'} onChange={() => onToggleTheme()} />
            <span className="theme-slider round">
              <SunIcon color={theme === 'light' ? '#FFF' : '#728EAC'} />
              <MoonIcon color={theme === 'dark' ? '#FFF' : '#728EAC'} />
            </span>
          </label>
        </div> */}
      </div>
      <ReactTooltip
        id="coin-balance"
        place="bottom"
        effect="solid"
      />
    </div>
  );
}
