import React from 'react';
import { Link } from 'react-router-dom';

import { IMAGES_URL } from 'src/constant/images';
import gitInfo from 'src/generatedGitInfo.json';

export function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="row items-center">
          <div className="col-md-3 col-sm-3 footer-logo">
            <Link to="/">
              <img src={IMAGES_URL.LOGO} className="logo" alt="" width={141} height={52} />
            </Link>
          </div>
          <div className="col-md-6 col-sm-5">
            <ul className="footer-link">
              <li><Link to="/terms-of-service">Terms of Service</Link></li>
              {/* <li><a href="">Privacy and Policy</a></li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-version">
        {gitInfo.gitBranch}
        {' '}
        /
        {' '}
        {gitInfo.gitCommitHash}
      </div>
    </div>

  );
}
