import { createAction } from '@reduxjs/toolkit';
import { AssignLaneRequest } from './model';

export const assignLaneForFreeRaceRequest = createAction(
  'lane/assignLaneForFreeRaceRequest',
  (data: AssignLaneRequest) => ({
    payload: data,
  }),
);

export const assignLaneForPaidRaceRequest = createAction(
  'lane/assignLaneForPaidRaceRequest',
  (data: AssignLaneRequest) => ({
    payload: data,
  }),
);
