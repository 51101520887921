import mitt from 'mitt';

import { IChickenStakingEvent, ICoinSaleTransactionMined } from 'src/models';
import { IJewelTransactionMined } from 'src/models/jewels.type';

export enum EventsEnum {
  CoinSaleTransactionMined = 'coin-sale-transaction-mined',
  JewelsMintTransactionMined = 'jewels-mint-transaction-mined',
  JewelsBurnTransactionMined = 'jewels-burn-transaction-mined',
  RefreshRaceList = 'refresh-race-list',
  ChickenStakingTransactionMined = 'chicken-staking-transaction-mined',
  BawkPresaleTransactionMined = 'bawk-presale-transaction-mined',
  BawkPresaleRefundTransactionMined = 'bawk-presale-refund-transaction-mined'
}

type Events = {
  [EventsEnum.CoinSaleTransactionMined]: ICoinSaleTransactionMined;
  [EventsEnum.JewelsMintTransactionMined]: IJewelTransactionMined;
  [EventsEnum.JewelsBurnTransactionMined]: IJewelTransactionMined;
  [EventsEnum.ChickenStakingTransactionMined]: IChickenStakingEvent;
  [EventsEnum.RefreshRaceList]: void
  [EventsEnum.BawkPresaleTransactionMined]: ICoinSaleTransactionMined;
  [EventsEnum.BawkPresaleRefundTransactionMined]: ICoinSaleTransactionMined;
}

type ValueOf<T> = T[keyof T];

export type EventPayloadType = ValueOf<Omit<Events, EventsEnum.RefreshRaceList>>;

export const events = mitt<Events>();
