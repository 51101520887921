import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LandState = {
  lands: any[];
  loading: boolean;
  isCheckedMint: boolean;
  isMinted: boolean;
  isMinting: boolean;
  error?: any;
};

const initialState: LandState = {
  lands: null,
  loading: false,
  isCheckedMint: false,
  isMinted: false,
  isMinting: false,
};

const landsSlice = createSlice({
  name: 'land',
  initialState,
  reducers: {
    clearIsMinted(state, action: PayloadAction<any>) {
      state.isMinted = false;
      state.isCheckedMint = false;
      state.isMinting = false;
    },
    checkLandMintRequest(state, action: PayloadAction<any>) {
      state.isMinted = false;
      state.isMinting = false;
      state.isCheckedMint = false;
    },
    checkLandMintResponse(state, action: PayloadAction<any>) {
      state.isCheckedMint = true;
      state.isMinting = true;
    },
    checkLandMintFailed(state, action: PayloadAction<any>) {
      state.isMinting = false;
      state.isCheckedMint = false;
    },
    checkLastLandMintRequest(state, action: PayloadAction<boolean>) {
      state.isMinted = false;
      state.isMinting = action.payload;
    },
    checkLastLandMintSuccess(state, action: PayloadAction<boolean>) {
      state.isMinted = action.payload;
      state.isMinting = false;
    },
    checkLastLandMintFailed(state, action: PayloadAction<any>) {
      state.isMinted = false;
      state.isMinting = false;
    },
    landMintRequest(state, action: PayloadAction<any>) {
      state.isMinting = true;
    },
    landMintSuccess(state, action: PayloadAction<boolean>) {
      state.isMinted = false;
      state.isCheckedMint = false;
    },
    landMintFailed(state, action: PayloadAction<any>) {
      state.isMinting = false;
      state.isCheckedMint = false;
    },
    getLandsRequest(state) {
      state.loading = true;
    },
    getLandsSuccess(state, action: PayloadAction<any>) {
      state.lands = action.payload;
      state.loading = false;
    },
    getLandsFailed(state) {
      state.loading = false;
    },
  },
});

export const {
  clearIsMinted,
  checkLandMintRequest,
  checkLandMintResponse,
  checkLandMintFailed,
  checkLastLandMintRequest,
  checkLastLandMintSuccess,
  checkLastLandMintFailed,
  landMintRequest,
  landMintSuccess,
  landMintFailed,
  getLandsRequest,
  getLandsSuccess,
  getLandsFailed,
} = landsSlice.actions;

export default landsSlice.reducer;
