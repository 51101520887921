import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

type PresaleData = {
  codes: string[];
  chickens: number;
  sales: number;
  refunds: number;
  won: number;
  lost: number;
  goldenTickets: number;
  inviteCodes: number;
}

type PresaleDates = {
  presaleStartsInSeconds?: number;
  presaleEndsInSeconds?: number;
  presaleRefundStartsInSeconds?: number;
  presaleRefundEndsInSeconds?: number;
  presaleStartsAt?: Date;
  presaleEndsAt?: Date;
  presaleRefundStartsAt?: Date;
  presaleRefundEndsAt?: Date;
}

type PresaleRefundData = {
  won: number,
  lost: number,
  signature: string,
}

type BawkPreSaleState = {
  isLoading: boolean;
  data?: PresaleData;
  dates: PresaleDates;
  refundData?: PresaleRefundData;
};

const initialState: BawkPreSaleState = {
  isLoading: false,
  dates: {},
};

const bawkPreSaleSlice = createSlice({
  name: 'bawk-pre-sale',
  initialState,
  reducers: {
    getBawkPreSaleInfoRequest(state) {
      state.isLoading = true;
    },
    getBawkPreSaleInfoResponse(state, action: PayloadAction<PresaleData>) {
      state.isLoading = false;
      state.data = action.payload;
    },
    claimBawkPreSaleInviteCodeRequest(state, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    claimBawkPreSaleInviteCodeSuccess(state) {
      state.isLoading = false;
    },
    claimBawkPreSaleInviteCodeFailed(state) {
      state.isLoading = false;
    },
    getBawkPreSaleDatesRequest(state) {
      state.isLoading = true;
      state.dates = {};
    },
    getBawkPreSaleDatesResponse(state, action: PayloadAction<PresaleDates>) {
      state.isLoading = false;

      const {
        presaleStartsInSeconds,
        presaleEndsInSeconds,
        presaleRefundStartsInSeconds,
        presaleRefundEndsInSeconds,
      } = action.payload;

      state.dates = {
        ...action.payload,
        presaleStartsAt: presaleStartsInSeconds ? moment().add(presaleStartsInSeconds, 'seconds').toDate() : null,
        presaleEndsAt: presaleEndsInSeconds ? moment().add(presaleEndsInSeconds, 'seconds').toDate() : null,
        presaleRefundStartsAt: presaleRefundStartsInSeconds ? moment().add(presaleRefundStartsInSeconds, 'seconds').toDate() : null,
        presaleRefundEndsAt: presaleRefundEndsInSeconds ? moment().add(presaleRefundEndsInSeconds, 'seconds').toDate() : null,
      };
    },
    getBawkPresaleRefundInfoRequest(state) {
      state.isLoading = true;
      state.refundData = undefined;
    },
    getBawkPresaleRefundInfoResponse(state, action: PayloadAction<PresaleRefundData>) {
      state.isLoading = true;
      state.refundData = action.payload;
    },
  },
});

export const {
  getBawkPreSaleInfoRequest,
  getBawkPreSaleInfoResponse,
  claimBawkPreSaleInviteCodeRequest,
  claimBawkPreSaleInviteCodeSuccess,
  claimBawkPreSaleInviteCodeFailed,
  getBawkPreSaleDatesRequest,
  getBawkPreSaleDatesResponse,
  getBawkPresaleRefundInfoRequest,
  getBawkPresaleRefundInfoResponse,
} = bawkPreSaleSlice.actions;

export default bawkPreSaleSlice.reducer;
