import { AbiItem } from 'web3-utils';

import { BAWK_PRESALE_CONTRACT } from 'src/abis/bawkPresaleContract';
import { ChainId } from 'src/constant/chainId';
import { exponentialBackOff } from 'src/utils/exponentialBackoff';

import { EventsEnum } from 'src/events';
import Web3 from 'web3';
import ContractService from './baseContractService';
import { ProviderService } from '../provider';

export class BawkPresaleContractService extends ContractService {
  public static readonly NUM_STAGES = 9;

  constructor() {
    super(ChainId.Ethereum, BAWK_PRESALE_CONTRACT.address, BAWK_PRESALE_CONTRACT.abi as AbiItem[]);

    ContractService.createContract(this.web3, this.contractAddress, this.abi);
  }

  // eslint-disable-next-line class-methods-use-this
  get web3() {
    return ContractService.ethWeb3;
  }

  async reserve(userWalletId: string, price: string, amount: number) {
    const balanceFunc = async () => this.web3.eth.getBalance(userWalletId);
    const { result: ethBalance } = await exponentialBackOff(balanceFunc);

    if (Web3.utils.toBN(ethBalance).lt(Web3.utils.toBN(price))) {
      throw new Error(`You have insufficient funds for buying ${amount} lots.`);
    }

    const method = this.contract.methods.reserve(amount);
    const functionSignature = method.encodeABI();

    const { transactionHash } = await this.sendEthTransaction(userWalletId, this.contractAddress, functionSignature, price);

    this.emitTransactionMined(EventsEnum.BawkPresaleTransactionMined, {
      transactionHash,
      amount,
    }, this.web3);
  }

  async sendEthTransaction(
    userWalletId: string,
    contractAddress: string,
    functionSignature: string,
    valueWei?: string,
    chainId = ChainId.Ethereum,
  ) {
    const tx: any = {
      chainId,
      from: userWalletId,
      to: contractAddress,
      data: functionSignature,
    };

    if (valueWei) {
      tx.value = Web3.utils.toHex(valueWei);
    }

    const sendTransactionFunc = async () => ProviderService.sendTransaction(chainId, userWalletId, tx);

    const { result: transactionHash } = await exponentialBackOff(
      sendTransactionFunc,
    );

    return {
      transactionHash,
    };
  }

  async refund(userWalletId: string, amount: number, signature: string) {
    const method = this.contract.methods.refund(amount, signature);
    const functionSignature = method.encodeABI();

    const { transactionHash } = await this.sendRawTransaction(userWalletId, this.contractAddress, functionSignature);

    this.emitTransactionMined(EventsEnum.BawkPresaleRefundTransactionMined, {
      transactionHash,
      amount,
    }, this.web3);
  }

  async getRefundPeriod() {
    const func = async () => this.contract.methods.MAX_REFUND_PERIOD().call();
    const { result } = await exponentialBackOff(func);

    return Number(result);
  }

  async getPresalePeriod() {
    const func = async () => this.contract.methods.PRESALE_PERIOD().call();
    const { result } = await exponentialBackOff(func);

    return Number(result);
  }

  async getPresaleStartDate() {
    const func = async () => this.contract.methods.presaleStartDate().call();
    const { result } = await exponentialBackOff(func);

    return Number(result);
  }

  async getPresaleRefundStartDate() {
    const func = async () => this.contract.methods.refundStartDate().call();
    const { result } = await exponentialBackOff(func);

    return Number(result);
  }

  async getPresalePrice() {
    const func = async () => this.contract.methods.unitPrice().call();
    const { result } = await exponentialBackOff(func);

    return result;
  }

  async getPresaleTotal() {
    const func = async () => this.contract.methods.presaleTotal().call();
    const { result } = await exponentialBackOff(func);

    return Number(result);
  }

  async getUserPresale(userWalletId: string) {
    const func = async () => this.contract.methods.usersTotalReserved(userWalletId).call();
    const { result } = await exponentialBackOff(func);

    return Number(result);
  }

  async getUserETHBalance(userWalletId: string) {
    const ethBalanceFunc = async () => this.web3.eth.getBalance(userWalletId);
    const { result: ethBalance } = await exponentialBackOff(ethBalanceFunc);

    return ethBalance;
  }
}
