import { cloneDeep } from 'lodash';
import React, {
  createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import { RaceFilter } from 'src/models/race-filter.type';

interface RaceFilterProps {
  onlyMyChickens: boolean,
  filters: RaceFilter
  onChangeFilters: (value: RaceFilter) => void;
  clearFilter: (field: string) => void;
  onChangeOnlyMyChickens: (value: boolean) => void
}

export const RaceFilterContext = createContext<RaceFilterProps | undefined>(
  undefined,
);

export const useRaceFilter = () => {
  const context = useContext(RaceFilterContext);

  return context;
};

export default function RaceFilterProvider(props: {
  children: React.ReactNode
}) {
  const [filters, setFilters] = useState<any>({});
  const [onlyMyChickens, setOnlyMyChickens] = useState(false);

  const onChangeOnlyMyChickens = useCallback((value) => {
    setOnlyMyChickens(value);
  }, []);

  const onChangeFilters = useCallback((value: RaceFilter) => {
    setFilters(value);
  }, []);

  const clearFilter = useCallback((field: string) => {
    if (field === 'all') {
      setFilters({});

      return;
    }

    const temp = cloneDeep(filters);

    if (field === 'date') {
      delete temp.startDate;
      delete temp.endDate;
    } else {
      delete temp[field];
    }

    setFilters(temp);
  }, [filters]);

  const value = useMemo(
    () => ({
      onlyMyChickens,
      filters,
      clearFilter,
      onChangeOnlyMyChickens,
      onChangeFilters,
    }),
    [
      onlyMyChickens,
      filters,
      clearFilter,
      onChangeOnlyMyChickens,
      onChangeFilters,
    ],
  );
  return (
    <RaceFilterContext.Provider
      value={value}
    >
      {props.children}
    </RaceFilterContext.Provider>
  );
}
