export const charliesChallenges = [{
  name: 'C1 - Race in different locations',
  description: '2pts per different location',
  pointBy: 'location',
  point: 2,
  goalPoints: 10,
}, {
  name: 'C2 - Finish 6th or 7th in a race',
  description: '4pts per 6th or 7th finish',
  pointBy: 'position',
  positions: [6, 7],
  point: 4,
  goalPoints: 8,
}, {
  name: 'C3 - Beat unnamed chickens',
  description: '1pt per unnamed chicken beat',
  pointBy: 'chicken_unnamed',
  point: 1,
  goalPoints: 30,
}, {
  name: 'C4 - Race on each terrain type',
  description: '10pts per set of terrains',
  pointBy: 'terrain_sets',
  point: 10,
  goalPoints: 10,
}, {
  name: 'C5 - Podium with wearables',
  description: '1pt per wearable worn',
  pointBy: 'wearable_podium',
  positions: [1, 2, 3],
  point: 1,
  goalPoints: 10,
}, {
  name: 'C6 - Use your talent',
  description: '3pts per use',
  pointBy: 'talent',
  point: 3,
  goalPoints: 6,
}, {
  name: 'C7 - Win money',
  description: '1pt per $0.50 won',
  pointBy: 'won',
  point: 1,
  goalPoints: 20,
}];
