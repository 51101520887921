import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { IS_PRODUCTION_ENV } from 'src/utils/config';

const StolenItemsPolicy = React.lazy(() => import('src/pages/landing-pages/stolenItemsPolicy'));
const TermsOfService = React.lazy(() => import('src/pages/landing-pages/termsOfService'));
const GetYourChicken = React.lazy(() => import('src/pages/landing-pages/get-your-chicken'));
const Bridge = React.lazy(() => import('src/pages/landing-pages/bridge'));
const JewelsSwap = React.lazy(() => import('src/pages/landing-pages/jewels-swap'));
const ChickenStaking = React.lazy(() => import('src/pages/chicken-staking'));
const SquidRouter = React.lazy(() => import('src/pages/squidrouter'));
const PreRegisterLand = React.lazy(() => import('src/pages/landing-pages/pre-register-land'));

const MainRoute = React.lazy(() => import('./main'));

function AppRoute() {
  return (
    <Routes>
      <Route path="/terms-of-service" element={<React.Suspense fallback={<div className="flex-1" />}><TermsOfService /></React.Suspense>} />
      <Route path="/stolen-items-policy" element={<React.Suspense fallback={<div className="flex-1" />}><StolenItemsPolicy /></React.Suspense>} />
      <Route path="/get-your-chicken" element={<React.Suspense fallback={<div className="flex-1" />}><GetYourChicken /></React.Suspense>} />
      <Route path="/farm" element={<React.Suspense fallback={<div className="flex-1" />}><PreRegisterLand /></React.Suspense>} />
      <Route path="/farm/:referralCode" element={<React.Suspense fallback={<div className="flex-1" />}><PreRegisterLand /></React.Suspense>} />
      <Route path="/bridge" element={<React.Suspense fallback={<div className="flex-1" />}><Bridge /></React.Suspense>} />
      <Route path="/jewels-swap" element={<React.Suspense fallback={<div className="flex-1" />}><JewelsSwap /></React.Suspense>} />
      <Route path="/vault/*" element={<React.Suspense fallback={<div className="flex-1" />}><ChickenStaking /></React.Suspense>} />
      <Route path="/squidrouter" element={IS_PRODUCTION_ENV ? <Navigate to="/" replace /> : <React.Suspense fallback={<div className="flex-1" />}><SquidRouter /></React.Suspense>} />
      <Route
        path="/*"
        element={(
          <React.Suspense fallback={<div className="flex-1" />}>
            <MainRoute />
          </React.Suspense>
        )}
      />
    </Routes>
  );
}

export default AppRoute;
