import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  takeLatest, call, put, delay,
} from 'redux-saga/effects';
import { apiService } from 'src/services/apiService';
import { toastService } from 'src/services/toastService';
import { SerumMintRequest } from './model';
import {
  checkLastSerumMintFailedResponse,
  checkLastSerumMintRequest,
  checkLastSerumMintSuccessResponse,
  checkSerumMintRequest,
  checkSerumMintSuccessResponse,
  serumMintFailedResponse,
  serumMintRequest,
  serumMintSuccessResponse,
  setSerumMintInfo,
} from './reducer';

export function* checkSerumMint(action: PayloadAction<SerumMintRequest>) {
  try {
    const res = yield call(apiService.checkSerumMint, action.payload);
    yield put(checkSerumMintSuccessResponse(res.data));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');

    yield put(checkLastSerumMintFailedResponse());
  }
}

export function* onSerumMint(action: PayloadAction<SerumMintRequest>) {
  try {
    yield call(apiService.serumMint, action.payload);

    yield put(serumMintSuccessResponse());

    yield put(checkLastSerumMintRequest(true));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');

    yield put(serumMintFailedResponse(undefined));
  }
}

export function* checkLastSerumMint({ payload }: { payload: boolean }) {
  try {
    const res = yield call(apiService.checkLastSerumMint);
    const { serumMint } = res.data;

    if (!serumMint) {
      yield put(checkLastSerumMintSuccessResponse(false));
      return;
    }

    if (serumMint.status === 'success') {
      yield put(setSerumMintInfo(serumMint));
      yield put(checkLastSerumMintSuccessResponse(!!payload));

      if (payload) {
        toast.success(`Minted ${serumMint.amount} serums successfully`);
      }
    } else if (serumMint.status === 'error') {
      yield put(checkLastSerumMintFailedResponse());

      if (payload) {
        throw new Error(serumMint.error.message);
      }
    } else {
      yield put(setSerumMintInfo(serumMint));
      yield delay(5 * 1000);
      yield put(checkLastSerumMintRequest(true));
    }
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
    yield put(checkLastSerumMintFailedResponse());
  }
}

export function* userEffects() {
  yield takeLatest(checkSerumMintRequest.type, checkSerumMint);
  yield takeLatest(serumMintRequest.type, onSerumMint);
  yield takeLatest(checkLastSerumMintRequest.type as any, checkLastSerumMint);
}

const fusionSagas = [call(userEffects)];

export default fusionSagas;
