import { WETH_CONTRACT } from 'src/abis';
import { BICONOMY_WETH_API_ID } from 'src/utils/config';
import { ChainId } from 'src/constant/chainId';
import { TokenContractService } from './tokenContractService';
import ContractService from './baseContractService';

export class WethContractService extends TokenContractService {
  private readonly biconomyApiId = BICONOMY_WETH_API_ID

  private readonly name = WETH_CONTRACT.name;

  private readonly version = WETH_CONTRACT.version

  constructor() {
    super(ChainId.Polygon, WETH_CONTRACT);
    ContractService.add(this);
  }

  public async approveWithBiconomy(
    account: string,
    functionSignature,
    {
      onError, onSubmit, onTx, onReceipt,
    },
  ) {
    return this.contractCall(async () => this.signAndSendMetaTransaction(
      this.biconomyApiId,
      this.name,
      this.version,
      this.contract,
      account,
      functionSignature,
      {
        onError,
        onSubmit,
        onTx,
        onReceipt,
      },
    ));
  }
}
