import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import * as _ from 'lodash';
import { themeColorScheme } from 'src/constant';
import { ThemeColorScheme, ThemeType } from 'src/models';

interface ThemeProps {
  theme: ThemeType
  themeColors: ThemeColorScheme;
  onToggleTheme: () => void;
}

const CHICKEN_DERBY_THEME_KEY = 'chickenderby_theme';

export const ThemeContext = createContext<ThemeProps | undefined>(
  undefined,
);

export const useThemeContext = () => {
  const context = useContext(ThemeContext);

  return context;
};

export default function ThemeProvider(props: {
  children: React.ReactChild[] | React.ReactChild;
}) {
  const [theme, setTheme] = useState<ThemeType>('dark');
  const [themeColors, setThemeColors] = useState<ThemeColorScheme>(themeColorScheme.dark);

  const onToggleTheme = useCallback(() => {
    const value = theme === 'light' ? 'dark' : 'light';
    localStorage.setItem(CHICKEN_DERBY_THEME_KEY, value);
    setTheme(value);
    setThemeColors(themeColorScheme[value] || themeColorScheme.light);
  }, [theme]);

  // useEffect(() => {
  //   const value = localStorage.getItem(CHICKEN_DERBY_THEME_KEY);
  //   const defaultTheme = (value || 'dark') as ThemeType;
  //   setTheme(defaultTheme);
  //   setThemeColors(themeColorScheme[defaultTheme] || themeColorScheme.light);
  // }, []);

  useEffect(() => {
    const themeColorKeys = Object.keys(themeColors);
    for (let i = 0; i < themeColorKeys.length; i += 1) {
      const key = themeColorKeys[i];
      document.documentElement.style.setProperty(`--${_.kebabCase(key)}`, themeColors[key]);
    }
    document.documentElement.style.setProperty('--toastify-color-dark', 'rgba(36, 46, 56, 1)');
  }, [themeColors]);

  useEffect(() => {
    document.getElementById('root')?.setAttribute('class', theme);
  }, [theme]);

  const value = useMemo(
    () => ({
      theme,
      themeColors,
      onToggleTheme,
    }),
    [
      theme,
      themeColors,
      onToggleTheme,
    ],
  );

  return (
    <ThemeContext.Provider
      value={value}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}
