import React from 'react';

export function MenuCloseIcon({
  size = 24,
  color = '#D6EAFF',
  onClick,
}: {
  size?: number,
  color?: string
  onClick?: () => void
}) {
  return (
    <svg onClick={onClick} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="vuesax/linear/add">
        <g id="add">
          <path id="Vector" d="M4 4.00085L19.9983 19.9991" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path id="Vector_2" d="M4.00171 19.9991L20 4.00085" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
    </svg>
  );
}
