import { cloneDeep } from 'lodash';
import React, {
  createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import { ChickenStatsFilterModal } from 'src/modals/chickenStatsFilterModal';
import { ChickenStatsFilter } from 'src/models/chicken-stats-filter.type';

interface ChickenStatusProps {
  filter: ChickenStatsFilter
  openChickenStatsFilter: () => void;
  clearFilter: (field: string) => void;
}

export const ChickenStatsFilterContext = createContext<ChickenStatusProps | undefined>(
  undefined,
);

export const useChickenStatsFilter = () => {
  const context = useContext(ChickenStatsFilterContext);

  return context;
};

export default function ChickenStatsFilterProvider(props: {
  children: React.ReactNode
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState<any>({});

  const filter = useMemo(() => ({
    ...filters,
    peckingOrder: filters.peckingOrder?.map((item) => item.value),
    distance: filters.distance?.map((item) => item.value),
    terrain: filters.terrain?.map((item) => item.value),
  }), [filters]);

  const openChickenStatsFilter = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onSetFilters = (value: ChickenStatsFilter) => {
    setFilters(value);
    setIsOpen(false);
  };

  const clearFilter = useCallback((field: string) => {
    if (field === 'all') {
      setFilters({});

      return;
    }

    const temp = cloneDeep(filters);

    if (field === 'date') {
      delete temp.startDate;
      delete temp.endDate;
    } else {
      delete temp[field];
    }

    setFilters(temp);
  }, [filters]);

  const value = useMemo(
    () => ({
      openChickenStatsFilter,
      clearFilter,
      filter,
    }),
    [openChickenStatsFilter, clearFilter, filter],
  );
  return (
    <ChickenStatsFilterContext.Provider
      value={value}
    >
      {props.children}
      <ChickenStatsFilterModal
        open={isOpen}
        filter={filters}
        onFilter={onSetFilters}
      />
    </ChickenStatsFilterContext.Provider>
  );
}
