import { IContract } from 'src/models';
import {
  BAWK_STAKING_CONTRACT_ADDRESS,
} from 'src/utils/config';

export const BAWK_STAKING_CONTRACT: IContract = {
  name: 'BawkStakingRewards',
  version: '1.0.0',
  address: BAWK_STAKING_CONTRACT_ADDRESS,
  abi: [{ inputs: [], stateMutability: 'nonpayable', type: 'constructor' }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'previousAdmin', type: 'address',
    }, {
      indexed: false, internalType: 'address', name: 'newAdmin', type: 'address',
    }],
    name: 'AdminChanged',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'account', type: 'address',
    }, {
      indexed: false, internalType: 'enum BawkStakingRewards.Company', name: 'cid', type: 'uint8',
    }, {
      indexed: false, internalType: 'uint256', name: 'epoch', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
    }],
    name: 'BawkStaked',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'account', type: 'address',
    }, {
      indexed: false, internalType: 'enum BawkStakingRewards.Company', name: 'cid', type: 'uint8',
    }, {
      indexed: false, internalType: 'uint256', name: 'epoch', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
    }],
    name: 'BawkUnstaked',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'beacon', type: 'address',
    }],
    name: 'BeaconUpgraded',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'uint8', name: 'version', type: 'uint8',
    }],
    name: 'Initialized',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferStarted',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'enum BawkStakingRewards.Company', name: 'cid', type: 'uint8',
    }, {
      indexed: false, internalType: 'uint256', name: 'epoch', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
    }],
    name: 'RewardsAppended',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'account', type: 'address',
    }, {
      indexed: false, internalType: 'enum BawkStakingRewards.Company', name: 'cid', type: 'uint8',
    }, {
      indexed: false, internalType: 'uint256', name: 'epoch', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
    }],
    name: 'RewardsClaimed',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'implementation', type: 'address',
    }],
    name: 'Upgraded',
    type: 'event',
  }, {
    inputs: [], name: 'acceptOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'admin', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'enum BawkStakingRewards.Company', name: '_cid', type: 'uint8' }, { internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'appendEarningEntry', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '', type: 'address' }, { internalType: 'enum BawkStakingRewards.Company', name: '', type: 'uint8' }], name: 'balances', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '', type: 'address' }, { internalType: 'enum BawkStakingRewards.Company', name: '', type: 'uint8' }, { internalType: 'uint256', name: '', type: 'uint256' }], name: 'balancesForEpoch', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'enum BawkStakingRewards.Company', name: '_cid', type: 'uint8' }, { internalType: 'uint256[]', name: '_epochs', type: 'uint256[]' }], name: 'batchClaimRewardsFromCompany', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'bawk', outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'bawkEscrow', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_account', type: 'address' }, { internalType: 'enum BawkStakingRewards.Company', name: '_cid', type: 'uint8' }, { internalType: 'uint256', name: '_epoch', type: 'uint256' }], name: 'calculateRewards', outputs: [{ internalType: 'uint256', name: 'earning', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'enum BawkStakingRewards.Company', name: '_cid', type: 'uint8' }, { internalType: 'uint256', name: '_epoch', type: 'uint256' }, {
      components: [{ internalType: 'bytes32', name: 'r', type: 'bytes32' }, { internalType: 'bytes32', name: 's', type: 'bytes32' }, { internalType: 'uint8', name: 'v', type: 'uint8' }], internalType: 'struct Coupon', name: 'coupon', type: 'tuple',
    }],
    name: 'claimRewardsFromCompanyByEpoch',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [], name: 'currentEpoch', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'duration', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }, { internalType: 'enum BawkStakingRewards.Company', name: '', type: 'uint8' }], name: 'earningJewels', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '', type: 'address' }, { internalType: 'enum BawkStakingRewards.Company', name: '', type: 'uint8' }], name: 'escrowedBalances', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_account', type: 'address' }, { internalType: 'enum BawkStakingRewards.Company', name: '_cid', type: 'uint8' }], name: 'getBalanceOfByCompanyId', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_account', type: 'address' }, { internalType: 'enum BawkStakingRewards.Company', name: '_cid', type: 'uint8' }], name: 'getEscrowedBalanceOfByCompanyId', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_account', type: 'address' }, { internalType: 'enum BawkStakingRewards.Company', name: '_cid', type: 'uint8' }], name: 'getNonEscrowedBalanceOfByCompanyId', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_bawk', type: 'address' }, { internalType: 'address', name: '_jewels', type: 'address' }], name: 'initialize', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'forwarder', type: 'address' }], name: 'isTrustedForwarder', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'jewels', outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '', type: 'address' }, { internalType: 'enum BawkStakingRewards.Company', name: '', type: 'uint8' }], name: 'lastUpdatedEpoch', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'payout', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'pendingOwner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'proxiableUUID', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_admin', type: 'address' }], name: 'setAdmin', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_bawk', type: 'address' }], name: 'setBawk', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_bawkEscrow', type: 'address' }], name: 'setBawkEscrow', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_duration', type: 'uint256' }], name: 'setDuration', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_payout', type: 'address' }], name: 'setPayout', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_forwarder', type: 'address' }], name: 'setTrustedForwarder', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'enum BawkStakingRewards.Company', name: '_cid', type: 'uint8' }, { internalType: 'uint256', name: '_amount', type: 'uint256' }, {
      components: [{ internalType: 'bytes32', name: 'r', type: 'bytes32' }, { internalType: 'bytes32', name: 's', type: 'bytes32' }, { internalType: 'uint8', name: 'v', type: 'uint8' }], internalType: 'struct Coupon', name: 'coupon', type: 'tuple',
    }],
    name: 'stake',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_account', type: 'address' }, { internalType: 'enum BawkStakingRewards.Company', name: '_cid', type: 'uint8' }, { internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'stakeEscrow', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_startTs', type: 'uint256' }], name: 'start', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'startTs', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'enum BawkStakingRewards.Company', name: '', type: 'uint8' }, { internalType: 'uint256', name: '', type: 'uint256' }], name: 'totalEpochStakedAmountByCid', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'trustedForwarder', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'enum BawkStakingRewards.Company', name: '_cid', type: 'uint8' }, { internalType: 'uint256', name: '_amount', type: 'uint256' }, {
      components: [{ internalType: 'bytes32', name: 'r', type: 'bytes32' }, { internalType: 'bytes32', name: 's', type: 'bytes32' }, { internalType: 'uint8', name: 'v', type: 'uint8' }], internalType: 'struct Coupon', name: 'coupon', type: 'tuple',
    }],
    name: 'unstake',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_account', type: 'address' }, { internalType: 'enum BawkStakingRewards.Company', name: '_cid', type: 'uint8' }, { internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'unstakeEscrow', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newImplementation', type: 'address' }], name: 'upgradeTo', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newImplementation', type: 'address' }, { internalType: 'bytes', name: 'data', type: 'bytes' }], name: 'upgradeToAndCall', outputs: [], stateMutability: 'payable', type: 'function',
  }, {
    inputs: [], name: 'versionRecipient', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'pure', type: 'function',
  }],
};
