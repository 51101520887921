export const wearableSortList: any = [
  { label: 'A to Z', value: 'name_ASC' },
  { label: 'Z to A', value: 'name_DESC' },
  { label: 'Newest First', value: 'id_DESC' },
  { label: 'Oldest First', value: 'id_ASC' },
  { label: 'Own - Ascending', value: 'ownerSupply_ASC' },
  { label: 'Own - Descending', value: 'ownerSupply_DESC' },
  { label: 'Maximum - Ascending', value: 'maxSupply_ASC' },
  { label: 'Maximum - Descending', value: 'maxSupply_DESC' },
];

export const wearableTypeList: any = [
  { label: 'Body', value: 'Body' },
  { label: 'Eyes', value: 'Eyes' },
  { label: 'Feet', value: 'Feet' },
  { label: 'Head', value: 'Head' },
  { label: 'Neck', value: 'Neck' },
];
