import { exponentialBackOff } from 'src/utils/exponentialBackoff';
import Web3 from 'web3';
import { IContract } from 'src/models';
import { ChainId } from 'src/constant/chainId';
import ContractService from './baseContractService';

export class TokenContractService extends ContractService {
  constructor(chainId: ChainId, contract: IContract, protected web3?: Web3) {
    super(chainId, contract.address, contract.abi);
  }

  async getAllowance(userWalletId: string, contractAddress: string) {
    return this.contractCall(async () => {
      const userAllowanceFunc = async () => this.contract.methods.allowance(userWalletId, contractAddress).call();
      const { result: userAllowance } = await exponentialBackOff(
        userAllowanceFunc,
      );

      return userAllowance;
    });
  }

  protected async isApproved(
    userWalletId: string,
    contractAddress: string,
    tether: string,
  ) {
    return this.contractCall(async () => {
      const allowance = await this.getAllowance(userWalletId, contractAddress);
      const isApproved = Web3.utils.toBN(allowance).gte(Web3.utils.toBN(tether));

      return { isApproved, allowance };
    });
  }

  protected async approve(
    userWalletId: string,
    contractAddress: string,
    tether: string,
  ) {
    return this.contractCall(async () => {
      const hexQty = Web3.utils.toHex(tether);

      const method = this.contract.methods.approve(contractAddress, hexQty);
      const functionSignature = method.encodeABI();

      return this.sendRawTransaction(
        userWalletId,
        this.contractAddress,
        functionSignature,
      );
    });
  }

  async checkApproval(
    userWalletId: string,
    contractAddress: string,
    tether: string,
  ) {
    const { isApproved } = await this.isApproved(
      userWalletId,
      contractAddress,
      tether,
    );

    if (!isApproved) {
      const { transactionHash } = await this.approve(
        userWalletId,
        contractAddress,
        Web3.utils.toBN(2 ** 64 - 1).toString(),
      );
      await this.getTransactionReceiptLoop(transactionHash, this.web3);
    }
  }

  async getBalance(userWalletId: string) {
    return this.contractCall(async () => {
      const getBalanceFunc = async () => this.contract.methods.balanceOf(userWalletId).call();
      console.log('contract', this.contract);
      const { result } = await exponentialBackOff(getBalanceFunc);

      return result;
    });
  }

  public async approveWithBiconomy(
    account: string,
    functionSignature,
    {
      onError, onSubmit, onTx, onReceipt,
    },
  ) {
    throw new Error(`Not support biconomy for ${this.contractAddress}`);
  }
}
