import {
  takeLatest, call, put,
} from 'redux-saga/effects';
import { apiService } from 'src/services/apiService';
import { toastService } from 'src/services/toastService';
import { PayloadAction } from '@reduxjs/toolkit';
import { PaginatedRequest } from 'src/models';
import { checkLastRaceAssignmentSuccessResponse } from '../lane/reducer';
import {
  checkIsDeployingRequest,
  getTotalRaceAction,
  refreshRaceAction,
} from './action';
import {
  getActiveRacesResponse,
  getScheduledRacesResponse,
  getGameResultsResponse,
  getFetchingResponse,
  refreshRaceResponse,
  getGameResultsRequest,
  getScheduledRacesRequest,
  getActiveRacesRequest,
  checkIsDeployingResponse,
  getChickenRacesRequest,
  getChickenRacesResponse,
  getRaceRequest,
  getRaceResponse,
  getTotalRaceResponse,
  getRaceChickensRequest,
  getRaceChickensResponse,
  getIncomingRacesRequest,
  getIncomingRacesResponse,
  getLeaderboardRequest,
  getLeaderboardResponse,
} from './reducer';
import { getCoinRate } from '../exchange/saga';
import { setChickensLoadingResponse } from '../chicken/reducer';

export function* getActiveRaces(data: any) {
  try {
    const { payload } = data;
    yield put(getFetchingResponse(true));
    const response = yield call(
      apiService.getRaces,
      payload,
    );

    yield put(getActiveRacesResponse(response?.data));
  } catch (err) {
    yield put(getActiveRacesResponse({
      rows: [],
      count: 0,
    }));
  } finally {
    yield put(getFetchingResponse(false));
  }
}

export function* getIncomingRaces(data: any) {
  try {
    const { payload } = data;
    yield put(getFetchingResponse(true));
    const response = yield call(
      apiService.getRaces,
      payload,
    );

    yield put(getIncomingRacesResponse(response?.data));
  } catch (err) {
    yield put(getIncomingRacesResponse({
      rows: [],
      count: 0,
    }));
  } finally {
    yield put(getFetchingResponse(false));
  }
}

export function* getScheduledRaces(data: any) {
  try {
    const { payload } = data;
    yield put(getFetchingResponse(true));

    if (payload?.filter?.includes('tournamentId')) {
      const response = yield call(
        apiService.getTournamentRaces,
        payload,
      );
      yield put(getScheduledRacesResponse(response?.data));
    } else {
      const response = yield call(
        apiService.getRaces,
        payload,
      );
      yield put(getScheduledRacesResponse(response?.data));
    }
  } catch (err) {
    yield put(getScheduledRacesResponse({ rows: [], count: 0 }));
  } finally {
    yield put(getFetchingResponse(false));
  }
}

export function* getChickenRaces(data: any) {
  try {
    const { payload } = data;
    yield put(getFetchingResponse(true));

    const response = yield call(
      apiService.getChickenRaces,
      payload,
    );

    yield put(getChickenRacesResponse(response?.data));
  } catch (err) {
    //
  } finally {
    yield put(getFetchingResponse(false));
  }
}

export function* getGameResults(data: any) {
  try {
    const { payload } = data;
    yield put(getFetchingResponse(true));

    console.log('payload', payload);
    console.log('data', data);

    if (payload?.filter?.includes('tournamentId')) {
      const response = yield call(
        apiService.getTournamentResults,
        payload,
      );

      console.log('response?.data 1', response?.data);

      yield put(getGameResultsResponse(response?.data));
    } else {
      const response = yield call(
        apiService.getResults,
        payload,
      );

      console.log('response?.data 2', response?.data);

      yield put(getGameResultsResponse(response?.data));
    }
  } catch (err) {
    yield put(getGameResultsResponse({ rows: [], count: 0 }));
  } finally {
    yield put(getFetchingResponse(false));
  }
}

export function* refreshRace(data: any) {
  try {
    const { payload } = data;
    const response = yield call(apiService.getRace, payload);
    yield put(refreshRaceResponse(response?.data));
  } catch (err) {
    //
  }
}

export function* getRace(data: any) {
  try {
    const { payload } = data;
    yield put(setChickensLoadingResponse(true));
    const response = yield call(apiService.getRace, payload);
    yield put(getRaceResponse(response?.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
  } finally {
    yield put(setChickensLoadingResponse(false));
  }
}

export function* getTotalRace() {
  try {
    const response = yield call(apiService.getTotalRaces);
    yield put(getTotalRaceResponse(response?.data));
  } catch (err) {
    //
  }
}

export function* checkIsDeploying() {
  try {
    const response = yield call(apiService.checkIsDeploying);
    yield put(checkIsDeployingResponse(response.data.isDeploying));
  } catch (err) {
    //
  }
}

function* getRaceChickens(action: PayloadAction<PaginatedRequest>) {
  try {
    const res = yield call(apiService.getChickens, action.payload);
    yield put(getRaceChickensResponse(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getRaceChickensResponse({
      rows: [],
      count: 0,
    }));
  }
}

function* getLeaderboard() {
  try {
    const res = yield call(apiService.getLeaderboard);
    yield put(getLeaderboardResponse(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getLeaderboardResponse(null));
  }
}

export function* userEffects() {
  yield takeLatest(getActiveRacesRequest.type, getActiveRaces);
  yield takeLatest(getIncomingRacesRequest.type, getIncomingRaces);
  yield takeLatest(getScheduledRacesRequest.type, getScheduledRaces);
  yield takeLatest(getChickenRacesRequest.type, getChickenRaces);
  yield takeLatest(getGameResultsRequest.type, getGameResults);
  yield takeLatest(checkLastRaceAssignmentSuccessResponse.type, getCoinRate);
  yield takeLatest(refreshRaceAction.type, refreshRace);
  yield takeLatest(getRaceRequest.type, getRace);
  yield takeLatest(checkIsDeployingRequest.type, checkIsDeploying);
  yield takeLatest(getTotalRaceAction.type, getTotalRace);
  yield takeLatest(getRaceChickensRequest.type, getRaceChickens);
  yield takeLatest(getLeaderboardRequest.type, getLeaderboard);
}

const racesSagas = [call(userEffects)];

export default racesSagas;
