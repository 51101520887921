/* eslint-disable no-underscore-dangle */
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import { CHICKEN_STAKING_CONTRACT } from 'src/abis';
import { CHICKEN_CONTRACT_ADDRESS } from 'src/utils/config';
import { EventsEnum } from 'src/events';
import { ChickenStakingStatus, IChickenStakeInfo } from 'src/models';
import { exponentialBackOff } from 'src/utils/exponentialBackoff';
import { ChainId } from 'src/constant/chainId';
import ContractService from './baseContractService';

export class ChickenStakingContractService extends ContractService {
  constructor() {
    super(ChainId.Polygon, CHICKEN_STAKING_CONTRACT.address, CHICKEN_STAKING_CONTRACT.abi as AbiItem[]);

    ContractService.add(this);
  }

  async stakeChickens(userWalletId: string, chickenIds: number[]) {
    return this.contractCall(async () => {
      await this.checkTokenApproval(userWalletId, CHICKEN_CONTRACT_ADDRESS);
      const method = this.contract.methods.stake(chickenIds);
      const functionSignature = method.encodeABI();

      const { transactionHash } = await this.sendRawTransaction(userWalletId, this.contractAddress, functionSignature);

      this.emitTransactionMined(
        EventsEnum.ChickenStakingTransactionMined,
        {
          stakingStatus: ChickenStakingStatus.Staked,
          transactionHash,
          chickenIds,
        },
      );
    });
  }

  async unstakeChickens(userWalletId: string, chickenIds: number[]) {
    return this.contractCall(async () => {
      const method = this.contract.methods.unstake(chickenIds);
      const functionSignature = method.encodeABI();

      const { transactionHash } = await this.sendRawTransaction(userWalletId, this.contractAddress, functionSignature);

      this.emitTransactionMined(
        EventsEnum.ChickenStakingTransactionMined,
        {
          stakingStatus: ChickenStakingStatus.Unstaked,
          transactionHash,
          chickenIds,
        },
      );
    });
  }

  async updateEscrowRewardEntry(userWalletId: string) {
    return this.contractCall(async () => {
      const method = this.contract.methods.updateEscrowRewardEntry(userWalletId);
      const functionSignature = method.encodeABI();

      const { transactionHash } = await this.sendRawTransaction(userWalletId, this.contractAddress, functionSignature);

      this.emitTransactionMined(
        EventsEnum.ChickenStakingTransactionMined,
        {
          stakingStatus: ChickenStakingStatus.Claimed,
          transactionHash,
        },
      );
    });
  }

  async getUserStakeInfo(userWalletId: string): Promise<IChickenStakeInfo> {
    return this.contractCall(async () => {
      const func = async () => this.contract.methods.userStakeInfo(userWalletId).call();
      const { result } = await exponentialBackOff(func);

      return {
        chickenIdsStaked: result._chickensStaked,
        availableRewards: Number(Web3.utils.fromWei(result._availableRewards, 'ether')),
      };
    });
  }

  async calculateRewards(userWalletId: string) {
    return this.contractCall(async () => {
      const calculateRewardsFunc = async () => this.contract.methods.calculateRewards(userWalletId).call();
      const { result } = await exponentialBackOff(calculateRewardsFunc);

      return Number(Web3.utils.fromWei(result, 'ether'));
    });
  }

  async getEarnedBawks(userWalletId: string) {
    return this.contractCall(async () => {
      const rewardsFunc = async () => this.contract.methods.rewards(userWalletId).call();
      const { result } = await exponentialBackOff(rewardsFunc);

      return Number(Web3.utils.fromWei(result, 'ether'));
    });
  }
}
