import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IMAGES_URL } from 'src/constant/images';
import { formatTokenAmount } from 'src/utils/utils';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { useThemeContext } from 'src/contexts/theme';
import { useTranslation } from 'react-i18next';
import { GOOGLE_RECAPTCHA_KEY } from 'src/utils/config';
import { useCurrency } from 'src/contexts/currency';

export function TransactionConfirmation({
  race,
  coinRate,
  open,
  onClose,
  onConfirm,
}: {
  race: any;
  coinRate: any;
  open: boolean;
  onClose: () => void;
  onConfirm: (captchaKey?: string) => void;
}) {
  const { theme } = useThemeContext();
  const { currency, currencyElement } = useCurrency();
  const [captchaKey, setCaptchaKey] = useState<string>();
  const [isRequiredCaptchaKey, setIsRequiredCaptchaKey] = useState(false);

  const onChangeCaptch = (data) => {
    setIsRequiredCaptchaKey(false);
    setCaptchaKey(data);
  };

  const onSubmit = () => {
    if (race.fee !== 0) {
      onConfirm();
      return;
    }

    if (race.peckingOrder !== 'CHICK' && !captchaKey) {
      setIsRequiredCaptchaKey(true);
      return;
    }

    if (isRequiredCaptchaKey) {
      return;
    }

    onConfirm(captchaKey);
    setCaptchaKey(undefined);
  };
  const { t } = useTranslation('translation', { keyPrefix: 'MODALS.TRANSACTION_CONFIRMATION' });

  useEffect(() => {
    if (open) {
      setCaptchaKey(undefined);
      setIsRequiredCaptchaKey(false);
    }
  }, [open]);

  return (
    <Modal
      centered
      dialogClassName="transaction-confirmation"
      show={open}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>{t('TRANSACTION_CONFIRMATION')}</Modal.Title>
        <button type="button" className="close" onClick={onClose}>
          <img src={IMAGES_URL.CLOSEICON} alt="" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <h4>{t('CURRENT_PRICE')}</h4>
        <p>
          {formatTokenAmount(race?.fee, coinRate, race?.coinType, currency)}
          {' '}
          {currencyElement(currency)}
        </p>
        <h4>{t('ESTIMATED_TX_FEE')}</h4>
        <p>
          0.00
          {' '}
          {currencyElement(currency)}
        </p>
        <h4>{t('ESTIMATED_TOTAL')}</h4>
        <p>
          {formatTokenAmount(race?.fee, coinRate, race?.coinType, currency)}
          {' '}
          {currencyElement(currency)}
        </p>
        <h4 className="text-center">
          {formatTokenAmount(race?.fee, coinRate, race?.coinType, currency)}
          {' '}
          {currencyElement(currency)}
        </h4>
        {race?.fee === 0 && race?.peckingOrder !== 'CHICK' && (
          <div className="mt-5 flex flex-column items-center justify-center">
            <ReCAPTCHA
              sitekey={GOOGLE_RECAPTCHA_KEY}
              onChange={onChangeCaptch}
              onErrored={() => setIsRequiredCaptchaKey(true)}
              onExpired={() => setIsRequiredCaptchaKey(true)}
              theme={theme}
            />
            {isRequiredCaptchaKey && <p className="captcah-error mt-2">{t('REQUIRED_RECAPTCHA')}</p>}
          </div>
        )}
        <div className="bottom-btn">
          <button type="button" onClick={onClose} className="btn btn-go">
            {t('GO_BACK')}
          </button>
          <button
            type="button"
            className="btn btn-confirm"
            onClick={onSubmit}
          >
            {t('CONFIRM')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
