import React from 'react';

export function CalendarIcon({
  size = 29,
  color = '#FFF',
  onClick,
}: {
  size?: number
  color?: string
  onClick?: (e: any) => void
}) {
  return (
    <svg onClick={onClick} width={size} height={size} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0254 4V7" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.0254 4V7" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.52539 11.0898H22.5254" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.0254 10.5V19C23.0254 22 21.5254 24 18.0254 24H10.0254C6.52539 24 5.02539 22 5.02539 19V10.5C5.02539 7.5 6.52539 5.5 10.0254 5.5H18.0254C21.5254 5.5 23.0254 7.5 23.0254 10.5Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.7211 15.7002H17.7301" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.7211 18.7002H17.7301" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.0219 15.7002H14.0308" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.0219 18.7002H14.0308" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.3187 15.7002H10.3277" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.3187 18.7002H10.3277" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}
