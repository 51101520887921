import React from 'react';
import { useThemeContext } from 'src/contexts/theme';

export const sortCaret = (sort) => <SortCaret sort={sort} />;

function SortCaret({ sort }: { sort?: string }) {
  const { themeColors } = useThemeContext();
  return (
    <div className="table-sort-caret">
      <svg
        className="svg-icon"
        width="1rem"
        height="1rem"
        viewBox="0 0 1024 256"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M512 0L0 614.4h1024L512 0z" fill={sort === 'asc' ? themeColors.text : '#ccc'} />
      </svg>
      <svg
        className="svg-icon"
        width="1rem"
        height="1rem"
        viewBox="0 0 1024 256"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M512 0L0 614.4h1024L512 0z" fill={sort === 'desc' ? themeColors.text : '#ccc'} />
      </svg>
    </div>
  );
}
