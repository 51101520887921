import { BAWK_CONTRACT } from 'src/abis/bawkContract';
import { BICONOMY_BAWKS_APPROVE_API_ID } from 'src/utils/config';
import { ChainId } from 'src/constant/chainId';
import { TokenContractService } from './tokenContractService';
import ContractService from './baseContractService';

export class BawkContractService extends TokenContractService {
  private readonly biconomyApiId = BICONOMY_BAWKS_APPROVE_API_ID;

  constructor() {
    super(ChainId.Polygon, BAWK_CONTRACT);
    ContractService.add(this);
  }

  public async approveWithBiconomy(
    account: string,
    functionSignature,
    {
      onError, onSubmit, onTx, onReceipt,
    },
  ) {
    return this.contractCall(async () => this.singAndSendForwardTransaction(
      this.biconomyApiId,
      account,
      functionSignature,
      {
        onError,
        onSubmit,
        onTx,
        onReceipt,
      },
    ));
  }
}
