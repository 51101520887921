import React, { useMemo } from 'react';
import { IMAGES_URL } from 'src/constant/images';
import { useThemeContext } from 'src/contexts/theme';
import './style.css';

interface Step {
  value: number,
  color: 'green' | 'yellow' | 'purple' | 'red'
}

const originalWidth = 319;

const lines = {
  green: IMAGES_URL.PROGRESS.BAR_GREEN,
  yellow: IMAGES_URL.PROGRESS.BAR_YELLOW,
  purple: IMAGES_URL.PROGRESS.BAR_PURPLE,
  red: IMAGES_URL.PROGRESS.BAR_RED,
};

const getStepColor = (steps: Array<Step>, value) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const step of steps) {
    if (value < step.value) {
      return step.color;
    }
  }

  return steps[steps.length - 1]?.color;
};

export function ProgressBar({
  steps,
  value,
  maxValue,
  width = 319,
}: {
  steps: Array<Step>,
  value: number,
  maxValue: number
  width?: number
}) {
  const { theme } = useThemeContext();
  const ratio = useMemo(() => width / originalWidth, [width]);
  const marginTop = useMemo(() => 7 * ratio, [ratio]);
  const marginLeft = useMemo(() => 11 * ratio, [ratio]);
  const marginRight = useMemo(() => 12 * ratio, [ratio]);
  const marginBottom = useMemo(() => 20 * ratio, [ratio]);
  const lineWidth = useMemo(() => 4 * ratio, [ratio]);
  const height = useMemo(() => 69 * ratio, [ratio]);

  const color = useMemo(() => getStepColor(steps, value), [steps, value]);
  const line = useMemo(() => lines[color], [color]);

  return (
    <div
      className="step-bar"
      style={{ width, height }}
    >
      <img className="step-bar-image" src={theme === 'light' ? IMAGES_URL.PROGRESS.PROGRESS_LIGHT : IMAGES_URL.PROGRESS.PROGRESS_DARK} alt="" />
      <div
        className="step-area"
        style={{
          left: marginLeft,
          top: marginTop,
          width: `calc(100% - ${marginLeft + marginRight}px)`,
          height: `calc(100% - ${marginTop + marginBottom}px)`,
        }}
      >
        {steps.map((step) => {
          if (step.value >= maxValue) {
            return <></>;
          }

          return (
            <img
              className="step-line"
              key={step.value}
              style={{
                left: `calc(${(step.value / maxValue) * 100}% - ${lineWidth / 2}px)`,
              }}
              src={theme === 'light' ? IMAGES_URL.PROGRESS.LINE_LIGHT : IMAGES_URL.PROGRESS.LINE_DARK}
              alt=""
            />
          );
        })}
        <img
          className="step-progress"
          style={{
            left: value >= maxValue ? 0 : `-${100 - ((value / maxValue) * 100)}%`,
          }}
          src={line}
          alt=""
        />
      </div>

      {/* <img src={IMAGES_URL.PROGRESS.LINE_LIGHT} alt="" className="" />
      <img src={IMAGES_URL.PROGRESS.BAR_GREEN} alt="" className="" /> */}
    </div>
  );
}
