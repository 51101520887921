import { IContract } from 'src/models';
import { MARKETPLACE_CONTRACT_ADDRESS } from 'src/utils/config';

export const MARKETPLACE_CONTRACT: IContract = {
  address: MARKETPLACE_CONTRACT_ADDRESS,
  abi: [{ inputs: [], name: 'CollectionNotExists', type: 'error' }, { inputs: [], name: 'CollectionNotWhitelisted', type: 'error' }, { inputs: [], name: 'InvalidCaller', type: 'error' }, { inputs: [], name: 'InvalidPrice', type: 'error' }, { inputs: [], name: 'ItemAlreadyExists', type: 'error' }, { inputs: [], name: 'LowMinimumPrice', type: 'error' }, { inputs: [], name: 'NonExistOffer', type: 'error' }, { inputs: [], name: 'NotActiveOffer', type: 'error' }, { inputs: [], name: 'NotForSale', type: 'error' }, { inputs: [], name: 'NotForTrade', type: 'error' }, { inputs: [], name: 'NotOfferMaker', type: 'error' }, { inputs: [], name: 'NotOnMarket', type: 'error' }, { inputs: [], name: 'NotOwnedBySeller', type: 'error' }, { inputs: [], name: 'NotSeller', type: 'error' }, { inputs: [], name: 'NotTokenOwner', type: 'error' }, { inputs: [], name: 'OfferAlreadyExists', type: 'error' }, { inputs: [], name: 'SellerNotAllowedToBuy', type: 'error' }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'uint8', name: 'version', type: 'uint8',
    }],
    name: 'Initialized',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'uint256', name: 'itemId', type: 'uint256',
    }],
    name: 'MarketItemCancelled',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'uint256', name: 'itemId', type: 'uint256',
    }, {
      indexed: true, internalType: 'address', name: 'nftContract', type: 'address',
    }, {
      indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }, {
      indexed: false, internalType: 'address', name: 'maker', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: 'price', type: 'uint256',
    }, {
      indexed: false, internalType: 'enum TradeType', name: 'tradeType', type: 'uint8',
    }, {
      indexed: false, internalType: 'uint256', name: 'minimum', type: 'uint256',
    }],
    name: 'MarketItemCreated',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'uint256', name: 'itemId', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'price', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'minimum', type: 'uint256',
    }, {
      indexed: false, internalType: 'enum TradeType', name: 'tradeType', type: 'uint8',
    }],
    name: 'MarketItemUpdated',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'uint256', name: 'offerId', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'marketItemId', type: 'uint256',
    }, {
      indexed: false, internalType: 'address', name: 'offerMaker', type: 'address',
    }, {
      indexed: false, internalType: 'address', name: 'nftContract', type: 'address',
    }, {
      indexed: false, internalType: 'uint256[]', name: 'tokensAttached', type: 'uint256[]',
    }, {
      indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
    }],
    name: 'TradeOfferMade',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'uint256', name: 'offerId', type: 'uint256',
    }, {
      indexed: false, internalType: 'address', name: 'nftContract', type: 'address',
    }, {
      indexed: false, internalType: 'uint256[]', name: 'tokensAttached', type: 'uint256[]',
    }, {
      indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
    }],
    name: 'TradeOfferUpdated',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'uint256[]', name: 'offerId', type: 'uint256[]',
    }],
    name: 'TradeOffersDeclined',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'uint256', name: 'marketItemId', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'tradeOfferId', type: 'uint256',
    }, {
      indexed: false, internalType: 'address', name: 'nftContract', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }, {
      indexed: false, internalType: 'address', name: 'seller', type: 'address',
    }, {
      indexed: false, internalType: 'address', name: 'buyer', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: 'price', type: 'uint256',
    }, {
      indexed: false, internalType: 'address', name: 'offeredNftContract', type: 'address',
    }, {
      indexed: false, internalType: 'uint256[]', name: 'tokensAttached', type: 'uint256[]',
    }, {
      indexed: false, internalType: 'uint256', name: 'marketFee', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'timestamp', type: 'uint256',
    }],
    name: 'TradeReleased',
    type: 'event',
  }, {
    inputs: [], name: 'adminAddr', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'itemId_', type: 'uint256' }], name: 'cancelMarketItem', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'offerId_', type: 'uint256' }], name: 'cancelOrDeclineOffer', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'collectionManager', outputs: [{ internalType: 'contract ICollectionManager', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'itemId_', type: 'uint256' }, { internalType: 'address', name: 'nftContract_', type: 'address' }, { internalType: 'uint256', name: 'tokenId_', type: 'uint256' }, { internalType: 'uint256', name: 'price_', type: 'uint256' }, { internalType: 'uint256', name: 'minimum_', type: 'uint256' }, { internalType: 'enum TradeType', name: 'tradeType', type: 'uint8' }], name: 'createMarketItem', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256[]', name: 'itemIds_', type: 'uint256[]' }], name: 'declineMultipleItems', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256[]', name: 'offerIds_', type: 'uint256[]' }], name: 'declineMultipleOffers', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'itemId_', type: 'uint256' }, { internalType: 'uint256', name: 'newPrice_', type: 'uint256' }, { internalType: 'uint256', name: 'minimum_', type: 'uint256' }, { internalType: 'enum TradeType', name: 'tradeType_', type: 'uint8' }], name: 'editMarketItem', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'offerId_', type: 'uint256' }, { internalType: 'uint256', name: 'amount_', type: 'uint256' }, { internalType: 'address', name: 'nftContract_', type: 'address' }, { internalType: 'uint256[]', name: 'tokensAttached_', type: 'uint256[]' }], name: 'editOffer', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'offset_', type: 'uint256' }, { internalType: 'uint256', name: 'limit_', type: 'uint256' }],
    name: 'fetchActiveMarketItems',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }, {
      components: [{ internalType: 'uint256', name: 'itemId', type: 'uint256' }, { internalType: 'address', name: 'nftContract', type: 'address' }, { internalType: 'uint256', name: 'tokenId', type: 'uint256' }, { internalType: 'address', name: 'seller', type: 'address' }, { internalType: 'address', name: 'buyer', type: 'address' }, { internalType: 'uint256', name: 'price', type: 'uint256' }, { internalType: 'enum TradeType', name: 'tradeType', type: 'uint8' }, { internalType: 'uint256', name: 'minimum', type: 'uint256' }, { internalType: 'uint256', name: 'modifiedAt', type: 'uint256' }, { internalType: 'uint256', name: 'soldAt', type: 'uint256' }, { internalType: 'uint256', name: 'canceledAt', type: 'uint256' }, { internalType: 'uint256', name: 'expiresAt', type: 'uint256' }], internalType: 'struct MarketItem[]', name: '', type: 'tuple[]',
    }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'offset_', type: 'uint256' }, { internalType: 'uint256', name: 'limit_', type: 'uint256' }],
    name: 'fetchActiveTradeOffers',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }, {
      components: [{ internalType: 'uint256', name: 'offerId', type: 'uint256' }, { internalType: 'uint256', name: 'marketItemId', type: 'uint256' }, { internalType: 'address', name: 'offerMaker', type: 'address' }, { internalType: 'address', name: 'nftContract', type: 'address' }, { internalType: 'uint256[]', name: 'tokensAttached', type: 'uint256[]' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }, { internalType: 'uint256', name: 'modifiedAt', type: 'uint256' }, { internalType: 'uint256', name: 'fulfilledAt', type: 'uint256' }, { internalType: 'uint256', name: 'canceledAt', type: 'uint256' }, { internalType: 'uint256', name: 'declinedAt', type: 'uint256' }, { internalType: 'uint256', name: 'expiresAt', type: 'uint256' }], internalType: 'struct TradeOffer[]', name: '', type: 'tuple[]',
    }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'offerId_', type: 'uint256' }], name: 'fulfillOffer', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'getMarketFee', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'getMarketFeeRecipient', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'idToMarketItems', outputs: [{ internalType: 'uint256', name: 'itemId', type: 'uint256' }, { internalType: 'address', name: 'nftContract', type: 'address' }, { internalType: 'uint256', name: 'tokenId', type: 'uint256' }, { internalType: 'address', name: 'seller', type: 'address' }, { internalType: 'address', name: 'buyer', type: 'address' }, { internalType: 'uint256', name: 'price', type: 'uint256' }, { internalType: 'enum TradeType', name: 'tradeType', type: 'uint8' }, { internalType: 'uint256', name: 'minimum', type: 'uint256' }, { internalType: 'uint256', name: 'modifiedAt', type: 'uint256' }, { internalType: 'uint256', name: 'soldAt', type: 'uint256' }, { internalType: 'uint256', name: 'canceledAt', type: 'uint256' }, { internalType: 'uint256', name: 'expiresAt', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'idToOffers', outputs: [{ internalType: 'uint256', name: 'offerId', type: 'uint256' }, { internalType: 'uint256', name: 'marketItemId', type: 'uint256' }, { internalType: 'address', name: 'offerMaker', type: 'address' }, { internalType: 'address', name: 'nftContract', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }, { internalType: 'uint256', name: 'modifiedAt', type: 'uint256' }, { internalType: 'uint256', name: 'fulfilledAt', type: 'uint256' }, { internalType: 'uint256', name: 'canceledAt', type: 'uint256' }, { internalType: 'uint256', name: 'declinedAt', type: 'uint256' }, { internalType: 'uint256', name: 'expiresAt', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'trustedForwarder_', type: 'address' }, { internalType: 'address', name: 'collectionManager_', type: 'address' }, { internalType: 'address', name: 'wethContract_', type: 'address' }, { internalType: 'address', name: 'recipient_', type: 'address' }, { internalType: 'uint256', name: 'feeAmount_', type: 'uint256' }], name: 'initialize', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'forwarder', type: 'address' }], name: 'isTrustedForwarder', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'offerId_', type: 'uint256' }, { internalType: 'uint256', name: 'itemId_', type: 'uint256' }, { internalType: 'address', name: 'nftContract_', type: 'address' }, { internalType: 'uint256[]', name: 'tokensAttached_', type: 'uint256[]' }, { internalType: 'uint256', name: 'amount_', type: 'uint256' }], name: 'makeOffer', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'itemId_', type: 'uint256' }], name: 'purchaseMarketItem', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'releasedTrades', outputs: [{ internalType: 'uint256', name: 'marketItemId', type: 'uint256' }, { internalType: 'uint256', name: 'tradeOfferId', type: 'uint256' }, { internalType: 'address', name: 'nftContract', type: 'address' }, { internalType: 'uint256', name: 'tokenId', type: 'uint256' }, { internalType: 'address', name: 'seller', type: 'address' }, { internalType: 'address', name: 'buyer', type: 'address' }, { internalType: 'uint256', name: 'price', type: 'uint256' }, { internalType: 'address', name: 'offeredNftContract', type: 'address' }, { internalType: 'uint256', name: 'marketFee', type: 'uint256' }, { internalType: 'uint256', name: 'timestamp', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_adminAddress', type: 'address' }], name: 'setAdminAddress', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'feeAmount_', type: 'uint256' }], name: 'setMarketFee', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'recipient_', type: 'address' }], name: 'setMarketFeeRecipient', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'forwarder_', type: 'address' }], name: 'setTrustedForwarder', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'trustedForwarder', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'versionRecipient', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'pure', type: 'function',
  }, {
    inputs: [], name: 'wethContract', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }],
};
