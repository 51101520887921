import React from 'react';
import './style.css';
import { IMAGES_URL } from 'src/constant/images';

export function NotificationModal({ open, onAccept, onReject }: { open: boolean, onAccept: () => void, onReject: () => void }) {
  return (
    <div className={`notification-modal ${open ? 'open' : ''}`}>
      <div className="flex">
        <img src={IMAGES_URL.CHICKEN_LOGO} alt="" />
        <div className="ml-4">Subscribe to our notifications for the latest news and updates. You can disable anytime.</div>
      </div>
      <div className="notification-actions">
        <a className="btn btn-later" onClick={() => onReject()}>Later</a>
        <a className="btn btn-subscribe" onClick={() => onAccept()}>Subscribe</a>
      </div>
    </div>
  );
}
