import { createAction } from '@reduxjs/toolkit';
import {
  BalanceRequest, RegisterRequest, LoginRequest, UpdateRequest,
} from './model';

export const getNonceAction = createAction(
  'userWallet/get_nonce',
  (userWalletId: string) => ({
    payload: userWalletId,
  }),
);

export const registerAction = createAction(
  'userWallet/register',
  (registerRequest: RegisterRequest) => ({
    payload: registerRequest,
  }),
);
export const loginAction = createAction(
  'userWallet/login',
  (loginRequest: LoginRequest) => ({
    payload: loginRequest,
  }),
);
export const logoutAction = createAction('userWallet/logout', () => ({
  payload: null,
}));

export const setBalancesAction = createAction(
  'userWallet/set_balances',
  (data: BalanceRequest) => ({
    payload: data,
  }),
);

export const updateAction = createAction(
  'userWallet/update',
  (data: UpdateRequest) => ({
    payload: data,
  }),
);

export const loadBalancesAction = createAction(
  'userWallet/load_balances',
  (payload: string) => ({
    payload,
  }),
);
