import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRoadMap } from 'src/models';
import { isRequiredBasicAuth } from 'src/utils/config';
import { BalanceRequest } from './model';

type UserState = {
  nonce?: any;
  userWallet: any;
  bawkBalance?: number;
  bawkEscrowBalance?: number;
  jewelsBalance?: number;
  logged: boolean;
  basicAuthLogged: boolean;
  userWalletId: string;
  loading: boolean;
  biconomyLoaded: boolean;
  publicUserWallet?: any;
  roadMaps: Array<IRoadMap>;
  loadedBalances: boolean;
};

const initialState: UserState = {
  userWalletId: localStorage.getItem('user_wallet_id'),
  userWallet: {},
  logged: !!localStorage.getItem('auth_token'),
  basicAuthLogged: isRequiredBasicAuth
    ? !!localStorage.getItem('basic_auth_token')
    : true,
  loading: false,
  biconomyLoaded: false,
  roadMaps: [],
  loadedBalances: false,
};

const userSlice = createSlice({
  name: 'userWallet',
  initialState,
  reducers: {
    setBiconomyLoaded(state, action: PayloadAction<boolean>) {
      state.biconomyLoaded = action.payload;
    },
    getNonceResponse(state, action: PayloadAction<any>) {
      state.nonce = action.payload;
      state.userWallet = null;
      state.logged = false;
    },
    resetNonce(state, action: PayloadAction<string>) {
      state.nonce = null;
    },
    registerResponse(state, action: PayloadAction<any>) {
      state.userWallet = action.payload;
      state.userWalletId = action.payload.id;
      state.logged = true;
    },
    registerResponseFailed(state, action) {
      state.logged = false;
      state.userWallet = null;
      state.nonce = null;
    },
    loginResponse(state, action: PayloadAction<any>) {
      state.logged = true;
      state.userWalletId = action.payload.id;
      state.userWallet = action.payload;
    },
    loginResponseFailed(state, action) {
      state.logged = false;
      state.userWallet = null;
      state.nonce = null;
    },
    logoutResponse(state, action: PayloadAction<any>) {
      state.userWallet = null;
      state.logged = false;
      state.nonce = null;
      state.bawkBalance = null;
      state.jewelsBalance = null;
      state.bawkEscrowBalance = null;
    },
    discordLoginRequest(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    discordLoginResponse(state, action: PayloadAction<any>) {
      state.userWallet = {
        ...state.userWallet,
        ...action.payload,
      };
      state.loading = false;
    },
    discordLoginError(state, action: PayloadAction<any>) {
      state.loading = false;
    },
    discordLogoutRequest(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    discordLogoutResponse(state, action: PayloadAction<any>) {
      state.userWallet = {
        ...state.userWallet,
        ...action.payload,
      };
      state.loading = false;
    },
    discordLogoutError(state, action: PayloadAction<any>) {
      state.loading = false;
    },
    setBalancesResponse(state, action: PayloadAction<BalanceRequest>) {
      state.bawkBalance = action.payload.bawkBalance;
      state.bawkEscrowBalance = action.payload.bawkEscrowBalance;
      state.jewelsBalance = action.payload.jewelsBalance;
      state.loadedBalances = true;
    },
    walletUpdateResponse(state, action: PayloadAction<any>) {
      state.logged = true;
      state.userWallet = action.payload;
    },
    getUserWalletRequest(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getUserWalletResponse(state, action: PayloadAction<any>) {
      state.userWallet = {
        ...state.userWallet,
        ...action.payload,
      };
      state.loading = false;
    },
    getPublicUserWalletRequest(state, action: PayloadAction<any>) {
      state.loading = true;
      state.publicUserWallet = undefined;
    },
    getPublicUserWalletResponse(state, action: PayloadAction<any>) {
      state.publicUserWallet = action.payload;
      state.loading = false;
    },
    basicLoginRequest(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    basicLoginSuccess(state, action) {
      state.basicAuthLogged = true;
      state.loading = false;
    },
    basicLoginFailed(state, action: PayloadAction<any>) {
      state.loading = false;
      state.basicAuthLogged = false;
    },
    getRoadMapsRequest() {
      //
    },
    getRoadMapsResponse(state, action: PayloadAction<Array<IRoadMap>>) {
      state.roadMaps = action.payload;
    },
  },
});

export const {
  getNonceResponse,
  resetNonce,
  registerResponse,
  registerResponseFailed,
  loginResponse,
  logoutResponse,
  loginResponseFailed,
  discordLoginRequest,
  discordLoginResponse,
  discordLoginError,
  discordLogoutRequest,
  discordLogoutResponse,
  discordLogoutError,
  setBalancesResponse,
  walletUpdateResponse,
  getUserWalletResponse,
  getUserWalletRequest,
  setBiconomyLoaded,
  basicLoginRequest,
  basicLoginSuccess,
  basicLoginFailed,
  getPublicUserWalletRequest,
  getPublicUserWalletResponse,
  getRoadMapsRequest,
  getRoadMapsResponse,
} = userSlice.actions;

export default userSlice.reducer;
