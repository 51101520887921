import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  takeLatest, call, put, select,
} from 'redux-saga/effects';
import { PaginatedRequest } from 'src/models';
import { apiService } from 'src/services/apiService';
import { toastService } from 'src/services/toastService';
import {
  getCoopsResponse,
  getCoopsRequest,
  getCoopsError,
  moveChickenToCoop,
  moveChickenToCoopResponse,
  moveChickenToCoopError,
  changeCoopNameRequest,
  changeCoopNameResponse,
  changeCoopNameError,
  getCoopChickensRequest,
  getCoopChickensResponse,
} from './reducer';

export function* getCoops() {
  const state = yield select();
  const isLoggedIn = state.userWallet?.logged;

  if (!isLoggedIn) {
    return;
  }

  try {
    const response = yield call(apiService.getCoops);

    yield put(getCoopsResponse(response?.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getCoopsError(err));
  }
}

export function* moveChicken({ payload }: any) {
  const { fromId, to, chicken } = payload;

  try {
    if (!to.id) {
      const res = yield call(apiService.removeChickenFromCoop, { coopId: fromId, chickenId: chicken.id });
      yield put(moveChickenToCoopResponse(res?.data));
    } else {
      const res = yield call(apiService.addChickenToCoop, { coopId: to.id, chickenId: chicken.id });
      yield put(moveChickenToCoopResponse(res?.data));
    }

    toast.success(`${chicken.name} moved to ${to.name}`);
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(moveChickenToCoopError(err));
  }
}

export function* changeCoopName({ payload }: any) {
  const { coopId, name } = payload;

  try {
    const res = yield call(apiService.updateCoop, coopId, { name });
    yield put(changeCoopNameResponse(res?.data));
    toast.success(`Changed coop name to ${name}`);
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(changeCoopNameError(err));
  }
}

function* getCoopChickens(action: PayloadAction<PaginatedRequest>) {
  try {
    const res = yield call(apiService.getChickens, action.payload);
    yield put(getCoopChickensResponse(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getCoopChickensResponse({
      rows: [],
      count: 0,
    }));
  }
}

export function* userEffects() {
  yield takeLatest(getCoopsRequest, getCoops);
  yield takeLatest(moveChickenToCoop, moveChicken);
  yield takeLatest(changeCoopNameRequest, changeCoopName);
  yield takeLatest(getCoopChickensRequest.type, getCoopChickens);
}

const userSagas = [call(userEffects)];

export default userSagas;
