import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedRequest } from 'src/models';
import * as _ from 'lodash';
import { SeasonRankingsRequest } from 'src/models/season.type';

type SeasonsState = {
  seasons: any
  season?: any
  loading: boolean
  seasonRankings: any
  seasonRankingsLoading: boolean
};

const initialState: SeasonsState = {
  loading: false,
  seasons: {
    rows: [],
    count: 0,
  },
  seasonRankings: {
    rows: [],
    count: 0,
  },
  seasonRankingsLoading: false,
};

const seasonSlice = createSlice({
  name: 'season',
  initialState,
  reducers: {
    getSeasonRequest(state, action: PayloadAction<any>) {
      state.loading = true;
      state.season = undefined;
    },
    getSeasonSuccess(state, action: PayloadAction<any>) {
      state.season = action.payload;
      state.loading = false;
    },
    getSeasonFailed(state, action: PayloadAction<any>) {
      state.loading = false;
    },
    getSeasonsRequest(state, action: PayloadAction<PaginatedRequest>) {
      if (action.payload.page === 1) {
        state.seasons = {
          rows: [],
          count: 0,
        };
      }
      state.loading = true;
    },
    getSeasonsSuccess(state, action: PayloadAction<any>) {
      const seasons = _.uniqBy([...state.seasons.rows, ...action.payload.rows], 'id');
      const isReached = !action.payload.rows?.length;

      state.seasons = {
        rows: seasons,
        count: action.payload.count,
        isReached,
      };

      state.loading = false;
    },
    getSeasonsFailed(state, action: PayloadAction<any>) {
      state.seasons = {
        rows: state.seasons.rows,
        count: state.seasons.count,
        isReached: true,
      };

      state.loading = false;
    },
    getSeasonRankingsRequest(state, action: PayloadAction<SeasonRankingsRequest>) {
      if (action.payload.page === 1) {
        state.seasonRankings = {
          rows: [],
          count: 0,
        };
      }
      state.seasonRankingsLoading = true;
    },
    getSeasonRankingsSuccess(state, action: PayloadAction<any>) {
      const rankings = _.uniqBy([...state.seasonRankings.rows, ...action.payload.rows], 'id');
      const isReached = !action.payload.rows?.length;

      state.seasonRankings = {
        rows: rankings,
        count: action.payload.count,
        isReached,
      };

      state.seasonRankingsLoading = false;
    },
    getSeasonRankingsFailed(state, action: PayloadAction<any>) {
      state.seasonRankings = {
        rows: state.seasonRankings.rows,
        count: state.seasonRankings.count,
        isReached: true,
      };

      state.seasonRankingsLoading = false;
    },
    clearSeason(state) {
      state.season = undefined;
    },
  },
});

export const {
  getSeasonRequest,
  getSeasonSuccess,
  getSeasonFailed,
  getSeasonsRequest,
  getSeasonsFailed,
  getSeasonsSuccess,
  getSeasonRankingsRequest,
  getSeasonRankingsSuccess,
  getSeasonRankingsFailed,
  clearSeason,
} = seasonSlice.actions;

export default seasonSlice.reducer;
