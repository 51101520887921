import React from 'react';
import { Modal } from 'react-bootstrap';
import { Loading } from 'src/components/loading';

export function LoadingModal({
  open,
  title,
  description,
  tip,
  color,
}: {
  open: boolean,
  title: string;
  description: string;
  tip: string
  color?: string
}) {
  return (
    <Modal
      centered
      dialogClassName="complete-transaction"
      show={open}
      backdrop="static"
    >
      <Modal.Body>
        <h3
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        <Loading size={150} color={color} />
        <h5
          dangerouslySetInnerHTML={{
            __html: tip,
          }}
        />
      </Modal.Body>
    </Modal>
  );
}
