import { createSelector } from 'reselect';

import { RootState } from '../common/reducer';

export const selectBawkStakingState = (rootState: RootState) => rootState.bawkStaking;

export const bawkStakingIsLoadingSelector = createSelector([selectBawkStakingState], (state) => state.isStatsLoading || false);
export const bawkStakingStatsSelector = createSelector([selectBawkStakingState], (state) => state.bawkStakingStats || {});

export const bawkStakingTypeSelector = createSelector([selectBawkStakingState], (state) => state.bawkStaking?.type);
export const bawkStakingGasLimitSelector = createSelector([selectBawkStakingState], (state) => state.bawkStaking?.gasLimit);
export const bawkStakingStatusSelector = createSelector([selectBawkStakingState], (state) => state.bawkStaking?.status);
export const bawkStakingCouponSelector = createSelector([selectBawkStakingState], (state) => state.bawkStaking?.coupon);
