import Web3 from 'web3';
import { Emitter } from 'mitt';
import { ChainId } from 'src/constant/chainId';
import { isEqual } from 'lodash';
import { ProviderEvents, ProviderEventsEnum } from './events';
import { IProvider } from './provider.interface';
import { ProviderName } from './provider.enum';
import { metamaskChainRequests } from './metamaskChainRequest';

export class MetamaskProvider implements IProvider {
  name = ProviderName.metamask;

  web3: Web3;

  accounts: string[] = [];

  constructor(private ethereum: any, private events: Emitter<ProviderEvents>) {
    this.ethereum = ethereum;
    this.web3 = new Web3(this.ethereum);
    this.ethereum.on('accountsChanged', (accounts: string[]) => {
      if (!this.accounts.length || isEqual(this.accounts, accounts)) {
        return;
      }

      this.events.emit(ProviderEventsEnum.AccountsChanged, accounts);
    });
  }

  static createInstance(
    ethereum: any,
    events: Emitter<ProviderEvents>,
  ): IProvider {
    return new MetamaskProvider(ethereum, events);
  }

  async getAccounts(): Promise<string[]> {
    this.accounts = await this.web3.eth.getAccounts();

    return this.accounts;
  }

  async login(): Promise<string[]> {
    this.accounts = await this.ethereum.request({
      method: 'eth_requestAccounts',
    });
    return this.accounts;
  }

  // eslint-disable-next-line class-methods-use-this
  async logout() {
    //
  }

  async sign(chainId: ChainId, account: string, message: string) {
    return this.ethereum.request({
      method: 'personal_sign',
      params: [message, account],
    });
  }

  async signTypedDataV4(chainId: ChainId, account: string, message: string) {
    return this.ethereum.request({
      method: 'eth_signTypedData_v4',
      params: [account, message],
    });
  }

  async sendTransaction(chainId: ChainId, account: string, tx: any) {
    await this.checkNetwork(chainId);
    return this.ethereum.request({
      method: 'eth_sendTransaction',
      params: [tx],
    });
  }

  async checkNetwork(chainId: ChainId) {
    if (this.ethereum.networkVersion === chainId) {
      return;
    }

    const chainIdHex = Web3.utils.toHex(chainId);

    try {
      await this.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIdHex }],
      });
    } catch (switchError) {
      const request = metamaskChainRequests[chainId];
      if (switchError.code === 4902 && request) {
        await this.ethereum.request(request);
      }

      throw switchError;
    }
  }
}
