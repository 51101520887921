import { createSelector } from 'reselect';
import { RootState } from '../common/reducer';

export const selectLaneState = (rootState: RootState) => rootState.lane;

export const isAssigningSelector = createSelector([selectLaneState], (state) => state.isAssigning);

export const isAssignedSelector = createSelector([selectLaneState], (state) => state.isAssigned);

export const isCheckedAssignSelector = createSelector([selectLaneState], (state) => state.isCheckedAssign);

export const raceGasLimitSelector = createSelector([selectLaneState], (state) => state.gasLimit);
export const raceCouponSelector = createSelector([selectLaneState], (state) => state.coupon);

export const isAssigningPaidRaceSelector = createSelector([selectLaneState], (state) => state.isAssigningPaidRace);
