import { Currency } from 'src/models/currency.enum';

/* eslint-disable class-methods-use-this */
export class LocalStorageService {
  private static instance: LocalStorageService;

  private static chickenderbyPrefix = 'chickenderby';

  static getInstance() {
    if (!LocalStorageService.instance) {
      LocalStorageService.instance = new LocalStorageService();
    }

    return LocalStorageService.instance;
  }

  private getFirstTimePreRegisteredLandKey(name: string) {
    return `${LocalStorageService.chickenderbyPrefix}/firstTimePreRegisteredLand_${name}`;
  }

  private getFirstTimePreRegisteredLand(name: string) {
    const key = this.getFirstTimePreRegisteredLandKey(name);
    const firstTimePreRegisteredLand = localStorage.getItem(key);

    return Number(firstTimePreRegisteredLand || 0);
  }

  isFirstTimePreRegisteredLand(name: string) {
    const firstTimePreRegisteredLand = this.getFirstTimePreRegisteredLand(name);
    return firstTimePreRegisteredLand === 1;
  }

  setFirstTimePreRegisteredLand(name: string) {
    const firstTimePreRegisteredLand = this.getFirstTimePreRegisteredLand(name);
    if (firstTimePreRegisteredLand <= 1) {
      const key = this.getFirstTimePreRegisteredLandKey(name);
      localStorage.setItem(key, `${firstTimePreRegisteredLand + 1}`);
    }
  }

  getShowBalanceOnHeader() {
    const showBalanceOnHeader = localStorage.getItem('SHOW_BALANCE_ON_HEADER') || 1;
    return Boolean(Number(showBalanceOnHeader));
  }

  setShowBalanceOnHeader(isShowBalance: boolean) {
    localStorage.setItem('SHOW_BALANCE_ON_HEADER', isShowBalance ? '1' : '0');
  }

  getCurrency(): Currency {
    return (localStorage.getItem('ChickenDerby/Currency') as Currency) || Currency.JEWELS;
  }

  setCurrency(currency: Currency) {
    localStorage.setItem('ChickenDerby/Currency', currency);
  }
}
