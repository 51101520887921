import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  takeLatest, call, put, select,
} from 'redux-saga/effects';
import { PaginatedRequest } from 'src/models';
import { apiService } from 'src/services/apiService';
import { toastService } from 'src/services/toastService';

import { ChickenStatsRequest } from 'src/models/chicken-stats-request.type';
import { ChickenStatsType } from 'src/models/chicken-stats-type.enum';
import {
  setChickenLoadingAction,
} from './action';
import {
  getChickenRequest,
  getChickenResponse,
  setChickensLoadingResponse,

  updateChickenNameRequest,
  updateChickenNameSuccess,
  updateChickenNameFailure,
  getChickenTradeOffersRequest,
  getChickenTradeOffersResponse,
  getChickenTradeActivitiesRequest,
  getChickenTradeActivitiesResponse,
  getChickenTournamentBadgesRequest,
  getChickenSeasonBadgesRequest,
  getChickenTournamentBadgesResponse,
  getChickenSeasonBadgesResponse,
  getChickensRequest,
  getChickensResponse,
  getChickenGenealogyRequest,
  getChickenGenealogyResponse,
  getChickenOverallStatsRequest,
  getChickenTerrainStatsRequest,
  getChickenDistanceStatsRequest,
  getChickenOverallStatsResponse,
  getChickenTerrainStatsResponse,
  getChickenDistanceStatsResponse,
  getChickenContractStatsRequest,
  getChickenContractStatsResponse,
} from './reducer';

function* getChicken(info: any) {
  try {
    const { chickenId } = info.payload;

    if (!chickenId) {
      return;
    }

    const res = yield call(apiService.getChicken, chickenId);

    yield put(getChickenResponse(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
  }
}

export function* setLoadingChicken(data) {
  yield put(setChickensLoadingResponse(data.payload));
}

function* updateChickenName(info: any) {
  try {
    const {
      chickenId,
      name,
    } = info.payload;

    const res = yield call(
      apiService.updateChickenName,
      chickenId,
      name,
    );

    yield put(updateChickenNameSuccess(res.data));
    toast.success(`Updated chicken name to ${name}`);
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(updateChickenNameFailure(err));
  }
}

function* getChickenTradeOffers(action) {
  try {
    const res = yield call(apiService.getChickenTradeOffers, action.payload);
    yield put(getChickenTradeOffersResponse(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getChickenTradeOffersResponse({
      rows: [],
      count: 0,
    }));
  }
}

function* getChickenTradeActivities(action: PayloadAction<PaginatedRequest>) {
  try {
    const res = yield call(apiService.getChickenTradeActivities, action.payload);
    yield put(getChickenTradeActivitiesResponse(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getChickenTradeActivitiesResponse({
      rows: [],
      count: 0,
    }));
  }
}

function* getChickenTournamentBadges(action: PayloadAction<PaginatedRequest>) {
  try {
    const state = yield select();
    const chicken = state.chicken?.chicken;

    if (!chicken) {
      return;
    }

    const res = yield call(apiService.getChickenTournamentBadges, chicken.id, action.payload);
    yield put(getChickenTournamentBadgesResponse(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getChickenTournamentBadgesResponse({
      rows: [],
      count: 0,
    }));
  }
}

function* getChickenSeasonBadges(action: PayloadAction<PaginatedRequest>) {
  try {
    const state = yield select();
    const chicken = state.chicken?.chicken;

    if (!chicken) {
      return;
    }

    const res = yield call(apiService.getChickenSeasonBadges, chicken.id, action.payload);
    yield put(getChickenSeasonBadgesResponse(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getChickenSeasonBadgesResponse({
      rows: [],
      count: 0,
    }));
  }
}

function* getChickens(action: PayloadAction<PaginatedRequest>) {
  try {
    const res = yield call(apiService.getChickens, action.payload);
    yield put(getChickensResponse(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getChickensResponse({
      rows: [],
      count: 0,
    }));
  }
}

function* getChickenGenealogy(action: PayloadAction<number>) {
  try {
    const res = yield call(apiService.getChickenGenealogy, action.payload);
    yield put(getChickenGenealogyResponse(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getChickenGenealogyResponse([]));
  }
}

function* getChickenOverallStats(action: PayloadAction<ChickenStatsRequest>) {
  try {
    const { chickenId, filter } = action.payload;
    const res = yield call(apiService.getChickenRaceHistory, chickenId, ChickenStatsType.All, filter);
    yield put(getChickenOverallStatsResponse(res?.data?.stats || []));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getChickenOverallStatsResponse([]));
  }
}

function* getChickenTerrainStats(action: PayloadAction<ChickenStatsRequest>) {
  try {
    const { chickenId, filter } = action.payload;
    const res = yield call(apiService.getChickenRaceHistory, chickenId, ChickenStatsType.Terrain, filter);
    yield put(getChickenTerrainStatsResponse(res?.data?.stats || []));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getChickenTerrainStatsResponse([]));
  }
}

function* getChickenDistanceStats(action: PayloadAction<ChickenStatsRequest>) {
  try {
    const { chickenId, filter } = action.payload;
    const res = yield call(apiService.getChickenRaceHistory, chickenId, ChickenStatsType.Distance, filter);
    yield put(getChickenDistanceStatsResponse(res?.data?.stats || []));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getChickenDistanceStatsResponse([]));
  }
}

function* getChickenContractStats(action: PayloadAction<void>) {
  try {
    const res = yield call(apiService.getChickenContractStats);
    yield put(getChickenContractStatsResponse(res?.data || null));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getChickenContractStatsResponse(null));
  }
}

export function* userEffects() {
  yield takeLatest(getChickenRequest.type, getChicken);
  yield takeLatest(setChickenLoadingAction.type, setLoadingChicken);
  yield takeLatest(updateChickenNameRequest.type, updateChickenName);
  yield takeLatest(getChickenTradeOffersRequest.type, getChickenTradeOffers);
  yield takeLatest(getChickenTradeActivitiesRequest.type as any, getChickenTradeActivities);
  yield takeLatest(getChickenTournamentBadgesRequest.type as any, getChickenTournamentBadges);
  yield takeLatest(getChickenSeasonBadgesRequest.type as any, getChickenSeasonBadges);
  yield takeLatest(getChickensRequest.type, getChickens);
  yield takeLatest(getChickenGenealogyRequest.type, getChickenGenealogy);
  yield takeLatest(getChickenOverallStatsRequest.type, getChickenOverallStats);
  yield takeLatest(getChickenTerrainStatsRequest.type, getChickenTerrainStats);
  yield takeLatest(getChickenDistanceStatsRequest.type, getChickenDistanceStats);
  yield takeLatest(getChickenContractStatsRequest.type, getChickenContractStats);
}

const chikenSagas = [call(userEffects)];

export default chikenSagas;
