import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Web3 from 'web3';
import { useDispatch, useSelector } from 'react-redux';
import { useWalletContext } from 'src/contexts/walletProvider';
import {
  biconomyLoadedSelector, isLoggedInSelector, userWalletIdSelector,
} from 'src/store/userWallet/selector';
import { normalizeNumber } from 'src/utils/utils';
import { fusionContractService } from 'src/services/contracts';
import { toast } from 'react-toastify';
import { LoadingModal } from 'src/modals/loadingModal';
import { checkIsDeployingRequest } from 'src/store/races/action';
import { useTranslation } from 'react-i18next';
import { WETH_CONTRACT } from 'src/abis';
import {
  checkLastSerumMintRequest,
  checkSerumMintRequest,
  clearIsMinted,
  serumMintRequest,
} from 'src/store/serum/reducer';
import { FUSION_SERUM_ID } from 'src/utils/config';
import {
  isCheckedSerumSelector, isCheckingSerumMintSelector, isMintedSerumSelector, isMintingSerumSelector, serumMintGasLimitSelector,
} from 'src/store/serum/selector';

export function SerumMint() {
  const dispatch = useDispatch();
  const { onLogin } = useWalletContext();
  const { t } = useTranslation('translation', { keyPrefix: 'PAGES.MINT_SERUM' });

  const biconomyLoaded = useSelector(biconomyLoadedSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userWalletId = useSelector(userWalletIdSelector);
  const isMinting = useSelector(isMintingSerumSelector);
  const isMinted = useSelector(isMintedSerumSelector);
  const isCheckingMint = useSelector(isCheckingSerumMintSelector);
  const isCheckedMint = useSelector(isCheckedSerumSelector);
  const gasLimit = useSelector(serumMintGasLimitSelector);

  const [amount, setAmount] = useState(1);
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingApproving, setIsWaitingApproving] = useState(false);
  const [isConfirmingMint, setIsConfirmingMint] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [price, setPrice] = useState('0');
  const [serumCount, setSerumCount] = useState(0);
  const [timeoutValue, setTimeoutValue] = useState<any>();

  const priceEther = useMemo(() => Web3.utils.fromWei(price, 'ether'), [price]);
  const totalPrice = useMemo(() => Web3.utils.toBN(price).mul(Web3.utils.toBN(amount)), [price, amount]);
  const isClosed = useMemo(() => totalSupply >= maxSupply, [totalSupply, maxSupply]);
  const available = useMemo(() => maxSupply - totalSupply, [maxSupply, totalSupply]);

  const [startPrice] = useState(0.0035);
  const [firstStageAmount] = useState(10000);
  const stage = useMemo(() => {
    if (totalSupply < firstStageAmount) {
      return 1;
    }
    return Math.ceil((totalSupply + 1 - firstStageAmount) / 1000) + 1;
  }, [totalSupply, firstStageAmount]);

  const nextStage = useMemo(() => stage + 1, [stage]);

  const getStagePrice = useCallback((stageValue: number) => {
    let value = startPrice;
    let stageAmount = firstStageAmount;

    for (let i = 0; i < (stageValue - 1); i += 1) {
      if (stageAmount < 20000) {
        value += 0.0001;
      } else {
        value += 0.00005;
      }

      stageAmount += 1000;
    }

    return value;
  }, [startPrice, firstStageAmount]);

  const nextStagePrice = useMemo(() => getStagePrice(nextStage), [nextStage, getStagePrice]);
  const currentStageStart = useMemo(() => {
    if (stage === 1) {
      return 0;
    }

    return firstStageAmount + (stage - 2) * 1000;
  }, [stage, firstStageAmount]);
  const currentStageEnd = useMemo(() => {
    if (stage === 1) {
      return firstStageAmount;
    }

    return firstStageAmount + (stage - 1) * 1000;
  }, [stage, firstStageAmount]);

  const solidPercent = useMemo(() => ((totalSupply - currentStageStart) / (currentStageEnd - currentStageStart)) * 100, [currentStageStart, currentStageEnd, totalSupply]);
  const remainingPercent = useMemo(() => ((currentStageEnd - totalSupply) / (currentStageEnd - currentStageStart)) * 100, [currentStageStart, currentStageEnd, totalSupply]);
  const isLastStage = useMemo(() => currentStageEnd === maxSupply, [currentStageEnd, maxSupply]);

  const stopInterval = () => {
    clearTimeout(timeoutValue);
  };

  const onChangeAmount = (value: number) => {
    const startTime = new Date();
    const timeout1 = setTimeout(function runIncrement() {
      setAmount((prevNumber) => {
        const updatedValue = prevNumber + value;

        if (updatedValue < 1) {
          return 1;
        }

        if (updatedValue > available) {
          return available;
        }

        return updatedValue;
      });
      const now = new Date();
      const delta = now.getTime() - startTime.getTime();
      const n = 2 + (1 / (2.1 ** (delta / 1000 - (2 * Math.log(10)) / Math.log(2.1))));// Whatever
      const timeout = setTimeout(runIncrement, parseInt(n.toString()));
      setTimeoutValue(timeout);
    }, 500);
    setTimeoutValue(timeout1);
  };

  const getSerumContractInfo = useCallback(async () => {
    if (!biconomyLoaded) {
      return;
    }

    try {
      const res = await fusionContractService.getSerumsInfo(FUSION_SERUM_ID);
      setIsActive(res[0]);
      setPrice(res[1]);
      setTotalSupply(Number(res[2]));
      setMaxSupply(Number(res[3]));
      setIsReady(true);
    } catch (err) {
      //
    }
  }, [biconomyLoaded]);

  const loadOwnerSerumCount = useCallback(async () => {
    if (!biconomyLoaded || !isLoggedIn || !userWalletId) {
      return;
    }

    try {
      const count = await fusionContractService.getSerumCount(userWalletId, FUSION_SERUM_ID);
      setSerumCount(count);
    } catch (err) {
      //
    }
  }, [biconomyLoaded, isLoggedIn, userWalletId]);

  const increaseAmount = (e) => {
    e.stopPropagation();
    clearTimeout(timeoutValue);

    if (isLoading) {
      return;
    }
    if (amount === available) {
      return;
    }

    setAmount(amount + 1);
  };

  const decreaseAmount = (e) => {
    e.stopPropagation();
    clearTimeout(timeoutValue);

    if (isLoading) {
      return;
    }

    if (amount === 1) {
      return;
    }

    setAmount(amount - 1);
  };

  const onMint = async () => {
    if (!isActive || isClosed) {
      return;
    }

    if (!isLoggedIn) {
      onLogin();
      return;
    }

    if (!isReady || isLoading) {
      return;
    }

    const weth = await fusionContractService.getTokenBalance(userWalletId, WETH_CONTRACT.address);
    const balance = Web3.utils.fromWei(weth, 'wei');

    if (Web3.utils.toBN(balance).lt(totalPrice)) {
      toast(<InefficientFundsMsg balance={balance} fee={totalPrice.toString()} count={amount} />, { type: 'warning' });

      return;
    }

    const allowance = await fusionContractService.getTokenAllowance(userWalletId, WETH_CONTRACT.address);

    const approved = Web3.utils
      .toBN(allowance)
      .gte(totalPrice);

    try {
      if (!approved) {
        setIsApproving(true);
        await fusionContractService.approveWethContract(userWalletId, () => {
          setIsWaitingApproving(true);
          setIsApproving(false);
        });
      }

      dispatch(checkSerumMintRequest({
        serumId: FUSION_SERUM_ID,
        amount,
      }));
    } catch (err) {
      dispatch(clearIsMinted());
      toast.error(err.message);
    } finally {
      setIsApproving(false);
      setIsWaitingApproving(false);
    }
  };

  const requestMintSerums = useCallback(async () => {
    if (!isCheckedMint || !gasLimit || !userWalletId || !amount) {
      return;
    }

    try {
      setIsConfirmingMint(true);
      const { signature, deadline } = await fusionContractService.mint(userWalletId, FUSION_SERUM_ID, amount, gasLimit);

      dispatch(serumMintRequest({
        serumId: FUSION_SERUM_ID,
        amount,
        signature,
        deadline,
        gasLimit,
      }));
    } catch (err) {
      dispatch(clearIsMinted());
      toast.error(err.message);
    } finally {
      setIsConfirmingMint(false);
    }
  }, [dispatch, isCheckedMint, gasLimit, userWalletId, amount]);

  useEffect(() => {
    if (isMinted) {
      dispatch(clearIsMinted());
      getSerumContractInfo();
      loadOwnerSerumCount();
    }
  }, [dispatch, isMinted, getSerumContractInfo, loadOwnerSerumCount]);

  useEffect(() => {
    dispatch(checkIsDeployingRequest());
  }, [dispatch, isApproving, isMinting, isMinted, isConfirmingMint]);

  useEffect(() => {
    requestMintSerums();
  }, [requestMintSerums]);

  useEffect(() => {
    getSerumContractInfo();
  }, [getSerumContractInfo]);

  useEffect(() => {
    loadOwnerSerumCount();
  }, [loadOwnerSerumCount]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(checkLastSerumMintRequest(false));
    }
  }, [dispatch, isLoggedIn]);

  const isLoading = useMemo(() => isApproving || isWaitingApproving || isConfirmingMint || isMinting || isCheckingMint, [isApproving, isWaitingApproving, isConfirmingMint, isMinting, isCheckingMint]);
  const loadingTitle = useMemo(() => {
    if (isApproving) {
      return 'Please approve our serum mint contract\n in MetaMask';
    }

    if (isWaitingApproving) {
      return 'Approving our serum mint contract';
    }

    if (isConfirmingMint) {
      return `Please confirm the transaction to\n mint ${amount} serum(s)`;
    }

    if (isMinting) {
      return 'Transaction Confirmed';
    }

    return '';
  }, [isApproving, isWaitingApproving, isConfirmingMint, isMinting, amount]);

  const loadingDescription = useMemo(() => {
    if (isApproving) {
      return 'To approve our serum mint contract click SIGN\n in the MetaMask window';
    }

    if (isWaitingApproving) {
      return 'Approving our serum mint contract. Please wait.';
    }

    if (isConfirmingMint) {
      return 'To complete your transaction click SIGN\n in the MetaMask window';
    }

    if (isMinting) {
      return 'Your serums are being minted. Please wait.';
    }

    return '';
  }, [isApproving, isWaitingApproving, isConfirmingMint, isMinting]);

  const loadingTip = useMemo(() => {
    if (isApproving) {
      return 'Tip: If MetaMask does not appear automatically,\n click on the MetaMask icon in your browser notification toolbar';
    }

    if (isWaitingApproving) {
      return 'Tip: This can take some time, depending on network congestion.';
    }

    if (isConfirmingMint) {
      return 'Tip: If MetaMask does not appear automatically,\n click on the MetaMask icon in your browser notification toolbar';
    }

    if (isMinting) {
      return 'Tip: This can take some time, depending on network congestion.';
    }

    return '';
  }, [isApproving, isWaitingApproving, isConfirmingMint, isMinting]);

  if (!isReady) {
    return <div className="flex-1" />;
  }

  return (
    <div className="mint-serum">
      <div className="mint-serum-warp">
        <div className="serum-container">
          <div className="serum-video">
            <iframe src="https://www.youtube.com/embed/Um7GCjLT33A?rel=0&controls=0&loop=1&playlist=Um7GCjLT33A" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
          </div>
          <h4 className="mt-3">
            Unleash the Power of Fusion!
          </h4>
          <div className="flex items-center justify-center mt-3">
            <ul className="mt-2" style={{ textAlign: 'left' }}>
              <li>{`Over ${normalizeNumber(totalSupply, 0)} of the ${normalizeNumber(maxSupply, 0)} serums minted.`}</li>
              <li>20% of funds towards WETH rewards.</li>
              <li>Fixed mint supply and burned after fusing.</li>
              <li>There is no gas, we got your gas fees covered.</li>
            </ul>
          </div>

        </div>
        <div className="serum-container items-center">
          <h1>
            {isClosed ? t('SERUM_MINT_CLOSED') : isActive ? t('SERUM_MINT_LIVE_NOW') : t('DROP_COMMING_SOON')}
          </h1>
          {!isClosed && (
            <h4 className="mt-4 mb-3">
              Stage
              {' '}
              {stage}
            </h4>
          )}
          {!isClosed && (
            <div className="serum-mint-stage">
              <div className="serum-mint-stage-container">
                <p>
                  {normalizeNumber(solidPercent, 2)}
                  %
                  {' '}
                  <span>Sold</span>
                </p>
                <p>
                  {normalizeNumber(remainingPercent, 2)}
                  %
                  {' '}
                  <span>Remaining</span>
                </p>
                <div className="serum-mint-stage-progress" style={{ width: `${solidPercent}%` }} />
              </div>
              <div className="flex stage-price">
                <div>
                  Current Stage:
                  {' '}
                  <b>
                    {Number(Number(priceEther).toFixed(6))}
                    {' '}
                    WETH
                  </b>
                </div>
                {!isLastStage && (
                  <div>
                    Next Stage:
                    {' '}
                    <b>
                      {Number(nextStagePrice.toFixed(6))}
                      {' '}
                      WETH
                    </b>
                  </div>
                )}

              </div>
            </div>
          )}
          <div className="mt-4 flex items-center justify-center">
            <a
              className="btn btn-minus"
              onClick={decreaseAmount}
              onMouseUp={stopInterval}
              onMouseLeave={stopInterval}
              onMouseDown={() => onChangeAmount(-1)}
              onTouchEnd={stopInterval}
              onTouchCancel={stopInterval}
              onContextMenu={(e) => e.preventDefault()}
              onTouchStart={(e) => {
                e.stopPropagation();
                onChangeAmount(-1);
              }}
            >
              -
            </a>
            <h5 className="amount">{amount}</h5>
            <a
              className="btn btn-plus"
              onClick={increaseAmount}
              onMouseUp={stopInterval}
              onMouseLeave={stopInterval}
              onMouseDown={() => onChangeAmount(1)}
              onTouchEnd={stopInterval}
              onTouchCancel={stopInterval}
              onContextMenu={(e) => e.preventDefault()}
              onTouchStart={(e) => {
                e.stopPropagation();
                onChangeAmount(1);
              }}
            >
              +
            </a>
          </div>
          <div className="mt-4 flex items-center justify-around">
            <p className="flex-1 text-center">
              {t('TOTAL_COST')}
              {' '}
              <b>
                {Number((amount * Number(priceEther)).toFixed(6))}
                {' '}
                WETH
              </b>
            </p>
          </div>
          <a className={`mt-3 btn btn-mint ${!isActive || isClosed ? 'disabled' : ''}`} onClick={onMint}>{t('MINT_SERUM')}</a>
          {isLoggedIn && (
            <div className="mt-3 flex flex-column items-center justify-around">
              <div className="flex-1 text-center">
                <span>
                  {t('YOU_HAVE_SERUM', { serumCount })}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <LoadingModal
        open={isLoading}
        title={loadingTitle}
        description={loadingDescription}
        tip={loadingTip}
      />
    </div>
  );
}

function InefficientFundsMsg({ balance, fee, count }: { balance: string, fee: string, count: number }) {
  const balanceText = normalizeNumber(Number(Web3.utils.fromWei(balance, 'ether')));
  const feeText = normalizeNumber(Number(Web3.utils.fromWei(fee, 'ether')));
  const { t } = useTranslation('translation', { keyPrefix: 'PAGES.MINT_SERUM' });

  return (
    <p>
      {t('YOU_HAVE_INSUFFICIENT', { balanceText, count, feeText })}
      <br />
      {t('YOU_MAY_NEED')}
      {' '}
      <a href="https://bridge.umbria.network" target="_blank" rel="noreferrer">https://bridge.umbria.network</a>
    </p>
  );
}
