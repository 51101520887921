import { IContract } from 'src/models';
import { CHICKEN_STAKING_CONTRACT_ADDRESS } from 'src/utils/config';

export const CHICKEN_STAKING_CONTRACT: IContract = {
  address: CHICKEN_STAKING_CONTRACT_ADDRESS,
  abi: [{ inputs: [], stateMutability: 'nonpayable', type: 'constructor' }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'previousAdmin', type: 'address',
    }, {
      indexed: false, internalType: 'address', name: 'newAdmin', type: 'address',
    }],
    name: 'AdminChanged',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'beacon', type: 'address',
    }],
    name: 'BeaconUpgraded',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: '', type: 'address',
    }],
    name: 'EscrowRewardUpdated',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'uint8', name: 'version', type: 'uint8',
    }],
    name: 'Initialized',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferStarted',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: '', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: '', type: 'uint256',
    }],
    name: 'RewardPaid',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: '', type: 'address',
    }, {
      indexed: false, internalType: 'uint256[]', name: '', type: 'uint256[]',
    }],
    name: 'Staked',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: '', type: 'address',
    }, {
      indexed: false, internalType: 'uint256[]', name: '', type: 'uint256[]',
    }],
    name: 'Unstaked',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'implementation', type: 'address',
    }],
    name: 'Upgraded',
    type: 'event',
  }, {
    inputs: [], name: 'acceptOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'admin', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'bawkEscrow', outputs: [{ internalType: 'contract BawkEscrow', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_user', type: 'address' }], name: 'calculateRewards', outputs: [{ internalType: 'uint256', name: '_rewards', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'calculateTakeback', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'chickenIdToInfo', outputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }, { internalType: 'address', name: 'staker', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '', type: 'address' }, { internalType: 'uint256', name: '', type: 'uint256' }], name: 'chickensStaked', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_account', type: 'address' }], name: 'claimRewards', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'crun', outputs: [{ internalType: 'contract ICRun', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_crun', type: 'address' }, { internalType: 'address', name: '_treasury', type: 'address' }], name: 'initialize', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'forwarder', type: 'address' }], name: 'isTrustedForwarder', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'lastTimeRewardApplicable', outputs: [{ internalType: 'uint256', name: '_lastRewardsApplicable', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'lastUpdateTime', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '', type: 'address' }, { internalType: 'address', name: '', type: 'address' }, { internalType: 'uint256', name: '', type: 'uint256' }, { internalType: 'bytes', name: '', type: 'bytes' }], name: 'onERC721Received', outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'pendingOwner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'periodFinish', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'proxiableUUID', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'rewardAmountForDuration', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'rewardPerChicken', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'rewardPerChickenStored', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'rewardRate', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'rewards', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'rewardsDuration', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_admin', type: 'address' }], name: 'setAdmin', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_bawkEscrow', type: 'address' }], name: 'setBawkEscrow', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_duration', type: 'uint256' }], name: 'setRewardsDuration', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_forwarder', type: 'address' }], name: 'setTrustedForwarder', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256[]', name: '_chickenIds', type: 'uint256[]' }], name: 'stake', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_startTimeStamp', type: 'uint256' }], name: 'startStakingPeriod', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'takeback', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'takebackChickenCount', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'takebackRewardPerChickenPaid', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'totalChickenSupply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'totalClaimedAccountBalance', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'treasury', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'trustedForwarder', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256[]', name: '_chickenIds', type: 'uint256[]' }], name: 'unstake', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_account', type: 'address' }], name: 'updateEscrowRewardEntry', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newImplementation', type: 'address' }], name: 'upgradeTo', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newImplementation', type: 'address' }, { internalType: 'bytes', name: 'data', type: 'bytes' }], name: 'upgradeToAndCall', outputs: [], stateMutability: 'payable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'userRewardPerChickenPaid', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_user', type: 'address' }], name: 'userStakeInfo', outputs: [{ internalType: 'uint256[]', name: '_chickensStaked', type: 'uint256[]' }, { internalType: 'uint256', name: '_availableRewards', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'versionRecipient', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'pure', type: 'function',
  }],
};
