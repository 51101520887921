import { takeLatest, call, put } from 'redux-saga/effects';
import { apiService } from 'src/services/apiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { GachaPullMode } from 'src/constant/gacha';
import { gachaPullError, gachaPullRequest, gachaPullResponse } from './reducer';
import { getUserWalletRequest } from '../userWallet/reducer';

export function* getGachaPull({ payload }: PayloadAction<GachaPullMode>) {
  try {
    const response = yield call(apiService.getGachaPull, payload);

    yield put(gachaPullResponse(response?.data?.trainingPartners || []));
  } catch (err) {
    yield put(
      gachaPullError(err?.data?.message || err.message || 'Unknown Error'),
    );
  }

  yield put(getUserWalletRequest({}));
}

export function* gachaEffects() {
  yield takeLatest(gachaPullRequest.type, getGachaPull);
}

const gachaSagas = [call(gachaEffects)];

export default gachaSagas;
