import {
  takeLatest, call, put, select,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { apiService } from 'src/services/apiService';
import { toastService } from 'src/services/toastService';
import { PayloadAction } from '@reduxjs/toolkit';
import { PaginatedRequest } from 'src/models';
import { getChickenRequest } from '../chicken/reducer';
import {
  getClothingsRequest,
  getClothingsSuccess,
  getClothingsFailed,
  setClothingRequest,
  setClothingSuccess,
  removeClothingRequest,
  getClothingSetsRequest,
  getClothingSetsSuccess,
  getClothingChickensRequest,
  getClothingChickensSuccess,
} from './reducer';

function* getClothings({ payload }: PayloadAction<PaginatedRequest>) {
  const state = yield select();
  const isLoggedIn = state.userWallet?.logged;

  if (!isLoggedIn) {
    return;
  }

  try {
    const res = yield call(apiService.getClothings, payload);

    yield put(getClothingsSuccess(res.data));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
    yield put(getClothingsFailed());
  }
}

function* setClothing({ payload }: any) {
  const state = yield select();
  const isLoggedIn = state.userWallet?.logged;

  if (!isLoggedIn) {
    return;
  }

  try {
    yield call(apiService.setClothing, payload);

    yield put(getChickenRequest({ chickenId: payload.chickenId, keep: true }));
    yield put(setClothingSuccess());
    toast.success(`Equipped ${payload.clothingName} successfully!`);
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
    yield put(setClothingSuccess());
  }
}

function* removeClothing({ payload }: any) {
  const state = yield select();
  const isLoggedIn = state.userWallet?.logged;
  const chicken = state.chicken?.chicken;

  if (!isLoggedIn || !chicken) {
    return;
  }

  try {
    yield call(apiService.removeClothing, payload.chickenId, payload.clothingId);

    yield put(getChickenRequest({ chickenId: chicken.id, keep: true }));
    yield put(setClothingSuccess());
    toast.success(`Unequipped ${payload.clothing.name} successfully!`);
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
    yield put(setClothingSuccess());
  }
}

function* getClothingSets() {
  try {
    const res = yield call(apiService.getClothingSets);

    yield put(getClothingSetsSuccess(res.data));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
  }
}

function* getClothingChickens({ payload }: any) {
  const state = yield select();
  const isLoggedIn = state.userWallet?.logged;

  if (!isLoggedIn) {
    return;
  }

  try {
    const res = yield call(apiService.getChickens, payload);
    yield put(getClothingChickensSuccess(res.data));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    toastService.send(message, 'error');
    yield put(getClothingChickensSuccess({
      rows: [],
      count: 0,
    }));
  }
}

export function* userEffects() {
  yield takeLatest(getClothingsRequest as any, getClothings);
  yield takeLatest(setClothingRequest as any, setClothing);
  yield takeLatest(removeClothingRequest as any, removeClothing);
  yield takeLatest(getClothingSetsRequest as any, getClothingSets);
  yield takeLatest(getClothingChickensRequest as any, getClothingChickens);
}

const clothingSagas = [call(userEffects)];

export default clothingSagas;
