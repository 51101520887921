import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import {
  GetOffersAction, MarketPlaceAction, MarketPlaceActionType, MarketplaceAssignmentStatus, PaginatedList, PaginatedRequest,
} from 'src/models';

type MarketPlaceState = {
  isCheckingAssignment: boolean
  isProcessing: boolean
  isLoadingList: boolean,
  action?: MarketPlaceActionType
  receivedOffers: PaginatedList<any>
  madeOffers: PaginatedList<any>
  chickenMarketItems: PaginatedList<any>
  chickenTradeActivities: PaginatedList<any>
  marketPlaceStatus: any;
};

const initialState: MarketPlaceState = {
  isProcessing: false,
  isCheckingAssignment: false,
  isLoadingList: false,
  marketPlaceStatus: {
    nftContract: '',
    totalSupply: 0,
    numberOfOwners: 0,
    floorPrice: 0,
    totalTrades: 0,
    totalVolume: 0,
    bestOffer: 0,
  },
  receivedOffers: {
    rows: [],
    count: 0,
    isReached: false,
  },
  madeOffers: {
    rows: [],
    count: 0,
    isReached: false,
  },
  chickenTradeActivities: {
    rows: [],
    count: 0,
    isReached: false,
  },
  chickenMarketItems: {
    rows: [],
    count: 0,
    isReached: false,
  },
};

const landsSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    processMarketplaceActionRequest(state, action: PayloadAction<MarketPlaceAction>) {
      state.isCheckingAssignment = false;
      state.isProcessing = true;
      state.action = action.payload.type;
    },
    processMarketplaceActionSuccess(state) {
      state.isCheckingAssignment = true;
      state.isProcessing = false;
    },
    processMarketplaceActionFailed(state) {
      state.isCheckingAssignment = false;
      state.isProcessing = false;
      state.action = undefined;
    },
    checkMarketplaceAssignmentRequest(state, action: PayloadAction<MarketplaceAssignmentStatus>) {
      state.isCheckingAssignment = action.payload?.isPending || false;
      state.action = action.payload?.action;
    },
    checkMarketplaceAssignmentSuccess(state) {
      state.isCheckingAssignment = false;
      state.action = undefined;
    },
    checkMarketplaceAssignmentFailed(state) {
      state.isCheckingAssignment = false;
      state.action = undefined;
    },
    getReceivedTradeOffersRequest(state, action: PayloadAction<GetOffersAction>) {
      if (action.payload.page === 1) {
        state.receivedOffers = {
          rows: [],
          count: 0,
          isReached: false,
        };
      }
      state.isLoadingList = true;
    },
    getReceivedTradeOffersSuccess(state, action: PayloadAction<PaginatedList<any>>) {
      const offers = _.uniqBy(action.payload.reset ? action.payload.rows : [...state.receivedOffers.rows, ...action.payload.rows], 'id');
      const isReached = !action.payload.rows?.length;
      state.receivedOffers = {
        rows: offers,
        count: action.payload.count,
        isReached,
      };
      state.isLoadingList = false;
    },
    getMadeTradeOffersRequest(state, action: PayloadAction<GetOffersAction>) {
      if (action.payload.page === 1) {
        state.madeOffers = {
          rows: [],
          count: 0,
          isReached: false,
        };
      }
      state.isLoadingList = true;
    },
    getMadeTradeOffersSuccess(state, action: PayloadAction<PaginatedList<any>>) {
      const offers = _.uniqBy(action.payload.reset ? action.payload.rows : [...state.madeOffers.rows, ...action.payload.rows], 'id');
      const isReached = !action.payload.rows?.length;
      state.madeOffers = {
        rows: offers,
        count: action.payload.count,
        isReached,
      };
      state.isLoadingList = false;
    },
    getChickenMarketItemsRequest(state, action: PayloadAction<PaginatedRequest>) {
      if (action.payload.page === 1) {
        state.chickenMarketItems = {
          rows: [],
          count: 0,
          isReached: false,
        };
      }
      state.isLoadingList = true;
    },
    getChickenMarketItemsSuccess(state, action: PayloadAction<PaginatedList<any>>) {
      const chickenMarketItems = _.uniqBy([...state.chickenMarketItems.rows, ...action.payload.rows], 'id');
      const isReached = !action.payload.rows?.length;
      state.chickenMarketItems = {
        rows: chickenMarketItems,
        count: action.payload.count,
        isReached,
      };
      state.isLoadingList = false;
    },
    getMarketplaceStatusRequest(state, action: PayloadAction<string>) {
      state.marketPlaceStatus = null;
    },
    getMarketplaceStatusSuccess(state, action: PayloadAction<any>) {
      state.marketPlaceStatus = action.payload;
    },
    getChickenTradeActivitiesRequest(state, action: PayloadAction<PaginatedRequest>) {
      if (action.payload.page === 1) {
        state.chickenTradeActivities = {
          rows: [],
          count: 0,
          isReached: false,
        };
      }
      state.isLoadingList = true;
    },
    getChickenTradeActivitiesSuccess(state, action: PayloadAction<PaginatedList<any>>) {
      const chickenTradeActivities = _.uniqBy([...state.chickenTradeActivities.rows, ...action.payload.rows], 'id');
      const isReached = !action.payload.rows?.length;
      state.chickenTradeActivities = {
        rows: chickenTradeActivities,
        count: action.payload.count,
        isReached,
      };
      state.isLoadingList = false;
    },
  },
});

export const {
  processMarketplaceActionRequest,
  processMarketplaceActionSuccess,
  processMarketplaceActionFailed,
  checkMarketplaceAssignmentRequest,
  checkMarketplaceAssignmentSuccess,
  checkMarketplaceAssignmentFailed,
  getReceivedTradeOffersRequest,
  getReceivedTradeOffersSuccess,
  getMadeTradeOffersRequest,
  getMadeTradeOffersSuccess,
  getChickenMarketItemsRequest,
  getChickenMarketItemsSuccess,
  getMarketplaceStatusRequest,
  getMarketplaceStatusSuccess,
  getChickenTradeActivitiesRequest,
  getChickenTradeActivitiesSuccess,
} = landsSlice.actions;

export default landsSlice.reducer;
