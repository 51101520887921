import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Countdown from 'react-countdown';
import { Helmet } from 'react-helmet';

import './styles.css';
import { CopyIcon } from 'src/components/copy-icon';
import { useThemeContext } from 'src/contexts/theme';
import { useWalletContext } from 'src/contexts/walletProvider';
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedInSelector, userWalletSelector } from 'src/store/userWallet/selector';
import { bawkPresaleContractService } from 'src/services/contracts';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import { formatNumber } from 'src/utils/utils';
import {
  claimBawkPreSaleInviteCodeRequest, getBawkPreSaleDatesRequest, getBawkPreSaleInfoRequest, getBawkPresaleRefundInfoRequest,
} from 'src/store/bawk-presale/reducer';
import { bawkPreSaleDatesSelector, bawkPreSaleInfoSelector, bawkPreSaleRefundDataSelector } from 'src/store/bawk-presale/selector';
import moment from 'moment-timezone';
import { EventsEnum, events } from 'src/events';
import { ICoinSaleTransactionMined } from 'src/models';
import { LoadingModal } from 'src/modals/loadingModal';
import { BawkHeader } from 'src/components/bawk-header';
import { AddToCalendar } from 'src/components/add-to-calendar';

export function CommunitySale() {
  const { themeColors } = useThemeContext();
  const { onLogin } = useWalletContext();
  const dispatch = useDispatch();

  const userWallet = useSelector(userWalletSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const preSaleInfo = useSelector(bawkPreSaleInfoSelector);
  const preSaleDates = useSelector(bawkPreSaleDatesSelector);
  const preSaleRefundInfo = useSelector(bawkPreSaleRefundDataSelector);

  const [inviteCode, setInviteCode] = useState<string>();

  const [maxLots] = useState(20000);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [isRefunding, setIsRefunding] = useState(false);
  const [isLoadingRefundData, setIsLoadingRefundData] = useState(false);
  const [lots, setLots] = useState<number | string>('0');
  const [total, setTotal] = useState(0);
  const [userSales, setUserSales] = useState(0);
  const [price, setPrice] = useState<string>();

  const location = useMemo(() => `${window.location.origin}/community-sale`, []);
  const reservedSales = useMemo(() => (lots ? Number(lots) : 0) + userSales, [userSales, lots]);
  const totalDeposit = useMemo(() => Number(Web3.utils.fromWei(Web3.utils.toBN(price || 0).mul(Web3.utils.toBN(total || 0)))), [price, total]);
  const lotsNumber = useMemo(() => (lots ? Number(lots) : 0), [lots]);
  const salePrice = useMemo(() => Number(Web3.utils.fromWei(Web3.utils.toBN(price || 0).mul(Web3.utils.toBN(lotsNumber || 0)))), [price, lotsNumber]);
  const salesProgress = useMemo(() => Number(((total / maxLots) * 100).toFixed(2)), [total, maxLots]);
  const chickens = useMemo(() => preSaleInfo?.chickens || 0, [preSaleInfo]);
  const goldenTickets = useMemo(() => preSaleInfo?.goldenTickets || 0, [preSaleInfo]);

  const presaleStartsAt = useMemo(() => preSaleDates.presaleStartsAt, [preSaleDates]);
  const presaleEndsAt = useMemo(() => preSaleDates.presaleEndsAt, [preSaleDates]);
  const reservedChickenHolderSales = useMemo(() => Math.min(reservedSales, chickens), [reservedSales, chickens]);
  const reservedGoldenTicketSales = useMemo(() => Math.max(Math.min(reservedSales - chickens, goldenTickets), 0), [reservedSales, chickens, goldenTickets]);
  const reservedPublicSales = useMemo(() => Math.max(reservedSales - chickens - goldenTickets, 0), [reservedSales, chickens, goldenTickets]);

  const chickenHolderSales = useMemo(() => Math.min(userSales, chickens), [userSales, chickens]);
  const goldenTicketSales = useMemo(() => Math.max(Math.min(userSales - chickens, goldenTickets), 0), [userSales, chickens, goldenTickets]);
  const publicSales = useMemo(() => Math.max(userSales - chickens - goldenTickets, 0), [userSales, chickens, goldenTickets]);

  const chickenHolderSalesPrice = useMemo(() => Number(Web3.utils.fromWei(Web3.utils.toBN(price || 0).mul(Web3.utils.toBN(chickenHolderSales || 0)))), [price, chickenHolderSales]);
  const goldenTicketSalesPrice = useMemo(() => Number(Web3.utils.fromWei(Web3.utils.toBN(price || 0).mul(Web3.utils.toBN(goldenTicketSales || 0)))), [price, goldenTicketSales]);
  const publicSalesPrice = useMemo(() => Number(Web3.utils.fromWei(Web3.utils.toBN(price || 0).mul(Web3.utils.toBN(publicSales || 0)))), [price, publicSales]);

  const now = useMemo(() => moment.utc(), []);
  const isAllowedPresale = useMemo(() => !!preSaleDates.presaleStartsAt, [preSaleDates]);
  const isAllowedRefund = useMemo(() => !!preSaleDates.presaleRefundStartsAt, [preSaleDates]);
  const isLivePresale = useMemo(() => preSaleDates.presaleStartsAt && preSaleDates.presaleEndsAt && now.isBetween(moment(preSaleDates.presaleStartsAt), moment(preSaleDates.presaleEndsAt), undefined, '[)'), [now, preSaleDates]);
  const isLivePresaleRefund = useMemo(() => preSaleDates.presaleRefundStartsAt && preSaleDates.presaleRefundEndsAt && now.isBetween(moment(preSaleDates.presaleRefundStartsAt), moment(preSaleDates.presaleRefundEndsAt), undefined, '[)'), [now, preSaleDates]);
  const isBeforePresale = useMemo(() => preSaleDates.presaleStartsAt && now.isBefore(moment(preSaleDates.presaleStartsAt)), [preSaleDates, now]);
  const isOverPresale = useMemo(() => preSaleDates.presaleEndsAt && moment(preSaleDates.presaleEndsAt).isBefore(now), [preSaleDates, now]);
  const isBeforeRefund = useMemo(() => preSaleDates.presaleRefundStartsAt && now.isBefore(moment(preSaleDates.presaleRefundStartsAt)), [preSaleDates, now]);
  const isOverRefund = useMemo(() => preSaleDates.presaleRefundEndsAt && moment(preSaleDates.presaleRefundEndsAt).isBefore(now), [preSaleDates, now]);
  const presaleDateTime = useMemo(() => (isBeforePresale ? preSaleDates.presaleStartsAt : preSaleDates.presaleEndsAt), [isBeforePresale, preSaleDates]);
  const refundDateTime = useMemo(() => (isBeforeRefund ? preSaleDates.presaleRefundStartsAt : preSaleDates.presaleRefundEndsAt), [isBeforeRefund, preSaleDates]);

  const getPresalePrice = useCallback(async () => {
    try {
      const res = await bawkPresaleContractService.getPresalePrice();

      setPrice(res);
    } catch (err) {
      toast.error(err.message);
    }
  }, []);

  const getPresaleAmounts = useCallback(async () => {
    try {
      const presaleTotal = await bawkPresaleContractService.getPresaleTotal();

      setTotal(presaleTotal);

      if (userWallet?.id) {
        const userPresale = await bawkPresaleContractService.getUserPresale(userWallet.id);

        setUserSales(userPresale);
      }
    } catch (err) {
      toast.error(err.message);
    }
  }, [userWallet]);

  const getPresaleInfo = useCallback(() => {
    if (!isLoggedIn || !userWallet?.id) {
      return;
    }

    dispatch(getBawkPreSaleInfoRequest());
  }, [isLoggedIn, userWallet, dispatch]);

  const getPresaleDates = useCallback(() => {
    dispatch(getBawkPreSaleDatesRequest());
  }, [dispatch]);

  const onClaimInviteCode = () => {
    if (!isLoggedIn) {
      onLogin();
      return;
    }

    if (!inviteCode) {
      return;
    }

    dispatch(claimBawkPreSaleInviteCodeRequest(inviteCode));
  };

  const onBuyLots = async () => {
    if (!isLoggedIn) {
      onLogin();
      return;
    }

    if (isPurchasing) {
      return;
    }

    if (!Number(lots)) {
      return;
    }

    try {
      if (!isAllowedPresale) {
        throw new Error('BAWK presale is not available at the moment.');
      }

      if (isBeforePresale) {
        throw new Error('BAWK presale will begin shortly. Please stay tuned.');
      }

      if (isOverPresale) {
        throw new Error('Sorry, you\'re late. The BAWK presale has already ended.');
      }

      setIsPurchasing(true);

      const lotsPrice = Web3.utils.toBN(price).mul(Web3.utils.toBN(lots)).toString();
      await bawkPresaleContractService.reserve(userWallet.id, lotsPrice, Number(lots));
    } catch (err) {
      toast.error(err.message);
      setIsPurchasing(false);
    }
  };

  const onBuySuccess = useCallback((eventData: ICoinSaleTransactionMined) => {
    toast.success(`Purchased ${eventData.amount} lots successfully!`);
    getPresaleAmounts();
    setLots('0');
    setIsPurchasing(false);
  }, [getPresaleAmounts]);

  const onRefundSuccess = useCallback((eventData: ICoinSaleTransactionMined) => {
    toast.success(`Refunded your lost ${preSaleRefundInfo.lost} lots successfully!`);
    setIsRefunding(false);
  }, [preSaleRefundInfo]);

  const onRefundLots = useCallback(async () => {
    try {
      if (!isLoadingRefundData || !preSaleRefundInfo || !userWallet?.id) {
        return;
      }

      setIsLoadingRefundData(false);
      setIsRefunding(true);

      await bawkPresaleContractService.refund(userWallet.id, preSaleRefundInfo.won, preSaleRefundInfo.signature);
    } catch (err) {
      toast.error(err.message);
      setIsRefunding(false);
    }
  }, [isLoadingRefundData, preSaleRefundInfo, userWallet]);

  const onClickRefund = useCallback(() => {
    try {
      if (!isLoggedIn) {
        onLogin();
        return;
      }

      if (!isAllowedRefund) {
        throw new Error('BAWK presale refund is not available at the moment.');
      }

      if (isBeforeRefund) {
        throw new Error('BAWK presale refund will begin shortly. Please stay tuned.');
      }

      if (isOverRefund) {
        throw new Error('Sorry, you\'re late. The BAWK presale refund has already ended.');
      }

      dispatch(getBawkPresaleRefundInfoRequest());
      setIsLoadingRefundData(true);
    } catch (err) {
      toast.error(err.message);
      setIsLoadingRefundData(false);
    }
  }, [isLoggedIn, isAllowedRefund, isBeforeRefund, isOverRefund, dispatch, onLogin]);

  const presaleTimer = ({
    days, hours, minutes, seconds, completed,
  }, prefix) => {
    if (completed) {
      return <></>;
    }

    let text = `${prefix} - `;

    if (days) {
      text += `${days}d, ${hours}h, ${minutes}m`;
    } else if (hours) {
      text += `${hours}h, ${minutes}m`;
    } else if (minutes) {
      text += `${minutes}m, ${seconds}s`;
    } else {
      text += `${seconds}s`;
    }

    // Render a countdown
    return (
      <h4>
        {text}
      </h4>
    );
  };

  useEffect(() => {
    events.on(EventsEnum.BawkPresaleTransactionMined, onBuySuccess);

    return () => {
      events.off(EventsEnum.BawkPresaleTransactionMined, onBuySuccess);
    };
  }, [onBuySuccess]);

  useEffect(() => {
    events.on(EventsEnum.BawkPresaleRefundTransactionMined, onRefundSuccess);

    return () => {
      events.off(EventsEnum.BawkPresaleRefundTransactionMined, onRefundSuccess);
    };
  }, [onRefundSuccess]);

  useEffect(() => {
    onRefundLots();
  }, [onRefundLots]);

  useEffect(() => {
    getPresaleAmounts();
  }, [getPresaleAmounts]);

  useEffect(() => {
    getPresalePrice();
  }, [getPresalePrice]);

  useEffect(() => {
    getPresaleInfo();
  }, [getPresaleInfo]);

  useEffect(() => {
    getPresaleDates();
  }, [getPresaleDates]);

  const loadingTitle = useMemo(() => {
    if (isPurchasing) {
      return `Please confirm the transaction to\n purchase ${lots} lot(s)`;
    }

    if (isRefunding) {
      return `Please confirm the transaction to\n refund lost ${preSaleRefundInfo?.lost} lot(s)`;
    }

    return '';
  }, [isPurchasing, isRefunding, lots, preSaleRefundInfo]);

  const loadingDescription = useMemo(() => 'To complete your transaction click SIGN\n in the MetaMask window', []);

  const loadingTip = useMemo(() => 'Tip: If MetaMask does not appear automatically,\n click on the MetaMask icon in your browser notification toolbar, This can take some time,\n depending on network congestion.', []);

  return (
    <div className="community-sale-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Community Fire Sale 🔥</title>
      </Helmet>
      <div className="mb-4">
        <BawkHeader isConnectWallet />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="community-sale-card">
              <h3>
                Community Fire Sale 🔥
                {isLivePresale && <span className="presale-live">Live</span>}
                {isOverPresale && <span className="presale-over">Completed</span>}
              </h3>
              <div className="flex items-center mb-1">
                {isAllowedPresale ? <Countdown date={presaleDateTime} renderer={(props) => presaleTimer(props, isBeforePresale ? 'Starts In' : 'Ends In')} /> : <h4>$BAWK Presale End of March</h4>}
                {isBeforePresale && (
                  <div
                    className="ml-2"
                  >
                    <AddToCalendar
                      title="$BAWK Community Fire Sale 🔥"
                      description={`$BAWK Community Fire Sale 🔥\n${location}`}
                      startTime={presaleStartsAt?.toISOString()}
                      endTime={presaleEndsAt?.toISOString()}
                      location={location}
                      tooltip="Add to calendar"
                    />
                  </div>
                )}
              </div>

              <div className="separator" />
              <div className="flex reserve-lots">
                <div className="flex-1">
                  <h3>Reserve Lots</h3>
                  <div className="sale-controller">
                    <div className="btn" onClick={() => !isPurchasing && setLots(Number(lots) ? Number(lots) - 1 : 0)}>-</div>
                    <input disabled={isPurchasing} value={lots} onChange={(e) => setLots(Number(e.target.value) < 0 ? '0' : e.target.value)} type="number" />
                    <div className="btn" onClick={() => !isPurchasing && setLots(Number(lots) + 1)}>+</div>
                  </div>
                  <p>
                    TOTAL DEPOSIT
                    {' '}
                    <b>
                      {price ? `${salePrice} ETH` : 'TBA'}
                    </b>
                  </p>
                </div>
                <div className="separator vertical" />
                <div className="flex-1">
                  <table>
                    <tr>
                      <th>
                        <b>LOTS TO BE RESERVED</b>
                      </th>
                      <th />
                    </tr>
                    <tr>
                      <td>Chicken Holder Sales</td>
                      <td>
                        <b>
                          {reservedChickenHolderSales}
                          {' '}
                          /
                          {' '}
                          {chickens}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>Golden Ticket Sales</td>
                      <td>
                        <b>
                          {reservedGoldenTicketSales}
                          {' '}
                          /
                          {' '}
                          {goldenTickets}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>Public Sales</td>
                      <td><b>{reservedPublicSales}</b></td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="btn connect-btn" onClick={onBuyLots}>
                {isLoggedIn ? isPurchasing ? 'Buying Lots...' : 'Buy Lots' : 'Connect'}
              </div>
            </div>
            {!isLoggedIn && (
              <div className="community-sale-card yellow-card">
                CONNECT TO CHECK WALLET STATUS
              </div>
            )}
            {(preSaleInfo?.chickens > 0 || preSaleInfo?.goldenTickets > 0) && (
              <div className="community-sale-card whitelist-card">
                YOU HAVE BEEN WHITELISTED 🎉
              </div>
            )}
            {preSaleInfo?.codes.length > 0 && (
              <div className="community-sale-card">
                <h3>Share your invite code</h3>
                <p>As a WL holder you get 3 single-use codes to share with your friends and communities! Spread the BAWK Love!</p>
                <div className="invite-code-container mt-3">
                  {preSaleInfo.codes.map((code) => (
                    <CopyToClipboard
                      onCopy={() => toast.success(`Copied your invite code (${code})`)}
                      key={code}
                      text={code}
                    >
                      <div className="invite-code">
                        {code}
                        <div className="copy-icon">
                          <CopyIcon color={themeColors.raceText} />
                        </div>
                      </div>
                    </CopyToClipboard>
                  ))}
                </div>
              </div>
            )}
            <div className="community-sale-card">
              <h3>Invite code claim</h3>
              <div className="eligibility-check">
                <div className="eligibility-check-input">
                  <input placeholder="Code" value={inviteCode} onChange={(e) => setInviteCode(e.target.value)} />
                </div>
                <div className="btn" onClick={() => onClaimInviteCode()}>
                  {isLoggedIn ? 'Claim' : 'Connect'}
                </div>
              </div>
            </div>
            <div className="community-sale-card">
              <table>
                <tr>
                  <th><b>YOUR STATUS</b></th>
                  <th>LOTS</th>
                  <th>DEPOSITED</th>
                </tr>
                <tr>
                  <td>Chicken Holder Sales</td>
                  <td>
                    <b>
                      {chickenHolderSales}
                      {' '}
                      /
                      {' '}
                      {chickens}
                    </b>
                  </td>
                  <td>
                    <b>
                      {chickenHolderSalesPrice}
                      {' '}
                      ETH
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>Golden Ticket Sales</td>
                  <td>
                    <b>
                      {goldenTicketSales}
                      {' '}
                      /
                      {' '}
                      {goldenTickets}
                    </b>
                  </td>
                  <td>
                    <b>
                      {goldenTicketSalesPrice}
                      {' '}
                      ETH
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>Public Sales</td>
                  <td><b>{publicSales}</b></td>
                  <td>
                    <b>
                      {publicSalesPrice}
                      {' '}
                      ETH
                    </b>
                  </td>
                </tr>
              </table>
              <div className="flex mt-3 justify-between">
                <div className="raffle-won">
                  Won:
                  {' '}
                  {preSaleInfo?.won || 'TBA'}
                </div>
                <div className="raffle-lost">
                  Lost:
                  {' '}
                  {preSaleInfo?.lost || 'TBA'}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="community-sale-card">
              <h3>Total Value Locked (TVL)</h3>
              <h2>
                {formatNumber(totalDeposit, '0,0.00')}
                {' '}
                ETH
              </h2>
              <div className="flex mb-3">
                <p className="flex-1"><b>{`Lots Bought ${salesProgress}%`}</b></p>
                <p>
                  <b>
                    {formatNumber(total, '0,0')}
                    {' '}
                    /
                    {' '}
                    {formatNumber(maxLots, '0,0')}
                  </b>
                </p>
              </div>
              <div className="progress-bar-container">
                <div className="progress-bar">
                  <div
                    className="progress-step"
                    style={{
                      width: `${salesProgress}%`,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="community-sale-card">
              <h3>How it works</h3>
              <ul>
                <li>Chicken and Golden ticket holders receive whitelist status, which gives them priority access.</li>
                <li>Public Wallets can join the sale, and will be added to the raffle in case of oversubscription. Pro Tip: Purchasing lots in the first hour increases your chances by 9.5x.</li>
                <li>Each lot costs $100, and holds a whopping 30,000 BAWK tokens. Exact price in ETH will be announced 2 hours before the sale.</li>
                <li>After the raffle ends, wallets can claim a refund for each non-winning deposit.</li>
                <li>Presale will take place on the ETH mainnet, and the tokens will be claimable on Polygon.</li>
              </ul>
            </div>
            <div className="community-sale-card">
              <h3>
                Raffle result
                {isLivePresaleRefund && <span className="presale-live">Live</span>}
                {isOverRefund && <span className="presale-over">Completed</span>}
              </h3>
              {isAllowedRefund ? <Countdown date={refundDateTime} renderer={(props) => presaleTimer(props, isBeforeRefund ? 'Starts In' : 'Ends In')} /> : <h4>Start date TBA.</h4>}
              <p className="mb-3 mt-3">
                You purchased
                {' '}
                {userSales}
                {' '}
                lots.
                {' '}
                {chickenHolderSales}
                {' '}
                are chicken holder sales,
                {' '}
                {goldenTicketSales}
                {' '}
                are golden ticket sales, and
                {' '}
                {publicSales}
                {' '}
                are public sales.
              </p>
              <p className="mb-3">Winning allotments can claim their tokens with the contributing wallet upon token launch, and losing tickets will claim their ETH back when the community sale is over.</p>
              <p>Come back when the sale is over to check the results!</p>
              <div
                className={`btn refund-btn ${isLivePresaleRefund ? '' : 'disabled'}`}
                onClick={onClickRefund}
              >
                Refund Lots
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingModal
        open={isPurchasing || isRefunding}
        title={loadingTitle}
        description={loadingDescription}
        tip={loadingTip}
      />
    </div>
  );
}
