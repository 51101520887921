import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NEED_LOCALIZATION } from 'src/utils/config';

interface LanguageContextType{
  lang: string;
  onSetLanguage: (lang: string) => void;
}

export const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined,
);

export const useLanguageContext = () => {
  const context = useContext(LanguageContext);

  return context;
};

const CHICKEN_DERBY_LANGUAGE_KEY = 'chickenderby_language';
export default function LanguageProvider(props: {
  children: React.ReactNode;
}) {
  const [lang, setLanguage] = useState<string>();

  const onSetLanguage = useCallback((_lang: string) => {
    localStorage.setItem(CHICKEN_DERBY_LANGUAGE_KEY, _lang);
    setLanguage(_lang);
  }, []);
  const { i18n } = useTranslation('translation');

  useEffect(() => {
    const language = localStorage.getItem(CHICKEN_DERBY_LANGUAGE_KEY);
    if (!language || !NEED_LOCALIZATION) {
      setLanguage('en');
    } else {
      setLanguage(language);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const value = useMemo(
    () => ({
      lang,
      onSetLanguage,
    }),
    [
      lang,
      onSetLanguage,
    ],
  );

  return (
    <LanguageContext.Provider
      value={value}
    >
      {props.children}
    </LanguageContext.Provider>
  );
}
