import { takeLatest, call, put } from 'redux-saga/effects';
import { apiService } from 'src/services/apiService';
import { getCoinRequest } from './action';
import { getCoinResponse } from './reducer';

export function* getCoinRate(data: any) {
  try {
    const response = yield call(apiService.getCoinRate);

    yield put(getCoinResponse(response?.data));
  } catch (err) {
    //
  }
}

export function* exchangeEffects() {
  yield takeLatest(getCoinRequest.type, getCoinRate);
}

const exchangeSagas = [call(exchangeEffects)];

export default exchangeSagas;
