import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ChainId } from 'src/constant/chainId';
import { ChooseAccount } from 'src/modals/chooseAccount';
import { ProviderService } from 'src/services/provider';
import { ProviderEventsEnum } from 'src/services/provider/events';
import { ProviderName } from 'src/services/provider/provider.enum';
import { WalletActions } from 'src/store/common/action';
import { resetNonce } from 'src/store/userWallet/reducer';
import {
  isLoggedInSelector,
  userWalletIdSelector,
  nonceSelector,
} from 'src/store/userWallet/selector';
import Web3 from 'web3';

interface MetamaskContextProps {
  onLogin: () => void;
  onLogout: () => void;
  isReady: boolean;
  openChooseAccount:boolean;
}

export const MetamaskContext = createContext<MetamaskContextProps | undefined>(
  undefined,
);

export const useWalletContext = () => {
  const context = useContext(MetamaskContext);

  return context;
};

export default function WalletProvider(props: {
  children: React.ReactChild[] | React.ReactChild;
}) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userWalletId = useSelector(userWalletIdSelector);
  const nonce = useSelector(nonceSelector);

  const [isReady, setIsReady] = useState(false);
  const [openChooseAccount, setOpenChooseAccount] = useState(false);
  const [account, setAccount] = useState<string>();

  const initProvider = useCallback(async () => {
    try {
      await ProviderService.init();

      setIsReady(true);
    } catch (err: any) {
      toast.error(err.message);
    }
  }, []);

  const openMetamask = () => {
    window.open(`https://metamask.app.link/dapp/${document.location.host}${document.location.pathname}`, '_blank');
  };

  const getAccounts = useCallback(async () => {
    if (!isReady) {
      return;
    }

    try {
      const accounts = await ProviderService.getAccounts();
      const firstAccount = accounts?.length ? accounts[0]?.toLowerCase() : null;

      if (isLoggedIn && !firstAccount) {
        dispatch(WalletActions.logoutAction());
      } else if (isLoggedIn && firstAccount && userWalletId && Web3.utils.toChecksumAddress(firstAccount) !== Web3.utils.toChecksumAddress(userWalletId)) {
        dispatch(WalletActions.logoutAction());
        ProviderService.logout();
      } if (!isLoggedIn && firstAccount) {
        ProviderService.logout();
      }
    } catch (err) {
      toast.error(err.message);
    }
  }, [dispatch, isLoggedIn, userWalletId, isReady]);

  const onLogout = useCallback(() => {
    if (!isLoggedIn) {
      return;
    }

    dispatch(WalletActions.logoutAction());
  }, [isLoggedIn, dispatch]);

  const onLogin = useCallback(async () => {
    setOpenChooseAccount(true);
  }, []);

  const onChooseAccount = async (providerName: ProviderName) => {
    try {
      setOpenChooseAccount(false);

      if (providerName === ProviderName.metamask && !window.ethereum?.isMetaMask) {
        openMetamask();
        return;
      }

      const accounts = await ProviderService.login(providerName);

      const firstAccount = accounts[0];

      if (!firstAccount) {
        return;
      }

      setAccount(firstAccount);

      dispatch(WalletActions.getNonceAction(firstAccount));
    } catch (err) {
      //
    }
  };

  const onCloseChooseAccount = () => {
    setOpenChooseAccount(false);

    if (isLoggedIn) {
      // logout if user logged and close choose account
      dispatch(WalletActions.logoutAction());
    }
  };

  const accountsChangedHandler = useCallback((accounts: any[]) => {
    const firstAccount = accounts[0];
    if (isLoggedIn && !firstAccount) {
      // logout if user logged and logged out on metamask
      dispatch(WalletActions.logoutAction());
      return;
    }

    if (isLoggedIn && firstAccount && account !== firstAccount) {
      dispatch(resetNonce());
      // show account choose modal if user logged and changed account on metamask
      setAccount(firstAccount);
      setOpenChooseAccount(true);
    }

    if (window.document.hidden) {
      return;
    }

    if (!isLoggedIn && firstAccount === userWalletId) {
      dispatch(WalletActions.getNonceAction(firstAccount));
    }
  }, [account, dispatch, isLoggedIn, userWalletId]);

  useEffect(() => {
    ProviderService.events.on(ProviderEventsEnum.AccountsChanged, accountsChangedHandler);

    return () => {
      ProviderService.events.off(ProviderEventsEnum.AccountsChanged, accountsChangedHandler);
    };
  }, [accountsChangedHandler]);

  const registerOrLogin = useCallback(async () => {
    if (!nonce) {
      return;
    }

    const { id, isRegister, message } = nonce;
    try {
      const signature = await ProviderService.sign(ChainId.Polygon, id, Web3.utils.fromUtf8(message));

      if (isRegister) {
        dispatch(
          WalletActions.registerAction({
            userWalletId: id,
            signature,
          }),
        );
      } else {
        dispatch(
          WalletActions.loginAction({
            userWalletId: id,
            signature,
          }),
        );
      }
    } catch (err) {
      ProviderService.logout();
      toast.error(err.message);
    }
  }, [nonce, dispatch]);

  useEffect(() => {
    registerOrLogin();
  }, [registerOrLogin]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  useEffect(() => {
    initProvider();
  }, [initProvider]);

  const value = useMemo(
    () => ({
      onLogin,
      onLogout,
      isReady,
      openChooseAccount,
    }),
    [
      onLogin,
      onLogout,
      isReady,
      openChooseAccount,
    ],
  );

  return (
    <MetamaskContext.Provider
      value={value}
    >
      {props.children}
      <ChooseAccount
        isOpen={openChooseAccount}
        onChoose={onChooseAccount}
        onClose={onCloseChooseAccount}
      />
    </MetamaskContext.Provider>
  );
}
