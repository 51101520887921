import React from 'react';
import { Modal } from 'react-bootstrap';
import { Loading } from 'src/components/loading';
import { useTranslation, Trans } from 'react-i18next';

export function TransactionComplete({
  open,
  race,
  isAssigningPaidRace,
  isApproving,
}: {
  open: boolean;
  race: any;
  isAssigningPaidRace: boolean;
  isApproving: boolean;
}) {
  const isFreeRace = race?.fee === 0;
  const { t } = useTranslation('translation', { keyPrefix: 'MODALS.TRANSACTION_COMPLETE' });

  return (
    <Modal
      centered
      dialogClassName="complete-transaction"
      show={open}
      backdrop="static"
    >
      <Modal.Body>
        {isAssigningPaidRace ? (
          <>
            <h3>{t('TRANSACTION_CONFIRMED')}</h3>
            <p>{t('YOUR_CHICKEN_IS_BEING_ENTERED_WAIT')}</p>
            <Loading size={150} />
            <h5>
              {t('TIP_THIS_CAN_TAKE_SOME_TIME')}
            </h5>
          </>
        ) : isFreeRace ? (
          <>
            <h3>{t('PLEASE_WAIT')}</h3>
            <p>{t('YOUR_CHICKEN_IS_BEING_ENTERED')}</p>
            <Loading size={150} />
          </>
        ) : (
          <>
            {isApproving ? (
              <>
                <h3>
                  <Trans t={t} i18nKey="APPROVE_CONTRACT">
                    Please approve our contract
                    <br />
                    in MetaMask
                  </Trans>
                </h3>
                <p>{t('TO_APPROVE_OUR_CONTRACT')}</p>
              </>
            ) : (
              <>
                <h3>
                  <Trans t={t} i18nKey="PLEASE_COMPLETE_YOUR_TRANSACTION_IN_METAMASK">
                    Please complete your transaction
                    <br />
                    in MetaMask
                  </Trans>
                </h3>
                <p>
                  {t('TO_COMPLETE_YOUR_TRANSACTION')}
                </p>
              </>
            )}
            <Loading size={150} />
            <h5>
              <Trans t={t} i18nKey="TIP_IF_METAMASK_DOES_NOT_APPEAR">
                Tip: If MetaMask does not appear automatically,
                <br />
                click on the MetaMask icon in your browser notification toolbar
              </Trans>
            </h5>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
