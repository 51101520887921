export const tournaments = [
  {
    title: '10k Tournament',
    start: '2022-04-20',
    end: '2022-05-03',
    link: 'https://discord.gg/chicken-derby',
  },
  {
    title: '20k Tournament',
    start: '2022-05-22',
    end: '2022-06-04',
    link: 'https://discord.gg/chicken-derby',
  },
  {
    title: 'Superhero Special',
    start: '2022-06-13',
    end: '2022-06-19',
    link: 'https://discord.gg/chicken-derby',
  },
  {
    title: 'Free Race Birthday Bonanza',
    start: '2022-07-16',
    end: '2022-07-16',
  },
];
