import { combineReducers } from '@reduxjs/toolkit';
import walletReducer from '../userWallet/reducer';
import racesReducer from '../races/reducer';
import chikenReducer from '../chicken/reducer';
import coopReducer from '../coop/reducer';
import laneReducer from '../lane/reducer';
import exchangeReducer from '../exchange/reducer';
import tournamentReducer from '../tournament/reducer';
import landReducer from '../land/reducer';
import clothingReducer from '../clothing/reducer';
import marketplaceReducer from '../marketplace/reducer';
import seasonReducer from '../season/reducer';
import fusionReducer from '../fusion/reducer';
import serumReducer from '../serum/reducer';
import preRegisterLandReducer from '../pre-register-land/reducer';
import coinPreSaleReducer from '../coin-presale/reducer';
import gachaReducer from '../gacha/reducer';
import traningPartnersReducer from '../traningPartners/reducer';
import bawkStakingReducer from '../bawk-staking/reducer';
import bawkPreSaleReducer from '../bawk-presale/reducer';
import chickenStakingReducer from '../chicken-staking/reducer';

const reducers = {
  userWallet: walletReducer,
  race: racesReducer,
  chicken: chikenReducer,
  coop: coopReducer,
  lane: laneReducer,
  exchange: exchangeReducer,
  tournament: tournamentReducer,
  land: landReducer,
  clothing: clothingReducer,
  marketplace: marketplaceReducer,
  season: seasonReducer,
  fusion: fusionReducer,
  serum: serumReducer,
  preRegisterLand: preRegisterLandReducer,
  coinPreSale: coinPreSaleReducer,
  gacha: gachaReducer,
  trainingPartners: traningPartnersReducer,
  bawkStaking: bawkStakingReducer,
  bawkPreSale: bawkPreSaleReducer,
  chickenStaking: chickenStakingReducer,
};

export let rootReducer = combineReducers({
  ...reducers,
});

export default function createReducer(injectedReducers = {}) {
  rootReducer = combineReducers({
    ...reducers,
    ...injectedReducers,
  });

  return rootReducer;
}

export type RootState = ReturnType<typeof rootReducer>;
