import React, { useState } from 'react';
import { IMAGES_URL } from 'src/constant/images';
import { useIntersection } from 'src/utils/userIntersection';
import ContentLoader from 'react-content-loader';
import { TrailerVideoModal } from '../trailer-video';
import './style.css';

export function TrailerVideo({ src, isPreview = true, noBorder }: { src: string, isPreview?: boolean, noBorder?: boolean }) {
  const { isIntersecting, intersectionRef } = useIntersection();

  const [isLoaded, setIsLoaded] = useState(false);
  const [openTrailerVideo, setOpenTrailerVideo] = useState(false);

  return (
    <div className={`trailer-video-container ${noBorder ? 'no-border' : ''}`} ref={intersectionRef}>
      {!isLoaded && (
        <ContentLoader viewBox="0 0 688 387" gradientRatio={1.2} backgroundColor="#d6d6d6">
          <rect x="0" y="0" rx="0" ry="0" width="688" height="387" />
        </ContentLoader>
      )}
      {isIntersecting && (
        <div className="video-wrap" onClick={() => isPreview && setOpenTrailerVideo(true)}>
          <video onLoadedData={() => setIsLoaded(true)} className="chicken-img" src={src} autoPlay muted loop />
          {isPreview && (<img className="play-icon" src={IMAGES_URL.PLAY_ICON} alt="" />)}
        </div>
      )}

      <TrailerVideoModal isOpen={openTrailerVideo} onClose={() => setOpenTrailerVideo(false)} />
    </div>
  );
}
