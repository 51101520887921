import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChickenRacesRequest, PaginatedRequest, RacesRequest,
} from 'src/models';
import * as _ from 'lodash';
import moment from 'moment-timezone';

interface SettingType {
  name?: string;
  value?: string;
  message?: string;
}

type UserState = {
  chickens: any,
  races: any;
  race: any;
  activeRaces: any;
  incomingRaces: any
  scheduledRaces: any;
  gameResults: any;
  chickenRaces: any;
  isFetching: boolean;
  isFetchingChickens: boolean;
  isDeploying: SettingType;
  totalRace?: any;
  leaderboard?: any;
};

const initialState: UserState = {
  race: null,
  races: {},
  chickens: {},
  activeRaces: {
    rows: [],
    count: 0,
  },
  incomingRaces: {
    rows: [],
    count: 0,
  },
  scheduledRaces: {
    rows: [],
    count: 0,
  },
  gameResults: {
    rows: [],
    totalEarnings: 0,
    totalRaces: 0,
    count: 0,
  },
  chickenRaces: {
    isReached: false,
    rows: [],
    count: 0,
  },
  isFetching: false,
  isFetchingChickens: false,
  isDeploying: null,
};
//
const racesSlice = createSlice({
  name: 'races',
  initialState,
  reducers: {
    getActiveRacesRequest(state, action: PayloadAction<RacesRequest>) {
      if (action.payload.page === 1) {
        state.activeRaces = {
          rows: [],
          count: 0,
        };
      }
    },
    getActiveRacesResponse(state, action: PayloadAction<any>) {
      const races = _.uniqBy([...state.activeRaces.rows, ...action.payload.rows], 'id').map((row) => ({
        ...row,
        raceScheduleTime: row.raceTimerDuration ? moment().add(row.raceTimerDuration || 0, 'seconds').toDate() : null,
      }));
      const isReached = !action.payload.rows?.length;
      state.activeRaces = {
        rows: races,
        count: action.payload.count,
        isReached,
      };
    },
    getIncomingRacesRequest(state, action: PayloadAction<RacesRequest>) {
      if (action.payload.page === 1) {
        state.incomingRaces = {
          rows: [],
          count: 0,
        };
      }
    },
    getIncomingRacesResponse(state, action: PayloadAction<any>) {
      const races = _.uniqBy([...state.incomingRaces.rows, ...action.payload.rows], 'id').map((row) => ({
        ...row,
        raceScheduleTime: row.raceTimerDuration ? moment().add(row.raceTimerDuration || 0, 'seconds').toDate() : null,
      }));
      const isReached = !action.payload.rows?.length;
      state.incomingRaces = {
        rows: races,
        count: action.payload.count,
        isReached,
      };
    },
    getScheduledRacesRequest(state, action: PayloadAction<RacesRequest>) {
      if (action.payload.page === 1) {
        state.scheduledRaces = {
          rows: [],
          count: 0,
        };
      }
    },
    getScheduledRacesResponse(state, action: PayloadAction<any>) {
      const scheduledRaces = action.payload.rows.map((race) => ({
        ...race,
        raceStartTime: moment().add(race.startTime || 0, 'seconds').toDate(),
      }));

      state.scheduledRaces = {
        rows: _.uniqBy([...state.scheduledRaces.rows, ...scheduledRaces], 'id'),
        count: action.payload.count,
      };
    },
    getGameResultsRequest(state, action: PayloadAction<RacesRequest>) {
      if (action.payload.page === 1) {
        state.gameResults = {
          rows: [],
          totalEarnings: 0,
          totalRaces: 0,
          count: 0,
        };
      }
    },
    getGameResultsResponse(state, action: PayloadAction<any>) {
      state.gameResults = {
        rows: _.uniqBy([...state.gameResults.rows, ...action.payload.rows], 'id'),
        count: action.payload.count,
        totalEarnings: action.payload.totalEarnings,
        totalRaces: action.payload.totalRaces,
      };
    },
    getChickenRacesRequest(state, action: PayloadAction<ChickenRacesRequest>) {
      if (action.payload.page === 1) {
        state.chickenRaces = {
          rows: [],
          count: 0,
          isReached: false,
        };
      }
    },
    getChickenRacesResponse(state, action: PayloadAction<any>) {
      const races = [...state.chickenRaces.rows, ...action.payload.rows];
      const isReached = !action.payload.rows?.length;
      state.chickenRaces = {
        rows: races,
        count: action.payload.count,
        isReached,
      };
    },
    getFetchingResponse(state, action: PayloadAction<any>) {
      state.isFetching = action.payload;
    },
    refreshRaceResponse(state, action: PayloadAction<any>) {
      const races = state.activeRaces.rows
        .map(
          (item) => (item.id === action.payload.id && action.payload) || item,
        )
        .filter((item) => item.status === 'open');
      state.activeRaces.rows = [...races];
    },
    checkIsDeployingResponse(state, action: PayloadAction<SettingType>) {
      state.isDeploying = action.payload;
    },
    getRaceRequest(state, action: PayloadAction<string>) {
      state.race = null;
    },
    getRaceResponse(state, action: PayloadAction<any>) {
      state.race = {
        ...action.payload,
        raceStartTime: action.payload.status === 'scheduled' ? moment().add(action.payload.startTime || 0, 'seconds').toDate() : null,
        raceScheduleTime: action.payload.raceTimerDuration > 0 ? moment().add(action.payload.raceTimerDuration || 0, 'seconds').toDate() : null,
      };
    },
    getTotalRaceResponse(state, action: PayloadAction<any>) {
      state.totalRace = action.payload;
    },
    getRaceChickensRequest(state, action: PayloadAction<PaginatedRequest>) {
      let chickens = {
        ...state.chickens,
        otherPeckingOrders: [],
      };
      if (action.payload.page === 1) {
        chickens = {
          rows: [],
          count: 0,
          isReached: false,
        };
      }
      state.chickens = chickens;
      state.isFetchingChickens = true;
    },
    getRaceChickensResponse(state, action: PayloadAction<any>) {
      const chickens = _.uniqBy([...state.chickens.rows, ...action.payload.rows], 'id');
      const isReached = chickens.length === action.payload.count || !action.payload.rows?.length;
      state.chickens = {
        rows: chickens,
        count: action.payload.count,
        isReached,
        otherPeckingOrders: action.payload.otherPeckingOrders || [],
      };
      state.isFetchingChickens = false;
    },
    getLeaderboardRequest(state) {
      state.isFetching = true;
      state.leaderboard = null;
    },
    getLeaderboardResponse(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.leaderboard = action.payload;
    },
  },
});

export const {
  getActiveRacesResponse,
  getIncomingRacesRequest,
  getIncomingRacesResponse,
  getScheduledRacesResponse,
  getGameResultsResponse,
  getFetchingResponse,
  getActiveRacesRequest,
  getScheduledRacesRequest,
  refreshRaceResponse,
  getGameResultsRequest,
  checkIsDeployingResponse,
  getChickenRacesRequest,
  getChickenRacesResponse,
  getRaceRequest,
  getRaceResponse,
  getTotalRaceResponse,
  getRaceChickensRequest,
  getRaceChickensResponse,
  getLeaderboardRequest,
  getLeaderboardResponse,
} = racesSlice.actions;

export default racesSlice.reducer;
