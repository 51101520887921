import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const chickenImage = require('src/assets/images/chicken.svg');

export const ChickenImg = React.memo(
  ({ src, size = 'auto', margin = 'auto' }: { src: string; size?: number | 'auto', margin?: number | 'auto' }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loaded, setLoaded] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [error, setError] = useState(false);

    return (
      <div className="chicken-image" style={{ width: size, height: size, margin }}>
        <ContentLoader
          viewBox="0 0 600 600"
          gradientRatio={1.2}
          backgroundColor="#d6d6d6"
        >
          <path
            d="M423.7,189.6c0,0,15.8-5.9,19.4-14.1s4.7-16.4-5.3-23.5c-10-7-14.1-5.9-14.1-5.9s-4.8-17.7-21.7-20
c-22.3-2.9-35.8,7.6-35.8,7.6s-25.2-14.7-44-1.2s-25.2,39.9-12.3,56.9s29.9,23.5,29.9,23.5s-2.3,21.1,2.9,31.7
c5.3,10.6,19.4,32.9,19.4,32.9s2.9,21.1-5.3,30.5c-8.2,9.4-20,22.9-20,22.9s-22.3-4.7-44,2.9c-21.7,7.6-43.4,20-43.4,20
s5.3-16.4,3.5-27c-1.8-10.6-7-24.4-7-24.4s-5.9-17.3-7-19.1c-1.2-1.8-11.7,14.7-11.7,14.7s-12.9-24.6-20.5-32.3
c-7.6-7.6-27.6-18.8-27.6-18.8v18.8c0,0-11.1-5.9-19.4-10c-8.2-4.1-34.6-10.6-34.6-10.6s2.9,7,5.3,11.1c2.3,4.1,6.5,8.2,6.5,8.2
l6.5-1.2l-1.8,4.7l5.3,5.3c0,0-23.5-2.3-38.1,12.3s-24.1,35.8-22.3,52.8c1.8,17,17.6,32.9,17.6,32.9s2.9-8.8,7-14.7s10-8.8,10-8.8
s-4.7,14.1-3.5,23.5c1.2,9.4,17.6,42.8,17.6,42.8s0.6-15.3,1.2-20c0.6-4.7,1.8-11.7,1.8-11.7l1.2,28.2c0,0,2.3,11.1,4.1,14.7
c1.8,3.5,6.5,12.3,6.5,12.3l8.8-14.1c0,0,2.3,12.3,4.1,17s19.8,23.3,19.8,23.3s0.4-16,0.4-18.9c0-2.9-0.9-14.4-0.9-14.4
s5.3,14.1,7.6,17c2.3,2.9,13.5,12.9,13.5,12.9s4.7-8.2,4.7-11.1c0-2.9,4.1-12.3,4.1-12.3s1.8,8.2,0.6,10.6s-2.9,10-2.9,10l7-7.6
l-1.8,14.1h10.6v10l8.8-5.9l-2.9,11.1c0,0,5.9,3.5,8.2,3.5c2.3,0,24.1,0,24.1,0l5.3,5.9l5.9,4.7l4.1,9.4l2.9,3.5v14.1
c0,0-1.8,9.4-5.9,14.7c-4.1,5.3-7.6,11.7-7.6,11.7s-5.9,4.7-6.5,7c-0.6,2.3,1.7,7.6,4.1,8.8c2.4,1.2,12.8,2.1,16.9,0.3
s3.6-7.3,3-10.3c-0.6-2.9,0-7.6,1.7-10.5c1.8-2.9,3.6-0.7,3.6-0.7l2.9,10c0,0,0.3,11.2,1.8,12.3c3.2,2.3,9.2,2.3,13.4,2.3
c9.7,0,13.1-4,12.5-6.9c-0.6-2.9-1.8-6.5-7.6-7.6c-5.9-1.2-6.7-8.4-6.7-8.4l6.7,3.7l1.2,3.9c0,0,7.7,7.7,15.8,5.5
c3.1-0.8,6.2-2.2,4.1-7.6c-1.9-5-7.8-7.6-11.7-8.8c-15.5-4.7-25-16.1-25-16.1l-0.8-18.6l8.5-6.4c0,0,4.4-7.6,5-11.7
c0.6-4.1,2.3-9.4,2.3-9.4s8.2,10,10.6,11.7s7.6,9.4,7.6,9.4l0.9-2.3l0.7,10.3c0,0,3.2,3.1,1.9,9.2c-1.3,6.2-2.6,11-6.6,12.8
c-4,1.8-13.3-0.8-13.3-0.8s-2.8,14.7,18.6,12.2c15.7-1.8,17.6-1.6,24.6-0.9c5.1,0.5,20.7,4.4,20.7,4.4s7.9,5.3,10.6,5.3
c2.6,0,8.8-1.3,11-4s4-7.9,0.9-11.4s-5.2-3.2-5.2-3.2s3.9,0.5,5.2-1.7c1.3-2.2,2.2-8.8-4-10.6c-6.2-1.8-18,3.6-18,3.6l-27.2-5.2
l-4.3-19.3c0,0,7.7-9.1,10.8-15.7c3.1-6.6,2.2-20.7,2.2-20.7s15.4-9.7,19.4-13.6c4-4,9.7-11.4,9.7-11.4s5.7,1.3,7-0.4
c1.3-1.8,4-9.2,4-9.2s13.2-15.4,16.7-21.1c3.5-5.7,13.2-24.2,13.2-27.7c0-3.5,1.8-7.9,1.8-7.9s6.6-10.1,6.6-13.6
c0-3.5-0.9-24.2-0.9-24.2s9.2-6.2,11.9-10.6c2.6-4.4,8.4-18.9,4-29.9c-4.4-11-11.9-21.1-11.9-21.1s9.4-2.6,13.5-5.7
c4.1-3.1,12.5-12.8,12.5-12.8l11-0.4c0,0-8.4-12.3-13.2-19.4c-4.8-7-9.7-10.6-15.8-9.7c-6.2,0.9-11.9,3.1-11.9,3.1s0.9-6.6,0-11.4
s-2.6-13.2-6.2-16.3C428.8,194.3,423.7,189.6,423.7,189.6z"
          />
        </ContentLoader>
        <div className="img-container">
          <LazyLoadImage
            alt=""
            height={size}
            src={src}
            placeholderSrc={chickenImage}
            width={size}
            onError={() => setError(true)}
            afterLoad={() => setLoaded(true)}
          />
        </div>
      </div>
    );
  },
);
