import React from 'react';

interface LoadingProps {
  size: number;
  color?: string
}

export function Loading({ size, color = '' } : LoadingProps) {
  return (
    <div className={`loading-spinner ${color}`} style={{ width: size, height: size }}>
      <div style={{ width: size - 16, height: size - 16 }} />
      <div style={{ width: size - 16, height: size - 16 }} />
      <div style={{ width: size - 16, height: size - 16 }} />
      <div style={{ width: size - 16, height: size - 16 }} />
    </div>
  );
}
