import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useValidation = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'ERROR' });

  const listSchema = useMemo(() => yup.object().shape({
    price: yup.number().positive().min(0.00001, t('MIM_ETH_PRICE')).max(999, t('MAX_ETH_PRICE'))
      .required(t('ETH_PRICE_REQUIRED')),
    minimum: yup.number().positive().max(yup.ref('price'), t('MINIMUM_PRICE_MUST_LESS_OR_EQUAL_WITH_PRICE'))
      .optional(),
    tradeType: yup.string().required(),
  }), [t]);

  const listOffersOnlySchema = useMemo(() => yup.object().shape({
    minimum: yup.number().positive().min(0.00001, t('MIM_MINIMUM_ETH_PRICE')).optional(),
    tradeType: yup.string().required(),
  }), [t]);

  const tradeOfferSchema = useMemo(() => yup.object().shape({
    price: yup.number().positive().min(0.00001, t('MIM_ETH_PRICE')).max(999, t('MAX_ETH_PRICE'))
      .required(t('ETH_PRICE_REQUIRED')),
  }), [t]);

  const tradeOfferWithMinSchema = useMemo(() => yup.object().shape({
    minimum: yup.number().optional(),
    price: yup.number().positive().min(yup.ref('minimum'), t('PRICE_MUST_GATHER_OR_EQUAL_WITH_MIN_PRICE')).max(999, t('MAX_ETH_PRICE'))
      .required(t('ETH_PRICE_REQUIRED')),
  }), [t]);

  return {
    listSchema,
    listOffersOnlySchema,
    tradeOfferSchema,
    tradeOfferWithMinSchema,
  };
};
