import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedList, PaginatedRequest } from 'src/models';
import * as _ from 'lodash';

type UserState = {
  coops: any[];
  chickens: any;
  loading: boolean;
  isLoadingChickens: boolean;
};

const initialState: UserState = {
  coops: [],
  chickens: {
    rows: [],
    count: 0,
    isReached: false,
  },
  loading: false,
  isLoadingChickens: false,
};

const userSlice = createSlice({
  name: 'coop',
  initialState,
  reducers: {
    getCoopsRequest(state, action: PayloadAction<any>) {
      state.loading = true;
      state.coops = [];
    },
    getCoopsResponse(state, action: PayloadAction<any>) {
      state.coops = action.payload;
      state.loading = false;
    },
    getCoopsError(state, action: PayloadAction<any>) {
      state.coops = [];
      state.loading = false;
    },
    moveChickenToCoop(state, action: PayloadAction<{
      fromId: number,
      to: any,
      chicken: any
    }>) {
      state.loading = true;
    },
    moveChickenToCoopResponse(state, action: PayloadAction<any>) {
      state.loading = false;
      state.coops = action.payload;
    },
    moveChickenToCoopError(state, action: PayloadAction<any>) {
      state.loading = false;
    },
    changeCoopNameRequest(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    changeCoopNameResponse(state, action: PayloadAction<any>) {
      const coops = [
        ...state.coops,
      ];

      const coop = coops.find((c) => c.id === action.payload.id);
      coop.name = action.payload.name;

      state.loading = false;
      state.coops = coops;
    },
    changeCoopNameError(state, action: PayloadAction<any>) {
      state.loading = false;
    },
    getCoopChickensRequest(state, action: PayloadAction<PaginatedRequest>) {
      if (action.payload.page === 1) {
        state.chickens = {
          rows: [],
          count: 0,
          isReached: false,
        };
      }
      state.isLoadingChickens = true;
    },
    getCoopChickensResponse(state, action: PayloadAction<PaginatedList<any>>) {
      const chickens = _.uniqBy([...state.chickens.rows, ...action.payload.rows], 'id');
      const isReached = chickens.length === action.payload.count || !action.payload.rows?.length;
      state.chickens = {
        rows: chickens,
        count: action.payload.count,
        isReached,
      };
      state.isLoadingChickens = false;
    },
  },
});

export const {
  getCoopsRequest,
  getCoopsResponse,
  getCoopsError,
  moveChickenToCoop,
  moveChickenToCoopResponse,
  moveChickenToCoopError,
  changeCoopNameRequest,
  changeCoopNameResponse,
  changeCoopNameError,
  getCoopChickensRequest,
  getCoopChickensResponse,
} = userSlice.actions;

export default userSlice.reducer;
