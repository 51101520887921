import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChickenContractStats, PaginatedList, PaginatedRequest } from 'src/models';
import * as _ from 'lodash';
import { ChickenStatsRequest } from 'src/models/chicken-stats-request.type';
import { ChickenStats } from 'src/models/chicken-stats.type';
import moment from 'moment-timezone';

type ChickenState = {
  chicken: any;
  chickens: any;
  loading: boolean;
  isFetchingChickens: boolean;
  error: any;
  tradeOffers: PaginatedList<any>
  tradeActivities: PaginatedList<any>
  isFetchingTradeOffers: boolean
  isFetchingTradeActivities: boolean
  isFetchingTournamentBadges: boolean;
  isFetchingSeasonBadges: boolean;
  isFetchingGenealogy: boolean;
  tournamentBadges: any;
  seasonBadges: any
  genealogy: any[]
  overallStats: any[]
  terrainStats: any[]
  distanceStats: any[]
  isFetchingOverallStats: boolean
  isFetchingTerrainStats: boolean
  isFetchingDistanceStats: boolean
  chickenContractStats: ChickenContractStats | null;
};

const initialState: ChickenState = {
  chicken: null,
  isFetchingTradeOffers: false,
  isFetchingTradeActivities: false,
  isFetchingSeasonBadges: false,
  isFetchingTournamentBadges: false,
  isFetchingChickens: false,
  isFetchingGenealogy: false,
  isFetchingOverallStats: false,
  isFetchingTerrainStats: false,
  isFetchingDistanceStats: false,
  loading: false,
  error: null,
  genealogy: [],
  overallStats: [],
  terrainStats: [],
  distanceStats: [],
  tradeActivities: {
    rows: [],
    count: 0,
    isReached: false,
  },
  tradeOffers: {
    rows: [],
    count: 0,
    isReached: false,
  },
  tournamentBadges: {
    rows: [],
    count: 0,
    isReached: false,
  },
  seasonBadges: {
    rows: [],
    count: 0,
    isReached: false,
  },
  chickens: {
    rows: [],
    count: 0,
    isReached: false,
  },
  chickenContractStats: null,
};

const chickenSlice = createSlice({
  name: 'chicken',
  initialState,
  reducers: {
    getChickenRequest(state, action: PayloadAction<{ chickenId: string, keep?: boolean }>) {
      if (!action.payload.keep) {
        state.chicken = null;
      }
      state.tradeActivities = initialState.tradeActivities;
      state.tradeOffers = initialState.tradeOffers;
      state.tournamentBadges = initialState.tournamentBadges;
      state.seasonBadges = initialState.seasonBadges;
      state.loading = true;
      state.error = null;
    },
    getChickenResponse(state, action: PayloadAction<any>) {
      state.chicken = action.payload;
      state.loading = false;
    },
    setChickensLoadingResponse(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    clearChickenError(state) {
      state.error = null;
    },
    updateChickenNameRequest(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    updateChickenNameSuccess(state, action: PayloadAction<any>) {
      state.chicken = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateChickenNameFailure(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getChickenTradeOffersRequest(state, action: PayloadAction<PaginatedRequest>) {
      if (action.payload.page === 1) {
        state.tradeOffers = {
          rows: [],
          count: 0,
          isReached: false,
        };
      }
      state.isFetchingTradeOffers = true;
    },
    getChickenTradeOffersResponse(state, action: PayloadAction<PaginatedList<any>>) {
      const offers = _.uniqBy([...state.tradeOffers.rows, ...action.payload.rows], 'id');
      const isReached = !action.payload.rows?.length;
      state.tradeOffers = {
        rows: offers,
        count: action.payload.count,
        isReached,
      };
      state.isFetchingTradeOffers = false;
    },
    getChickenTradeActivitiesRequest(state, action: PayloadAction<PaginatedRequest>) {
      if (action.payload.page === 1) {
        state.tradeActivities = {
          rows: [],
          count: 0,
          isReached: false,
        };
      }
      state.isFetchingTradeActivities = true;
    },
    getChickenTradeActivitiesResponse(state, action: PayloadAction<PaginatedList<any>>) {
      const tradeActivities = _.uniqBy([...state.tradeActivities.rows, ...action.payload.rows], 'id');
      const isReached = !action.payload.rows?.length;
      state.tradeActivities = {
        rows: tradeActivities,
        count: action.payload.count,
        isReached,
      };
      state.isFetchingTradeActivities = false;
    },
    getChickenTournamentBadgesRequest(state, action: PayloadAction<PaginatedRequest>) {
      if (action.payload.page === 1) {
        state.tournamentBadges = {
          rows: [],
          count: 0,
          isReached: false,
        };
      }
      state.isFetchingTournamentBadges = true;
    },
    getChickenTournamentBadgesResponse(state, action: PayloadAction<PaginatedList<any>>) {
      const badges = _.uniqBy([...state.tournamentBadges.rows, ...action.payload.rows], 'id');
      const isReached = !action.payload.rows?.length;

      state.tournamentBadges = {
        rows: badges,
        count: action.payload.count,
        isReached,
      };
      state.isFetchingTournamentBadges = false;
    },
    getChickenSeasonBadgesRequest(state, action: PayloadAction<PaginatedRequest>) {
      if (action.payload.page === 1) {
        state.seasonBadges = {
          rows: [],
          count: 0,
          isReached: false,
        };
      }
      state.isFetchingSeasonBadges = true;
    },
    getChickenSeasonBadgesResponse(state, action: PayloadAction<PaginatedList<any>>) {
      const badges = _.uniqBy([...state.seasonBadges.rows, ...action.payload.rows], 'id');
      const isReached = !action.payload.rows?.length;
      state.seasonBadges = {
        rows: badges,
        count: action.payload.count,
        isReached,
      };
      state.isFetchingSeasonBadges = false;
    },
    getChickensRequest(state, action: PayloadAction<PaginatedRequest>) {
      if (action.payload.page === 1) {
        state.chickens = {
          rows: [],
          count: 0,
          isReached: false,
        };
      }
      state.isFetchingChickens = true;
    },
    getChickensResponse(state, action: PayloadAction<PaginatedList<any>>) {
      const chickens = _.uniqBy([...state.chickens.rows, ...action.payload.rows], 'id').map((chicken) => ({
        ...chicken,
        resetSituationAt: chicken?.resetSituationSeconds ? moment().add(chicken?.resetSituationSeconds, 'seconds').toDate() : null,
      }));
      const isReached = chickens.length === action.payload.count || !action.payload.rows?.length;
      state.chickens = {
        rows: chickens,
        count: action.payload.count,
        isReached,
      };
      state.isFetchingChickens = false;
    },
    getChickenGenealogyRequest(state, action: PayloadAction<number>) {
      state.genealogy = [];
      state.isFetchingGenealogy = true;
    },
    getChickenGenealogyResponse(state, action: PayloadAction<any[]>) {
      state.genealogy = action.payload;
      state.isFetchingGenealogy = false;
    },
    getChickenOverallStatsRequest(state, action: PayloadAction<ChickenStatsRequest>) {
      state.overallStats = [];
      state.isFetchingOverallStats = true;
    },
    getChickenOverallStatsResponse(state, action: PayloadAction<ChickenStats[]>) {
      state.overallStats = action.payload;
      state.isFetchingOverallStats = false;
    },
    getChickenTerrainStatsRequest(state, action: PayloadAction<ChickenStatsRequest>) {
      state.terrainStats = [];
      state.isFetchingTerrainStats = true;
    },
    getChickenTerrainStatsResponse(state, action: PayloadAction<ChickenStats[]>) {
      state.terrainStats = action.payload;
      state.isFetchingTerrainStats = false;
    },
    getChickenDistanceStatsRequest(state, action: PayloadAction<ChickenStatsRequest>) {
      state.distanceStats = [];
      state.isFetchingDistanceStats = true;
    },
    getChickenDistanceStatsResponse(state, action: PayloadAction<ChickenStats[]>) {
      state.distanceStats = action.payload;
      state.isFetchingDistanceStats = false;
    },
    getChickenContractStatsRequest(state, action: PayloadAction<void>) {
      state.chickenContractStats = null;
      state.loading = true;
    },
    getChickenContractStatsResponse(state, action: PayloadAction<ChickenContractStats>) {
      state.chickenContractStats = action.payload;
      state.loading = false;
    },
  },
});

export const {
  setChickensLoadingResponse,
  getChickenRequest,
  getChickenResponse,
  clearChickenError,
  updateChickenNameRequest,
  updateChickenNameSuccess,
  updateChickenNameFailure,
  getChickenTradeOffersRequest,
  getChickenTradeOffersResponse,
  getChickenTradeActivitiesRequest,
  getChickenTradeActivitiesResponse,
  getChickenTournamentBadgesRequest,
  getChickenTournamentBadgesResponse,
  getChickenSeasonBadgesRequest,
  getChickenSeasonBadgesResponse,
  getChickensRequest,
  getChickensResponse,
  getChickenGenealogyRequest,
  getChickenGenealogyResponse,
  getChickenOverallStatsRequest,
  getChickenOverallStatsResponse,
  getChickenTerrainStatsRequest,
  getChickenTerrainStatsResponse,
  getChickenDistanceStatsRequest,
  getChickenDistanceStatsResponse,
  getChickenContractStatsRequest,
  getChickenContractStatsResponse,
} = chickenSlice.actions;

export default chickenSlice.reducer;
