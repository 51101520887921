import { POLYGON_USDC_CONTRACT } from 'src/abis/polygonUsdcContract';
import { ChainId } from 'src/constant/chainId';
import ContractService from './baseContractService';
import { TokenContractService } from './tokenContractService';

export class PolygonUsdcContractService extends TokenContractService {
  constructor() {
    super(ChainId.Polygon, POLYGON_USDC_CONTRACT);

    ContractService.add(this);
  }
}
