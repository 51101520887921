import { Theme } from 'src/models';

export const themeColorScheme: Theme = {
  light: {
    table: 'rgba(255, 255, 255, 0.7)',
    tableStriped: 'rgba(0,0,0,.05)',
    tableRowOdd: '#d8e6ee',
    tableRowEven: '#e5f2fb',
    tableBorder: '#dee2e6',
    subTable: '#FFF6F6',
    subTableHeader: 'rgb(253 227 227)',
    box: 'rgba(32, 95, 188, 0.66)',
    boxLine: '#FFD600',
    text: 'black',
    textLight: '#2b2b2b',
    textBlue: '#2b8df5',
    textBlueLight: '#599EF8',
    navBg: '#DBEDFF',
    navSubBg: '#728EAC',
    navText: '#182F44',
    background: 'transparent',
    modal: 'white',
    modalTitle: '#363636',
    label: '#666666',
    input: 'white',
    loading: '#f5f5f5',
    select: '#e6e6e6',
    placeholder: '#6c757d',
    hoverBlue: '#4da1fb',
    hoverDanger: '#da6b6c',
    hoverYellow: '#FFE500',
    hoverGreen: '#5bc889',
    homeBg: 'rgba(255, 255, 255, 0.9)',
    classBg: '#2B2B2B',
    tournament: '#E9CCFF',
    tag: '#F2F2F2',
    marketplaceBg: '#bcdff3',
    fusedChickenBg: '#e8ffe8',
    materialChickenBg: '#ffeff2',
    boxShadow: 'rgba(0,0,0,0.5)',
    genealogyLine: '#21e421',
    // coin mint
    coinMintBorder: '1px solid rgba(0, 0, 0, 0.10)',
    coinMintTopBg: '#FFF',
    coinMintProgressBorder: '2px solid #628500',
    coinMintProgressBottomColor: '#6E9500',
    coinMintSwitchActive: '#599EF8',
    coinMintBottomBg: '#EEF5FE',
    lineGradient1: '#DAEBF6',
    lineGradient2: '#FDFEFF',
    // track ownership
    trackOwnershipBg: '#E3F1FF',
    tableBtn: '#C0E1FF',
    trackOwnershipTableFooterBorderColor: '#1283FF',
    trackOwnershipIconBg: '#D6EAFF',
    trackOwnershipMobileHeader: '#459EFF',
    // race tracks
    raceTrackTableRowEvenBg: '#DBEDFF',
    raceTrackTableRowOddBg: '#E3F1FF',

    // bawk staking
    chickenStakingBgSecondary: '#D6EAFF',
    chickenStakingTitle: '#1283FF',
    chickenStakingSwitchActive: '#1283FF',
    chickenStakingDivider: '#1283FF',

    // New Variables
    bgPrimary: '#E3F1FF',
    raceBg: '#DBEDFF',
    raceBgHover: '#DBEDFF',
    raceBgDark: '#D3E9FF',
    primary: '#1283FF',
    raceText: '#003977',
    raceTextLight: '#003977',
    raceTextGray: '#728EAC',
    raceLine: '#A0CEFF',
    presaleDashboardCopyIcon: '#000000',
    raceTrackImgGradient: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #162431 119.71%),
    linear-gradient(90deg, rgba(22, 36, 49, 0.5) 0%, rgba(0, 0, 0, 0) 175.27%)
    `,
    raceDetailsText: '#003977',
    miniScroll: '#9AB2CA',
    chickenProfileBargraphBg: '#D3E9FF',
    chickenCardCompBorder: '#A0CEFF',
    // jewels
    jewelInputBg: '#D6EAFF',
    jewelBg: '#E3F1FF',
    checkoutModalBg: '#D6EAFF',
    completeCheckoutBorder: '#1283FF',
    jewelDividerBg: '#A0CEFF',
    dailyPassTextColor: '#003977',
    packingOrderTextColor: '#527DAA',
    packingButtonBg: '#C1DFFF',
    dailyPassBg: '#DBEDFF',
    racesTabBg: '#DBEDFF',

    waitlistCardBg: '#C0E1FF',
    waitlistCardBoxShadow: '#A0CEFF33',
    communitySaleCardShadow: '#1283FF4D',
  },
  dark: {
    table: '#162431',
    tableStriped: 'rgba(255,255,255,.05)',
    tableRowOdd: '#3a4751',
    tableRowEven: '#2f3b46',
    tableBorder: '#55616b',
    subTable: 'rgba(42, 53, 63,.5)',
    subTableHeader: 'rgba(42, 53, 63,0.8)',
    box: 'rgba(42, 53, 63, 0.8)',
    boxLine: '#656e77',
    text: 'white',
    textLight: 'white',
    textBlue: 'white',
    textBlueLight: 'white',
    navBg: '#162431',
    navSubBg: '#182F44',
    navText: '#FFF',
    background: '#11202ECC',
    modal: '#162431',
    modalTitle: 'white',
    label: 'white',
    input: 'rgba(36, 46, 56, 1)',
    loading: '#33414e',
    select: '#425467',
    placeholder: '#6c757d',
    hoverBlue: '#4da1fb',
    hoverDanger: '#da6b6c',
    hoverYellow: '#FFE500',
    hoverGreen: '#5bc889',
    homeBg: 'rgba(42, 53, 63, 0.9)',
    classBg: '#5c5b5b',
    tournament: '#1c232a',
    tag: '#2B2B2B',
    marketplaceBg: '#37434d',
    fusedChickenBg: 'rgba(36, 46, 56, 1)',
    materialChickenBg: 'rgba(42, 53, 63, 0.8)',
    boxShadow: 'rgba(255,255,255,0.5)',
    genealogyLine: '#a8a8a8',
    // coin mint
    coinMintBorder: '1px solid rgba(0, 0, 0, 0.50)',
    coinMintTopBg: '#414D59',
    coinMintProgressBorder: '2px solid #303A44',
    coinMintProgressBottomColor: '#97CD00',
    coinMintSwitchActive: '#29323C',
    coinMintBottomBg: '#29323B',
    lineGradient1: '#232e38',
    lineGradient2: '#414d59',
    // track ownership
    trackOwnershipBg: '#11202E',
    tableBtn: '#2A353F',
    trackOwnershipTableFooterBorderColor: '#003977',
    trackOwnershipIconBg: '#2A353F',
    trackOwnershipMobileHeader: '#EEEEEE',
    // race tracks
    raceTrackTableRowEvenBg: '#162431',
    raceTrackTableRowOddBg: '#11202E',

    // bawk staking
    chickenStakingBgSecondary: '#162431',
    chickenStakingTitle: 'white',
    chickenStakingSwitchActive: '#1283FF',
    chickenStakingDivider: '#162431',

    // new variables
    bgPrimary: '#11202E',
    raceBg: '#162431',
    raceBgHover: '#1C3144',
    raceBgDark: '#11202E',
    primary: '#459EFF',
    raceText: '#FFF',
    raceTextGray: '#728EAC',
    raceTextLight: '#D6EAFF',
    raceLine: '#2A353F',
    presaleDashboardCopyIcon: '#EEEEEE',
    raceTrackImgGradient: 'linear-gradient(90deg, #11202E 0%, rgba(0, 0, 0, 0) 175.27%)',
    raceDetailsText: '#FFFFFF',
    miniScroll: '#58636D',
    chickenProfileBargraphBg: '#11202E',
    chickenCardCompBorder: '#42576D',
    // jewels
    jewelInputBg: '#11202E',
    jewelBg: '#121D27',
    checkoutModalBg: '#162431',
    completeCheckoutBorder: '#42576D',
    jewelDividerBg: '#1E3C58',
    dailyPassTextColor: '#FFFFFF',
    packingOrderTextColor: '#D6EAFF',
    packingButtonBg: '#1E3C58',
    dailyPassBg: '#162431',
    racesTabBg: '#162431',
    waitlistCardBg: '#22394E',
    waitlistCardBoxShadow: '#A0CEFF33',
    communitySaleCardShadow: '#A0CEFF33',
  },
};
