import { createSelector } from 'reselect';
import { RootState } from '../common/reducer';

export const selectClothingState = (rootState: RootState) => rootState.clothing;

export const clothingLoadingSelector = createSelector([selectClothingState], (state) => state.loading || false);
export const clothingsSelector = createSelector([selectClothingState], (state) => state.clothings?.rows || []);
export const clothingsReachedSelector = createSelector([selectClothingState], (state) => state.clothings?.isReached || false);
export const clothingSettingLoadingSelector = createSelector([selectClothingState], (state) => state.isSettingClothing || false);
export const clothingCountSelector = createSelector([selectClothingState], (state) => state.clothings?.count || 0);
export const clothingsTotalSelector = createSelector([selectClothingState], (state) => state.clothings?.totalCount || 0);
export const clothingSetsSelector = createSelector([selectClothingState], (state) => state.clothingSets || []);
export const clothingChickensSelector = createSelector([selectClothingState], (state) => state.chickens?.rows || []);
export const clothingChickensCountSelector = createSelector([selectClothingState], (state) => state.chickens?.count || 0);
export const clothingChickensReachedSelector = createSelector([selectClothingState], (state) => state.chickens?.isReached || false);
export const clothingChickensLoadingSelector = createSelector([selectClothingState], (state) => state.isChickensLoading || false);
