import * as WalletActions from '../userWallet/action';
import * as RacesActions from '../races/action';
import * as ChickenActions from '../chicken/action';
import * as LaneActions from '../lane/action';
import * as ExchangeActions from '../exchange/action';
import * as PreRegisterLandActions from '../pre-register-land/action';
import * as CoinPreSaleActions from '../coin-presale/action';

export {
  WalletActions,
  RacesActions,
  ChickenActions,
  LaneActions,
  ExchangeActions,
  PreRegisterLandActions,
  CoinPreSaleActions,
};
