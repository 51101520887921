import React from 'react';
import { useTranslation } from 'react-i18next';

export function ChickenFilters({ filter, clearFilter }: { filter: any, clearFilter: (value?: string) => void }) {
  const { t } = useTranslation('translation', { keyPrefix: 'COMPONENTS.CHICKEN_FILTERS' });
  return (
    <div className="row mt-3">
      <div className="col-sm-12 filter-btns">
        {filter?.name && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('NAME')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.name}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('name')}
              />
            </button>
          </div>
        )}
        {filter?.peckingOrder?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('PECKING_ORDER')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.peckingOrder.join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('peckingOrder')}
              />
            </button>
          </div>
        )}
        {filter?.heritage?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('HERITAGE')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.heritage

                  .join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('heritage')}
              />
            </button>
          </div>
        )}
        {filter?.talent?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('TALENT')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.talent.join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('talent')}
              />
            </button>
          </div>
        )}
        {filter?.terrainPreference?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              Terrain
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.terrainPreference.join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('terrainPreference')}
              />
            </button>
          </div>
        )}
        {filter?.distancePreference?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              Distance
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.distancePreference.join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('distancePreference')}
              />
            </button>
          </div>
        )}
        {filter?.talentPreference?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              Talent Pref
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.talentPreference.join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('talentPreference')}
              />
            </button>
          </div>
        )}
        {filter?.stock?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('STOCK')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.stock.join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('stock')}
              />
            </button>
          </div>
        )}
        {filter?.listed?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('LISTED')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.listed.join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('listed')}
              />
            </button>
          </div>
        )}
        {filter?.gender?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('GENDER')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.gender.join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('gender')}
              />
            </button>
          </div>
        )}
        {filter?.baseBody?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('COLOR')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.baseBody.join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('baseBody')}
              />
            </button>
          </div>
        )}
        {filter?.combColor?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('COMB')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.combColor.join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('combColor')}
              />
            </button>
          </div>
        )}
        {filter?.wattleColor?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('WATTLE')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.wattleColor.join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('wattleColor')}
              />
            </button>
          </div>
        )}
        {filter?.beakColor?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('BEAK')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.beakColor.join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('beakColor')}
              />
            </button>
          </div>
        )}
        {filter?.beakAccessory?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('ACCESSORY')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.beakAccessory

                  .join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('beakAccessory')}
              />
            </button>
          </div>
        )}
        {filter?.eyesType?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('EYE')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.eyesType.join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('eyesType')}
              />
            </button>
          </div>
        )}
        {filter?.background?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('BACKGROUND')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.background

                  .join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('background')}
              />
            </button>
          </div>
        )}
        {filter?.stripes?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('STRIPE')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.stripes.join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('stripes')}
              />
            </button>
          </div>
        )}
        {filter?.perfectionMin && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('PERFECTION')}
              :(
              <span className="text-dark">{t('MIN')}</span>
              ):
              {' '}
              <span className="selected-text">
                {filter?.perfectionMin}
                %
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('perfectionMin')}
              />
            </button>
          </div>
        )}
        {filter?.perfectionMax && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('PERFECTION')}
              :(
              <span className="text-dark">{t('MAX')}</span>
              ):
              {' '}
              <span className="selected-text">
                {filter?.perfectionMax}
                %
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('perfectionMax')}
              />
            </button>
          </div>
        )}
        {filter?.racesMin && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('RACES')}
              :(
              <span className="text-dark">{t('MIN')}</span>
              ):
              {' '}
              <span className="selected-text">{filter?.racesMin}</span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('racesMin')}
              />
            </button>
          </div>
        )}
        {filter?.racesMax && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('RACES')}
              :(
              <span className="text-dark">{t('MAX')}</span>
              ):
              {' '}
              <span className="selected-text">{filter?.racesMax}</span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('racesMax')}
              />
            </button>
          </div>
        )}
        {filter?.placedMin && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('PLACED')}
              :(
              <span className="text-dark">{t('MIN')}</span>
              ):
              {' '}
              <span className="selected-text">{filter?.placedMin}</span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('placedMin')}
              />
            </button>
          </div>
        )}
        {filter?.placedMax && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('PLACED')}
              :(
              <span className="text-dark">{t('MAX')}</span>
              ):
              {' '}
              <span className="selected-text">{filter?.placedMax}</span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('placedMax')}
              />
            </button>
          </div>
        )}
        {filter?.earningsMin && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('WINNINGS')}
              :(
              <span className="text-dark">{t('MIN')}</span>
              ):
              {' '}
              <span className="selected-text">
                {filter?.earningsMin}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('earningsMin')}
              />
            </button>
          </div>
        )}
        {filter?.earningsMax && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('WINNINGS')}
              :(
              <span className="text-dark">{t('MAX')}</span>
              ):
              {' '}
              <span className="selected-text">
                {filter?.earningsMax}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('earningsMax')}
              />
            </button>
          </div>
        )}
        {filter?.legs?.length > 0 && (
          <div className="buttons d-inline-block">
            <button className="btn" type="button">
              {' '}
              {t('LEGS')}
              {': '}
              <span className="selected-text">
                {' '}
                {filter?.legs.join(', ')}
                {' '}
              </span>
              {' '}
              <i
                className="fa fa-times"
                onClick={() => clearFilter('legs')}
              />
            </button>
          </div>
        )}
        {(filter?.name
          || filter?.peckingOrder?.length
          || filter?.heritage?.length
          || filter?.talent?.length
          || filter?.terrainPreference?.length
          || filter?.distancePreference?.length
          || filter?.talentPreference?.length
          || filter?.stock?.length
          || filter?.gender?.length
          || filter?.baseBody?.length
          || filter?.combColor?.length
          || filter?.wattleColor?.length
          || filter?.beakColor?.length
          || filter?.beakAccessory?.length
          || filter?.eyesType?.length
          || filter?.background?.length
          || filter?.listed?.length
          || filter?.stripes?.length
          || filter?.perfectionMin
          || filter?.perfectionMax
          || filter?.racesMin
          || filter?.racesMax
          || filter?.placedMin
          || filter?.placedMax
          || filter?.earningsMin
          || filter?.earningsMax
          || filter?.legs) && (
            <div
              className="buttons d-inline-block"
              style={{ verticalAlign: 'top', marginTop: '11px' }}
            >
              <div
                className="clear-all"
                onClick={(e) => {
                  e.preventDefault();
                  clearFilter();
                }}
              >
                {t('CLEAR_ALL')}
              </div>
            </div>
        )}
      </div>
    </div>
  );
}
