/* eslint-disable class-methods-use-this */
import Web3 from 'web3';
import { Emitter } from 'mitt';
import Client from '@walletconnect/sign-client';
import { getAppMetadata, getSdkError } from '@walletconnect/utils';
import {
  DEFAULT_APP_METADATA,
  DEFAULT_CHAINS,
  DEFAULT_EIP155_METHODS,
  DEFAULT_LOGGER,
  DEFAULT_PROJECT_ID,
  DEFAULT_RELAY_URL,
} from 'src/constant/walletconnect';
import {
  getRequiredNamespaces,
} from 'src/utils/walletconnect';
import { Web3Modal } from '@web3modal/standalone';
import { SessionTypes } from '@walletconnect/types';
import { ChainId } from 'src/constant/chainId';
import { uniq } from 'lodash';
import { ProviderEvents, ProviderEventsEnum } from './events';
import { IProvider } from './provider.interface';
import { ProviderName } from './provider.enum';

const web3Modal = new Web3Modal({
  projectId: DEFAULT_PROJECT_ID,
  themeMode: 'light',
  walletConnectVersion: 2,
});

export class WalletconnectProvider implements IProvider {
  name = ProviderName.walletconnect;

  web3: Web3;

  session: SessionTypes.Struct;

  constructor(
    private client: Client,
    private events: Emitter<ProviderEvents>,
  ) {
    if (client.session.length) {
      const lastKeyIndex = client.session.keys.length - 1;
      this.session = client.session.get(
        client.session.keys[lastKeyIndex],
      );
    }

    this.subscribeToEvents();
  }

  get allNamespaceAccounts() {
    if (!this.session) {
      return [];
    }

    return Object.values(this.session.namespaces)
      .map((namespace) => namespace.accounts)
      .flat()
      .map((item) => {
        const [network, chainId, address] = item.split(':');

        return [network, chainId, Web3.utils.toChecksumAddress(address)].join(':');
      });
  }

  get accounts() {
    return uniq(this.allNamespaceAccounts.map((account) => account.split(':').pop())).map((address) => Web3.utils.toChecksumAddress(address));
  }

  get chains() {
    if (!this.session) {
      return [];
    }

    return Object.keys(this.session.namespaces);
  }

  static async createInstance(
    events: Emitter<ProviderEvents>,
  ): Promise<IProvider> {
    const claimedOrigin = 'unknown';
    const client = await Client.init({
      logger: DEFAULT_LOGGER,
      relayUrl: DEFAULT_RELAY_URL,
      projectId: DEFAULT_PROJECT_ID,
      metadata: {
        ...(getAppMetadata() || DEFAULT_APP_METADATA),
        url: claimedOrigin,
        verifyUrl:
          claimedOrigin === 'unknown'
            ? 'http://non-existent-url'
            : DEFAULT_APP_METADATA.verifyUrl, // simulates `UNKNOWN` verify context
      },
    });

    return new WalletconnectProvider(client, events);
  }

  private getChainId(chainNumber: ChainId) {
    return `eip155:${chainNumber}`;
  }

  private validateAccount(chainNumber: ChainId, account: string) {
    if (!this.session) {
      throw new Error("You didn't connected yet");
    }

    const address = Web3.utils.toChecksumAddress(account);

    if (!this.accounts.includes(address)) {
      throw new Error('Invalid wallet address');
    }

    if (
      !this.allNamespaceAccounts.includes(
        `${this.getChainId(chainNumber)}:${address}`,
      )
    ) {
      throw new Error(`Please connect ${ChainId[chainNumber]} network`);
    }
  }

  private subscribeToEvents() {
    if (!this.client) {
      return;
    }

    // this.client.on('session_ping', (args) => {
    // });

    // this.client.on('session_event', (args) => {
    // });

    this.client.on('session_update', ({ topic, params }) => {
      const { namespaces } = params;
      const session = this.client.session.get(topic);
      const updatedSession = { ...session, namespaces };
      this.session = updatedSession;
      this.events.emit(ProviderEventsEnum.AccountsChanged, this.accounts);
    });

    this.client.on('session_delete', () => {
      this.session = null;
      this.events.emit(ProviderEventsEnum.AccountsChanged, this.accounts);
    });
  }

  async getAccounts(): Promise<string[]> {
    return this.accounts;
  }

  async login(): Promise<string[]> {
    const chains = DEFAULT_CHAINS;
    try {
      // if (this.session) {
      //   return this.accounts;
      // }

      const requiredNamespaces = getRequiredNamespaces(chains);
      const { uri, approval } = await this.client.connect({
        requiredNamespaces,
      });

      // Open QRCode modal if a URI was returned (i.e. we're not connecting an existing pairing).
      if (uri) {
        // Create a flat array of all requested chains across namespaces.
        const standaloneChains = Object.values(requiredNamespaces)
          .map((namespace) => namespace.chains)
          .flat() as string[];

        web3Modal.openModal({ uri, standaloneChains });
      }

      this.session = await approval();

      return this.accounts;
    } catch (err) {
      throw err;
    } finally {
      web3Modal.closeModal();
    }
  }

  async logout() {
    if (!this.session) {
      return;
    }

    await this.client.disconnect({
      topic: this.session.topic,
      reason: getSdkError('USER_DISCONNECTED'),
    });
  }

  async sign(chainId: ChainId, account: string, message: string) {
    await this.validateAccount(chainId, account);

    return this.client.request<string>({
      topic: this.session.topic,
      chainId: this.getChainId(chainId),
      request: {
        method: DEFAULT_EIP155_METHODS.PERSONAL_SIGN,
        params: [message, account],
      },
    });
  }

  async signTypedDataV4(chainId: ChainId, account: string, message: string) {
    await this.validateAccount(chainId, account);

    return this.client.request<string>({
      topic: this.session.topic,
      chainId: this.getChainId(chainId),
      request: {
        method: DEFAULT_EIP155_METHODS.ETH_SIGN_TYPED_DATA_V4,
        params: [account, message],
      },
    });
  }

  async sendTransaction(chainId: ChainId, account: string, tx: any) {
    await this.validateAccount(chainId, account);

    return this.client.request<string>({
      topic: this.session.topic,
      chainId: this.getChainId(chainId),
      request: {
        method: DEFAULT_EIP155_METHODS.ETH_SEND_TRANSACTION,
        params: [tx],
      },
    });
  }
}
