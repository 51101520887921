import { createSelector } from 'reselect';
import { RootState } from '../common/reducer';

export const selectFusionState = (rootState: RootState) => rootState.serum;

export const isMintedSerumSelector = createSelector([selectFusionState], (state) => state.isMinted);
export const isMintingSerumSelector = createSelector([selectFusionState], (state) => state.isMinting);
export const isCheckingSerumMintSelector = createSelector([selectFusionState], (state) => state.isCheckingSerumMint);
export const isCheckedSerumSelector = createSelector([selectFusionState], (state) => state.isCheckedSerumMint);
export const serumMintGasLimitSelector = createSelector([selectFusionState], (state) => state.gasLimit);
export const serumMintInfo = createSelector([selectFusionState], (state) => state.serumMint);
