import React from 'react';
import BasicAuthProvider from './basicAuth';
import ChickenProvider from './chicken';
import EnterRaceProvider from './enterRace';
import BawkStakingProvider from './bawk-staking';
import LanguageProvider from './language';
import MarketplaceProvider from './marketplace';
import WalletProvider from './walletProvider';
import ThemeProvider from './theme';
import CurrencyProvider from './currency';
import ChickenStatsFilterProvider from './statsFilter';
import RaceFilterProvider from './raceFilter';

export function ContextProvider(props: {
  children: React.ReactNode;
}) {
  return (
    <CurrencyProvider>
      <ThemeProvider>
        <LanguageProvider>
          <BasicAuthProvider>
            <WalletProvider>
              <MarketplaceProvider>
                <EnterRaceProvider>
                  <BawkStakingProvider>
                    <ChickenProvider>
                      <ChickenStatsFilterProvider>
                        <RaceFilterProvider>
                          {props.children}
                        </RaceFilterProvider>
                      </ChickenStatsFilterProvider>
                    </ChickenProvider>
                  </BawkStakingProvider>
                </EnterRaceProvider>
              </MarketplaceProvider>
            </WalletProvider>
          </BasicAuthProvider>
        </LanguageProvider>
      </ThemeProvider>
    </CurrencyProvider>
  );
}
