import { IContract } from 'src/models';
import { JEWELS_CONTRACT_ADDRESS } from 'src/utils/config';

export const JEWELS_CONTRACT: IContract = {
  address: JEWELS_CONTRACT_ADDRESS,
  abi: [{ inputs: [], stateMutability: 'nonpayable', type: 'constructor' }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'previousAdmin', type: 'address',
    }, {
      indexed: false, internalType: 'address', name: 'newAdmin', type: 'address',
    }],
    name: 'AdminChanged',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'owner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'spender', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
    }],
    name: 'Approval',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'beacon', type: 'address',
    }],
    name: 'BeaconUpgraded',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'uint8', name: 'version', type: 'uint8',
    }],
    name: 'Initialized',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferStarted',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'from', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'to', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
    }],
    name: 'Transfer',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'implementation', type: 'address',
    }],
    name: 'Upgraded',
    type: 'event',
  }, {
    inputs: [], name: 'acceptOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'owner', type: 'address' }, { internalType: 'address', name: 'spender', type: 'address' }], name: 'allowance', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'approve', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }], name: 'balanceOf', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'burn', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'calcUsdcAmount', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'pure', type: 'function',
  }, {
    inputs: [], name: 'decimals', outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' }], name: 'decreaseAllowance', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, { internalType: 'uint256', name: 'addedValue', type: 'uint256' }], name: 'increaseAllowance', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_usdc', type: 'address' }, { internalType: 'string', name: 'name', type: 'string' }, { internalType: 'string', name: 'symbol', type: 'string' }], name: 'initialize', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'forwarder', type: 'address' }], name: 'isTrustedForwarder', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_account', type: 'address' }, { internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'mint', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'pendingOwner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'proxiableUUID', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_forwarder', type: 'address' }], name: 'setTrustedForwarder', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_usdc', type: 'address' }], name: 'setUsdc', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'symbol', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'totalSupply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'transfer', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'transferFrom', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'trustedForwarder', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newImplementation', type: 'address' }], name: 'upgradeTo', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newImplementation', type: 'address' }, { internalType: 'bytes', name: 'data', type: 'bytes' }], name: 'upgradeToAndCall', outputs: [], stateMutability: 'payable', type: 'function',
  }, {
    inputs: [], name: 'usdc', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'versionRecipient', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'pure', type: 'function',
  }],
};
