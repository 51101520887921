import { takeLatest, call, put } from 'redux-saga/effects';
import { apiService } from 'src/services/apiService';
import { toastService } from 'src/services/toastService';
import {
  getSeasonFailed, getSeasonRankingsFailed, getSeasonRankingsRequest,
  getSeasonRankingsSuccess, getSeasonRequest, getSeasonsFailed, getSeasonsRequest, getSeasonsSuccess, getSeasonSuccess,
} from './reducer';

export function* getSeason({ payload }: any) {
  try {
    const res = yield call(apiService.getSeason, payload);
    yield put(getSeasonSuccess(res.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getSeasonFailed(err));
  }
}

export function* getSeasons({ payload }: any) {
  try {
    const res = yield call(
      apiService.getSeasons,
      payload,
    );
    yield put(getSeasonsSuccess(res?.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getSeasonsFailed(err));
  }
}

export function* getSeasonRankings({ payload }: any) {
  try {
    const res = yield call(
      apiService.getSeasonRankings,
      payload,
    );
    yield put(getSeasonRankingsSuccess(res?.data));
  } catch (err) {
    toastService.send(err?.data?.message || err.message, 'error');
    yield put(getSeasonRankingsFailed(err));
  }
}

export function* userEffects() {
  yield takeLatest(getSeasonRequest, getSeason);
  yield takeLatest(getSeasonsRequest, getSeasons);
  yield takeLatest(getSeasonRankingsRequest, getSeasonRankings);
}

const tournamentSagas = [call(userEffects)];

export default tournamentSagas;
