import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createInjectorsEnhancer } from 'redux-injectors';

import { IS_LOCAL_ENV } from 'src/utils/config';
import createReducer from './reducer';
import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const { run: runSaga } = sagaMiddleware;

const enhancers = [
  createInjectorsEnhancer({
    createReducer,
    runSaga,
  }),
];

const AllRootSaga = (preloadedState = {}) => {
  const store = configureStore({
    reducer: createReducer(),
    middleware,
    preloadedState,
    enhancers,
    devTools: IS_LOCAL_ENV,
  });
  sagaMiddleware.run(rootSaga);
  return store;
};

export const store = AllRootSaga();
